import React, { useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'

import ToolbarContent from 'components/ToolbarContent'
import ContentHeader from 'components/ContentHeader'
import withUserData from 'components/withUserData'
import { billingStatusVal } from 'services/constantVariables/billing'
import { joinPath } from 'services/utilityFunctions'
import StartTrial from 'scenes/Billing/components/StartTrial'
import { useTrialInfo, prettyDate } from 'scenes/Billing/components/TrialInfo'
import TrialBanner from './components/TrialBanner'
import PublicWaterSystems from './components/PublicWaterSystems'

const styles = theme => ({
  heading: {
    marginBottom: theme.spacing.unit * 2,
  }
})

function Reports(props) {
  const {
    history,
    match,
    userContext,
  } = props

  const billingStatus = userContext.userData.organization.billingStatus
  const { trial, endDate } = useTrialInfo(userContext.userData.organizationId, billingStatus)

  const [pwsNotFound, setPwsNotFound] = useState(true)

  return (
    <>
      <ToolbarContent
        screenTitle = "Reports"
        isMainScreen = {true}
      />
      {(() => {
        switch (billingStatus) {
          case billingStatusVal.NEW:
            return <StartTrial />
          case billingStatusVal.TRIAL_ACTIVE:
          case billingStatusVal.TRIAL_OVER:
          case billingStatusVal.SUBSCRIBED:
            return (
              <>
                {billingStatus === billingStatusVal.TRIAL_ACTIVE ? (
                  <TrialBanner trial={trial} endDate={endDate ? prettyDate(endDate) : null} />
                ) : null}
                {!pwsNotFound ? (
                  <ContentHeader
                    breadcrumbs={[
                      { name: 'Reports' } 
                    ]}
                    text={pwsNotFound ? null : 'Select a PWS to generate a report'}
                  />
                ) : null}
                <PublicWaterSystems 
                  onItemClick={(item, type) => { 
                    if (type === "UNSUBSCRIBED") {
                      history.push({
                        pathname: 'billing/subscriptions'
                      })
                    } else if (type === "SUBSCRIBED") {
                      history.push({
                        pathname: joinPath(match.url, `date-range-select/${item.id}`),
                        state: { pws: item }
                      })
                    } else {
                      return
                    }
                  }}
                  notFoundMessage="No Water System found. To record event data you must create a Water System first."
                  setNotFound={setPwsNotFound}
                  trialActive={billingStatus === billingStatusVal.TRIAL_ACTIVE}
                />
              </>
            )
          default:
            return <CircularProgress />
        }
      })()}
    </>
  )
}

export default compose(
  withStyles(styles),
  withUserData
)(Reports)