import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import { API, graphqlOperation } from 'aws-amplify'

import * as mutations from 'graphql/mutations'
import { removeEmpty } from 'services/graphqlHelpers'
import ToolbarContent from 'components/ToolbarContent'
import UserForm from '../../components/UserForm'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%'
  },
  buttonSave: {
    margin: 'auto',
    marginBottom: 16,
  },
  buttonContainer: {
    position: 'sticky',
    bottom: 0,
    paddingTop: 16,
    background: '#fff'
  }
})

const handleSave = async (fieldsUpdate, setUser, setSaveProgress, setIsUpdated, history) => {
  setUser(fieldsUpdate)
  setIsUpdated(false)
  setSaveProgress(true)
  
  let inputUpdateUser = {
    ...fieldsUpdate,
  }

  inputUpdateUser = removeEmpty(inputUpdateUser)

  try {
    await API.graphql(graphqlOperation(mutations.updateUser, { input: inputUpdateUser }))
    history.goBack()
  } catch (error) {
    console.log(error)
  }
}

function UserEdit(props) {
  const { 
    classes,
    match,
    location,
    history,
  } = props
  const userData = location.state.user

  const [user, setUser] = useState(userData)
  const [updatedUser, setUpdatedUser] = useState(userData)
  const [isUpdated, setIsUpdated] = useState(false)
  const [saveProgress, setSaveProgress] = useState(false)

  let isMounted

  useEffect(() => {
    isMounted = true

    ;(async () => {
      if (!isMounted) return
    })()

    return () => {
      isMounted = false
    }

  }, [match.params.userId])

  return (
    <>
      <ToolbarContent
        screenTitle = "Edit Contact"
        isMainScreen = {false}
      />
      <UserForm 
        user={user} 
        setUser={setUpdatedUser} 
        setIsUpdated={setIsUpdated} 
        emailDisabled={user.email ? true : false}
      />
      <Grid className={classes.buttonContainer}>
        <Button
          className={classes.buttonSave} 
          variant="contained" 
          color="primary"
          disabled={!isUpdated}
          fullWidth
          onClick={() => handleSave(
            updatedUser,
            setUser,
            setSaveProgress,
            setIsUpdated,
            history,
          )}
        > 
          {saveProgress ? <CircularProgress color="inherit" size={16} style={{ marginRight: 5 }} /> : null}
          SAVE
        </Button>
      </Grid>
    </>
  )
}

export default withStyles(styles)(UserEdit)