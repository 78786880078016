import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import { API, graphqlOperation } from 'aws-amplify'

import * as mutations from 'graphql/mutations'
import { removeEmpty } from 'services/graphqlHelpers'
import ToolbarContent from 'components/ToolbarContent'
import ConfirmDeleteDialog from 'components/ConfirmDeleteDialog'
import WaterSystemForm from '../../components/WaterSystemForm'
import SubscriptionWarning from './components/SubscriptionWarning'
import { checkTierChange } from 'services/utilityFunctions/subscription'

const styles = theme => ({
  button: {
    margin: 'auto',
    marginBottom: 16,
  },
  buttonContainer: {
    position: 'sticky',
    bottom: 0,
    paddingTop: 16,
    background: '#fff'
  },
  buttonDelete: {
    color: theme.palette.error.main,
  },
})

const handleSave = async(fieldsUpdate, setPws, setSaveProgress, history) => {
  setPws(fieldsUpdate)
  setSaveProgress(true)
  
  //take all fields except waterSources
  const { waterSources, costInputs, ...pws } = fieldsUpdate

  let inputUpdateWaterSystem = {
    ...pws,
  }
  
  inputUpdateWaterSystem = removeEmpty(inputUpdateWaterSystem)

  try {
    await API.graphql(graphqlOperation(mutations.updateWaterSystem, { input: inputUpdateWaterSystem }))
    history.goBack()
  } catch (error) {
    console.log(error)  
  }
}

const handleDelete = async (id, deleteProgress, history) => {
  deleteProgress(true)

  try {
    await API.graphql(graphqlOperation(mutations.deleteWaterSystem, { input: { id: id } }))
    history.push('/water-systems')
  } catch (error) {
    console.log(error)
  }
}

const handleCancel = async pws => {
  const inputApiAction = {
    name: 'stripeCancelSubscription',
    parameters: [
      pws.subscriptionId,
      pws.id
    ]
  }
  
  await API.graphql(graphqlOperation(mutations.createApiAction, { input: inputApiAction }))
}

function WaterSystemEdit(props) {
  const { 
    classes,
    match,
    history,
    location,
  } = props

  const [pws, setPws] = useState(location.state.pws)
  const [updatedPws, setUpdatedPws] = useState(location.state.pws)
  const [isUpdated, setIsUpdated] = useState(false)
  const [saveProgress, setSaveProgress] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [deleteProgress, setDeleteProgress] = useState(false)
  const [openSubWarning, setOpenSubWarning] = useState(false)

  useEffect(() => {
    if (confirmDelete) {
      if (pws.subscribed) {
        handleCancel(pws)  
      }

      handleDelete(pws.id, setDeleteProgress, history)
    }
  }, [match.params.id, confirmDelete])

  return (
    <>
      <ToolbarContent
        screenTitle = {`Edit ${pws.name}`}
        isMainScreen = {false}
      />
      <WaterSystemForm pws={pws} setIsUpdated={setIsUpdated} setPws={setUpdatedPws} />
      <Grid className={classes.buttonContainer}>
        <Button
          className={classes.button} 
          variant="contained" 
          color="primary"
          disabled={!isUpdated || saveProgress}
          fullWidth
          onClick={() => {
            // check change in tier if active connections is changed
            if (pws.subscribed && pws.activeConnections != updatedPws.activeConnections) {
              const isTierChanged = checkTierChange(pws.activeConnections, updatedPws.activeConnections)

              if (isTierChanged) {
                // show warning dialog
                setOpenSubWarning(true)
                return
              }
            }
            
            handleSave(
              updatedPws,
              setPws,
              setSaveProgress,
              history,
            )
          }}
        > 
          {saveProgress ? <CircularProgress color="inherit" size={16} style={{ marginRight: 5 }} /> : null}
          SAVE
        </Button>
      </Grid>
      <SubscriptionWarning
        open={openSubWarning} 
        setOpenDialog={setOpenSubWarning} 
        subscriptionId={pws.subscriptionId}
        prevActiveConnections={pws.activeConnections}
        newActiveConnections={updatedPws.activeConnections}
        onConfirm={() => {
          handleSave(
            updatedPws,
            setPws,
            setSaveProgress,
            history,
          )
        }}
      />
      <Button
        className={`${classes.button} ${classes.buttonDelete}`}
        disabled={deleteProgress}
        fullWidth
        onClick={() => {
          setOpenDeleteDialog(true)
        }}
      > 
        {deleteProgress ? <CircularProgress color="inherit" size={16} style={{ marginRight: 5 }} /> : null}
        DELETE
      </Button>
      <ConfirmDeleteDialog 
        open={openDeleteDialog} 
        setOpenDialog={setOpenDeleteDialog} 
        setConfirm={setConfirmDelete} 
        startText={(() => {
          return 'Warning! Deleting this PWS will also remove'
          + (pws.subscribed ? ' its subscription and' : '')
          + ' all its consumption, production, and events data.'
        })()}
        objectName='PWS'
        fieldDescription='PWS name' 
        defaultValue={pws.name}
      />
    </>
  )
}

export default withStyles(styles)(WaterSystemEdit)