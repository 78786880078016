import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import AccountCircleOutlined from '@material-ui/icons/AccountCircleOutlined'
import Email from '@material-ui/icons/Email'

const handleClose = (setOpen) => {
  setOpen(false)
}

function NewUserDialog(props) {
  const {
    open,
    setOpen,
    history,
  } = props

  return (
    <Dialog 
      open={open} 
      onClose={() => handleClose(setOpen)} 
      aria-labelledby="simple-dialog-title"
    >
      <DialogTitle id="simple-dialog-title">Add new</DialogTitle>
      <div>
        <List>
          <ListItem 
            button 
            onClick={() => 
              history.push({
                pathname: '/user-management/new',
                state: { response: 'user' },
              })
            }
          >
            <ListItemAvatar>
              <Avatar>
                <Email />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Create a user" />
          </ListItem>
          <ListItem
            button 
            onClick={() => 
              history.push({
                pathname: '/user-management/new',
                state: { response: 'contact' },
              })
            }
          >
          <ListItemAvatar>
              <Avatar>
                <AccountCircleOutlined />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Create a contact" />
          </ListItem>
        </List>
      </div>
    </Dialog>
  )
}

NewUserDialog.propTypes = {
  history: PropTypes.object.isRequired,
}

export default NewUserDialog