import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { styled } from '@material-ui/styles'

const commonStyle = {
  border: '1px solid black'
}

const cellStyle = {
  paddingLeft: 8,
  paddingRight: 8,
}

const Table = styled('table')({
  ...commonStyle
})

const TBody = styled('tbody')({
  ...commonStyle
})

const Tr = styled('tr')({
  ...commonStyle
})

const Td = styled('td')({ 
  ...commonStyle, 
  ...cellStyle,
  textAlign: 'center', 
})

function SubdivionTable(props) {
  return (
    <>
      <Grid style={{ overflowX: 'auto' }}>
        <Typography component="article">
        <Table>
          <TBody>
            <Tr>
              <Td rowSpan={2}>Water Imported</Td>
              <Td rowSpan={10}>System Input Volume (corrected for known errors)</Td>
              <Td>Water Exported</Td>
              <Td>&nbsp;</Td>
              <Td>&nbsp;</Td>
              <Td>&nbsp;</Td>
              <Td>Billed Water Exported</Td>
            </Tr>
            <Tr>
              <Td rowSpan={9}>Water Supplied</Td>
              <Td rowSpan={4}>Authorised Consumption</Td>
              <Td rowSpan={2}>Billed Authorised Consumption</Td>
              <Td rowSpan={2}>Revenue Water</Td>
              <Td>Billed Metered Consumption</Td>
            </Tr>
            <Tr>
              <Td rowSpan={8}>Volume from Own Sources</Td>
              <Td>Billed Unmetered Consumption</Td>
            </Tr>
            <Tr>
              <Td rowSpan={2}>Unbilled Authorised Consumption</Td>
              <Td rowSpan={7}>Non-Revenue Water</Td>
              <Td>Unbilled Metered Consumption</Td>
            </Tr>
            <Tr>
              <Td>Unbilled Unmetered Consumption</Td>
            </Tr>
            <Tr>
              <Td rowSpan={5}>Water Losses</Td>
              <Td rowSpan={2}>Apparent Losses</Td>
              <Td>Unauthorised Consumption</Td>
            </Tr>
            <Tr>
              <Td>Customer Metering Inaccuracies and Data Handling Errors</Td>
            </Tr>
            <Tr>
              <Td rowSpan={3}>Real Losses</Td>
              <Td>Leakage on Mains</Td>
            </Tr>
            <Tr>
              <Td>Storage Leakage and Overflows</Td>
            </Tr>
            <Tr>
              <Td>Leakage on Service Connections up to point of Customer Metering</Td>
            </Tr>
          </TBody>
        </Table>
        </Typography>
      </Grid>
    </>
  )
}

export default SubdivionTable
