import React from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

import WaterSystemsList from '../WaterSystemsList'

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
  },
  button: {
    marginTop: theme.spacing.unit,
  },
  badge: {
    top: -5,
    right: -25,
  },
})

function Subscriptions(props) {
  const {
    classes,
    history,
  } = props

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h6">
          Subscriptions
        </Typography>
        <WaterSystemsList 
          notFoundMessage="No water system found" 
          showIndicator
        />
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={() => history.push('/billing/subscriptions')}
        >
          MANAGE
        </Button>
      </Paper>
    </>
  )
}

export default withStyles(styles)(Subscriptions)