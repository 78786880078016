import React, { useEffect, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { API, graphqlOperation } from 'aws-amplify'

import * as queries from 'graphql/queries'
import ToolbarContent from 'components/ToolbarContent'
import ContentHeader from 'components/ContentHeader'
import AddFabButton from 'components/AddFabButton'
import { joinPath } from 'services/utilityFunctions'

function Events(props) {
  const {
    history,
    location,
  } = props

  const { pws } = location.state

  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(false)

  let isMounted

  useEffect(() => {
    isMounted = true

    getEvents()

    return () => {
      isMounted = false
    }
  }, [])

  async function getEvents() {
    const listEventsFilter = {
      filter: {
        waterSystemId: {
          eq: pws.id
        }
      },
      limit: 1000
    }

    setLoading(true)

    try {
      const result = await API.graphql(graphqlOperation(queries.listEvents, listEventsFilter)) 
      if (!isMounted) return
      const events = result.data.listEvents.items
      
      setEvents(events)
    
    } catch (error) {
      console.log(error)
    }

    setLoading(false)
  }

  const EventsList = () => (
    <List>
      {events.length === 0 ? 'No event data found' : events.map(item => (
        <ListItem
          key={item.id}
          button
          divider
          onClick={() => history.push({
            pathname: joinPath(location.pathname, `view/${item.id}`),
            state: { pws, event: item }
          })}
        >
          <ListItemText 
            primary={item.name}
          />
        </ListItem>
      ))}
    </List>
  )

  return (
    <>
      <ToolbarContent
        screenTitle = "Event Data"
        isMainScreen = {false}
      />
      <ContentHeader
        breadcrumbs={[
          { name: 'Event Data', goBack: true, step: 1 },
          { name: pws.name }, 
        ]}
        history={history}
      />
      {loading ? <CircularProgress /> : <EventsList /> }
      <AddFabButton 
        onClick={() => history.push({
          pathname: joinPath(location.pathname, 'new'),
          state: { pws },
        })}
      />
    </>
  )
}

export default Events