import { tiersList } from '../constantVariables/subscriptionTier'

export const checkTierChange = (prevActiveConnections, newActiveConnections) => {
  // get tier of the previous value for active connections
  const prevTier = getTier(prevActiveConnections)

  // get tier with the new value for active connections
  const newTier = getTier(newActiveConnections)
  
  if (prevTier.id === newTier.id) {
    return false
  } else {
    return true
  }
}

export const getTier = (activeConnections) => {
  if (activeConnections < 1) {
    return { 
      id: 0, 
      name: 'NO TIER', 
      description: 'NO TIER',
    }
  }

  return tiersList.find(tier => {
    return activeConnections >= tier.limits[0] && activeConnections <= tier.limits[1]
  })
}