import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import ToolbarContent from 'components/ToolbarContent'
import WaterSystemsList from '../../components/WaterSystemsList'

const styles = theme => ({
  root: {
    padding: theme.spacing.unit
  },
  divider: {
    height: 15,
    background: 'none',
  },
  button: {
    marginTop: 10,
  },
})

function Subscriptions(props) {
  const {
    classes,
    history,
  } = props

  const [pws, setPws] = useState([])

  return (
    <>
      <ToolbarContent
        screenTitle = "Manage Subscriptions"
        isMainScreen = {false}
      />
      <Paper className={classes.root}>
        <Typography variant="h6">
          Active Subscriptions
        </Typography>
        <WaterSystemsList 
          addedFilters={{ subscribed: { eq: true } }} 
          onItemClick={pws => history.push({
            pathname: `/billing/subscriptions/cancel/${pws.subscriptionId}`,
            state: { pws }
          })}
        />
      </Paper>
      <Divider className={classes.divider} />
      <Paper className={classes.root}>
        <Typography variant="h6">
          Systems that require payment before a report can be generated
        </Typography>
        <WaterSystemsList 
          addedFilters={{ subscribed: { ne: true } }} 
          pwsHook={setPws} 
        />
        {pws.length > 0 ? (
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => history.push({
              pathname: '/billing/subscriptions/payment',
              state: { pws },
            })}
          >
            ACTIVATE
          </Button>
        ) : null}
      </Paper>
    </>
  )
} 

export default withStyles(styles)(Subscriptions) 