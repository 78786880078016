import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

import ToolbarContent from 'components/ToolbarContent'

const styles = theme => ({
  main: {
    height: 'calc(100vh - 86px)',
  },
  content: {
    maxHeight: 'calc(100vh - 130px)',
    overflowY: 'scroll',
    paddingTop: 50,
  },
})

function NotFound(props) {
  const {
    classes,
    history,
  } = props
  
  return (
    <>
      <ToolbarContent 
        screenTitle = "404 Not Found"
        isMainScreen = {false}
        renderIcon = {false}
      />
      <Grid className={classes.main} container direction="column" justify="space-between">
        <Grid className={classes.content}>
          <Typography variant="h5">
            Sorry, but the page you're looking for can't be found.
          </Typography>
        </Grid>
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={() => history.push('/')}
        >
          GO TO DASHBOARD
        </Button>
      </Grid>
    </>
  )
}

export default withStyles(styles)(NotFound)