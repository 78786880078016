import React, { Component } from 'react'
import { Storage, API, graphqlOperation } from 'aws-amplify'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import Close from '@material-ui/icons/Close'
import CircularProgress from '@material-ui/core/CircularProgress'
import countryList from 'country-list'
import isEqual from 'lodash/isEqual'
import { compose } from 'recompose'

import * as mutations from 'graphql/mutations'
import { removeEmpty } from 'services/graphqlHelpers'
import ToolbarContent from 'components/ToolbarContent'
import withUserData from 'components/withUserData'
import { UserContext } from 'contexts'
import Img from 'components/Img'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%'
  },
  buttonSave: {
    margin: 'auto',
    marginBottom: 16,
  },
  buttonContainer: {
    position: 'sticky',
    bottom: 0,
    paddingTop: 16,
    background: '#fff'
  },
  centerWrapper: {
    textAlign: 'center',
  },
  logoGroup: {
    display: 'inline-block',
    position: 'relative',
  },
  buttonResetUpload: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: 5,
  },
  buttonUpload: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    margin: 5,
  },
  logo: {
    margin: 'auto',
    display: 'block',
  },
  input: {
    display: 'none',
  },
})

const inputFields = {
  logo: require('images/default-image.png'),
  name: '',
  street: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
  country: 'United States',
}

class OrganizationEdit extends Component {
  state = {
    originalData: inputFields,
    currentData: inputFields,
    image: null,
    logoPreview: require('images/default-image.png'),
    logoFileData: null,
    isLogoChanged: false,
    updateContext: false,
    updatedUserData: null,
    saveDisabled: true,
    saveProgress: false,
  }

  async componentDidMount() {
    const { organization } = this.props.userContext.userData
    
    const originalData = this.state.originalData
    originalData.name = organization.name
    originalData.logo = organization.logo || originalData.logo

    if(organization.mailingAddress) {
      originalData.street = organization.mailingAddress.street || ''
      originalData.street2 = organization.mailingAddress.street2 || ''
      originalData.city = organization.mailingAddress.city || ''
      originalData.state = organization.mailingAddress.state || ''
      originalData.zip = organization.mailingAddress.zip || ''
      originalData.country = organization.mailingAddress.country || ''
    }
    
    const currentData = originalData 

    this.setState({ 
      originalData, 
      currentData,
      logoPreview: organization.logo || this.state.logoPreview
    })
  }

  handleImageUpload = async event => {
    event.persist()
    
    const logoFileData = event.target.files[0]
    event.target.value = ''

    if(!logoFileData) {
      this.setState(prevState => ({ 
        currentData: {
          ...prevState.currentData,
          logo: this.state.originalData.logo
        } 
      }))

      return
    }

    let reader = new FileReader()
    reader.onload = (e) => {
      this.setState({ logoPreview: e.target.result })
    }

    reader.readAsDataURL(logoFileData)

    await this.setState({
      logoFileData,
      isLogoChanged: true,
    })

    this.checkChanges()
  }

  resetUpload = async () => {
    await this.setState({
      logoPreview: this.state.originalData.logo,
      isLogoChanged: false,
      logoFileData: null,
    })

    this.checkChanges()
  }
  
  handleChange = name => async event => {
    event.persist()
    
    if(name==='zip') {
      if(event.target.value.length > 5) {
        return
      }
    }

    await this.setState(prevState => ({
      currentData: { 
        ...prevState.currentData,
        [name]: event.target.value,
      }
    }))

    this.checkChanges()
  }

  handleSave = async () => {
    let { 
      logo,
      name,
      street,
      street2,
      city,
      state,
      zip,
      country,
    } = this.state.currentData
    const { logoFileData } = this.state
    const userData = this.props.userContext.userData
    const { id } = userData.organization

    this.setState({ 
      saveDisabled: true,
      saveProgress: true, 
    })

    if(logoFileData) {
      const result = await Storage.put(`${id}-logo.jpeg`, logoFileData, {
        contentType: 'image/jpeg'
      })
      const logoUrl = await Storage.get(result.key)
      //do not include the access token
      logo = logoUrl.split('?')[0]

      this.setState(prevState => ({
        currentData: {
          ...prevState.currentData,
          logo,
        },
      }))
    }

    let inputUpdateOrganization = {
      id: userData.organization.id,
      name,
      logo,
      mailingAddress: {
        street,
        street2,
        city,
        state,
        zip,
        country,
      },
    }

    inputUpdateOrganization = removeEmpty(inputUpdateOrganization)

    //update organization in DynamoDB
    const updateOrganizationResult = await API.graphql(graphqlOperation(mutations.updateOrganization, {input: inputUpdateOrganization}))
    const updatedOrganization = updateOrganizationResult.data.updateOrganization
    userData.organization = updatedOrganization

    //update user context
    await this.setState({ updatedUserData: userData })
    await this.setState({ updateContext: true })    
    this.setState({ 
      originalData: this.state.currentData,
      isLogoChanged: false,
      saveProgress: false,
    }, () => this.props.history.push('/organization'))
  }
  
  checkChanges = () => {
    const {
      currentData,
      originalData,
      logoFileData,
    } = this.state
    if(isEqual(currentData, originalData) && !logoFileData) {
      this.setState({
        saveDisabled: true,
      })
    } else {
      this.setState({
        saveDisabled: false,
      })
    }
  }

  updateContext = () => {
    this.setState({ updateContext: false })
    return (
      <UserContext.Consumer>
        {({ updateUserData }) => {
          updateUserData(this.state.updatedUserData)
        }}
      </UserContext.Consumer>
    )
  }

  render() {
    const { classes } = this.props
    const { 
      currentData,
      logoPreview, 
      updateContext, 
      saveDisabled,
      isLogoChanged,
      saveProgress,
    } = this.state

    return (
      <>
        {updateContext ? this.updateContext() : null}
        <ToolbarContent
          screenTitle = "Edit Organization"
          isMainScreen = {false}
        />
        <div className={classes.centerWrapper}>
          <div className={classes.logoGroup}>
            {/* <img
              id="logo"
              alt="logo"
              className={classes.logo}
              /> */}
            <Img
              className={classes.logo}
              src={logoPreview}
              width={250}
              imgProps={{
                id: "logo",
                alt: "logo"
              }}
            />
            <input
              accept="image/*"
              className={classes.input}
              id="logo-upload"
              type="file"
              onChange={this.handleImageUpload}
            />
            <label htmlFor="logo-upload">
              <Button 
                variant="contained"
                component="span" 
                className={classes.buttonUpload}
              >
                EDIT
              </Button>
            </label>
            <Fab 
              className={classes.buttonResetUpload}
              style={isLogoChanged ? null : { display: 'none' }}
              component="span" 
              size="small"
              onClick={this.resetUpload}
            >
              <Close />
            </Fab>
          </div>
        </div>
        <form className={classes.container} noValidate autoComplete="off">
          <TextField
            id="organization-name"
            label="Organization Name"
            className={classes.textField}
            value={currentData.name}
            onChange={this.handleChange('name')}
            margin="normal"
          />
          <TextField
            id="street"
            label="Street"
            className={classes.textField}
            value={currentData.street}
            onChange={this.handleChange('street')}
            margin="normal"
          />
          <TextField
            id="street2"
            label="Street 2"
            className={classes.textField}
            value={currentData.street2}
            onChange={this.handleChange('street2')}
            margin="normal"
          />
          <TextField
            id="city"
            label="City"
            className={classes.textField}
            value={currentData.city}
            onChange={this.handleChange('city')}
            margin="normal"
          />
          <TextField
            id="state"
            label="State"
            className={classes.textField}
            value={currentData.state}
            onChange={this.handleChange('state')}
            margin="normal"
          />
          <Grid container spacing={24}>
            <Grid item xs={6}>
              <TextField
                id="zip"
                label="ZIP"
                type="number"
                className={classes.textField}
                value={currentData.zip}
                onChange={this.handleChange('zip')} 
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="country"
                select
                label="Country"
                className={classes.textField}
                value={currentData.country}
                onChange={this.handleChange('country')}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
              >
                {countryList.getNames().map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </form>
        <Grid className={classes.buttonContainer}>
          <Button
            className={classes.buttonSave} 
            variant="contained" 
            color="primary"
            disabled={saveDisabled}
            fullWidth
            onClick={this.handleSave}
          > 
            {saveProgress ? <CircularProgress color="inherit" size={16} style={{ marginRight: 5 }} /> : null}
            SAVE
          </Button>
        </Grid>
      </>
    )
  }
}

OrganizationEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default compose(
  withUserData,
  withStyles(styles)
)(OrganizationEdit)