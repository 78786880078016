import React, { useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { API, graphqlOperation } from 'aws-amplify'

import * as mutations from 'graphql/mutations'
import ToolbarContent from 'components/ToolbarContent'
import ContentHeader from 'components/ContentHeader'
import MaintenanceJournalForm from '../../components/MaintenanceJournalForm'

const styles = theme => ({
  button: {
    marginBottom: 16,
  },
  buttonContainer: {
    position: 'sticky',
    bottom: 0,
    paddingTop: 16,
    background: '#fff'
  }
})

const handleRecord = async args => {
  const {
    waterSystemId,
    modifiedBy,
    timestamp,
    text,
  } = args

  const inputCreateMaintenanceJournal = {
    waterSystemId,
    modifiedBy,
    timestamp,
    text,
  }
  
  try {
    const result = await API.graphql(graphqlOperation(mutations.createMaintenanceJournal, { input: inputCreateMaintenanceJournal }))
    const maintenanceJournal = result.data.createMaintenanceJournal
    
    return maintenanceJournal
  } catch (error) {
    return console.log(error)  
  }
}

function MaintenanceJournalNew(props) {
  const {
    classes,
    history,
    location,
  } = props

  const { pws } = location.state
  const [maintenanceJournal, setMaintenanceJournal] = useState({})
  
  const [loading, setLoading] = useState(false)

  return (
    <>
      <ToolbarContent
        screenTitle="Maintenance Journal New"
        isMainScreen={false}
      />
      <ContentHeader
        breadcrumbs={[
          { name: 'Maintenance Journal', goBack: true, step: 2 },
          { name: pws.name, goBack: true, step: 1 }, 
          { name: 'New' }, 
        ]}
        history={history}
      />
      <MaintenanceJournalForm 
        waterSystemId={pws.id}
        onChange={item => setMaintenanceJournal(item)} 
      />
      <Grid className={classes.buttonContainer}>
        <Button
          className={classes.button}
          disabled={loading || !maintenanceJournal.text}
          onClick={async () => {
            setLoading(true)
            const newRecord = await handleRecord(maintenanceJournal)
            
            if (!newRecord) return
            
            setLoading(false)
            history.replace({
              pathname: location.pathname.replace('new', `view/${newRecord.id}`),
              state: { 
                pws, 
                maintenanceJournal: newRecord 
              }
            })
          }}
          variant="contained"
          color="primary"  
          fullWidth
        >
          {loading ? <CircularProgress color="inherit" size={16} style={{ marginRight: 5 }} /> : null}
          SAVE
        </Button>
      </Grid>
    </>
  )
}


export default withStyles(styles)(MaintenanceJournalNew)