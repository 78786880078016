import React from 'react'
import AWSAppSyncClient from 'aws-appsync'
import awsmobile from './aws-exports'
import { ApolloProvider } from 'react-apollo'
import { Rehydrated } from 'aws-appsync-react'
import { Auth } from 'aws-amplify'
import App from './App'

const AppSyncConfig = awsmobile

const client = new AWSAppSyncClient({
  url: AppSyncConfig.aws_appsync_graphqlEndpoint,
  region: AppSyncConfig.aws_appsync_region,
  auth: {
    type: AppSyncConfig.aws_appsync_authenticationType,
    jwtToken: async () => {
      const authSession = await Auth.currentSession()
      return authSession.accessToken.jwtToken
    },
  }
})

const WithProvider = () => (
  <ApolloProvider client={client}>
    <Rehydrated>
      <App />
    </Rehydrated>
  </ApolloProvider>
)

export default WithProvider