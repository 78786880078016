// This button is positioned relative to paper component wrapping AppContent
// <Paper style={{ position: "relative" }} />
import React from 'react'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/styles'


const useStyles = makeStyles({
  fab: {
    position: 'fixed',
    bottom: 26,
  },
  fabContainer: {
    position: 'absolute',
    bottom: 16,
    right: 16,
    height: 56,
    width: 56,
  }
})

function AddFabButton(props) {
  const classes = useStyles()

  const {
    className,
    color,
    ...otherProps
  } = props

  return (
    <>
      <div className={classes.fabContainer}>
        <Fab 
          className={classes.fab} 
          color="primary"
          {...otherProps}
        >
          <AddIcon />
        </Fab>
      </div>
    </>
  )
}

export default AddFabButton