import React, { useState, useEffect, } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { API, graphqlOperation } from 'aws-amplify'

import * as mutations from 'graphql/mutations.js'
import { removeEmpty } from 'services/graphqlHelpers/'
import ToolbarContent from 'components/ToolbarContent'
import ImagePicker from 'components/ImagePicker'
import WaterSourceForm from '../../components/WaterSourceForm'

const styles = theme => ({
  buttonSave: {
    margin: 'auto',
    marginBottom: 16,
  },
  buttonContainer: {
    position: 'sticky',
    bottom: 0,
    paddingTop: 16,
    background: '#fff'
  },
  label: {
    marginBottom: 10,
  }
})

const handleSave = async(newValues, setTarget, setSaveProgress, history, sourceImage, installImage, pwsId) => {
  setTarget(newValues)
  setSaveProgress(true)
  
  const sourceImageUrl = await sourceImage.upload()
  const installImageUrl = await installImage.upload()

  let inputCreateWaterSource = {
    ...newValues,
    sourceImage: sourceImageUrl,
    installImage: installImageUrl,
    waterSystemId: pwsId,
    waterSourceWaterSystemId: pwsId, 
  }
  
  inputCreateWaterSource = removeEmpty(inputCreateWaterSource)

  try {
    await API.graphql(graphqlOperation(mutations.createWaterSource, { input: inputCreateWaterSource }))
    history.goBack()
  } catch (error) {
    console.log(error)  
  }
}

function WaterSourceNew(props) {
	const {
		classes,
		location,
    history,
	} = props

  const pws = location.state.pws

  const fields = {
    name: '',
    type: '',
    units: '',
    isMetered: true,
    estimate: '',
    meterSize: '',
    meterMakeModel: '',
    meterInstallYear: '',
    meterCalibDate: '', 
  }

  const [source, setSource] = useState(fields)
  const [sourceImage, setSourceImage] = useState({})
  const [installImage, setInstallImage] = useState({})
  const [updatedSource, setUpdatedSource] = useState(fields)
  const [isUpdated, setIsUpdated] = useState(false)
  const [saveProgress, setSaveProgress] = useState(false)

  useEffect(() => {
    if (sourceImage.imageFileData || installImage.imageFileData) {
      setIsUpdated(true)
    } else {
      setIsUpdated(false)
    }
  }, [
    sourceImage,
    installImage,
  ])

	return (
		<>
			<ToolbarContent
				screenTitle = {`New Water Source`}
        isMainScreen = {false}
			/>
      <Typography 
        className={classes.label}
        color="textSecondary" 
        variant="caption"
      >
        Water Source Site Image 
      </Typography>
      <ImagePicker 
        id={`${source.id}-source`} 
        setStorageUploadFunc={setSourceImage} 
      />
			<WaterSourceForm 
        data={source} 
        setIsUpdated={setIsUpdated} 
        setUpdatedData={setUpdatedSource} 
      />
			<div style={updatedSource.isMetered ? null : { display: 'none' }}>
        <Typography 
          className={classes.label}
          color="textSecondary"
          variant="caption"
        >
          Installation Image 
        </Typography>
        <ImagePicker 
          id={`${source.id}-install`} 
          setStorageUploadFunc={setInstallImage} 
        />
      </div>
      <Grid className={classes.buttonContainer}>  
        <Button
          className={classes.buttonSave} 
          variant="contained" 
          color="primary"
          disabled={!isUpdated || saveProgress}
          fullWidth
          onClick={() => handleSave(
            updatedSource,
            setSource,
            setSaveProgress,
            history,
            sourceImage,
            installImage,
            pws.id
          )}
        > 
          {saveProgress ? <CircularProgress color="inherit" size={16} style={{ marginRight: 5 }} /> : null}
          SAVE
        </Button>
      </Grid>
		</>	
	)
}

export default withStyles(styles)(WaterSourceNew)