import React, { useState, useEffect, } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import { API, graphqlOperation } from 'aws-amplify'

import * as mutations from 'graphql/mutations'

const handleChange = (e, setCode, setHasError, setVerifyComplete, setCouponObject) => {
  setCode(e.target.value)
  setVerifyComplete(false)
  setCouponObject(null)
  setHasError(true)

  if (!e.target.value) {
    setHasError(false)
  }
}

const validate = async (code, verifyComplete, setHasError, setLoading, setVerifyComplete, setCouponObject) => {
  if (!code) return setHasError(false)
  if (verifyComplete) return

  setLoading(true)

  const inputApiAction = {
    name: 'stripeCouponVerify', 
    parameters: [
      code
    ]
  }

  try {
    const result = await API.graphql(graphqlOperation(mutations.createApiAction, { input: inputApiAction }))
    const couponJSON = result.data.createAPIAction.stripeCouponVerify
    const coupon = JSON.parse(couponJSON)
    setCouponObject(coupon)
    setHasError(false)
  } catch (error) {
    console.log(error)
    setHasError(true)
  } 
  setLoading(false)
  setVerifyComplete(true)
}

function Coupon(props) {
  const { 
    setCoupon,
    setCouponError,
  } = props

  const [code, setCode] = useState('')
  const [hasError, setHasError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [verifyComplete, setVerifyComplete] = useState(false)
  const [couponObject, setCouponObject] = useState(null)

  useEffect(() => {
    setCouponError(hasError)
    setCoupon(couponObject)
  }, [hasError, couponObject])

  return (
    <>
      <TextField 
        value={code}
        error={hasError}
        placeholder="Enter coupon code"
        onChange={e => handleChange(e, setCode, setHasError, setVerifyComplete, setCouponObject)}
        onBlur={() => validate(code, verifyComplete, setHasError, setLoading, setVerifyComplete, setCouponObject)}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            validate(code, verifyComplete, setHasError, setLoading, setVerifyComplete, setCouponObject) 
            e.preventDefault()
          }
        }}
        fullWidth
      />
      <Typography 
        variant="caption"
      >
        {loading ? <CircularProgress color="inherit" size={16} style={{ marginRight: 5 }}  /> : null}
        {verifyComplete ? (
          <>
            {hasError ? 'Invalid coupon code' : <><span style={{ color: 'teal' }}>&#10004;</span> {`Verified: ${couponObject.name}`}</>}
          </>
        ) : null}
      </Typography>
    </>
  )
}

Coupon.defaultProps = {
  setCoupon: () => {},
  setCouponError: () => {},
}

Coupon.propTypes = {
  setCoupon: PropTypes.func, 
  setCouponError: PropTypes.func, 
}

export default Coupon