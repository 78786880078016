import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Skeleton from 'react-loading-skeleton'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { API, graphqlOperation } from 'aws-amplify'

import withUserData from 'components/withUserData'
import * as queries from 'graphql/queries'

const styles = theme => ({
  tableWrapper: {
    overflowX: 'auto',
  }
})

const CustomTableCell = withStyles(theme => ({
  head: {
    color: theme.palette.primary.dark,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const SkeletonRows = props => {
  const {
    showIndicator
  } = props
  
  const items = [1, 2, 3]

  return items.map(item => (
    <TableRow key={item}>
      {showIndicator ? (
        <TableCell align="center">
          <Skeleton width={14} />
        </TableCell>
      ) : null}
      <TableCell
        component="th" 
        scope="row"
        align="center"
      >
        <Skeleton />
      </TableCell>
      <TableCell align="center">
        <Skeleton width={40} />
      </TableCell>
    </TableRow>
  ))
}

const TableRows = props => {
  const { 
    pws, 
    onItemClick,
    notFoundMessage,
    showIndicator,
  } = props
  
  return pws.length === 0 ? (
    <TableRow>
      <TableCell align="center" colSpan={showIndicator ? 3 : 2}>
        <Typography variant="caption">
          {notFoundMessage}
        </Typography>
      </TableCell>
    </TableRow>
  ) : (
    pws.map(item => (
      <TableRow key={item.id} onClick={() => onItemClick(item)}>
        {showIndicator ? (
          <TableCell>
            <div 
              style={{ 
                height: 14, 
                width: 14, 
                borderRadius: 7,
                marginLeft: 'auto', 
                marginRight: 'auto', 
                background: item.subscribed ? '#4caf50' : '#f44336', 
              }} 
            />
          </TableCell>
        ): null}
        <TableCell 
          component="th" 
          scope="row"
          align="center"
        >
          {item.name}
        </TableCell>
        <TableCell align="center">
          {Number(item.activeConnections).toLocaleString()}
        </TableCell>
      </TableRow>
    ))
  )
}

function WaterSystemsList(props) {
  const {
    classes,
    style,
    userContext,
    addedFilters,
    pwsHook,
    onItemClick,
    notFoundMessage,
    showIndicator,
  } = props

  const organizationId = userContext.userData.organizationId || ''

  const [pws, setPws] = useState([])
  const [loading, setLoading] = useState(false)

  let isMounted

  useEffect(() => {
    isMounted = true

    ;(async () => {
      if (!organizationId) return console.log('No organization')
      const listWaterSystemsFilter = {
        filter: {
          organizationId: {
            eq: organizationId
          },
          activeConnections: {
            ne: 0
          },
          ...addedFilters,
        },
        limit: 1000
      }
      setLoading(true)
      try {
        const resultListWaterSystems = await API.graphql(graphqlOperation(queries.listWaterSystems, listWaterSystemsFilter))  
        const pws = resultListWaterSystems.data.listWaterSystems.items
        
        let pwsSubbed, pwsNotSubbed, pwsFinal = []        

        // subcribed
        pwsSubbed = pws.filter(pws => pws.subscribed)
        pwsSubbed.sort((a, b) => {
          return a['name'].localeCompare(b['name'])
        })

        // not subscribed
        pwsNotSubbed = pws.filter(pws => !pws.subscribed)
        pwsNotSubbed.sort((a, b) => {
          return a['name'].localeCompare(b['name'])
        })

        // join
        pwsFinal = pwsSubbed.concat(pwsNotSubbed)

        if (!isMounted) return
        setLoading(false)
        setPws(pwsFinal)
        pwsHook(pwsFinal)
      } catch (error) {
        setLoading(false)
      }
    })()

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <>
      <div className={classes.tableWrapper}>
        <Table style={style} padding="dense">
          <TableHead>
            <TableRow>
              {showIndicator ? <CustomTableCell align="center">Status</CustomTableCell> : null}
              <CustomTableCell align="center">Water System</CustomTableCell>
              <CustomTableCell align="center">Active Connections</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <SkeletonRows showIndicator={showIndicator} /> 
              ) : (
                <TableRows 
                  pws={pws} 
                  onItemClick={onItemClick} 
                  notFoundMessage={notFoundMessage}
                  showIndicator={showIndicator}
                />
                )}
          </TableBody>
        </Table>
      </div>
    </>
  )
}

WaterSystemsList.defaultProps = {
  style: {},
  addedFilters: {},
  pwsHook: () => {},
  onItemClick: () => {},
  notFoundMessage: 'Nothing to show',
  showIndicator: false,
}

WaterSystemsList.propTypes = {
  style: PropTypes.object,
  addedFilters: PropTypes.object,
  pwsHook: PropTypes.func,
  onItemClick: PropTypes.func,
  notFoundMessage: PropTypes.string,
  showIndicator: PropTypes.bool,
}

export default compose(
  withUserData,
  withStyles(styles),
)(WaterSystemsList)