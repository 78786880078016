import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import { formatNumber } from 'accounting'
import { API, graphqlOperation } from 'aws-amplify'

import * as queries from 'graphql/queries'
import ToolbarContent from 'components/ToolbarContent'
import ContentHeader from 'components/ContentHeader'
import AddFabButton from 'components/AddFabButton'
import { joinPath, formatDate } from 'services/utilityFunctions'
import { removeEmpty } from 'services/graphqlHelpers'

function ProductionDataList(props) {
  const {
    location,
    history,
  } = props

  const { pwsName, source } = location.state

  const [sourceProduction, setSourceProduction] = useState([])
  const [lastReading, setLastReading] = useState()
  const [loading, setLoading] = useState(true)

  let isMounted

  useEffect(() => {
    isMounted = true
    
    // retrieve production data
    ;(async () => {
      setLoading(true)
      
      let argsListSourceProduction = {
        filter: source.id ? {
          waterSourceId: { eq: source.id }
        } : null,
        limit: 1000
      } 
      
      removeEmpty(argsListSourceProduction)
      
      try {
        const result = await API.graphql(graphqlOperation(queries.listSourceProductions, argsListSourceProduction ))
        if (!isMounted) return
        const sourceProduction = result.data.listSourceProductions.items
        
        // sort by type
        sourceProduction.sort((a, b) => {
          if(a.type < b.type) return 1
          if(a.type > b.type) return -1
          return 0
        })
        
        // sort by date latest on top
        sourceProduction.sort((a, b) => {
          if (a.type === b.type) {
            return a.type === 'meter' ? b.timestamp.slice(0, 8) - a.timestamp.slice(0, 8) : b.startDate - a.startDate
          }
          return null
        })        

        const productionMeter  = sourceProduction.filter(item => item.type === 'meter')
        if (productionMeter.length > 0) {
          setLastReading(productionMeter[0])
        }

        setSourceProduction(sourceProduction)
      } catch (error) {
        console.log(error)
      }

      setLoading(false)
    })()
    
    return () => {
      isMounted = false
    }
  }, [])

  function ProductionList() {
    return (
      <List>
        {sourceProduction.length === 0 ? 'No production data found' : sourceProduction.map(item => (
          <ListItem 
            key={item.id}
            button
            divider
            onClick={() => history.push({
              pathname: joinPath(location.pathname, `view/${item.id}`),
              state: { pwsName, source, sourceProduction: item }
            })}
          >
            <ListItemText 
              primary={
                <Grid container direction="column">
                  <Grid container direction="row" justify="space-between">
                    <Typography>
                      {item.type === 'meter' ? (
                        formatDate(item.timestamp.slice(0, 8), 'Mmm/dd/yyyy')
                      ) : (
                        <>
                          {formatDate(item.startDate, 'Mmm/dd/yyyy')} - {formatDate(item.endDate, 'Mmm/dd/yyyy')}
                        </>
                      )}
                    </Typography>
                    <Typography variant="subtitle2">
                      {item.type === 'meter' ? (
                        <>
                          {formatNumber(item.reading, 2)} {source.units}
                        </>
                      ) : (
                        <>
                          {formatNumber(item.volume, 2)} {source.units}
                        </>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              } 
              secondary={
                <>
                  {item.type === 'meter' ? 'Meter' : 'Historical'}          
                </>
              } 
            />
          </ListItem>
        ))}
      </List>
    )
  }

  return (
    <>
      <ToolbarContent
        screenTitle = {`Production Data`}
        isMainScreen = {false}
      />
      <ContentHeader
        breadcrumbs={[
          { name: 'Production Data', goBack: true, step: 2 },
          { name: pwsName, goBack: true, step: 1 }, 
          { name: source.name } 
        ]}
        history={history}
      />
      {loading ? <CircularProgress /> : <ProductionList />}
      <AddFabButton
        onClick={() => history.push({
          pathname: joinPath(location.pathname, 'new'),
          state: { pwsName, source, lastReading },
        })}
      />
    </>
  )
}

export default ProductionDataList