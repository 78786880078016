import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  paper: {
    padding: 10,
  },
  emojiButtonRoot: {
    padding: `2px !important`,
    borderRadius: `0 !important`,
  },
  emojiLabel: {
    height: 32,
    width: 'max-content !important'
  }
})

const EmojiButton = props => {
  const classes = useStyles()
  return (
    <IconButton 
      {...props} 
      classes={{
        root: classes.emojiButtonRoot,
        label: classes.emojiLabel,
      }} 
    />
  )
}

const validityEmoji = {
  1: '🙁',
  2: '😐',
  3: '😊',
}

function ValidityButton(props) {
  const {
    validity: validityProp,
    onChange
  } = props
  
  const classes = useStyles()
  
  const [firstRender, setFirstRender] = useState(true)
  const [validity, setValidity] = useState(3)
  const [anchorEl, setAnchorEl] = useState()
  const [open, setOpen] = useState(false)
  
  const handleOpen = e => {
    setAnchorEl(e.currentTarget)
    setOpen(prevState => !prevState)
  }

  let isMounted

  useEffect(() => {

  }, [])

  useEffect(() => {
    isMounted = true

    if (firstRender) {
      setFirstRender(false)
      setValidity(validityProp)
      return
    }

    if (!isMounted) return
    onChange(validity)

    return () => {
      isMounted = false
    }

  }, [validity])
  
  return (
    <>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <>
        <Popper open={open} anchorEl={anchorEl} placement="top-end" transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={250}>
              <Paper className={classes.paper}>
                <Typography>How confident are you with this input value?</Typography>
                <Grid container justify="center" spacing={24}>
                  <Grid item>
                    <EmojiButton onClick={() => setValidity(1)}>{validityEmoji[1]}</EmojiButton>  
                  </Grid>
                  <Grid item>
                    <EmojiButton onClick={() => setValidity(2)}>{validityEmoji[2]}</EmojiButton>  
                  </Grid>
                  <Grid item>
                    <EmojiButton onClick={() => setValidity(3)}>{validityEmoji[3]}</EmojiButton>  
                  </Grid>
                </Grid>
              </Paper>
            </Fade>
          )}
        </Popper>
        <EmojiButton onClick={e => handleOpen(e)}>{validityEmoji[validity]}&#8963;</EmojiButton >
        </>
      </ClickAwayListener>
    </>
  )
}

ValidityButton.defaultProps = {
  validity: '3',
  onChange: () => {},
}

ValidityButton.propTypes = {
  validity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func
}

export default ValidityButton