import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

function Background(props) {
  const {
    sourceNames,
    periodStart,
    periodEnd,
  } = props
  
  return (
    <>
      <Typography variant="h6" gutterBottom>
        BACKGROUND
      </Typography>
      <Typography paragraph>
        This report compares Water Supplied volume from 
        {sourceNames.map((name, index) => (
          <span key={index}>
            {index !== (sourceNames.length - 1) ? ` ${name},` : ` and ${name}`}
          </span>
        ))} to total water consumption from {periodStart} to {periodEnd}.
      </Typography>
    </>
  )
}

Background.propTypes = {
  sourceNames: PropTypes.array.isRequired,
  periodStart: PropTypes.string.isRequired,
  periodEnd: PropTypes.string.isRequired,
}

export default Background