import React, { useState, useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'

import { userStatus } from 'services/constantVariables/user'

function OrganizationContacts(props) {
  const { users } = props

  const [contacts, setContacts] = useState(null)
  const [queryComplete, setQueryComplete] = useState(false)

  let isMounted

  useEffect(() => {
    isMounted = true

    ;(async() => {
      if (!isMounted) return
      setQueryComplete(false)

      if (!users) return
      const contacts = users.filter(user => user.status === userStatus.CONTACT)
      setContacts(contacts)
      setQueryComplete(true)
    })()

    return () => {
      isMounted = false
    }

  }, [users])

  if(!queryComplete) {
    return (
      <ListItem>
        <CircularProgress />
      </ListItem>
    )
  }

  if(!contacts.length) {
    return (
      <ListItem>
        <ListItemText primary="None" />
      </ListItem>
    )
  }
  
  return (
    <>
      {contacts.map((user) => (
        <ListItem 
          key={user.id}
          button
          onClick={() => props.history.push({
            pathname: `${props.location.pathname}/view/${user.id}`,
            state: { user: user },
          })}  
        >
          <Avatar>
            <ContactPhoneIcon />
          </Avatar>
          <ListItemText 
            primary={`${user.firstName||''} ${user.lastName||''}`} 
            secondary={user.email} 
          />
        </ListItem>
      ))}
    </>
  )
}

export default OrganizationContacts