import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import { withStyles } from '@material-ui/core/styles'

import NumberField from 'components/NumberField'

const styles = theme => ({
  customLabel: {
    fontSize: '.85rem',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'block',
  },
  value: {
    fontSize: '0.875rem',
    textAlign: 'right'
  },
  adornment: {
    whiteSpace: 'nowrap',
  }
})

function LabeledNumberField(props) {
  let {
    classes,
    label,
    value: initialValue,
    onChange,
    startAdornment,
    endAdornment,
    disabled,
    precision
  } = props

  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    onChange(value)
  }, [value])

  return (
    <Grid container spacing={16}>
      <Grid item xs={7}>
        <Typography
          variant="caption"
          component={() => (
            <span className={classes.customLabel}>
              {label}
            </span>
          )}
        />
      </Grid>
      <Grid container item justify="flex-end" xs={5}>
        <NumberField 
          value={value}
          precision={precision}
          onChange={v => setValue(v)}
          InputProps={{
            inputProps: {
              className: classes.value
            },
            startAdornment: startAdornment ? (
              <InputAdornment 
                className={classes.adornment}
                position="end"
              >
                {startAdornment}
              </InputAdornment>
            ) : null,
            endAdornment: endAdornment ? (
              <InputAdornment 
                className={classes.adornment}
                position="end"
              >
                {endAdornment}
              </InputAdornment>
            ) : null,
          }}
          disabled={disabled}
        />  
      </Grid>
    </Grid>
  )
}

LabeledNumberField.defaultProps = {
  value: 0,
  onChange: () => {},
  disabled: false,
  precision: null,
}

LabeledNumberField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  startAdornment: PropTypes.string,
  endAdornment: PropTypes.string,
  disabled: PropTypes.bool,
  precision: PropTypes.number,
}

export default withStyles(styles)(LabeledNumberField)