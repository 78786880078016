import React, { useState } from 'react'

import ToolbarContent from 'components/ToolbarContent'
import ContentHeader from 'components/ContentHeader'
import { joinPath } from 'services/utilityFunctions'
import PublicWaterSystems from 'scenes/WaterSystems/components/PublicWaterSystems'

function EventData(props) {
  const {
    history,
    match,
  } = props
  
  const [pwsNotFound, setPwsNotFound] = useState(true)

  return (
    <>
      <ToolbarContent
        screenTitle = "Event Data"
        isMainScreen = {true}
      />
      <ContentHeader
        breadcrumbs={[
          { name: 'Event Data' } 
        ]}
        text={pwsNotFound ? null : 'Select a PWS'}
      />
      <PublicWaterSystems 
        onItemClick={item => history.push({
          pathname: joinPath(match.url, `pws/${item.id}`),
          state: { pws: item }
        })}
        notFoundMessage="No Water System found. To record event data you must create a Water System first."
        setNotFound={setPwsNotFound}
      />
    </>
  )
}

export default EventData