import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import scrollToElement from 'scroll-to-element'
import Link from '@material-ui/core/Link'

import BarChart from './components/BarChart'
import WaterLossTable from './components/WaterLossTable'
import { cleanNumber } from 'services/utilityFunctions/numbers'

function AuditResults(props) {
  const {
    pwsName,
    timePeriod,
    timePeriodDays,
    unit,
    sourceInput,
    totalMeteredUsage,
    lossVolume,
    UARL,
    CARL,
    realWaterLoss,
    apparentWaterLoss,
    nonRevenueWater,
    realWaterLossCost,
    apparentWaterLossCost,
    ILI,
    realLossPerService,
    percentLoss,
    unauthorizedConsumption,
    customerMeteringInaccuracies,
    sysDataHandlingErrors,
    waterMainLen,
    serviceConnections,
    avePipeLength,
    aveSysPressure,
    chartData,
    history,
    classes,
  } = props
  
  return (
    <>
      <Typography variant="h6" gutterBottom>
        AUDIT RESULTS
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle2">
        Water Loss - Non-Revenue Water
      </Typography>
      <Typography paragraph>
        Non-Revenue Water is comprised of Real and Apparent Losses plus Unbilled Authorized Consumption.  
        The total Water Loss volume is a simple calculation, assuming good data quality.  The process of 
        separating Real and Apparent Loss is more difficult and usually an estimation.
      </Typography>
      <WaterLossTable 
        pwsName={pwsName}
        timePeriod={timePeriod}
        unit={unit}
        sourceInput={sourceInput}
        totalMeteredUsage={totalMeteredUsage}
        lossVolume={lossVolume}
        UARL={UARL}
        realWaterLoss={realWaterLoss}
        apparentWaterLoss={apparentWaterLoss}
        nonRevenueWater={nonRevenueWater}
        realWaterLossCost={realWaterLossCost}
        apparentWaterLossCost={apparentWaterLossCost}
        ILI={ILI}
        realLossPerService={realLossPerService}
        percentLoss={percentLoss}
      />
      <Typography className={classes.subtitle} variant="subtitle2">
        Apparent Losses
      </Typography>
      <Typography>
        Apparent Losses are valued at the retail cost of water lost and minimizing these losses have a
        high rate of return. Based upon data provided and default loss estimates listed below the system’s 
        Apparent Loss is {apparentWaterLoss} {unit} valued 
        at ${apparentWaterLossCost}.
      </Typography>
      <Typography component="article">
        <ul>
          <li>Unauthorized Consumption {unauthorizedConsumption} {unit}</li>
          <li>Customer Metering Inaccuracies {customerMeteringInaccuracies} {unit}</li>
          <li>Systematic Data Handling Errors {sysDataHandlingErrors} {unit}</li>
        </ul>
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle2">
        Real Losses
      </Typography>
      <Typography component="article" paragraph>
        Real Loss is the water that physically leaks from your system. This can be from water main
        breaks, service laterals leaks, storage overflows or other appurtenance leakage. The CARL 
        is {CARL} {unit}. The UARL calculation for the system
        is {UARL} {unit} per {timePeriodDays} days based upon:
        <ul>
          <li>Water mains = {waterMainLen} miles</li>
          <li>Service connections = {serviceConnections}</li>
          <li>Average length of private service connection = {avePipeLength} feet</li>
          <li>Average system pressure = {aveSysPressure} psi</li>
        </ul>
        The UARL is a theoretical low leakage estimate. The difference between the CARL and UARL 
        is {(cleanNumber(CARL) - cleanNumber(UARL)).toLocaleString()} {unit} and is the potentially recoverable loss volume. The ILI for the system 
        is {ILI} and is a dimensionless unit, please refer to the ILI chart to determine your systems 
        ILI goal. Every system is different and needs to be analyzed as such. It does need to be noted that 
        small systems are capable of having an ILI of less than 1. That is they are able to have losses less than
        the theoretical low leakage limit.  Please refer to the ILI explanation table on 
        page&nbsp;
        <Link 
          color="secondary"
          onClick={() => {
            const id = '#definition-ili'
            scrollToElement(id)
            history.push({ hash: id, state: history.location.state })
          }} 
        >
          ILI Table Page
        </Link>.
      </Typography>
      <section className="page-break">
        <Typography className={classes.subtitle} variant="subtitle2">
          Graph
        </Typography>
        <BarChart chartData={chartData} />
      </section>
    </>
  )
}

AuditResults.propTypes = {
  pwsName: PropTypes.string.isRequired,
  timePeriod: PropTypes.string.isRequired,
  timePeriodDays: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  sourceInput: PropTypes.string,
  totalMeteredUsage: PropTypes.string,
  lossVolume: PropTypes.string,
  UARL: PropTypes.string,
  CARL: PropTypes.string,
  realWaterLoss: PropTypes.string,
  apparentWaterLoss: PropTypes.string,
  nonRevenueWater: PropTypes.string,
  realWaterLossCost: PropTypes.string,
  apparentWaterLossCost: PropTypes.string,
  ILI: PropTypes.string,
  realLossPerService: PropTypes.string,
  percentLoss: PropTypes.string,
  unauthorizedConsumption: PropTypes.string.isRequired,
  customerMeteringInaccuracies: PropTypes.string.isRequired,
  sysDataHandlingErrors: PropTypes.string.isRequired,
  aveRetailUnitCost: PropTypes.string.isRequired,
  waterSupplied: PropTypes.string.isRequired,
  waterMainLen: PropTypes.string.isRequired,
  serviceConnections: PropTypes.string.isRequired,
  avePipeLength: PropTypes.string.isRequired,
  aveSysPressure: PropTypes.string.isRequired,
  chartData: PropTypes.array,
}

export default AuditResults