import React from 'react'
import DevDebug from './scenes/DevDebug'
import Dashboard from './scenes/Dashboard'
import OrganizationSettings from './scenes/OrganizationSettings'
import OrganizationEdit from './scenes/OrganizationSettings/scenes/OrganizationEdit'
import Billing from './scenes/Billing'
import Subscriptions from './scenes/Billing/scenes/Subscriptions'
import Payment from './scenes/Billing/scenes/Subscriptions/scenes/Payment'
import SubscriptionCancel from './scenes/Billing/scenes/Subscriptions/scenes/SubscriptionCancel'
import PaymentSuccessful from './scenes/Billing/scenes/Subscriptions/scenes/PaymentSuccessful'
import PaymentFailed from './scenes/Billing/scenes/Subscriptions/scenes/PaymentFailed'
import PaymentInfo from './scenes/Billing/scenes/PaymentInfo'
import PaymentInfoEdit from './scenes/Billing/scenes/PaymentInfo/scenes/PaymentInfoEdit'
import PaymentInfoNew from './scenes/Billing/scenes/PaymentInfo/scenes/PaymentInfoNew'
import PaymentHistory from './scenes/Billing/scenes/PaymentHistory'
import PriceChart from './scenes/Billing/scenes/PriceChart'
import UserManagement from './scenes/UserManagement'
import UserNew from './scenes/UserManagement/scenes/UserNew'
import UserView from './scenes/UserManagement/scenes/UserView'
import UserEdit from './scenes/UserManagement/scenes/UserEdit'
import WaterSystems from './scenes/WaterSystems'
import WaterSystemActions from './scenes/WaterSystems/scenes/WaterSystemActions'
import CostInputs from './scenes/WaterSystems/scenes/WaterSystemActions/scenes/CostInputs'
import WaterSystemNew from './scenes/WaterSystems/scenes/WaterSystemNew'
import WaterSystemView from './scenes/WaterSystems/scenes/WaterSystemView'
import WaterSystemEdit from './scenes/WaterSystems/scenes/WaterSystemEdit'
import WaterSources from './scenes/WaterSystems/scenes/WaterSources'
import WaterSourceView from './scenes/WaterSystems/scenes/WaterSources/scenes/WaterSourceView'
import WaterSourceEdit from './scenes/WaterSystems/scenes/WaterSources/scenes/WaterSourceEdit'
import WaterSourceNew from './scenes/WaterSystems/scenes/WaterSources/scenes/WaterSourceNew'
import ProductionData from './scenes/ProductionData'
import ProductionDataSourcesList from './scenes/ProductionData/scenes/ProductionDataSourcesList'
import ProductionDataList from './scenes/ProductionData/scenes/ProductionDataSourcesList/scenes/ProductionDataList'
import ProductionDataNew from './scenes/ProductionData/scenes/ProductionDataSourcesList/scenes//ProductionDataList/scenes/ProductionDataNew'
import ProductionDataView from './scenes/ProductionData/scenes/ProductionDataSourcesList/scenes/ProductionDataList/scenes/ProductionDataView'
import ProductionDataEdit from './scenes/ProductionData/scenes/ProductionDataSourcesList/scenes/ProductionDataList/scenes/ProductionDataEdit'
import ConsumptionData from './scenes/ConsumptionData'
import ConsumptionAndUse from './scenes/ConsumptionData/scenes/ConsumptionAndUse'
import ConsumptionAndUseNew from './scenes/ConsumptionData/scenes/ConsumptionAndUse/scenes/ConsumptionAndUseNew'
import ConsumptionAndUseView from './scenes/ConsumptionData/scenes/ConsumptionAndUse/scenes/ConsumptionAndUseView'
import ConsumptionAndUseEdit from './scenes/ConsumptionData/scenes/ConsumptionAndUse/scenes/ConsumptionAndUseEdit'
import EventData from './scenes/EventData'
import Event from './scenes/EventData/scenes/Event'
import EventNew from './scenes/EventData/scenes/Event/scenes/EventNew'
import EventView from './scenes/EventData/scenes/Event/scenes/EventView'
import EventEdit from './scenes/EventData/scenes/Event/scenes/EventEdit'
import Reports from './scenes/Reports'
import ReportDateRangeSelect from './scenes/Reports/scenes/ReportDateRangeSelect'
import ReportOptions from './scenes/Reports/scenes/ReportOptions'
import ReportRun from './scenes/Reports/scenes/ReportRun'
import MaintenanceJournal from './scenes/MaintenanceJournal'
import MaintenanceJournalList from './scenes/MaintenanceJournal/scenes/MaintenanceJournalList'
import MaintenanceJournalNew from './scenes/MaintenanceJournal/scenes/MaintenanceJournalList/scenes/MaintenanceJournalNew'
import MaintenanceJournalView from './scenes/MaintenanceJournal/scenes/MaintenanceJournalList/scenes/MaintenanceJournalView'
import MaintenanceJournalEdit from './scenes/MaintenanceJournal/scenes/MaintenanceJournalList/scenes/MaintenanceJournalEdit'
import Onboarding from './scenes/Onboarding'
import SearchOrganization from './scenes/Onboarding/scenes/SearchOrganization'
import JoinOrganization from './scenes/Onboarding/scenes/JoinOrganization'
import RequestSent from './scenes/Onboarding/scenes/RequestSent'
import NewOrganization from './scenes/Onboarding/scenes/NewOrganization'
import PendingRequest from './scenes/Dashboard/PendingRequest'
import NotFound from './scenes/NotFound'

const routes = [
  { 
    path: '/', 
    component: (props) => <Dashboard {...props} />, 
    exact: true,
  },
  { 
    path: '/devdebug', 
    component: (props) => <DevDebug {...props}/>, 
    exact: true,
  },
  {
    path: '/organization', 
    component: (props) => <OrganizationSettings {...props} />, 
    exact: true,
  },
  {
    path: '/organization/edit', 
    component: (props) => <OrganizationEdit {...props} />, 
    exact: true,
  },
  {
    path: '/billing', 
    component: (props) => <Billing {...props} />, 
    exact: true,
  },
  {
    path: '/billing/subscriptions', 
    component: (props) => <Subscriptions {...props} />, 
    exact: true,
  },
  {
    path: '/billing/subscriptions/payment', 
    component: (props) => <Payment {...props} />, 
    exact: true,
  },
  {
    path: '/billing/subscriptions/cancel/:id', 
    component: (props) => <SubscriptionCancel {...props} />, 
    exact: true,
  },
  {
    path: '/billing/subscriptions/payment-successful', 
    component: (props) => <PaymentSuccessful {...props} />, 
    exact: true,
  },
  {
    path: '/billing/subscriptions/payment-failed', 
    component: (props) => <PaymentFailed {...props} />, 
    exact: true,
  },
  {
    path: '/billing/payment-info', 
    component: (props) => <PaymentInfo {...props} />, 
    exact: true,
  },
  {
    path: '/billing/payment-info/edit/:id', 
    component: (props) => <PaymentInfoEdit {...props} />, 
    exact: true,
  },
  {
    path: '/billing/payment-info/new', 
    component: (props) => <PaymentInfoNew {...props} />, 
    exact: true,
  },
  {
    path: '/billing/price-chart', 
    component: (props) => <PriceChart {...props} />, 
    exact: true,
  },
  {
    path: '/billing/payment-history', 
    component: (props) => <PaymentHistory {...props} />, 
    exact: true,
  },
  {
    path: '/user-management', 
    component: (props) => <UserManagement {...props} />, 
    exact: true,
  },
  {
    path: '/user-management/new', 
    component: (props) => <UserNew {...props} />, 
    exact: true,
  },
  {
    path: '/user-management/view/:userId', 
    component: (props) => <UserView {...props} />, 
    exact: true,
  },
  {
    path: '/user-management/edit/:userId', 
    component: (props) => <UserEdit {...props} />, 
    exact: true,
  },
  {
    path: '/water-systems', 
    component: (props) => <WaterSystems {...props} />, 
    exact: true,
  },
  {
    path: '/water-systems/actions/:id', 
    component: (props) => <WaterSystemActions {...props} />, 
    exact: true,
  },
  {
    path: '/water-systems/new', 
    component: (props) => <WaterSystemNew {...props} />, 
    exact: true,
  },
  {
    path: '/water-systems/view/:id', 
    component: (props) => <WaterSystemView {...props} />, 
    exact: true,
  },
  {
    path: '/water-systems/edit/:id', 
    component: (props) => <WaterSystemEdit {...props} />, 
    exact: true,
  },
  {
    path: '/water-systems/:pwsId/sources', 
    component: (props) => <WaterSources {...props} />,
    exact: true,
  },
  {
    path: '/water-systems/:pwsId/cost-inputs', 
    component: (props) => <CostInputs {...props} />,
    exact: true,
  },
  {
    path: '/water-systems/:pwsId/sources/view/:id', 
    component: (props) => <WaterSourceView {...props} />,
    exact: true,
  },
  {
    path: '/water-systems/:pwsId/sources/edit/:id', 
    component: (props) => <WaterSourceEdit {...props} />,
    exact: true,
  },
  {
    path: '/water-systems/:pwsId/sources/new', 
    component: (props) => <WaterSourceNew {...props} />,
    exact: true,
  },
  {
    path: '/production-data', 
    component: (props) => <ProductionData {...props} />,
    exact: true, 
  },
  {
    path: '/production-data/pws/:pwsId', 
    component: (props) => <ProductionDataSourcesList {...props} />,
    exact: true, 
  },
  {
    path: '/production-data/source/:sourceId', 
    component: (props) => <ProductionDataList {...props} />,
    exact: true, 
  },
  {
    path: '/production-data/source/:sourceId/new', 
    component: (props) => <ProductionDataNew {...props} />,
    exact: true, 
  },
  {
    path: '/production-data/source/:sourceId/view/:productionId', 
    component: (props) => <ProductionDataView {...props} />,
    exact: true, 
  },
  {
    path: '/production-data/source/:sourceId/edit/:productionId', 
    component: (props) => <ProductionDataEdit {...props} />,
    exact: true, 
  },
  {
    path: '/consumption-data', 
    component: (props) => <ConsumptionData {...props} />,
    exact: true, 
  },
  {
    path: '/consumption-data/pws/:pwsId', 
    component: (props) => <ConsumptionAndUse {...props} />,
    exact: true,
  },
  {
    path: '/consumption-data/pws/:pwsId/new', 
    component: (props) => <ConsumptionAndUseNew {...props} />,
    exact: true,
  },
  {
    path: '/consumption-data/pws/:pwsId/view/:consumptionId', 
    component: (props) => <ConsumptionAndUseView {...props} />,
    exact: true,
  },
  {
    path: '/consumption-data/pws/:pwsId/edit/:consumptionId', 
    component: (props) => <ConsumptionAndUseEdit {...props} />,
    exact: true,
  },
  {
    path: '/event-data', 
    component: (props) => <EventData {...props} />,
    exact: true, 
  },
  {
    path: '/event-data/pws/:pwsId', 
    component: (props) => <Event {...props} />,
    exact: true,
  },
  {
    path: '/event-data/pws/:pwsId/new', 
    component: (props) => <EventNew {...props} />,
    exact: true,
  },
  {
    path: '/event-data/pws/:pwsId/view/:eventId', 
    component: (props) => <EventView {...props} />,
    exact: true,
  },
  {
    path: '/event-data/pws/:pwsId/edit/:eventId', 
    component: (props) => <EventEdit {...props} />,
    exact: true,
  },
  {
    path: '/reports', 
    component: (props) => <Reports {...props} />,
    exact: true,
  },
  {
    path: '/reports/date-range-select/:id', 
    component: (props) => <ReportDateRangeSelect {...props} />,
    exact: true,
  },
  {
    path: '/reports/options/:id', 
    component: (props) => <ReportOptions {...props} />,
    exact: true,
  },
  {
    path: '/reports/run/:id', 
    component: (props) => <ReportRun {...props} />,
    exact: true,
  },
  {
    path: '/maintenance-journal', 
    component: (props) => <MaintenanceJournal {...props} />,
    exact: true, 
  },
  {
    path: '/maintenance-journal/pws/:pwsId', 
    component: (props) => <MaintenanceJournalList {...props} />,
    exact: true,
  },
  {
    path: '/maintenance-journal/pws/:pwsId/new', 
    component: (props) => <MaintenanceJournalNew {...props} />,
    exact: true,
  },
  {
    path: '/maintenance-journal/pws/:pwsId/view/:maintenanceJournalId', 
    component: (props) => <MaintenanceJournalView {...props} />,
    exact: true,
  },
  {
    path: '/maintenance-journal/pws/:pwsId/edit/:maintenanceJournalId', 
    component: (props) => <MaintenanceJournalEdit {...props} />,
    exact: true,
  },


  {
    path: '/onboarding', 
    component: (props) => <Onboarding {...props} />,
    exact: true,
  },
  {
    path: '/onboarding/search-organization', 
    component: (props) => <SearchOrganization {...props} />,
    exact: true,
  },
  {
    path: '/onboarding/join/:orgid/:orgname', 
    component: (props) => <JoinOrganization {...props} />,
    exact: true,
  },
  {
    path: '/onboarding/request-sent/:reqid', 
    component: (props) => <RequestSent {...props} />,
    exact: true,
  },
  {
    path: '/new-organization', 
    component: (props) => <NewOrganization {...props} />,
    exact: true,
  },
  {
    path: '/request-pending/:reqid', 
    component: (props) => <PendingRequest {...props} />,
    exact: true,
  },
  {
    component: (props) => <NotFound {...props} />,
  },
]

export default routes