import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import WaterIcon from '@material-ui/icons/Waves'

import InfoCard from 'components/InfoCard'

function WaterSupplied(props) {
  const {
    prodVolume,
    unit
  } = props
  
  return (
    <>
      <InfoCard 
        Icon={WaterIcon}
        color="blue"
        title="Water Supplied"
        bodyText={prodVolume ? `${prodVolume} ${unit}` : <Skeleton />}
        caption=""
        linkHref=""
        linkText=""
      />
    </>
  )
}

WaterSupplied.propTypes = {
  prodVolume: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired
}

export default WaterSupplied