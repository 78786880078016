import React, { useState, useContext, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { formatNumber } from 'accounting' 
import { styled } from "@material-ui/styles"
import { API, graphqlOperation } from 'aws-amplify'

import * as mutations from 'graphql/mutations'
import { UserContext } from 'contexts'
import WaterSystemDropdown from './components/WaterSystemDropdown'
import WaterSupplied from './components/WaterSupplied'
import NotesAndEvents from './components/NotesAndEvents'
import Consumption from './components/Consumption'
import Chart from './components/Chart'

const Gap = styled('hr')({
  height: 16,
  visibility: 'hidden',
})

function FactsBlocks(props) {
  const user = useContext(UserContext)
  const { organizationId } = user.userData

  const [pwsId, setPwsId] = useState()
  const [prodVolume, setProdVolume] = useState('')
  const [event, setEvent] = useState()
  const [conVolume, setConVolume] = useState('')
  const [chartData, setChartData] = useState()
  const [unit, setUnit] = useState('Kgal')
  
  let isMounted

  useEffect(() => {
    isMounted = true

    getProductionAndConsumption(pwsId)

    return () => {
      isMounted = false
    }
  }, [pwsId])
  

  async function getProductionAndConsumption(pwsId) {
    setProdVolume('')
    setEvent(null)
    setConVolume('')
    setChartData()
    const inputApiAction = {
      name: 'getProdConOneMonthPrior',
      parameters: [
        pwsId,
        'Kgal'
      ]
    } 

    try {
      const result = await API.graphql(graphqlOperation(mutations.createApiAction, { input: inputApiAction })) 
      if (!isMounted) return
      const prodConDataJSON = result.data.createAPIAction.getProdConOneMonthPrior
      const prodConData = JSON.parse(prodConDataJSON)

      setProdVolume(formatNumber(prodConData.production, 2))
      setEvent(prodConData.events)
      setConVolume(formatNumber(prodConData.consumption, 2))
      setChartData(prodConData.chartData)
      setUnit(prodConData.unit)

    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <WaterSystemDropdown
        organizationId={organizationId}
        onChange={pwsId => setPwsId(pwsId)}
      />
      <Gap />
      <Grid container direction="column">
        <Grid item container spacing={24}>
          <Grid item xs={12} md={4}>
            <WaterSupplied 
              prodVolume={prodVolume} 
              unit={unit}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <NotesAndEvents 
              event={event}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Consumption 
              conVolume={conVolume} 
              unit={unit}
            />
          </Grid>
        </Grid>
        <Grid item container style={{ paddingTop: 24 }}>
          <Grid item xs={12}>
            <Chart 
              chartData={chartData} 
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default FactsBlocks