import React, { Component } from 'react'
import { Router } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import Amplify from 'aws-amplify'
import aws_exports from './aws-exports'
import { withAuthenticator } from 'aws-amplify-react'

import AppView from './components/AppView'
import { AuthStateContext } from './contexts'
import history from './history'
import appTheme from './theme'

Amplify.configure(aws_exports)
const theme = createMuiTheme(appTheme)

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <MuiThemeProvider theme={theme}>
          <AuthStateContext.Provider value={{onStateChange: this.props.onStateChange}}>
            <AppView />
          </AuthStateContext.Provider>
        </MuiThemeProvider>
      </Router>
    )
  }
}

export default withAuthenticator(App)