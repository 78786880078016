// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_content_delivery_bucket": "wua-20190521120942-hostingbucket-maaa",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://daepe18sb916e.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-west-2:f97d06ac-a3a9-47c4-814b-1fcba65099b1",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_6tsaexAGu",
    "aws_user_pools_web_client_id": "5qj6s7gchf7o921fvttfa38vdp",
    "oauth": {},
    "aws_user_files_s3_bucket": "wuastorage-maaa",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://r542t22nnbatnmato22dw2ths4.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
