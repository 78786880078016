import React from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

import ToolbarContent from 'components/ToolbarContent'
import Payments from '../../components/Payments'

const styles = theme => ({
  root: {
    padding: theme.spacing.unit
  },
})

function PaymentHistory(props) {
  const {
    classes,
  } = props

  return (
    <>
      <ToolbarContent
        screenTitle = "Payment History"
        isMainScreen = {false}
      />
      <Paper className={classes.root}>
        <Typography variant="h6">
          Recent Payments
        </Typography>
        <Payments />
      </Paper>
    </>
  )
}

export default withStyles(styles)(PaymentHistory) 