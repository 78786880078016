import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'

import LabeledNumberField from 'components/LabeledNumberField'
import LabeledNumber from 'components/LabeledNumber'
import DateForm, { getCurrentDate } from 'components/DateForm'
import { volumeUnitsList, volumeUnitShort } from 'services/constantVariables/units'

const styles = theme => ({
  textField: {
    minWidth: 150,
  },
  percentField: {
    textAlign: "right",
  },
  button: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  customLabel: {
    fontSize: '.85rem',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'block',
  },
  verticalSpace: {
    marginBottom: theme.spacing.unit * 2,
    backgroundColor: 'transparent',
  },
  textRight: {
    textAlign: 'right'
  },
  value: {
    fontSize: '0.875rem',
    textAlign: 'right'
  }
})

const cleanNumber = numberString => {
  numberString = String(numberString)
  return Number(numberString.replace(/,/g,''))
}

const cleanDate = dateString => {
  dateString = String(dateString)
  return dateString.replace(/-/g,'')
}

function ConsumptionAndUseForm(props) {
  const {
    classes,
    consumption,
    setConsumption,
  } = props

  const currentDate = getCurrentDate()

  const waterSystemId = consumption.waterSystemId || props.waterSystemId
  const [startDate, setStartDate] = useState(consumption.startDate || currentDate)
  const [endDate, setEndDate] = useState(consumption.endDate || currentDate)
  
  const [meteredBilledConsumption, setMeteredBilledConsumption] = useState(consumption.meteredBilledConsumption)
  const [meteredUnbilledConsumption, setMeteredUnbilledConsumption] = useState(consumption.meteredUnbilledConsumption)
  const [unmeteredBilledConsumption, setUnmeteredBilledConsumption] = useState(consumption.unmeteredBilledConsumption)
  const [unmeteredUnbilledConsumption, setUnmeteredUnbilledConsumption] = useState(consumption.unmeteredUnbilledConsumption)
  const [unit, setUnit] = useState(consumption.unit || volumeUnitsList[0])

  const total = cleanNumber(meteredBilledConsumption) +
                cleanNumber(meteredUnbilledConsumption) +
                cleanNumber(unmeteredBilledConsumption) +
                cleanNumber(unmeteredUnbilledConsumption)

  useEffect(() => {
    // call hook for setting consumption object  
    setConsumption({
      id: consumption.id,
      waterSystemId,
      startDate: cleanDate(startDate),
      endDate: cleanDate(endDate),
      meteredBilledConsumption: cleanNumber(meteredBilledConsumption).toString(),
      meteredUnbilledConsumption: cleanNumber(meteredUnbilledConsumption).toString(),
      unmeteredBilledConsumption: cleanNumber(unmeteredBilledConsumption).toString(),
      unmeteredUnbilledConsumption: cleanNumber(unmeteredUnbilledConsumption).toString(),
      unit,
    })
  }, [
    startDate,
    endDate,
    meteredBilledConsumption,
    meteredUnbilledConsumption,
    unmeteredBilledConsumption,
    unmeteredUnbilledConsumption,
    unit,
  ])

  return (
    <>
      <DateForm
        id="start-date"
        label="Start Date"
        year={startDate.substr(0,4)}
        month={startDate.substr(4,2)}
        day={startDate.substr(6,2)} 
        onChange={date => setStartDate(`${date.year}${date.month}${date.day}`)}
      />
      <DateForm
        id="end-date"
        label="End Date"
        year={endDate.substr(0,4)}
        month={endDate.substr(4,2)}
        day={endDate.substr(6,2)} 
        onChange={date => setEndDate(`${date.year}${date.month}${date.day}`)}
      />
      <Divider className={classes.verticalSpace}/>
      <Grid>
        <TextField 
          id="unit" 
          label="Measurement Unit" 
          className={classes.textField} 
          value={unit} 
          onChange={(e) => setUnit(e.target.value)} 
          margin="normal" 
          select
          SelectProps={{
            native: true,
          }} 
        >
          {volumeUnitsList.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </TextField>
      </Grid>
      <Typography variant="h6" color="secondary">
        Metered
      </Typography>
      <LabeledNumberField 
        label="Billed Consumption"
        value={meteredBilledConsumption}
        onChange={v => setMeteredBilledConsumption(v)}
        endAdornment={volumeUnitShort[unit]}
      />
      <LabeledNumberField 
        label="Unbilled Consumption"
        value={meteredUnbilledConsumption}
        onChange={v => setMeteredUnbilledConsumption(v)}
        endAdornment={volumeUnitShort[unit]}
      />
      <Divider className={classes.verticalSpace}/>
      <Typography variant="h6" color="secondary">
        Unmetered
      </Typography>
      <LabeledNumberField 
        label="Billed Consumption"
        value={unmeteredBilledConsumption}
        onChange={v => setUnmeteredBilledConsumption(v)}
        endAdornment={volumeUnitShort[unit]}
      />
      <LabeledNumberField 
        label="Unbilled Consumption"
        value={unmeteredUnbilledConsumption}
        onChange={v => setUnmeteredUnbilledConsumption(v)}
        endAdornment={volumeUnitShort[unit]}
      />
      <Divider className={classes.verticalSpace}/>
      <LabeledNumber
        label="Total for time period"
        value={`${total} ${volumeUnitShort[unit]}`}
        variant="bold"
      />
    </>
  )
}

ConsumptionAndUseForm.defaultProps = {
  consumption: {
    waterSystemId: '',
    startDate: '',
    endDate: '',
    meteredBilledConsumption: '',
    meteredUnbilledConsumption: '',
    unmeteredBilledConsumption: '',
    unmeteredUnbilledConsumption: '',
    unit: '',
  },
  waterSystemId: '',
  error: false,
}

ConsumptionAndUseForm.propTypes = {
  consumption: PropTypes.object,
  waterSystemId: PropTypes.string,
  error: PropTypes.bool,
}

export default withStyles(styles)(ConsumptionAndUseForm)