import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import { API, graphqlOperation } from 'aws-amplify'
import { makeStyles } from '@material-ui/styles'

import * as mutations from 'graphql/mutations'
import { removeEmpty } from 'services/graphqlHelpers'
import { userStatus } from 'services/constantVariables/user'
import UserForm from 'scenes/UserManagement/components/UserForm'

const useStyles = makeStyles({
  buttonSave: {
    margin: 'auto',
    marginBottom: 16,
  },
  buttonContainer: {
    position: 'sticky',
    bottom: 0,
    paddingTop: 16,
    background: '#fff'
  }  
})

const handleSave = async (fieldsUpdate, setUser, setSaveProgress, setIsUpdated, history) => {
  setUser(fieldsUpdate)
  setIsUpdated(false)
  setSaveProgress(true)
  
  let inputUser = {
    ...fieldsUpdate,
  }

  inputUser = removeEmpty(inputUser)

  try {
    const resultCreateUser = await API.graphql(graphqlOperation(mutations.createUser, { input: inputUser }))
    const user = resultCreateUser.data.createUser
    history.replace({
      pathname: `/user-management/view/${user.id}`,
      state: { user: user }
    })
  } catch (error) {
    console.log(error)
  }
}

function CreateNonUser(props) {
  const {
    history,
    userContext,
  } = props
  
  const classes = useStyles()

  const { organizationId } = userContext.userData
  
  const initContact = {
    organizationId: organizationId,
    userOrganizationId: organizationId,
    firstName: '',
    lastName: '', 
    company: '',
    title: '',
    phone: '',
    phone2: '',
    email: '',
    status: userStatus.CONTACT,
  }

  const [contact, setContact] = useState(initContact)
  const [updatedContact, setUpdatedContact] = useState(initContact)
  const [isUpdated, setIsUpdated] = useState(false)
  const [saveProgress, setSaveProgress] = useState(false)

  useEffect(() => {
  }, [contact])

  return (
    <>
      <UserForm 
        user={contact} 
        setUser={setUpdatedContact}
        setIsUpdated={setIsUpdated} 
        emailDisabled={false}
      />
      <Grid className={classes.buttonContainer}>
        <Button
          className={classes.buttonSave}
          variant="contained" 
          color="primary"
          disabled={!isUpdated}
          fullWidth
          onClick={() => handleSave(
            updatedContact,
            setContact,
            setSaveProgress,
            setIsUpdated,
            history
          )}
        > 
          {saveProgress ? <CircularProgress color="inherit" size={16} style={{ marginRight: 5 }} /> : null}
          SAVE
        </Button>
      </Grid>
    </>
  )
}

export default CreateNonUser