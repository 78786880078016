import React, { useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import { API, graphqlOperation } from 'aws-amplify'

import * as mutations from 'graphql/mutations'
import ToolbarContent from 'components/ToolbarContent'
import ContentHeader from 'components/ContentHeader'
import { removeEmpty } from 'services/graphqlHelpers'
import EventForm from '../../components/EventForm'

const useStyles = makeStyles({
  buttonContainer: {
    position: 'sticky',
    bottom: 0,
    paddingTop: 16,
    background: '#fff'
  },
  buttonSave: {
    margin: 'auto',
    marginBottom: 16,
  },
})

const handleSave = async event => {
  const {
    id,
    name,
    type,
    estimatedLoss,
    estimatedLossUnit,
    startTimestamp,
    endTimestamp,
    location,
    notes,
    waterSystemId,
  } = event

  let inputUpdateEvent = {
    id,
    name,
    type,
    estimatedLoss,
    estimatedLossUnit,
    startTimestamp,
    endTimestamp,
    location,
    notes,
    waterSystemId,
  }
  
  inputUpdateEvent = removeEmpty(inputUpdateEvent)

  try {
    const result = await API.graphql(graphqlOperation(mutations.updateEvent, { input: inputUpdateEvent }))
    const event = result.data.updateEvent
    
    return event
  } catch (error) {
    return console.log(error)  
  }
}

function EventEdit(props) {
  const {
    history,
    location
  } = props

  const classes = useStyles()
  const { pws, event: eventState } = location.state

  const [event, setEvent] = useState()
  const [isUpdated, setIsUpdated] = useState(false)
  const [saveInProgress, setSaveInProgress] = useState(false)
  
  return (
    <>
      <ToolbarContent
        screenTitle = "Event Data Edit"
        isMainScreen = {false}
      />
      <ContentHeader
        breadcrumbs={[
          { name: 'Event Data', goBack: true, step: 3 },
          { name: pws.name, goBack: true, step: 2 }, 
          { name: eventState.name || '', goBack: true, step: 1 }, 
          { name: 'Edit' }, 
        ]}
        history={history}
      />
      <EventForm 
        data={eventState}
        onChange={(data, isUpdated) => {
          setEvent(data)
          setIsUpdated(isUpdated)
        }}
      />
      <Grid className={classes.buttonContainer}>
        <Button
          className={classes.buttonSave}
          color="primary"
          variant="contained"
          fullWidth
          disabled={saveInProgress || !isUpdated}
          onClick={async () => {
            setSaveInProgress(true)
            const newEvent = await handleSave({ ...event, id: eventState.id, waterSystemId: eventState.waterSystemId })
            history.replace({
              pathname: location.pathname.replace('edit', 'view'),
              state: { pws, event: newEvent }
            })
          }}
        >
          {saveInProgress ? <CircularProgress color="inherit" size={16} style={{ marginRight: 5 }} /> : null}
          SAVE
        </Button>
      </Grid>
    </>
  )
}

export default EventEdit