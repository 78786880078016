import React, { useState, useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined'

import { userStatus } from 'services/constantVariables/user'

function InviteUsers(props) {
  const { users } = props

  const [userInvites, setUserInvites] = useState([])
  const [queryComplete, setQueryComplete] = useState(false)

  let isMounted

  useEffect(() => {
    isMounted = true

    ;(async() => {
      if (!isMounted) return
      setQueryComplete(false)

      if (!users) return
      const userInvites = users.filter(user => user.status === userStatus.INVITE_PENDING)
      setUserInvites(userInvites)
      setQueryComplete(true)
    })()

    return () => {
      isMounted = false
    }
  
  }, [users])

  if(!queryComplete) {
    return (
      <ListItem>
        <CircularProgress />
      </ListItem>
    )
  }

  if(!userInvites.length) {
    return (
      <ListItem>
        <ListItemText primary="None" />
      </ListItem>
    )
  }
  
  return (
    <>
      {userInvites.map((invite) => (
        <ListItem 
          key={invite.id}
        >
          <Avatar>
            <AccountCircleIcon />
          </Avatar>
          <ListItemText 
            secondary={invite.email} 
          />
        </ListItem>
      ))}
    </>
  )
}

export default InviteUsers