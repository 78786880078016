import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Media from 'react-media'
import { withTheme } from '@material-ui/core/styles'

import { ToolbarContext } from 'contexts'
import AppDrawer from 'components/AppDrawer'
import AppContent from 'components/AppContent'
import AppFrameSmall from 'components/AppFrameSmall'
import AppFrameMedium from 'components/AppFrameMedium'
import AppFrameLarge from 'components/AppFrameLarge'
import AppToolbar from './components/AppToolbar'
import './index.css'

class AppView extends Component {
  state = {
    contentClass: null,
    contentPaperClass: null,
    rootPositionStyle: 'flex',
    toolbarContextValue: {
      title: "",
      isMainScreen: true,
      renderIcon: true,
      update: (title, isMainScreen, renderIcon) => {
        this.updateToolbarTitle(title, isMainScreen, renderIcon)
      },
    },
  }

  updateToolbarTitle = (title, isMainScreen, renderIcon) => {
    this.setState(prevState => ({
      toolbarContextValue: {
        ...prevState.toolbarContextValue,
        title, isMainScreen, renderIcon
      }
    }))
  }

  render() {
    const {
      toolbarContextValue,
      contentClass,
      contentPaperClass,
      rootPositionStyle,
    } = this.state
    const { theme } = this.props
    const { breakpoints } = theme

    const AppToolbarWithContext = (props) => (
      <AppToolbar 
        menuClick={props.menuClick}
        title={toolbarContextValue.title}
        isMainScreen={toolbarContextValue.isMainScreen}
        renderIcon={props.renderIcon === false ? false : toolbarContextValue.renderIcon}
      />
    ) 

    return (
      <div id="app-root" style={{ display: rootPositionStyle }}>
        <ToolbarContext.Provider value={toolbarContextValue}>
          <div id="no-print">
            <Media query={{ maxWidth: breakpoints.values['sm'] }}>
              {matches => 
                matches ? (
                  <AppFrameSmall
                    AppToolbar={AppToolbarWithContext}
                    AppDrawer={AppDrawer}
                    onRender={classes => {
                      this.setState({ 
                        contentClass: classes.content,
                        contentPaperClass: classes.contentPaper,
                        rootPositionStyle: 'block'
                      })
                    }}
                  />
                ) : (
                  <Media query={{ maxWidth: breakpoints.values['md'] }}>
                    {matches => 
                      matches ? (
                        <AppFrameMedium
                          AppToolbar={AppToolbarWithContext}
                          AppDrawer={AppDrawer}
                          onRender={classes => {
                            this.setState({ 
                              contentClass: classes.content,
                              contentPaperClass: classes.contentPaper,
                              rootPositionStyle: 'flex'
                            })
                          }}
                        />
                      ) : (
                        <Media query={{ maxWidth: breakpoints.values['xl'] }}>
                          {matches => 
                            matches ? (
                              <AppFrameLarge
                                AppToolbar={AppToolbarWithContext}
                                AppDrawer={AppDrawer}
                                onRender={classes => {
                                  this.setState({ 
                                    contentClass: classes.content,
                                    contentPaperClass: classes.contentPaper,
                                    rootPositionStyle: 'flex'
                                  })
                                }}
                              />
                            ) : null
                          }
                        </Media>
                      )
                    }
                  </Media>
                )
              }
            </Media>
          </div>
          <main className={contentClass}>
            <Paper id="content-wrapper" className={contentPaperClass}>
              <AppContent id="content" />
            </Paper>
          </main>
        </ToolbarContext.Provider>
      </div>
    )
  }
}

export default withTheme()(AppView)