import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import ButtonBase from '@material-ui/core/ButtonBase'
import Divider from '@material-ui/core/Divider'
import getSymbolFromcurrency from 'currency-symbol-map'

const styles = theme => ({
  root: {
    padding: 10,
  },
  buttonRemove: {
    color: theme.palette.action.active,
    paddingTop: 5,
    paddingBottom: 5,
  },
})

function SubscriptionItem(props) {
  const { 
    classes,
    name,
    price,
    currency,
    connections,
    tierNum,
    handleRemove,
    disabled,
  } = props

  return (
    <>
      <Grid className={classes.root} container>
        <Grid container direction="row">
          <Grid item container xs={8}>
            <Typography gutterBottom variant="subtitle2">
              {name}
            </Typography>
          </Grid>
          <Grid item container xs={4} direction="row-reverse">
            <Grid item>
              <Typography variant="subtitle2" align="right">
                {getSymbolFromcurrency(currency)}
                {price.toLocaleString()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container>
          <Typography color="textSecondary" style={{ width: '100%' }}>
            New water system with {connections.toLocaleString()} connections. 
            This system is graded at Tier {tierNum}.
          </Typography>
          <ButtonBase 
            className={classes.buttonRemove}
            disabled={disabled}
            onClick={() => handleRemove()}
            >
            <DeleteIcon/>
            Remove from order
          </ButtonBase>
        </Grid>
      </Grid>
      <Divider />
    </>
  )
}

SubscriptionItem.defaultProps = {
  handleRemove: () => {},
  disabled: false,
}

SubscriptionItem.propTypes = {
  name: PropTypes.string,
  price: PropTypes.string,
  currency: PropTypes.string,
  connections: PropTypes.string,
  tierNum: PropTypes.string,
  handleRemove: PropTypes.func,
  disabled: PropTypes.bool,
}

export default withStyles(styles)(SubscriptionItem)