import React from 'react'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  paper: {
    position: 'relative',
    top: '45%',
    transform: 'translateY(-50%)',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '70%',
    maxHeight: '75%',
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: 'none',
  },
})

function ModalContainer(props) {
  const {
    classes,
  } = props

  return (
    <div className={classes.paper}>
        {props.children}
    </div>
  )
}

export default withStyles(styles)(ModalContainer)