import React, { useState, useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  header: {
    marginTop: 16,
  },
  item: {
    paddingLeft: `16px !important`,
  },
}))

const zeroPad = (input, length) => {
  if (typeof input !== "string") {
    console.error("Warning: Invalid argument `input`, expected `string`.")
  }

  if (input.length > length) {
    console.error("Warning: Invalid argument `input`, string length must not be longer than `length`.")
  }

  const diff = length - input.length
  const zero = '0'
  const padding = zero.repeat(diff)
  
  return padding.concat(input)
}

const years = ((startYear) => {
  return {
    list: () => {
      const currentYear = new Date().getFullYear()
      let years = []
      // default start year is 1800
      let locStartYear = startYear || 1800

      while (locStartYear <= currentYear) {
        years.push(locStartYear++)
      } 
    
      years.reverse()

      return years
    },
    setStartYear: value => {
      startYear = value
    },
  }
})() 

const months = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
]

const days = (month, year) => {
  const count = new Date(year, month, 0).getDate()
  let days = []
  let i = 1

  while (i <= count) {
    days.push(i)
    i++
  }

  return days
}

// returns date string with format 'yyyymmdd'
const getCurrentDate = () => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = zeroPad((currentDate.getMonth() + 1).toString(), 2)
  const currentDay = zeroPad(currentDate.getDate().toString(), 2)

	return `${currentYear}${currentMonth}${currentDay}` 
}

function YearDropdown(props) {
  return (
    <TextField 
      {...props}
      fullWidth
      margin="normal"
      select
      SelectProps={{
        native: true,
      }} 
    >
      {years.list().map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </TextField>
  )
}

YearDropdown.propTypes = {
  value: PropTypes.string.isRequired
}

function MonthDropdown(props) {
  return (
    <TextField 
      {...props}
      fullWidth
      margin="normal"
      select
      SelectProps={{
        native: true,
      }} 
    >
      {months.map((option, i) => {
        return (
          <option key={i} value={option}>
            {option}
          </option>
        )
      })}
    </TextField>
  )
}

function DayDropdown(props) {
  const {
    month,
    year,
  } = props

  return (
    <TextField 
      {...props}
      fullWidth
      margin="normal"
      select
      SelectProps={{
        native: true,
      }} 
    >
      {days(month, year).map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </TextField>
  )
}

function DateForm(props) {
  const {
    label,
    style,
    onChange,
  } = props
  const classes = useStyles()

  const [year, setYear] = useState(props.year)
  const [month, setMonth] = useState(months[Number(props.month) - 1])
  const [day, setDay] = useState(Number(props.day).toString())
  
  // execute onChange after update only and not on mount
  const didUpdate = useRef(false)
  useLayoutEffect(() => {
    if (!didUpdate.current) {
      didUpdate.current = true
      return
    }

    const date = {
      year,
      month: zeroPad((months.indexOf(month) + 1).toString(), 2),
      monthName: month,
      day: zeroPad(day, 2),
    }

    onChange(date)
  }, [year, month, day])

  return (
    <div style={style}>
      <Typography 
        className={classes.header} 
        color="textSecondary" 
        variant="caption"
      >
        {label}
      </Typography>
      <YearDropdown 
        className={classes.item}
        InputLabelProps={{
          className: classes.item
        }}
        label="Year"
        value={year}
        onChange={(e) => setYear(e.target.value)}
      />
      <MonthDropdown
        className={classes.item}
        InputLabelProps={{
          className: classes.item
        }}
        label="Month"
        value={month}
        onChange={(e) => setMonth(e.target.value)}
      />
      <DayDropdown
        className={classes.item}
        InputLabelProps={{
          className: classes.item
        }}
        label="Day"
        value={day}
        onChange={e => setDay(e.target.value)}
        month={months.indexOf(month) + 1}
        year={year}
      />
    </div>
  )
}

DateForm.defaultProps = {
  label: 'Date',
  onChange: () => {},
}

DateForm.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired,
}

export default DateForm

export {
  YearDropdown,
  MonthDropdown,
  DayDropdown,
  years,
  months,
  getCurrentDate,
  zeroPad,
} 