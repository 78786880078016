import React from 'react'

export const ToolbarContext = React.createContext({
  title: "",
  isMainScreen: false,
  renderIcon: true,
  update: () => {},
})

export const AuthStateContext = React.createContext({
  onStateChange: null,
})

export const UserContext = React.createContext({
  currentUser: null,
  userData: null,
  updateUserData: () => {},
})