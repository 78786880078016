import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import CircularProgress from '@material-ui/core/CircularProgress'
import { formatNumber } from 'accounting'
import { API, graphqlOperation } from 'aws-amplify'
import { compose } from 'recompose'

import * as queries from 'graphql/queries'
import withUserData from 'components/withUserData'
import { removeEmpty } from 'services/graphqlHelpers'
import { formatDate } from 'services/utilityFunctions'

function ConsumptionAndUseList(props) {
  const {
    onItemClick,
    notFoundMessage,
    waterSystemId,
    filter,
  } = props

  const [consumptions, setConsumptions] = useState([])
  const [loading, setLoading] = useState(true)

  let isMounted

  useEffect(() => {
    isMounted = true
    
    ;(async () => {
      if (!isMounted) return
      
      setLoading(true)

      let argsListConsumptionAndUses = {
        filter: waterSystemId ? {
          waterSystemId: { eq: waterSystemId }
        } : null,
        limit: 1000
      } 

      removeEmpty(argsListConsumptionAndUses)
      
      try {
        const result = await API.graphql(graphqlOperation(queries.listConsumptionAndUses, argsListConsumptionAndUses ))
        if (!isMounted) return
        const consumptions = result.data.listConsumptionAndUses.items

        // sort by start date
        consumptions.sort((a, b) => {
          return b.startDate - a.startDate
        })
        
        // sort by end date
        consumptions.sort((a, b) => {
          if (a.startDate === b.startDate) {
            return b.endDate - a.endDate
          }
          return null
        })

        setConsumptions(consumptions)
      } catch (error) {
        console.log(error)
      }
      setLoading(false)
    })()

    return () => {
      isMounted = false
    }

  }, [filter])

  if (loading) return <CircularProgress />

  if (!loading && consumptions.length === 0) {
    return (
      <>
        {notFoundMessage}
      </>
    )
  }

  return (
    <>
      <List>
        {consumptions.map(consumption => (
          <ListItem 
            key={consumption.id}
            button
            divider
            onClick={() => onItemClick(consumption)}
          >
            <ListItemText 
              primary={
                <Grid
                  container
                  direction="column"
                >
                  <Grid container direction="row">
                    <Typography>
                      {formatDate(consumption.startDate, 'Mmm/dd/yyyy')} - {formatDate(consumption.endDate, 'Mmm/dd/yyyy')} 
                    </Typography>
                  </Grid>
                </Grid>
              } 
              secondary={
                <>
                  {formatNumber(
                    (Number(consumption.meteredBilledConsumption) + 
                    Number(consumption.meteredUnbilledConsumption) +
                    Number(consumption.unmeteredBilledConsumption) +
                    Number(consumption.unmeteredUnbilledConsumption)),
                    2
                  )}
                  <span> </span>
                  {consumption.unit}
                </>
              } 
            />
          </ListItem>
        ))}
      </List>
    </>
  )
}

ConsumptionAndUseList.defaultProps = {
  onItemClick: () => {},
  notFoundMessage: 'No consumption & use record found',
  waterSystemId: null,
  filter: null,
}

ConsumptionAndUseList.propTypes = {
  onItemClick: PropTypes.func,
  notFoundMessage: PropTypes.string,
  waterSystemId: PropTypes.string,
  filter: PropTypes.func,
}

export default compose(
  withUserData,
)(ConsumptionAndUseList)