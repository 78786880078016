import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Modal from '@material-ui/core/Modal'
import { withStyles } from '@material-ui/core/styles'
import { API, graphqlOperation } from 'aws-amplify'
import { compose } from 'recompose'

import ToolbarContent from 'components/ToolbarContent'
import withUserData from 'components/withUserData'
import * as mutations from 'graphql/mutations'
import { getTier } from 'services/constantVariables/subscriptionTier'
import SubscriptionItem from './components/SubscriptionItem'
import PaymentMethodAdd from './components/PaymentMethodAdd'
import PaymentMethodChange from './components/PaymentMethodChange'
import Coupon from './components/Coupon'
import ModalContainer from './components/ModalContainer'
import PaymentCards from 'scenes/Billing/components/PaymentCards'

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
    overflowX: 'auto',
  },
  buttonRight: {
    float: 'right',
  },
  buttonPay: {
    marginTop: 20,
  },
  options: {
    paddingTop: 15,
    paddingBottom: 10,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: -20,
    marginLeft: -16,
    background: '#eeeeee',
    width: 'calc(100% + 32px)',
  },
  amountLabel: {
    maxWidth: 200,
  },
  amount: {
    minWidth: 80,
  }
})

const handlePayment = async (pws, customerId, coupon, newCard, setPaymentInProgress) => {
  setPaymentInProgress(true)

  const items = pws.map(item => ({ 
    water_system: item.name, 
    connections: item.activeConnections,
    pwsId: item.id, 
  }))
  const itemsJSON = JSON.stringify(items)
  const inputApiAction = {
    name: 'stripeSubscribeToPlan', //get from services/constantVariables
    parameters: [
      customerId,
      itemsJSON,  
      coupon ? coupon.id : null,
      newCard ? newCard.id : null,
    ]
  }

  let hasError = false

  try {
    await API.graphql(graphqlOperation(mutations.createApiAction, { input: inputApiAction }))
  } catch (error) {
    console.log(error)
    hasError = true
  } 
  setPaymentInProgress(false)
  return hasError
}

function Payment(props) {
  const {
    classes,
    history,
    location,
    userContext,
  } = props

  const { customerId } = userContext.userData.organization 

  const [cardAdded, setCardAdded] = useState(false)
  const [pws, setPws] = useState(location.state.pws)
  useEffect(() => {
    if (pws.length === 0) history.goBack()

    if (cardAdded) {
      setCardModalOpen(false)
    }
  }, [pws, cardAdded])

  const [coupon, setCoupon] = useState(null)
  const [couponError, setCouponError] = useState(false)
  useEffect(() => {
    if (coupon) {
      setDiscount(subtotal * (coupon.percent_off / 100))
    } else {
      setDiscount(0)
    }
  }, [coupon])
  
  const [newCard, setNewCard] = useState()
  const [newCardFilter, setNewCardFilter] = useState({ filter: () => {} })
  useEffect(() => {
    if (newCard) setNewCardFilter({ filter: card => card.id === newCard.id })
  }, [newCard])

  const [cardExists, setCardExists] = useState(true)
  const [cardLoading, setCardLoading] = useState(true)
  const [paymentInProgress, setPaymentInProgress] = useState(false)
  const [cardModalOpen, setCardModalOpen] = useState(false)
  const [discount, setDiscount] = useState(0)
  
  let totalConnections = 0
  let subtotal = 0

  const LabeledAmount = props => {
    const {
      label,
      amount,
      labTxtVariant,
      amoTxtVariant
    } = props

    return (
      <Grid 
        container
        justify="flex-end"
        spacing={8}
      >
        <Grid className={classes.amountLabel} item>
          <Typography 
            variant={labTxtVariant} 
            align="right"
          >
            {label}
          </Typography>
        </Grid>
        <Grid className={classes.amount} item>
          <Typography 
            variant={amoTxtVariant} 
            align="right"
          >
            {amount}
          </Typography>
        </Grid>
      </Grid>
    )
}

  return (
    <>
      <ToolbarContent
        screenTitle = "Payment Required"
        isMainScreen = {false}
      />
      <Grid 
        className={classes.options}
        container 
        justify="space-between" 
        spacing={8}
      >
        <Grid item  xs={6}> 
          <Coupon 
            setCoupon={setCoupon} 
            setCouponError={setCouponError} 
          />
        </Grid>
        <Grid item xs={6}>
          <Button 
            disabled={paymentInProgress}
            variant="outlined"
            onClick={() => history.push('/billing/price-chart')}
            size="small"
            fullWidth
          >
            SEE PRICING TIERS
          </Button>
        </Grid>
      </Grid>
      {pws.map((item, index) => {
        const tier = getTier(item.activeConnections)
        totalConnections = Number(totalConnections) + Number(item.activeConnections)
        subtotal = Number(subtotal) + Number(tier.price)

        return (
          <SubscriptionItem 
            key={item.id}
            name={item.name}
            price={tier.price.toString()}
            currency={tier.currency}
            connections={Number(item.activeConnections).toString()}
            tierNum={tier.id.toString()}
            handleRemove={() => {
              const updatedPws = pws.slice()
              updatedPws.splice(index, 1)
              setPws(updatedPws)
            }}
            disabled={paymentInProgress}    
          />
        )
      })}
      <Typography 
        variant="caption" 
        align="right"
      >
        {totalConnections.toLocaleString()} connections enabled
      </Typography>
      <LabeledAmount 
        label="Subtotal" 
        amount={`$${subtotal.toLocaleString()}`} 
        labTxtVariant="subtitle2"
        amoTxtVariant="subtitle2"
      />
      {coupon ? (
        <>
          <LabeledAmount 
            label={`${coupon.percent_off}% OFF`} 
            amount={`-$${discount.toLocaleString()}`} 
            labTxtVariant="subtitle2"
            amoTxtVariant="subtitle2"
          />
          <LabeledAmount 
            label={coupon.name} 
            labTxtVariant="caption"
          />
        </>
      ): null}
      <LabeledAmount 
        label="Annual Total" 
        amount={`$${(subtotal - discount).toLocaleString()}`} 
        labTxtVariant="h6"
        amoTxtVariant="h6"
      />
      <Grid 
        container 
        justify="flex-end"
      >
        <Typography variant="caption">
          {cardExists || cardLoading ? null : "No card found. Add now."}
        </Typography>
        {newCard ? (
          <>
            <PaymentCards 
              filter={newCardFilter.filter} 
            /> 
          </>
        ) : (
          <>
            <PaymentCards 
              setCardExists={setCardExists}
              setCardLoading={setCardLoading}
              forceUpdate={cardAdded}
              defaultOnly 
            /> 
          </>
        )}
      </Grid>
      <Button 
        className={classes.buttonRight}
        disabled={paymentInProgress || cardLoading}
        variant="outlined"
        size="small"
        onClick={() => setCardModalOpen(true)}
      >
        {cardExists? 'CHANGE ' : 'ADD '} 
        PAYMENT METHOD
      </Button>
      <Modal 
        open={cardModalOpen}
        onClose={() => setCardModalOpen(false)}
      >
        <ModalContainer>
          {cardExists ? (
            <PaymentMethodChange 
              onItemClick={card => {
                setNewCard(card)
                setCardModalOpen(false)
              }} 
            />
          ) : (
            <PaymentMethodAdd 
              onComplete={() => {
                setCardAdded(true)
              }}
            />
          )}
        </ModalContainer>
      </Modal>
      <Button 
        className={classes.buttonPay}
        disabled={paymentInProgress || couponError || !cardExists || cardLoading}
        variant="contained"
        color="primary"
        fullWidth
        onClick={async () => {
          const hasError = await handlePayment(pws, customerId, coupon, newCard, setPaymentInProgress)
          if (hasError) {
            history.push('/billing/subscriptions/payment-failed')
          } else {
            history.replace('/billing/subscriptions/payment-successful')
          }
        }}
      >
        {paymentInProgress ? <CircularProgress color="inherit" size={16} style={{ marginRight: 5 }} /> : null}
        ACTIVATE & PAY ${(subtotal - discount).toLocaleString()}
      </Button>
      <Typography 
        variant="caption" 
        align="center"
      >
        Secure payments powered by Stripe
      </Typography>
    </>
  )
}

export default compose(
  withUserData,
  withStyles(styles)
)(Payment) 