import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { API, graphqlOperation } from 'aws-amplify'

import withUserData from 'components/withUserData'
import * as queries from 'graphql/queries'

const styles = theme => ({
  item: {
    backgroundColor: theme.palette.secondary.main,
    marginBottom: 12,
  },
  itemText: {
    color: 'white',
  }
})

function WaterSourcesList(props) {
  const { 
    classes,
    onItemClick,
    notFoundMessage,
    pwsId,
  } = props

  const [sources, setSources] = useState([])
  const [queryComplete, setQueryComplete] = useState(false)

  let isMounted

  useEffect(() => {
    isMounted = true

    ;(async() => {
      if(!isMounted) return
      
      setQueryComplete(false)
      const listWaterSourcesFilter = {
        filter: {
          waterSystemId: {
            eq: pwsId
          }
        },
        limit: 1000
      }
      const resultListWaterSources = await API.graphql(graphqlOperation(queries.listWaterSources, listWaterSourcesFilter))  
      let sources = resultListWaterSources.data.listWaterSources.items
      
      if(!isMounted) return
      
      setSources(sources)
      setQueryComplete(true)
    })()

    return () => {
      isMounted = false
    }
  
  }, [pwsId])

  if(!queryComplete) {
    return (
      <ListItem> 
        <CircularProgress />
      </ListItem>
    )
  }

  if(!sources.length) {
    return (
      <ListItem>
        <ListItemText primary={notFoundMessage} />
      </ListItem>
    )
  }
    
  return (
    <>
      {sources.map((item) => (        
        <Card 
          className={classes.item} 
          key={item.id}
          raised
        >
          <CardActionArea 
            onClick={() => onItemClick(item)}
          >
            <CardContent>
              <Typography 
                className={classes.itemText} 
                variant="h5" 
                component="h3"
              >
                {item.name}
              </Typography>    
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </>
  )
}

WaterSourcesList.defaultProps = {
  onItemClick: () => {},
  notFoundMessage: "No water source found",
}

WaterSourcesList.propTypes = {
  pwsId: PropTypes.string.isRequired,
  notFoundMessage: PropTypes.string,
  onItemClick: PropTypes.func,
}

export default compose(
  withUserData,
  withStyles(styles)
)(WaterSourcesList)