import React from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

import ToolbarContent from 'components/ToolbarContent'

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
  },
  paragraph: {
    marginBottom: 10,
  },
  button: {
    marginTop: 10,
  }
})

function PaymentFailed(props) {
  const {
    classes,
    history,
  } = props

  return (
    <>
      <ToolbarContent
        screenTitle = "Payment Failed"
        isMainScreen = {false}
      />
      <Paper className={classes.root}>
        <Typography className={classes.paragraph} variant="body1">
          Your payment was unsuccessful.
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          Please check with your card provider to understand what went wrong.
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          Thank you using Water Utility App and if you ever need help just email WaterUtilityApp@gmail.com or call (208) 424-1000.
        </Typography>
        <Typography variant="body1">
          Sincerely,
        </Typography>
        <Typography variant="body1">
          Tui Anderson
        </Typography>
        <Button 
          className={classes.button}
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => history.goBack()}
        >
          BACK TO PAYMENTS PAGE
        </Button>
      </Paper>
    </>
  )
}

export default withStyles(styles)(PaymentFailed) 