import React, { useEffect } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import * as mutations from 'graphql/mutations'
import ToolbarContent from 'components/ToolbarContent'
import ContentHeader from 'components/ContentHeader'
import { removeEmpty } from 'services/graphqlHelpers'
import { prettifyTimestamp, prettifyDateRange } from 'services/utilityFunctions'
import ProductionDataForm from '../../components/ProductionDataForm'

const handleSave = async (params) => {
  const {
    id,
    waterSourceId,
    type,
    date,
    time,
    pumpRunTime,
    currentMeterRead,
    startDate,
    endDate,
    volume,
  } = params
  
  let inputSourceProductionUpdate = {
    id,
    waterSourceId,
    type,
    timestamp: String(date) + String(time).replace(':', ''),
    reading: currentMeterRead,
    pumpRunTime,
    startDate,
    endDate,
    volume,
  }

  inputSourceProductionUpdate = removeEmpty(inputSourceProductionUpdate)
  
  try {
    await API.graphql(graphqlOperation(mutations.updateSourceProduction, { input: inputSourceProductionUpdate }))
  } catch (error) {
    console.log(error)
  }
}

function ProductionDataEdit(props) {
  const {
    location,
    history,
  } = props

  const { pwsName, source, sourceProduction } = location.state
  const title = sourceProduction.type === 'meter' 
    ? prettifyTimestamp(sourceProduction.timestamp)
    : prettifyDateRange(sourceProduction.startDate, sourceProduction.endDate)

  useEffect(() => {

  }, [])

  return (
    <>
      <ToolbarContent
        screenTitle = {`Production Data Edit`}
        isMainScreen = {false}
      />
      <ContentHeader
        breadcrumbs={[
          { name: 'Production Data', goBack: true, step: 4 },
          { name: pwsName, goBack: true, step: 3 }, 
          { name: source.name, goBack: true, step: 2 }, 
          { name: `${title}`, goBack: true, step: 1 },
          { name: `Edit` } 
        ]}
        history={history}
      />
      <ProductionDataForm 
        switchDisabled
        source={source}
        sourceProduction={sourceProduction}
        handleSave={async (args) => handleSave(args)}
        history={history}
      />
    </>
  )
}

export default ProductionDataEdit