import React from 'react'
import Typography from '@material-ui/core/Typography'

import ILIRangeTable from './components/ILIRangeTable'

function Definition(props) {
  function Bold(props) {
    const { children, ...inputProps } = props
    return (
      <Typography {...inputProps} variant="subtitle2" component="span" inline>
        {children}
      </Typography>
    )
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        DEFINITION
      </Typography>
      <Typography paragraph>
        Note: The International Water Association (IWA) and the American Water Works Association
        (AWWA) have developed the following standardized definitions and performance indicators
        used in the IWA/AWWA Water Audit Methodology.
      </Typography>
      <Typography paragraph>
        <Bold>Apparent Losses: </Bold> 
        Unauthorized consumption, all types of customer metering inaccuracies, and systematic data 
        handling errors in customer billing operations.
      </Typography>
      <Typography paragraph>
        <Bold>Authorized Consumption: </Bold>
        Volume of metered and/or unmetered water taken by registered customers, the water supplier 
        and others who are implicitly or explicitly authorised to do so by the water supplier, for 
        residential, commercial and industrial purposes.
      </Typography>
      <Typography paragraph>
        <Bold>Current Annual Real Losses (CARL): </Bold>  
        The annual volume lost through all types of leaks and breaks in water mains and service connections, 
        up to the point of customer metering.  Real losses also include overflows and leaks from treated 
        water storage tanks.
      </Typography>
      <Typography paragraph>
        <Bold>Non-Revenue Water: </Bold> 
        The sum of Water Losses plus Unbilled Authorized Consumption. The term Non-Revenue Water should be
        used instead of the imprecise term unaccounted-for Water. It is recognized that some of this component 
        water of Non-Revenue water is authorized consumption (unbilled).
      </Typography>
      <Typography paragraph>
        <Bold>Unavoidable Annual Real Losses (UARL): </Bold>
        Reported in gallons, based on miles of mains, number of service connections, total length of 
        customer service connection pipe from curb stop to customer meter, and average system pressure. 
        The <Bold>UARL</Bold> is a theoretical reference value representing the technical low limit of leakage
        that would exist in a distribution even if all of today’s best leakage control technology could be 
        successfully applied in that system. The <Bold>UARL</Bold> is not a performance indicator but is used
        as the denominator in calculating the Infrastructure Leakage Index <Bold>(ILI)</Bold> No system can 
        achieve zero water loss because water distribution systems are not perfectly sealed. The <Bold>UARL </Bold>
        is a system-specific calculation that varies among systems as the miles of pipe increases, system pressure 
        changes, connections are added/lost, and other system changes are made.
      </Typography>
      <Typography paragraph>
        <Bold>Water Audit: </Bold>
        A water audit is an accounting of all of the water in a water system resulting in a quantified 
        understanding of the integrity of the water system and its operation. It is the first step in 
        formulating an economically sound plan to address water losses.
      </Typography>
      <Typography paragraph>
        <Bold>Water Losses: </Bold>
        The difference between System Input Volume and Authorized Consumption, consisting of Apparent 
        Losses and Real Losses.
      </Typography>
      <Typography paragraph>
        <Bold>Water Supplied: </Bold>
        The sum volumes of water supplied to the system including water pumped from well sources, 
        water from a treatment plant, water from spring sources and bulk water purchases. Basically this
        is the sum of the treated/potable water supplied to the water system. 
      </Typography>
      <Typography paragraph>
        <Bold id="definition-ili">Infrastructure Leakage Index (ILI): </Bold>
        The <Bold>ILI</Bold> is calculated by dividing Current Annual Real Losses (<Bold>CARL</Bold>) by 
        <Bold> UARL</Bold>. (ratio of <Bold>CARL</Bold> to <Bold>UARL</Bold>) The ILI is the best performance 
        indicator for benchmarking leakage within an individual water provider because it is a dimensionless 
        performance indicator. An ILI close to “1” indicates the utility’s Real Losses are close to the UARL
        and therefore further reductions in Real Water Losses might be unattainable or uneconomical. The ILI 
        range table is located below to assist with goal setting.
      </Typography>
      <ILIRangeTable />
      <Typography component="div" variant="caption" paragraph>
        <ul style={{ listStyle: 'none '}}>
          <li>
            Note:	Current AWWA water audit software does not calculate a UARL for systems if:
            <ul style={{ listStyle: 'none '}}>
              <li>
                (Lm x 32) + Nc &lt; 3,000 (where Lm = length of mains, Nc = number of customer service connections) 
                Or: P &lt; 35 psi, where P = average system pressure.
              </li>
            </ul>
          </li>
        </ul>
      </Typography>
      <Typography paragraph>
        This is the note from AWWA regarding the UARL calculation: “The UARL calculation has not yet been 
        proven fully effective for very small or very low pressure water systems.“
      </Typography>
      <Typography>
        <Bold>However</Bold>, recent research in Europe suggests the UARL calculation can be valid for small systems, less 
        than 3,000 services. These small systems can achieve ILIs less than 1.0. This is due to several possible
        reasons which can occur separately or together:
      </Typography>
      <Typography component="article">
        <ul>
          <li>Pressures less than 60 psi and high % of flexible pipes</li>
          <ul>
            <li>This can lead to a lower ILI &nbsp;for systems of all sizes</li>
          </ul>
          <li>If all leaks surface the UARL formula may be 25% too high</li>
          <ul>
            <li>This is true for systems of all sizes</li>
          </ul>
          <li>Individual unreported bursts can be easily identified and fixed</li>
          <ul>
            <li>This can lead &nbsp;to a lower ILI for small systems</li>
          </ul>
          <li>Burst frequencies lower than assumed in UARL formula</li>
          <ul>
            <li>This can lead &nbsp;to a lower ILI for small systems</li>
          </ul>
        </ul>
      </Typography>
      <Typography paragraph>
        The factors listed above can lead to an overestimated CARL, even if no systematic errors, 
        especially for small water systems.
      </Typography>
      <Typography paragraph>
        Small water systems, less than 5,000 service connections, are potentially able to achieve an ILI of 1 or 
        less. This is due to the ability to have a “tighter” systems. Leaks and breaks are able to be identified 
        and fixed more quickly than a larger system. If a small system has a significant line break the operator 
        is able to notice changes to their system very quickly such as: lower pressure, pumps running more often 
        or storage tank levels dropping.  The operator of a small system will often times need to find and fix the
        break for the system to maintain operations.
      </Typography>
    </>
  )
}

export default Definition