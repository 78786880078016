// NEW - new organization, will be given an option to start a 30 day trial as a new customer
// TRIAL_ACTIVE - organization is on trial mode, users can use all the paid features of the app like the reporting 
// TRIAL_OVER - organization has used up its trial period and is not subscribed to Stripe
// SUBSCRIBED - organization has provided a payment method (credit card) and has been subscribed to Stripe

export const billingStatusVal = {
  NEW: 'NEW',
  TRIAL_ACTIVE: 'TRIAL_ACTIVE',
  TRIAL_OVER: 'TRIAL_OVER',
  SUBSCRIBED: 'SUBSCRIBED',
}