import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/styles'
import { API, graphqlOperation } from 'aws-amplify'

import * as queries from 'graphql/queries'

const useStyles = makeStyles({
  select: {
    minWidth: 150,
  },
})

function WaterSystemDropdown(props) {
  const {
    onChange,
    organizationId
  } = props
  
  const classes = useStyles()

  const [pwsList, setPwsList] = useState([])
  const [pwsId, setPwsId] = useState(' ')

  let isMounted

  useEffect(() => {
    isMounted = true

    getPWs()

    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    onChange(pwsId)
  }, [pwsId])

  async function getPWs() {
    const listWaterSystemsFilter = {
      filter: {
        organizationId: {
          eq: organizationId
        }
      },
      limit: 1000
    }
    const resultListWaterSystems = await API.graphql(graphqlOperation(queries.listWaterSystems, listWaterSystemsFilter))  
    let pwsList = resultListWaterSystems.data.listWaterSystems.items
    
    if (!isMounted || pwsList.length === 0) return

    setPwsList(pwsList)
    setPwsId(pwsList[0].id)
  }

  return (
    <>
      <TextField 
        id="pws" 
        label="Water System" 
        className={classes.select}
        value={pwsId} 
        onChange={(e) => setPwsId(e.target.value)} 
        margin="normal" 
        select
        SelectProps={{
          native: true,
        }} 
      >
        {pwsList.map((option, i) => (
          <option key={i} value={option.id}>
            {option.name}
          </option>
        ))}
      </TextField>
    </>
  )
}

WaterSystemDropdown.defaultProps = {
  onChange: () => {}
}

WaterSystemDropdown.propTypes = {
  onChange: PropTypes.func,
  organizationId: PropTypes.string.isRequired,
}

export default WaterSystemDropdown