import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import Search from '@material-ui/icons/Search'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { API, graphqlOperation, } from 'aws-amplify'
import { compose } from 'recompose'

import * as mutations from 'graphql/mutations'
import * as queries from 'graphql/queries'
import withUserData from 'components/withUserData'
import ToolbarContent from 'components/ToolbarContent'
import { UserContext } from 'contexts'

const styles = () => ({
  button: {
    margin: 'auto',
  },
})

class SearchOrganization extends Component {
  state = {
    didSearch: false,
    searchComplete: false,
    organizationsSearch: [],
    organizationsList: [],
    updatedUserData: null,
    updateContext: false,
  }

  onSearchEnter = async (searchInput) => {
    if (searchInput.length === 0) {
      await this.setState({ 
        organizationsSearch: [], 
        didSearch: false,
        searchComplete: false,
      })
      return 
    }
    this.setState({ didSearch: true })
    searchInput = searchInput.toLowerCase()
    let regexpInput = ''
    let splitInput = searchInput.split(' ')
    splitInput.map((item, index) => {
      if(index === 0) return regexpInput = `.*${item}.*`
      if(item.length === 0) return null
      return regexpInput = regexpInput +`|.*${item}.*`
    })
    try {
      const queryOrganizationsList = await API.graphql(graphqlOperation(queries.searchOrganizations, { filter: { name: { regexp: regexpInput } } } ))
      const organizationsSearch = queryOrganizationsList.data.searchOrganizations.items
      if(this.state.didSearch) {
        this.setState({ 
          organizationsSearch,
          searchComplete: true,
        })
      }      
    } catch (error) {
      this.setState({ searchComplete: true })
    }
  }
  
  createNewOrganization = () => async () => {
    const inputCreateOrganization = {
      name: 'Your Organization',
      description: 'No description',
    }
    const createdOrganization = await API.graphql(graphqlOperation(mutations.createOrganization, { input: inputCreateOrganization }))
    //connect current User to newly created organization
    
    console.dir(createdOrganization)

    const inputUpdateUser = {
      id: this.props.userContext.currentUser.attributes.sub, 
      organizationId: createdOrganization.data.createOrganization.id,
      userOrganizationId: createdOrganization.data.createOrganization.id,
    }
    const updateUserResult = await API.graphql(graphqlOperation(mutations.updateUser, { input: inputUpdateUser }))
    
    const updatedUserData = updateUserResult.data.updateUser 
    //update context
    await this.setState({ 
      updateContext: true,
      updatedUserData
    })

    // Create a PWS with a source    
    try {
      const inputCreateWaterSystem = {
        name: 'Your Public Water System',
        organizationId: createdOrganization.data.createOrganization.id,
        activeConnections: '0',
        activeConnectionsValidity: '3',
        inactiveConnections: '0',
        inactiveConnectionsValidity: '3',
        waterMainLen: '0',
        waterMainLenValidity: '3',
        percentFlexiblePipe: '0',
        aveSysPressure: '0',
        aveSysPressureValidity: '3',
        avePipeLength: '0',
        isFullyMetered: true,
        hasProgram: false,
        aveMeterAge: '0',
      }
      const result = await API.graphql(graphqlOperation(mutations.createWaterSystem, { input: inputCreateWaterSystem }))
      const pws = result.data.createWaterSystem

      const inputCreateWaterSource = {
        name: 'Your Water Source',
        waterSystemId: pws.id,
        waterSourceWaterSystemId: pws.id,
        units: 'Kgal',
        isMetered: false,
      }
      await API.graphql(graphqlOperation(mutations.createWaterSource, { input: inputCreateWaterSource }))
    } catch (error) {
      console.log(error)  
    }

    this.props.history.push('/new-organization')
  }

  updateContext = () => {
    this.setState({ updateContext: false })
    return (
      <UserContext.Consumer>
        {({ updateUserData }) => {
          updateUserData(this.state.updatedUserData)
        }}
      </UserContext.Consumer>
    )
  }

  render() {
    const { classes } = this.props
    const { 
      updateContext,
      organizationsSearch, 
      didSearch,
      searchComplete,
    } = this.state
 
    return (
      <>
        {updateContext ? this.updateContext() : null}
        <ToolbarContent
          screenTitle = "Search Organization"
          isMainScreen = {false}
        />
        <Grid item xs={12} sm={6}>
          <Typography paragraph>
            Start typing the name of your organization and it will auto complete.
            If you find it, just tap the name to request to join.
          </Typography>
          <TextField
            className={classes.margin}
            onChange={e => this.onSearchEnter(e.target.value)}
            id="search-field"
            type="search"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          {(didSearch && !searchComplete) ? <CircularProgress /> : null}
          {(searchComplete && organizationsSearch.length !== 0) ? (
            <>
              <List>
                {organizationsSearch.map((org) => (
                  <ListItem key={org.id} button component={Link}
                    to={`join/${org.id}/${org.name}`}
                  >
                    <ListItemText primary={org.name} />
                  </ListItem>
                ))}
              </List>
            </>
          ) : null}
          {(searchComplete && organizationsSearch.length === 0) ? (
            <>
              <Typography paragraph>
                Not found
              </Typography>
            </>
          ) : null}
          {searchComplete ? (
            <Button 
              className={classes.button} 
              variant="contained" 
              color="primary"
              fullWidth
              onClick={this.createNewOrganization()}
            >
              START A NEW ORGANIZATION
            </Button>
          ) : null}
        </Grid>
      </>
    )
  }
}

export default compose(
  withUserData,
  withStyles(styles)
)(SearchOrganization)