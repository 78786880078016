import React, { useState } from 'react'

import ToolbarContent from 'components/ToolbarContent'
import AddFabButton from 'components/AddFabButton'
import PaymentCards from '../../components/PaymentCards'

function PaymentInfo(props) {
  const {
    history,
    location,
  } = props

  const [cardExists, setCardExists] = useState(true)

  return (
    <>
      <ToolbarContent
        screenTitle = "Payment Information"
        isMainScreen = {false}
      />
      {!cardExists ? <>Tap the "Plus" button to add a credit card.</> : null}
      <PaymentCards 
        setCardExists={setCardExists}
        onItemClick={card => history.push({
          pathname: `${location.pathname}/edit/${card.id}`,
          state: { card },
        })} 
      />
      <AddFabButton
        id="customButton"
        onClick={() => history.push(`${location.pathname}/new`)}
      />
    </>
  )
}

export default PaymentInfo 