import React, { useState, useEffect } from 'react'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Skeleton from 'react-loading-skeleton'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import intersection from 'lodash/intersection'
import { makeStyles } from '@material-ui/styles'
import { API, graphqlOperation } from 'aws-amplify'

import * as mutations from 'graphql/mutations'
import ToolbarContent from 'components/ToolbarContent'
import ContentHeader from 'components/ContentHeader'
import { formatDate } from 'services/utilityFunctions'
import CustomSlider from './components/CustomSlider'

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: 50,
  },
  sliderWrapper: {
    height: 200,
    float: 'left'
  }
}))

function ReportDateRangeSelect(props) {
  const classes = useStyles()
  const {
    history,
    match,
    location
  } = props

  const { pws } = location.state

  const [loading, setLoading] = useState(true)
  const [allDates, setAllDates] = useState([])
  const [sliderBlocks, setSliderBlocks] = useState([])
  const [activeGroup, setActiveGroup] = useState(1)
  const [force, setForce] = useState(false)
  
  const dateSelection = allDates.filter(item => item.tag === 'common' && item.group === activeGroup)
  let group = 0
  let isMounted

  useEffect(() => {
    isMounted = true

    ;(async () => {
      // get production and consumption dates
      const inputApiAction = {
        name: 'getProdAndConsDates',
        parameters: [
          pws.id,
        ]
      } 

      const result = await API.graphql(graphqlOperation(mutations.createApiAction, { input: inputApiAction })) 
      
      if (!isMounted) return
      
      const datesJSON = result.data.createAPIAction.getProdAndConsDatesResolver
      const dates = JSON.parse(datesJSON)

      // align dates of production and consumption data
      const extractedProdDates = dates.production
      const extractedConsDates = dates.consumption
      let allDates = []

      // collect common dates
      const commonDates = intersection(extractedProdDates, extractedConsDates)
      const labelledCommonDates = commonDates.map(date => ({ date, tag: 'common' }))
      
      // add dates with label to the master array
      Array.prototype.push.apply(allDates, labelledCommonDates)
      
      // collect dates for production
      const uniqueProdDates = extractedProdDates.filter(date => !commonDates.includes(date))
      const labelledUniqueProdDates = uniqueProdDates.map(date => ({ date, tag: 'production' }))
      
      // add dates with label to the master array
      Array.prototype.push.apply(allDates, labelledUniqueProdDates)
      
      // collect dates for consumption
      const uniqueConsDates = extractedConsDates.filter(date => !commonDates.includes(date))
      const labelledUniqueConsDates = uniqueConsDates.map(date => ({ date, tag: 'consumption' }))
      
      // add dates with label to the master array
      Array.prototype.push.apply(allDates, labelledUniqueConsDates)

      // sort dates with the most recent on top
      allDates.sort((a, b) => {
        return b.date - a.date
      })

      setAllDates(allDates)

      // arrange slider section
      let sliderBlocks = []
      const blockHeight = 35.99
      let block = 0
      let isCommon = false
      let group = 0
      for (let i = 0; i < allDates.length; i++) {
        if (allDates[i].tag === 'common' && !isCommon) {
          isCommon = true

          group++
          allDates[i].group = group
          
          if (block !== 0) { 
            sliderBlocks.push(block)
          }

          block = 1
          continue
        } else if (allDates[i].tag !== 'common' && isCommon) {
          isCommon = false
          sliderBlocks.push(block)
          block = -1
          continue
        }
        
        if (isCommon) {
          allDates[i].group = group
          block++
        } else {
          block--
        }
        
        if (i === allDates.length - 1) {
          sliderBlocks.push(block)
        }
      }

      setSliderBlocks(sliderBlocks.map(unit => unit * blockHeight))
      setLoading(false)
    })()

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <>
      <ToolbarContent
        screenTitle = 'Date Ranges'
        isMainScreen = {false}
      />
      <ContentHeader
        breadcrumbs={[
          { name: 'Reports', goBack: true, step: 1 }, 
          { name: pws.name + ' Date Range Select' }, 
        ]}
        history={history}
      />
      <Divider style={{ height: 30, background: 'transparent' }} />
      <Typography align="center" gutterBottom>
        Choose the date range for this report
      </Typography>
      <Divider style={{ background: 'transparent' }} />
      <Grid container>
        <Grid item xs={5}>
          <Typography variant="subtitle2" align="center">
            Production Data
          </Typography>
        </Grid>
        <Grid item xs={2}>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="subtitle2" align="center">
            Consumption Data
          </Typography>
        </Grid>
      </Grid>
      {loading ? (
        <Grid container>
          <Grid item xs={5}>
            <List dense>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography align="center">
                      <Skeleton width="80%" count={12} />
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={2}>
          </Grid>
          <Grid item xs={5}>
            <List dense>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography align="center">
                      <Skeleton width="80%" count={12} />
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      ) : null}
      <Grid container>
        <Grid item xs={5}>
          <List dense>
            {allDates.map(item => (
              <ListItem key={item.date}>
                <ListItemText
                  primary={
                    <Typography 
                      align="center" 
                      color={item.tag === 'common' && item.group === activeGroup ? 'secondary' : 'default'}
                    >
                      {item.tag !== 'consumption' ? formatDate(item.date, 'Mmm yyyy') : <>&nbsp;</>}
                    </Typography>
                  }
                  style={{ paddingRight: 0 }}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={2}>
          {sliderBlocks.map((height, index) => (
            <div key={index}>
              {height > 0 ? (
                (() => {
                  group++
                  return (
                    <div style={{ height }}>
                      <CustomSlider 
                        group={group} 
                        activeGroup={activeGroup}
                        activeGroupSetter={setActiveGroup}
                        height={height} 
                        dates={allDates}
                        onChange={date => {
                          // force re-render by toggling this state
                          setForce(prevState => !prevState)
                          
                          // update dates
                          setAllDates(date)
                        }}
                      />
                    </div>
                  ) 
                })()
              ) : (
                <div style={{ height: Math.abs(height) }} />
              )}
            </div>
          ))}
        </Grid>
        <Grid item xs={5}>
          <List dense>
            {allDates.map(item => (
              <ListItem key={item.date}>
                <ListItemText
                  primary={
                    <Typography 
                      align="center" 
                      color={item.tag === 'common' && item.group === activeGroup ? 'secondary' : 'default'}
                    >
                      {item.tag !== 'production' ? formatDate(item.date, 'Mmm yyyy') : <>&nbsp;</>}
                    </Typography>
                  }
                  style={{ paddingRight: 0 }}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
      <Divider style={{ background: 'transparent' }} />
      <Button
        className={classes.button}
        disabled={loading || dateSelection.length === 0 ? true : false}
        variant="contained"
        fullWidth
        color="primary"
        onClick={() => {
          // dateRange takes first array item as the start date and the second item as end date
          // the last item of dateSelection is the start date since the array is sorted with the latest on top
          const dateRange = [ dateSelection[dateSelection.length - 1], dateSelection[0] ]
          
          history.push({
            pathname: match.url.replace('date-range-select', 'options'),
            state: { 
              pws, 
              dateRange,
            }
          })
        }}
      >
        Next
      </Button>
    </>
  )
}

export default ReportDateRangeSelect