import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/SwipeableDrawer'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { withStyles } from '@material-ui/core/styles'

const drawerWidth = 300

const style = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    paddingLeft: 10,
    paddingRight: 10,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    maxWidth: 840,
    paddingTop: 74,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  contentPaper: {
    minHeight: 'calc(100vh - 84px)',
    position: 'relative',
    marginBottom: 10,
  },
  paper: {
    width: 'inherit',
  }
})

function AppFrameMedium(props) {
  const {
    AppToolbar,
    AppDrawer,
    classes,
    onRender,
  } = props

  const [drawerOpen, setDrawerOpen] = useState(true) 

  useEffect(() => {
    onRender({
      content: classes.content, 
      contentPaper: classes.contentPaper
    })
  }, [])

  return (
    <>
      <AppBar 
        color="secondary"
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.appBarShift]: drawerOpen,
        })}  
      >
        <AppToolbar 
          menuClick={() => setDrawerOpen(true)} 
          renderIcon={!drawerOpen}
        />
      </AppBar>
      <Drawer 
        className={
          classNames({
          [classes.drawer]: true,
          [classes.hide]: !drawerOpen,
        })}
        classes={{
          paper: classes.paper
        }}
        variant="persistent"  
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => setDrawerOpen(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <AppDrawer itemClick={() => setDrawerOpen(false)} />
      </Drawer>
      {/* <main className={classes.content}>
        <Paper className={classes.contentPaper}>
          <AppContent />
        </Paper>
      </main> */}
    </>
  )
}

AppFrameMedium.defaultProps = {
  AppToolbar: () => {},
  AppDrawer: () => {},
  onRender: () => {},
} 
  
AppFrameMedium.propTypes = {
  AppToolbar: PropTypes.func.isRequired,
  AppDrawer: PropTypes.func.isRequired,
  onRender: PropTypes.func,
}

export default withStyles(style)(AppFrameMedium)