import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import CircularProgress from '@material-ui/core/CircularProgress'
import { API, graphqlOperation } from 'aws-amplify'
import { compose } from 'recompose'

import * as queries from 'graphql/queries'
import withUserData from 'components/withUserData'
import { removeEmpty } from 'services/graphqlHelpers'
import { formatDate, getTimeString } from 'services/utilityFunctions'

function MaintenanceJournalListItems(props) {
  const {
    onItemClick,
    notFoundMessage,
    waterSystemId,
    filter,
  } = props

  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)

  let isMounted

  useEffect(() => {
    isMounted = true
    
    setStates()

    return () => {
      isMounted = false
    }

  }, [filter])

  async function setStates() {
    const items = await getListItems() || []
    
    if (!isMounted) return 

    setItems(items)
  }

  async function getListItems() {
    setLoading(true)
    const tableName = "MaintenanceJournal"
    
    let args = {
      filter: waterSystemId ? {
        waterSystemId: { eq: waterSystemId }
      } : null,
      limit: 1000
    } 
    
    removeEmpty(args)

    try {
      const result = await API.graphql(graphqlOperation(queries["list" + tableName + "s"], args))
      const items = result.data["list" + tableName + "s"].items
      
      if (!isMounted) return []
      
      // sort by start date
      items.sort((a, b) => {
        return b.timestamp - a.timestamp
      })
      
      setLoading(false)
      
      return items
    
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  if (loading) return <CircularProgress />

  if (!loading && items.length === 0) {
    return (
      <>
        {notFoundMessage}
      </>
    )
  }

  return (
    <>
      {items.map(item => {
        let { id, timestamp, text } = item
        const date = formatDate(timestamp ? timestamp.slice(0, 8) : null, 'Mmm dd, yyyy')
        const time = timestamp ? getTimeString(timestamp.slice(8, 10), timestamp.slice(10, 12)) : null
        
        text = text.split('\n')

        // Remove starting lines that are blank
        let temp = []
        let lineFound = false
        text.forEach(line => {
          if (lineFound) {
            return temp.push(line)
          }

          if (line && !lineFound) {
            lineFound = true
            return temp.push(line)
          }
        })

        text = temp

        return (
          <ListItem 
            key={id}
            button
            divider
            onClick={() => onItemClick(item)}
          >
            <ListItemText 
              primary={
                <Grid
                  container
                  direction="column"
                >
                  <Grid container direction="row">
                    <Typography 
                      variant="body2" 
                      style={{     
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {text[0]}
                    </Typography>
                  </Grid>
                </Grid>
              } 
              secondary={<>{date} {time}</>} 
            />
          </ListItem>
        )
      })}
    </>
  )
}

MaintenanceJournalListItems.defaultProps = {
  onItemClick: () => {},
  notFoundMessage: 'No journal entry found',
  waterSystemId: null,
  filter: null,
}

MaintenanceJournalListItems.propTypes = {
  onItemClick: PropTypes.func,
  notFoundMessage: PropTypes.string,
  waterSystemId: PropTypes.string,
  filter: PropTypes.func,
}

export default compose(
  withUserData,
)(MaintenanceJournalListItems)