import React from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  // Title,
  Legend,
} from '@devexpress/dx-react-chart-material-ui'
import { withStyles } from '@material-ui/core/styles'
import { Stack, Animation } from '@devexpress/dx-react-chart'

import DataTable from './components/DataTable'

const legendStyles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
})
const legendRootBase = ({ classes, ...restProps }) => (
  <Legend.Root {...restProps} className={classes.root} />
)
const Root = withStyles(legendStyles, { name: 'LegendRoot' })(legendRootBase)
const legendLabelStyles = () => ({
  label: {
    whiteSpace: 'nowrap',
  },
})
const legendLabelBase = ({ classes, ...restProps }) => (
  <Legend.Label className={classes.label} {...restProps} />
)
const Label = withStyles(legendLabelStyles, { name: 'LegendLabel' })(legendLabelBase)

const cleanNumber = numberString => {
  numberString = String(numberString)
  return Number(numberString.replace(/,/g,''))
}

function BarChart(props) {
  const {
    chartData
  } = props
  
  const dataWithSortedKey = []
  const dataNumbers = []
  const volumeTotalPerItem = []

  chartData.map((item) => {
    // convert display values into numbers
    dataNumbers.push({
      date: item.display_date.slice(0, 4) + "'" + item.display_date.slice(-2), // with shortened year format, ex. 2010 -> '10
      recoverableRealLoss: cleanNumber(item.recoverableRealLoss), 
      apparentLoss: cleanNumber(item.aparentLoss), 
      unavoidableLoss: cleanNumber(item.unavoidableLoss), 
      authorizedConsumption: cleanNumber(item.authorizedConsumption), 
    })
    
    // sort keys
    dataWithSortedKey.push({
      date: item.display_date, 
      recoverableRealLoss: item.recoverableRealLoss, 
      apparentLoss: item.aparentLoss, 
      unavoidableLoss: item.unavoidableLoss, 
      authorizedConsumption: item.authorizedConsumption, 
    })

    // get total volume per item
    const volumeTotal = Number(item.authorizedConsumption) 
                        + Number(item.unavoidableLoss) 
                        + Number(item.aparentLoss)
                        + Number(item.recoverableRealLoss)

    volumeTotalPerItem.push(volumeTotal)

    return item
  })

  let max = volumeTotalPerItem.sort((a, b) => {
    return b - a
  })[0]

  max = Math.ceil(max)

  return (
    <Paper id="content-wrapper" style={{ padding: 8, marginTop: 10, marginBottom: 20 }}>
      <Grid id="chart-wrapper" style={{ overflowX: 'auto' }}>
        <Chart
          data={dataNumbers}
        >
          <ArgumentAxis />
          <ValueAxis
            max={max}
          />
          <BarSeries
            name="Authorized Consumption"
            valueField="authorizedConsumption"
            argumentField="date"
            color="#3182bd"
            maxBarWidth={30}
          />
          <BarSeries
            name="Unavoidable Loss"
            valueField="unavoidableLoss"
            argumentField="date"
            color="#6baed6"
            maxBarWidth={30}
          />
          <BarSeries
            name="Apparent Loss"
            valueField="apparentLoss"
            argumentField="date"
            color="#9ecae1"
            maxBarWidth={30}
          />
          <BarSeries
            name="Recoverable Real Loss"
            valueField="recoverableRealLoss"
            argumentField="date"
            color="#cc3b47"
            maxBarWidth={30}
          />
          <Animation />
          <Legend position="bottom" rootComponent={Root} labelComponent={Label} />
          {/* <Title text="Consumption and Loss" /> */}
          <Stack
            stacks={[
              { series: [
                'Recoverable Real Loss',
                'Apparent Loss', 
                'Unavoidable Loss', 
                'Authorized Consumption', 
              ]},
            ]}
          />
        </Chart>
        <DataTable 
          data={dataWithSortedKey}
        />
      </Grid>
    </Paper>
  )
}


export default BarChart