import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import piexif from 'piexifjs'
import CircularProgress from '@material-ui/core/CircularProgress'
import { styled } from '@material-ui/styles'

const exemptedDevices = [
  'iPad Simulator',
  'iPhone Simulator',
  'iPod Simulator',
  'iPad',
  'iPhone',
  'iPod'
]

const toDataURL = async url => {
  const response = await fetch(url)
  const blob = await response.blob()

  return await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = function() {
      resolve(reader.result)
    }
    reader.onerror = function(e) {
      reject(e);
    }
    reader.readAsDataURL(blob)
  })
}

const getDimensions = async url => {
  return await new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve({ width: img.width, height: img.height })
    }
    img.onerror = function(e) {
      reject(e);
    }
    img.src = url
  })
}

function Img(props) {
  const {
    src,
    width: propWidth,
    loader,
    unloader,
    imgProps,
    ...others
  } = props

  const [transformStyle, setTransformStyle] = useState('initial')
  const [transformOriginStyle, setTransformOriginStyle] = useState('unset')
  const [imgWidth, setImgWidth] = useState(0)
  const [imgHeight, setImgHeight] = useState(0)
  const [scaledWidth, setScaledWidth] = useState(0)
  const [scaledHeight, setScaledHeight] = useState(0)
  const [processing, setProcessing] = useState(true)
  const [exif, setExif] = useState(false)
  const [renderUnloader, setRenderUnloader] = useState(false)

  let isMounted

  useEffect(() => {
    isMounted = true
    
    try {
      (async () => {
        setProcessing(true)

        if (!src) {
          setProcessing(false)
          setRenderUnloader(true)
          return
        }

        if (exemptedDevices.find(device => navigator.platform === device)) {
          setProcessing(false)
          return
        }

        const dataUrl = await toDataURL(src)

        let dimensions
        try {
          dimensions = await getDimensions(src)
        } catch (error) {
          // Render unloader
          setProcessing(false)
          setRenderUnloader(true)
          return
        }

        let scaleByWidth = true
        let transformStyleStr = 'initial'
        let transformOriginStyleStr = 'unset'
        let exifObj = {}
        try {
          exifObj = piexif.load(dataUrl)
          setExif(true)
        } catch (error) {
          console.log(error)
          setExif(false)
          setProcessing(false)
          return
        }

        // Transform image to display original orientation
        switch (exifObj["0th"][piexif.ImageIFD.Orientation]) {
          case 2:
            scaleByWidth = true
            transformStyleStr = 'rotateY(180deg)'
            break
          case 3:
            scaleByWidth = true
            transformStyleStr = 'rotate(180deg)'
            break
          case 4:
            scaleByWidth = true
            transformStyleStr = 'rotateX(180deg)'
            break
          case 5:
            scaleByWidth = false
            transformStyleStr = 'rotateY(180deg) rotate(90deg)'
            transformOriginStyleStr = 'left top'
            break
          case 6:
            scaleByWidth = false
            transformStyleStr = `rotate(-270deg) translateY(-#widthpx)`
            transformOriginStyleStr = 'left top'
            break
          case 7:
            scaleByWidth = false
            transformStyleStr = `rotateY(180deg) rotate(-90deg) translateY(-#widthpx) translateX(-#heightpx)`
            transformOriginStyleStr = 'left top'
            break
          case 8:
            scaleByWidth = false
            transformStyleStr = `rotate(-90deg) translateX(-#heightpx)`
            transformOriginStyleStr = 'left top'
            break
          default:
            transformOriginStyleStr = 'unset'  
            break
        }

        const refWidth = propWidth || 250

        const width = scaleByWidth 
          ? refWidth / dimensions.width * dimensions.width
          : refWidth / dimensions.height * dimensions.width
        
        const height = scaleByWidth 
          ? refWidth / dimensions.width * dimensions.height 
          : refWidth / dimensions.height * dimensions.height 
        
        setScaledWidth(width)
        setScaledHeight(height)

        setImgWidth(scaleByWidth ? width : height)
        setImgHeight(scaleByWidth ? height : width)

        transformStyleStr = transformStyleStr.replace('#width', scaleByWidth ? width : height)
        transformStyleStr = transformStyleStr.replace('#height', scaleByWidth ? height : width)
        setTransformStyle(transformStyleStr)
        setTransformOriginStyle(transformOriginStyleStr)
      
        setProcessing(false)
      })()
      
    } catch (error) {
      console.log(error)
      setExif(false)
      setProcessing(false)
      if (!isMounted) return
      // Do nothing
      // Continue next process
    }

    return () => {
      isMounted = false
    }

  }, [src])
  
  const FixedImg = styled('img')({
    width: scaledWidth,
    height: scaledHeight,
    msTransform: transformStyle,
    MozTransform: transformStyle,
    OTransform: transformStyle,
    WebkitTransform: transformStyle,
    transform: transformStyle,
    msTransformOrigin: transformOriginStyle,
    MozTransformOrigin: transformOriginStyle,
    OTransformOrigin: transformOriginStyle,
    WebkitTransformOrigin: transformOriginStyle,
    transformOrigin: transformOriginStyle,
  })

  if (processing) {
    return (
      <div 
        {...others}
        style={{
          width: imgWidth || 50,
          height: imgHeight || 50,
        }}
      >
        {loader ? loader : <CircularProgress style={{ margin: 'auto' }} />}
      </div>
    )
  }

  if (renderUnloader && unloader) {
    return (
      <>
        {unloader}
      </>
    )
  }

  return (
    <>
      <div  
        {...others}
        style={exif 
          ? {
            width: imgWidth,
            height: imgHeight,  
          }
          : {
            width: propWidth
          }
        }
      >
        {exif 
          ? <FixedImg src={src} {...imgProps} /> 
          : <img src={src} {...imgProps} style={{ width: propWidth }} />
        }
      </div>
    </>
  )
}

Img.propTypes = {
  src: PropTypes.string,
  imgProps: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unloader: PropTypes.object,
  loader: PropTypes.object,
}

export default Img