import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  card: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  title: {
    color: theme.palette.primary.contrastText,
  }
})

function TrialBanner(props) {
  const {
    classes,
    trial,
    endDate,
  } = props
  
  return (
    <>
      {trial && endDate ? (
        <Card className={classes.card}>
          <Typography 
            className={classes.title} 
            align="center" 
            gutterBottom
            variant="subtitle1"
          >
            You have {trial.daysLeft} {trial.daysLeft === '1' ? 'day' : 'days'} left of your free trial
          </Typography>
          <Typography 
            className={classes.title} 
            align="center" 
          >
            End date: {endDate}
          </Typography>
        </Card>
      ) : (
        <CircularProgress />
      )}
    </>
  )
}

TrialBanner.propTypes = {
  trial: PropTypes.object,
  endDate: PropTypes.string,
}

export default withStyles(styles)(TrialBanner)