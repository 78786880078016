import React, { useEffect, useState} from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import { formatNumber } from 'accounting'
import { API, graphqlOperation } from 'aws-amplify'

import * as mutations from 'graphql/mutations'
import ToolbarContent from 'components/ToolbarContent'
import ContentHeader from 'components/ContentHeader'
import LabeledText from 'components/LabeledText'
import ConfirmDeleteDialog from 'components/ConfirmDeleteDialog'
import { prettifyTimestamp } from 'services/utilityFunctions'
import { volumeUnitShort } from 'services/constantVariables/units'

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing.unit * 3,
  },
  buttonDelete: {
    color: theme.palette.error.main 
  }
}))

function EventView(props) {
  const {
    history,
    location
  } = props

  const classes = useStyles()
  const { pws, event } = location.state
  
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [deleteInProgress, setDeleteInProgress] = useState(false)
  
  const [confirmDelete, setConfirmDelete] = useState(false)

  useEffect(() => {
    handleDelete()
  }, [confirmDelete])
  
  async function handleDelete() {
    if (!confirmDelete) return
    
    setDeleteInProgress(true)
    
    try {
      await API.graphql(graphqlOperation(mutations.deleteEvent, { input: { id: event.id } }))
      history.goBack()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <ToolbarContent
        screenTitle = "Event Data View"
        isMainScreen = {false}
      />
      <ContentHeader
        breadcrumbs={[
          { name: 'Event Data', goBack: true, step: 2 },
          { name: pws.name, goBack: true, step: 1 }, 
          { name: event.name }, 
        ]}
        history={history}
      />
      <LabeledText
        label="Name"
        value={event.name}
      />
      <LabeledText
        label="Type"
        value={event.type}
      />
      <LabeledText
        label="Estimated Loss"
        value={`${formatNumber(event.estimatedLoss, 2)} ${volumeUnitShort[event.estimatedLossUnit]}`}
      />
      <LabeledText
        label="Started"
        value={`${prettifyTimestamp(event.startTimestamp)}`}
      />
      <LabeledText
        label="Ended"
        value={`${prettifyTimestamp(event.endTimestamp)}`}
      />
      <LabeledText
        label="Location"
        value={event.location}
      />
      <LabeledText
        label="Notes"
        value={event.notes}
      />
      <Button
        className={classes.button}
        color="default"
        variant="contained"
        fullWidth
        disabled={deleteInProgress}
        onClick={() => {
          history.push({
            pathname: location.pathname.replace('view', 'edit'),
            state: { pws, event }
          })
        }}
      >
        EDIT
      </Button>
      <Button
        className={`${classes.button} ${classes.buttonDelete}`}
        fullWidth
        disabled={deleteInProgress}
        onClick={() => {
          setOpenDeleteDialog(true)
        }}
      >
        DELETE
      </Button>
      <ConfirmDeleteDialog 
        open={openDeleteDialog} 
        setOpenDialog={setOpenDeleteDialog} 
        setConfirm={setConfirmDelete} 
        objectName="Event"
        fieldDescription="word" 
        actionText="Delete"
        confirmText="CONFIRM"
        cancelText="BACK"
        defaultValue="DELETE"
      />
    </>
  )
}

export default EventView