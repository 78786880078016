import React from 'react'
import { UserContext } from 'contexts'

function withUserData(Component) {
  return (props) => (
    <UserContext.Consumer>
      {userContext => (
        <Component {...props} userContext={userContext} />
      )}
    </UserContext.Consumer>
  )
}

export default withUserData