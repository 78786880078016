import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import isEqual from 'lodash/isEqual'
import { withStyles } from '@material-ui/core/styles'
import { API, graphqlOperation } from 'aws-amplify'

import * as mutations from 'graphql/mutations'
import ToolbarContent from 'components/ToolbarContent'
import ContentHeader from 'components/ContentHeader'
import MaintenanceJournalForm from '../../components/MaintenanceJournalForm'

const styles = theme => ({
  button: {
    marginBottom: 16,
  },
  buttonContainer: {
    position: 'sticky',
    bottom: 0,
    paddingTop: 16,
    background: '#fff'
  }
})

const handleSave = async args => {
  const {
    id,
    modifiedBy,
    timestamp,
    text,
  } = args

  const updatedFields = {
    id,
    modifiedBy,
    timestamp,
    text,
  }

  const tableName = "MaintenanceJournal"

  try {
    await API.graphql(graphqlOperation(mutations["update" + tableName], { input: updatedFields }))
  } catch (error) {
    console.log(error)  
  }
}

function MaintenanceJournalEdit(props) {
  const {
    classes,
    history,
    location,
  } = props
  
  const { pws, maintenanceJournal: initMaintenanceJournal } = location.state
  const [maintenanceJournal, setMaintenanceJournal] = useState(initMaintenanceJournal)
  const [isChanged, setIsChanged] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (isEqual(initMaintenanceJournal, maintenanceJournal)) {
      setIsChanged(false)
    } else {
      setIsChanged(true)
    }
  }, [maintenanceJournal])

  return (
    <>
      <ToolbarContent
        screenTitle="Maintenance Journal Edit"
        isMainScreen={false}
      />
      <ContentHeader
        breadcrumbs={[
          { name: 'Maintenance Journal', goBack: true, step: 3 },
          { name: pws.name, goBack: true, step: 2 }, 
          { name: 'View', goBack: true, step: 1 }, 
          { name: 'Edit' }, 
        ]}
        history={history}
      />
      <MaintenanceJournalForm 
        waterSystemId={pws.id}
        maintenanceJournal={initMaintenanceJournal}
        onChange={item => setMaintenanceJournal(item)} 
      />
      <Grid className={classes.buttonContainer}>
        <Button
          className={classes.button}
          disabled={loading || !isChanged}
          onClick={async () => {
            setLoading(true)
            await handleSave(maintenanceJournal)
            setLoading(false)
            history.goBack()
          }}
          variant="contained"
          color="primary"  
          fullWidth
        >
          {loading ? <CircularProgress color="inherit" size={16} style={{ marginRight: 5 }} /> : null}
          SAVE
        </Button>
      </Grid>
    </>
  )
}

export default withStyles(styles)(MaintenanceJournalEdit)