import React, { useState, useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import { userStatus } from 'services/constantVariables/user'

function OrganizationUsers(props) {
  const { users } = props

  const [orgUsers, setOrgUsers] = useState([])
  const [queryComplete, setQueryComplete] = useState(false)
  
  let isMounted

  useEffect(() => {
    isMounted = true

    ;(async() => {
      if (!isMounted) return
      setQueryComplete(false)

      if (!users) return
      let orgUsers = users.filter(user => user.status === userStatus.USER)
      orgUsers =  orgUsers.filter(user => user.id !== props.userContext.userData.id)
      setOrgUsers(orgUsers)
      setQueryComplete(true)
    })()

    return () => {
      isMounted = false
    }

  }, [users])

  if(!queryComplete) {
    return (
      <ListItem>
        <CircularProgress />
      </ListItem>
    )
  }

  if(!orgUsers.length) {
    return (
      <ListItem>
        <ListItemText primary="None" />
      </ListItem>
    )
  }
  
  return (
    <>
      {orgUsers.map((user) => (
        <ListItem 
          key={user.id}
          button
          onClick={() => props.history.push({
            pathname: `${props.location.pathname}/view/${user.id}`,
            state: { user: user },
          })}  
        >
          <Avatar>
            <AccountCircleIcon />
          </Avatar>
          <ListItemText 
            primary={`${user.firstName||''} ${user.lastName||''}`} 
            secondary={user.email} 
          />
        </ListItem>
      ))}
    </>
  )
}

export default OrganizationUsers