import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import startCase from 'lodash/startCase'
import { formatNumber } from 'accounting'
import { styled } from '@material-ui/styles'
import { withSize } from 'react-sizeme'

const commonStyle = {
  border: '1px solid black'
}

const cellStyle = {
  paddingLeft: 8,
  paddingRight: 8,
}

const Table = styled('table')({
  ...commonStyle
})

const TBody = styled('tbody')({
  ...commonStyle
})

const Tr = styled('tr')({
  ...commonStyle
})

const Td = styled('td')({ 
  ...commonStyle, 
  ...cellStyle 
})

const Th = styled('th')({ 
  ...commonStyle, 
  ...cellStyle 
})

const ThWithSize = withSize()(props => {
  const {
    children,
    size,
    getWidth
  } = props

  useEffect(() => {
    getWidth(size.width)
  })

  return (
    <Th>{children}</Th>
  )
})

const TdWithSize = withSize()(props => {
  const {
    children,
    size,
    getWidth
  } = props

  useEffect(() => {
    getWidth(size.width)
  })

  return (
    <Td>{children}</Td>
  )
})

function DataTable(props) {
  let {
    data,
    size
  } = props

  data = data.map(item => {
    item.recoverableRealLoss = formatNumber(item.recoverableRealLoss, 2)
    item.apparentLoss = formatNumber(item.apparentLoss, 2)
    item.unavoidableLoss = formatNumber(item.unavoidableLoss, 2)
    item.authorizedConsumption = formatNumber(item.authorizedConsumption, 2)

    return item
  })

  // Calculate column widths for table split for print layout
  //   Note: Value for authorized consumption will always have the longest
  //   value. Use this as reference for value column width
  const containerWidth = size.width
  const [headColWidth, setHeadColWidth] = useState()
  const [colWidth, setColWidth] = useState()

  // Calculate max number of columns per table
  const maxNumCol = Math.floor((containerWidth - headColWidth) / colWidth)
  
  // Split data into arrays with number of items equal to max number of columns
  let splitData = []
  let tempArray = []
  let count = maxNumCol
  data.forEach((item, index) => {
    tempArray.push(item)
    count--

    if (count === 0 || index + 1 === data.length) {
      splitData.push(tempArray)
      tempArray = []
      count = maxNumCol
    }
  })

  return (
    <>
      <Grid style={{ overflowX: 'auto' }}>
        <Typography component="article">
          <div id="scroll-table">
            <Table>
              <TBody>
                {Object.keys(data[0]).map((name, index) => (
                  <Tr key={name}>
                    <ThWithSize 
                      getWidth={index + 1 === Object.keys(data[0]).length 
                        ? width => setHeadColWidth(width)
                        : () => {}
                      }
                    >
                      {startCase(name)}
                    </ThWithSize>  
                    {data.map((item, index) => (
                      <TdWithSize 
                        key={index} 
                        getWidth={index === 1 && name === "authorizedConsumption"
                          ? width => setColWidth(width) 
                          : () => {}
                        }
                      >
                        {item[name]}
                      </TdWithSize>
                    ))}
                  </Tr>
                ))}
              </TBody>
            </Table>
          </div>
          <div id="split-table" style={{ display: 'none' }}>
            {splitData.map((splitItem, index) => (
              <Table key={index} style={{ marginTop: 10 }}>
                <TBody>
                  {Object.keys(splitItem[0]).map(name => (
                    <Tr key={name}>
                      <Th style={{ width: headColWidth }}>{startCase(name)}</Th>  
                      {splitItem.map((item, index) => (
                        <Td key={index} style={{ width: colWidth }}>
                          {item[name]}
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </TBody>
              </Table>
            ))}
          </div>
        </Typography>
      </Grid>
    </>
  )
}

DataTable.propTypes = {
  data: PropTypes.array.isRequired
}

export default withSize()(DataTable)