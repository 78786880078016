import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import CircularProgress from '@material-ui/core/CircularProgress'

import ToolbarContent from 'components/ToolbarContent'
import withUserData from 'components/withUserData'
import Img from 'components/Img'

const styles = () => ({
  main: {
    textAlign: 'center'
  },
  button: {
    margin: 'auto',
    marginTop: 50, 
  },
  logo: {
    width: 250,
    margin: 'auto',
    display: 'block',
  },
  address: {
    alignContent: 'center',
  },
  addressContent: {
    width: 'fit-content',
    textAlign: 'left',
    margin: 'auto'
  }
})

class OrganizationSettings extends Component {

  renderAddress = (organization) => {
    const { classes } = this.props

    if(!organization.mailingAddress) {
      return
    }

    const { 
      street,
      street2,
      city,
      state,
      zip,
      country,
    } = organization.mailingAddress

    return (
      <>
        <Typography align='center' className={classes.addressContent} paragraph>
          {street ? <>{street}<br /></> : null}
          {street2 ? <>{street2}<br /></>: null}
          {city}
          {city && state ? ', ' : null}
          {state}
          {' '}
          {zip}
          {(city || state || zip) ? <br /> : null}
          {country}
        </Typography>
      </>
    )
  }

  render() {
    const { classes } = this.props

    if(!this.props.userContext.userData) {
      return null
    }

    const { organization } = this.props.userContext.userData

    return (
      <>
        <ToolbarContent
          screenTitle = "Organization Settings"
          isMainScreen = {true}
        />
        <Img
          className={classes.logo}
          src={organization.logo}
          width={250}
          loader={
            <CircularProgress className={classes.logo} />
          }
          unloader={
            <img
              id="logo"
              alt="logo"
              className={classes.logo}
              src={require('images/default-image.png')}
            />
          }
        />
        <Grid className={classes.main}>
          <Typography variant="h6" gutterBottom>
            {organization.name}
          </Typography>
          <Grid className={classes.address}>
            {organization.mailingAddress ? this.renderAddress(organization) : null}
          </Grid>
        </Grid>
        <Button 
          className={classes.button} 
          variant="contained" 
          color="secondary"
          fullWidth
          component={Link}
          to={`/organization/edit`}
        >
          EDIT
        </Button>
      </>
    )
  }
}

export default compose(
  withUserData,
  withStyles(styles)
)(OrganizationSettings)