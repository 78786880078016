export const getTier = connections => {
  let index = 0
  while (index < tiersList.length) {
    const tier = tiersList[index]
    if (connections >= tier.limits[0] && connections <= tier.limits[1]) {
      return tier
    }
    index++
  }
}

export const tiersList = [
  { 
    id: 1, 
    name: 'VS', 
    price: 200,
    currency: 'USD', 
    description: '1 - 200',
    limits: [1, 200], 
  },
  { 
    id: 2, 
    name: 'S', 
    price: 400, 
    currency: 'USD',
    description: '201 - 1,500', 
    limits: [201, 1500],
  },
  { 
    id: 3, 
    name: 'M', 
    price: 750,
    currency: 'USD', 
    description: '1,501 - 4,000',
    limits: [1501, 4000], 
  },
  { 
    id: 4, 
    name: 'L', 
    price: 2000,
    currency: 'USD', 
    description: '4,001 - 40,000', 
    limits: [4001, 40000], 
  },
  { 
    id: 5, 
    name: 'VL', 
    price: 4000, 
    currency: 'USD',
    description: '40,000 above', 
    limits: [40001, Infinity], 
  },
]