import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import validator from 'email-validator'
import { API, graphqlOperation } from 'aws-amplify'

import * as mutations from 'graphql/mutations'
import withUserData from 'components/withUserData'
import { inviteState } from 'services/constantVariables/userInvite'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%'
  },
  buttonSend: {
    margin: 'auto',
    marginTop: 50,
  },
})

const handleSend = async(userData, organizationId, setSendDisabled, setSendProgress, setOpenSnack) => {
  setSendDisabled(true)
  setSendProgress(true)

  const userInviteInput = {
    organizationId,
    email: userData.email,
    state: inviteState.NEW,
  }

  try {
    await API.graphql(graphqlOperation(mutations.createUserInvite, { input: userInviteInput }))
    setSendProgress(false)
    setOpenSnack(true)
  } catch (error) {
    
  }
}

const handleSubmit = (e, sendDisabled, callback) => {
  e.preventDefault()
  if(sendDisabled) {
    return
  } else {
    callback()
  }
}

function UserNew(props) {
  const { 
    classes, 
    history, 
    location,
  } = props

  const passedEmail = location.state.email

  const [email, setEmail] = useState(passedEmail || '')
  const [sendDisabled, setSendDisabled] = useState(true)
  const [sendProgress, setSendProgress] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)

  const organizationId = props.userContext.userData.organizationId

  useEffect(() => {
    if (validator.validate(email)) {
      setSendDisabled(false)
    } else {
      setSendDisabled(true)
    }
  }, [email])

  return (
    <>
      <form 
        className={classes.container} 
        noValidate 
        autoComplete="off" 
        onSubmit={e => {
          handleSubmit(
            e, 
            sendDisabled, 
            () => handleSend(
              { email },
              organizationId,
              setSendDisabled,
              setSendProgress,
              setOpenSnack,
            )
          )
        }}
      >
        <TextField
          id="email"
          label="Email"
          type="email"
          className={classes.textField}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          autoFocus
        />
      </form>
      <Button
        className={classes.buttonSend} 
        variant="contained" 
        color="primary"
        disabled={sendDisabled}
        fullWidth
        onClick={() => handleSend(
          { email },
          organizationId,
          setSendDisabled,
          setSendProgress,
          setOpenSnack,
        )}
      > 
        {sendProgress ? <CircularProgress /> : 'SEND'}
      </Button>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnack}
        onClose={() => history.push('/user-management')}
        action={
          <Button 
            color="secondary" 
            size="small"
            onClick={() => history.push('/user-management')}>
            OK
          </Button>
        }
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={
          <span id="message-id">
            An email invite has been sent to {email} with a temporary password for first time sign in. 
          </span>
        }
      />
    </>
  )
}

export default compose(
  withUserData,
  withStyles(styles)
)(UserNew)