import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

import { getCurrentDate, zeroPad } from 'components/DateForm'
import { formatDate, getTimeString } from 'services/utilityFunctions'
import { UserContext } from 'contexts'

function MaintenanceJournalForm(props) {
  const {
    maintenanceJournal,
    onChange,
  } = props

  const currentUser = useContext(UserContext)

  const modifiedBy = maintenanceJournal.modifiedBy || currentUser.userData.email
  const lastModifiedBy = maintenanceJournal.modifiedBy
  const waterSystemId = maintenanceJournal.waterSystemId || props.waterSystemId
  const [timestamp, setTimestamp] = useState(maintenanceJournal.timestamp || null)
  const [text, setText] = useState(maintenanceJournal.text || '')
  
  const date = formatDate(timestamp ? timestamp.slice(0, 8) : null, 'Mmm dd, yyyy')
  const time = timestamp ? getTimeString(timestamp.slice(8, 10), timestamp.slice(10, 12)) : null

  useEffect(() => {
    // update timestamp every journal edit
    if (maintenanceJournal.text !== text) {
      setTimestamp(getNow)
    }

    onChange({
      id: maintenanceJournal.id,
      waterSystemId,
      modifiedBy,
      timestamp,
      text,
    })
  }, [
    waterSystemId,
    modifiedBy,
    timestamp,
    text,
  ])

  function getNow() {
    const currentDateObj = new Date()
    const currentTime = zeroPad(currentDateObj.getHours().toString(), 2) 
      + zeroPad(currentDateObj.getMinutes().toString(), 2)

    const currentDate = getCurrentDate()
    
    return currentDate + currentTime
  }

  return (
    <>
      <Typography>
        {date} {time}
      </Typography>
      <Typography
        variant="caption"
      >
        {lastModifiedBy ? <>Last modified by: {modifiedBy}</> : null}
      </Typography>
      <TextField
        variant="outlined"
        fullWidth
        multiline
        rows={10}
        rowsMax={20}
        value={text}
        onChange={e => {
          setText(e.target.value)
        }}
      />
    </>
  )
}

MaintenanceJournalForm.defaultProps = {
  maintenanceJournal: {},
}

MaintenanceJournalForm.propTypes = {
  waterSystemId: PropTypes.string,
  maintenanceJournal: PropTypes.object,
}

export default MaintenanceJournalForm