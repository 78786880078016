import React from 'react'
import Divider from '@material-ui/core/Divider'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'

import ToolbarContent from 'components/ToolbarContent'
import withUserData from 'components/withUserData'
import { billingStatusVal } from 'services/constantVariables/billing'
import StartTrial from './components/StartTrial'
import TrialInfo from './components/TrialInfo'
import Subscriptions from './components/Subscriptions'
import PaymentMethod from './components/PaymentMethod'
import PaymentHistory from './components/PaymentHistory'

const styles = () => ({
  divider: {
    height: 15,
    background: 'none',
  },
})

function Billing(props) {
  const {
    classes,
    history,
    location,
    userContext,
  } = props

  const billingStatus = userContext.userData.organization.billingStatus

  return (
    <>
      <ToolbarContent
        screenTitle = "Billing Settings"
        isMainScreen = {true}
      />
      {(() => {
        switch (billingStatus) {
          case billingStatusVal.NEW:
            return <StartTrial />
          case billingStatusVal.TRIAL_ACTIVE:
            return <TrialInfo />
          case billingStatusVal.TRIAL_OVER:
          case billingStatusVal.SUBSCRIBED:
            return (
              <>
                <Subscriptions history={history} />
                <Divider className={classes.divider} />
                <PaymentMethod history={history} location={location} />
                <Divider className={classes.divider} />
                <PaymentHistory history={history} />
              </>
            )
          default:
            return <CircularProgress />
        }
      })()}
    </>
  )
}

export default compose(
  withUserData,
  withStyles(styles),
)(Billing)