import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Title,
  Legend,
} from '@devexpress/dx-react-chart-material-ui'
import { withStyles } from '@material-ui/styles'
import { Stack, Animation } from '@devexpress/dx-react-chart'
import { ClientStyle as Style } from 'react-css-component'

import { formatDate } from 'services/utilityFunctions'

const legendStyles = () => ({
  root: {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'column',
  },
})
const legendRootBase = ({ classes, ...restProps }) => (
  <Legend.Root {...restProps} className={classes.root} />
)
const Root = withStyles(legendStyles, { name: 'LegendRoot' })(legendRootBase)
const legendLabelStyles = () => ({
  label: {
    whiteSpace: 'nowrap',
  },
})
const legendLabelBase = ({ classes, ...restProps }) => (
  <Legend.Label className={classes.label} {...restProps} />
)
const Label = withStyles(legendLabelStyles, { name: 'LegendLabel' })(legendLabelBase)

function WaterSuppliedBarChart(props) {
  let { data } = props

  const [chartData, setChartData] = useState([])

  const chartWidth = Math.max(chartData.length * 100, 150)
  
  const chartRootStyles = `
    #ws-barchart #center-axis-container {
      width: ${chartWidth}px !important;
      margin-right: auto;
      margin-left: auto;
    }
    #ws-barchart #bottom-axis-container {
      width: ${chartWidth}px !important;
      margin-right: auto;
      margin-left: auto;
    }
    #ws-barchart #center-container #center-center {
      width: 100% !important;
      overflow: auto;
    }
    #ws-barchart > div {
      width: 100% !important
    },
  `
  
  useEffect(() => {
    // sort date by date
    data.sort((a, b) => {
      return a.date - b.date;
    })

    // scale data and format date
    data = data.map(item => {
      item.cons_volume = Number(item.cons_volume)
      item.prod_volume = Number(item.prod_volume)
      item.date = formatDate(item.date, 'Mmm dd')
      return item
    })
    
    setChartData(data)
    
    // refresh injected stylesheet
    let elements = document.querySelectorAll('[id^=__react_css_component_id]')
    for (var i=0, max = elements.length; i < max; i++) {
      elements[i].parentNode.removeChild(elements[i]);
    }

  }, [data])

  if (chartData.length < 1) {
    return <>No data found</>
  }

  return (
    <>
      <Style css={chartRootStyles} />
      <Grid id="ws-barchart" container>
        <Chart
          data={chartData}
        >
          <ArgumentAxis />
          <ValueAxis />
          <BarSeries
            name="Production"
            valueField="prod_volume"
            argumentField="date"
            color="#3182bd"
          />
          <BarSeries
            name="Consumption"
            valueField="cons_volume"
            argumentField="date"
            color="#ffd700"
          />
          <Animation />
          <Legend position="bottom" rootComponent={Root} labelComponent={Label} />
          <Title text="Recent Production and Consumption" />
          <Stack />
        </Chart>
      </Grid>
    </>
  )
}

export default WaterSuppliedBarChart