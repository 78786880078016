// This component is used to display breadcrumbs and/or a message
// text or a description on the top part the AppContent component
import React from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '@material-ui/lab/Breadcrumbs'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Divider from '@material-ui/core/Divider'
import { styled } from '@material-ui/styles'

const BottomGutter = styled(Divider)({
  background: 'transparent',
  height: 16,
})

function ContentHeader(props) {
  const {
    breadcrumbs,
    text,
    history,
  } = props

  let err

  // Check breadcrumb properties
  if (breadcrumbs.length > 0) {
    breadcrumbs.map((breadcrumb, index) => {
      const valueError = propName => (
        'Warning: The `' + propName + '` property of breadcrumbs item is undefined.'
      )
      const typeError = propName => (
        'Warning: Failed prop type: Invalid prop `' + propName + '` of type '
        + typeof breadcrumb[propName] 
        + ' supplied to `breadcrumbs` item, expected `string`.'
      )
      let propName

      // Check for property `name`
      propName = 'name'
      if (breadcrumb[propName] === undefined) {
        console.error(valueError(propName))
        err = true
      } else {
        if (typeof breadcrumb[propName] !== 'string' && typeof breadcrumb[propName] !== 'object') {
          console.error(typeError(propName))
          err = true
        }
      }

      // Check for property `step` if item is configured with goBack as true
      // If last breadcrumb item, skip step check and make sure goBack
      // is false because the first item is just a title
      if (index === breadcrumbs.length - 1) {
        breadcrumb.goBack = false
        return null
      }

      propName = 'step'
      if (breadcrumb[propName] === undefined) {
        console.error(valueError(propName))
        err = true
      } else {
        if (typeof breadcrumb[propName] !== 'string' && typeof breadcrumb[propName] !== 'number') {
          console.error(typeError(propName))
          err = true
        }
      }

      return null
    })
  }
  
  if (err) return null

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} arial-label="Breadcrumb">
        {breadcrumbs.map((breadcrumb, index) => {
          if (index === breadcrumbs.length - 1) {
            return (
              <Typography 
                key={index} 
                color="textPrimary"
              >
                {breadcrumb.name}
              </Typography>
            )
          }
          
          return (
            <Link 
              key={index}
              color="inherit" 
              onClick={() =>{
                if (breadcrumb.goBack) {
                  history.go(-breadcrumb.step)
                }
              }}
            >
              {breadcrumb.name}
            </Link>
          )
        })}
      </Breadcrumbs>
      <Typography color="textSecondary">
        {text}
      </Typography>
      <BottomGutter />
    </>
  )
}

ContentHeader.defaultProps = {
  breadcrumbs: [],
  text: '',
}

// A breadcrumb array item should be an object
// that has attributes: name, goBack, step
// The first item will look like: { name: "Current Screen" }
// the second item will look like: { name: "Current Screen", goBack: true, step: 1 }
// the third item will look like: { name: "Current Screen", goBack: true, step: 2 }
// and so on.
ContentHeader.propTypes = {
  breadcrumbs: PropTypes.array,
  text: PropTypes.string,
  history: PropTypes.object,
}

export default ContentHeader