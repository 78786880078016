import React, { useState, useEffect, } from 'react'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { API, graphqlOperation } from 'aws-amplify'

import * as queries from 'graphql/queries'
import ToolbarContent from 'components/ToolbarContent'
import withUserContext from 'components/withUserData'
import AddFabButton from 'components/AddFabButton'
import OrganizationUsers from './components/OrganizationUsers'
import OrganizationContacts from './components/OrganizationContacts'
import RequestUsers from './components/RequestUsers'
import InviteUsers from './components/InviteUsers'
import NewUserDialog from './components/NewUserDialog'

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
})

function UserManagement(props) {
  const { 
    classes, 
    history, 
    location,
  } = props
  const { userData, currentUser } = props.userContext
  const {
    organizationId,
    firstName,
    lastName,
    email,
  } = userData

  const [openDialog, setOpenDialog] = useState(false)
  const [users, setUsers] = useState(null)
  const [update, setUpdate] = useState(false)

  let isMounted

  useEffect(() => {
    isMounted = true

    ;(async() => {
      if (!isMounted) return
      const userFilter = {
        and: [
          { 
            organizationId: { eq: organizationId },
          }
        ]
      }
      const queryUsers = await API.graphql(graphqlOperation(queries.listUsers, { filter: userFilter, limit: 1000 }))
      const users = queryUsers.data.listUsers.items
      setUsers(users)
    })()

    return () => {
      isMounted = false
    }
  }, [update])
  
  return (
    <>
      <ToolbarContent
        screenTitle = "User Management"
        isMainScreen = {true}
      />
      <List 
        className={classes.root}
        subheader={
          <ListSubheader component="span">
            My Profile
          </ListSubheader>
        }
      >
        <ListItem 
          color="primary"
          button
          onClick={() => history.push({
            pathname: `${location.pathname}/view/${props.userContext.userData.id}`,
            state: { user: userData }
          })}
        >
          <Avatar>
            <AccountCircleIcon />
          </Avatar>
          <ListItemText 
            primary={`${firstName||''} ${lastName||''}`} 
            secondary={
              <>
                {currentUser.username}
                <br/>
                {email}
              </>
            } 
          />
        </ListItem>
      </List>
      <Divider />
      <List
        className={classes.root}
        subheader={
          <ListSubheader component="span">
            Users
          </ListSubheader>
        }
      >
        <OrganizationUsers {...props} users={users} />
      </List>
      <Divider />
      <List
        className={classes.root}
        subheader={
          <ListSubheader component="span">
            Contacts
          </ListSubheader>
        }
      >
        <OrganizationContacts {...props} users={users} />
      </List>
      <Divider />
      <List
        className={classes.root}
        subheader={
          <ListSubheader component="span">
            Pending Invites
          </ListSubheader>
        }
      >
        <InviteUsers {...props} users={users} />
      </List>
      <Divider />
      <List
        className={classes.root}
        subheader={
          <ListSubheader component="span">
            Join Requests
          </ListSubheader>
        }
      >
        <RequestUsers organizationId={organizationId} {...props} setUpdate={setUpdate} />
      </List>
      <div style={{ marginBottom: 50 }} />
      <AddFabButton
        onClick={() => setOpenDialog(true)}
      />
      <NewUserDialog 
        open={openDialog}
        setOpen={setOpenDialog}
        history={history}
      />
    </>
  )
}

export default compose(
  withUserContext,
  withStyles(styles)
)(UserManagement)