import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  content: {
    paddingTop: 66,
    maxWidth: 840,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  contentPaper: {
    minHeight: 'calc(100vh - 76px)',
    marginBottom: 10,
  },
})

function AppFrameSmall(props) {
  const {
    AppToolbar,
    AppDrawer,
    onRender,
  } = props

  const classes = useStyles()

  const [drawerOpen, setDrawerOpen] = useState(false)

  useEffect(() => {
    onRender({
      content: classes.content, 
      contentPaper: classes.contentPaper
    })
  }, [])

  return (
    <>
      <AppBar 
        color="secondary"
        position="fixed"
      >
        <AppToolbar 
          menuClick={() => setDrawerOpen(true)} 
        />
      </AppBar>
      {/* <main className={classes.content}>
        <Paper className={classes.contentPaper}>
          <AppContent />
        </Paper>
      </main> */}
      <SwipeableDrawer 
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        swipeAreaWidth={30}
      >
        <AppDrawer itemClick={() => setDrawerOpen(false)} />
      </SwipeableDrawer>
    </>
  )
}

AppFrameSmall.defaultProps = {
  AppToolbar: () => {},
  AppDrawer: () => {},
  onRender: () => {},
} 
  
AppFrameSmall.propTypes = {
  AppToolbar: PropTypes.func.isRequired,
  AppDrawer: PropTypes.func.isRequired,
  onRender: PropTypes.func,
}

export default AppFrameSmall