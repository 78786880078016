import React from 'react'
import PropTypes from 'prop-types'
import ChartIcon from '@material-ui/icons/ShowChart'
import Skeleton from 'react-loading-skeleton'

import InfoCard from 'components/InfoCard'
import WaterSuppliedBarChart from './components/WaterSuppliedBarChart'

function Chart(props) {
  const {
    chartData
  } = props

  return (
    <>
      <InfoCard 
        Icon={ChartIcon}
        color="orange"
        title="Chart"
        titleAlign="left"
        BodyContent={
          <>
            {chartData ? <WaterSuppliedBarChart data={chartData} /> : <Skeleton />}
          </>
        }
        caption=""
        linkHref=""
        linkText=""
      />
    </>
  )
}

Chart.propTypes = {
  chartData: PropTypes.array
}

export default Chart