import React from 'react'
import { Elements, StripeProvider } from 'react-stripe-elements'
import { compose } from 'recompose'

import ToolbarContent from 'components/ToolbarContent'
import withUserData from 'components/withUserData'
import CheckoutForm from 'components/CheckoutForm'

function PaymentInfoNew(props) {
  const {
    history,
    userContext,
  } = props

  const { id, customerId } = userContext.userData.organization
  const { email } = userContext.userData

  if (!process.env.REACT_APP_STRIPE_API_KEY) {
    return (
      <>
        Error on configuration
      </>
    )
  }
  
  return (
    <>
      <ToolbarContent
        screenTitle = "Add Payment Information"
        isMainScreen = {false}
      />
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
        <div>
          <h1>Payment Info</h1>
          <Elements>
            <CheckoutForm 
              email={email}
              organizationId={id}
              customerId={customerId} 
              handleOnComplete={() => history.goBack()} 
            />
          </Elements>
        </div>
      </StripeProvider>
    </>
  )
}

export default compose(
  withUserData,
)(PaymentInfoNew) 

