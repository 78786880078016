import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import { styled } from '@material-ui/styles'

import * as mutations from 'graphql/mutations'
import * as queries from 'graphql/queries'
import ToolbarContent from 'components/ToolbarContent'
import withUserData from 'components/withUserData'
import { requestState } from 'services/constantVariables/joinRequest'
import FactsBlocks from './components/FactsBlocks'

const Gap = styled('hr')({
  height: 20,
  visibility: 'hidden',
})

class Dashboard extends Component {
  state = {
    checkComplete: false,
    hasOrganization: false,
    hasPendingRequest: false,
    joinRequests: null,
    organizationId: null,
  }

  async componentDidMount() {
    await this.checkOrganization()
    await this.checkPendingRequest()
    await this.getJoinRequests(this.state.organizationId)
    await this.setState({checkComplete: true})
  }

  checkOrganization = async () => {
    const { userData } = this.props.userContext
    if(userData.organizationId) {
      await this.setState({
        hasOrganization: true,
        organizationId: userData.organizationId,
      })
    }
  }
  
  checkPendingRequest = async () => {
    const { joinRequest } = this.props.userContext.userData
    if(!joinRequest) {
      return
    }

    if(joinRequest.state === requestState.NEW || joinRequest.state === requestState.DENIED) {
      await this.setState({hasPendingRequest: true})
    }
  }

  getJoinRequests = async (organizationId) => {
    if(!organizationId) {
      return
    }
    const joinRequestsData = await API.graphql(graphqlOperation(queries.listJoinRequests, { filter:  { and: [{ organizationId: { eq: organizationId }, state: { eq: requestState.NEW } }] }, limit: 1000 } ))
    const joinRequests = joinRequestsData.data.listJoinRequests
    await this.setState({
      joinRequests,
    })
  }

  approveJoinRequest = async (request) => {
    const inputUpdateJoinRequest = {
      id: request.id, 
      state: requestState.APPROVED,
    }
    await API.graphql(graphqlOperation(mutations.updateJoinRequest, {input: inputUpdateJoinRequest}))

    const inputUpdateUser = {
      id: request.user.id, 
      organizationId: request.organization.id,
      userOrganizationId: request.organization.id,
    }
    await API.graphql(graphqlOperation(mutations.updateUser, {input: inputUpdateUser}))
    await this.getJoinRequests(this.state.organizationId)
  }

  denyJoinRequest = async (request) => {
    const inputUpdateJoinRequest = {
      id: request.id, 
      state: requestState.DENIED,
    }
    await API.graphql(graphqlOperation(mutations.updateJoinRequest, {input: inputUpdateJoinRequest}))
    await this.getJoinRequests(this.state.organizationId)
  }

  render() {
    const { joinRequests } = this.state
    const { userData, currentUser } = this.props.userContext

    if(!this.state.checkComplete) {
      return (
        <>
          <ToolbarContent
            screenTitle = "Dashboard"
            isMainScreen = {true}
          />
          <p>Checking for updates...</p>
        </>
      )
    }
    
    if(this.state.hasPendingRequest) {
      return <Redirect to={{ pathname: `/request-pending/${userData.joinRequest.id}`, }} />
    }

    if(!this.state.hasOrganization) {
      return <Redirect to={{ pathname: "/onboarding", }} />
    }

    return (
      <>        
        <ToolbarContent
          screenTitle = "Dashboard"
          isMainScreen = {true}
        />
        <Typography align="right" variant="subtitle2">
          {currentUser.username}
        </Typography>
        <Typography align="right" paragraph>
          {userData.email}
        </Typography>
        {joinRequests.items.map((request) => (
          <Card key={request.id}>
            <CardContent>
              <Typography variant="h6">
                Join Request
              </Typography>
              <Typography>
                Email: {request.user.email} 
              </Typography>
            </CardContent>
            <CardActions>
              <Button 
                onClick={() => this.denyJoinRequest(request)}
              >
                DENY
              </Button>
              <Button 
                onClick={() => this.approveJoinRequest(request)}
              >
                APPROVE
              </Button>
            </CardActions>
          </Card>
        ))}     
        <Gap />
        <FactsBlocks />
      </>
    )
  }
}

export default withUserData(Dashboard)