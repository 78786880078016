import React from 'react'

import ToolbarContent from 'components/ToolbarContent'
import AddFabButton from 'components/AddFabButton'
import WaterSourcesList from './components/WaterSourcesList'

function WaterSources(props) {
	const {
		location,
		history,
	} = props

	const pws = location.state.pws

	return (
		<>
			<ToolbarContent
        screenTitle = {`${pws.name} Sources`}
        isMainScreen = {false}	
      />
			<WaterSourcesList 
				pwsId={pws.id}
				notFoundMessage="Tap the plus button to create the first Water Source for this PWS."
				onItemClick={item => history.push({
					pathname: `sources/view/${item.id}`,
					state: { source: item },
				})}	
			/>
			<AddFabButton
				onClick={() => history.push({
        	pathname: `${location.pathname}/new`,
        	state: { pws: pws }
        })}
			/>
		</>
	)
}

export default WaterSources