import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import { API, graphqlOperation } from 'aws-amplify'
import { compose } from 'recompose'

import * as mutations from 'graphql/mutations'
import withUserData from 'components/withUserData'

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
  },
  button: {
    marginTop: theme.spacing.unit,
  },
  content: {
    paddingLeft: theme.spacing.unit*2,
    paddingRight: theme.spacing.unit*2,
  }
})

function PaymentCards(props) {
  const {
    onItemClick,
    setCardExists,
    setCardLoading,
    defaultOnly,
    defaultNone,
    hideChip,
    forceUpdate,
    filter,
    userContext,
  } = props

  const { customerId } = userContext.userData.organization
  const [cards, setCards] = useState([])
  const [loading, setLoading] = useState(true)

  let isMounted

  useEffect(() => {
    isMounted = true
    
    ;(async () => {
      if (!isMounted) return
      
      setCardLoading(true)
      setLoading(true)
      
      if (!customerId) {
        setLoading(false)
        setCardLoading(false)
        setCardExists(false)
        return 
      }
      
      const inputApiAction = {
        name: 'stripeRetrieveCustomer',
        parameters: [
          customerId,
        ]
      } 
      
      try {
        const result = await API.graphql(graphqlOperation(mutations.createApiAction, { input: inputApiAction } ))
        if (!isMounted) return
        const customerJSON = result.data.createAPIAction.stripeRetrieveCustomerResolver
        const customer = JSON.parse(customerJSON)
        
        let cards = customer.sources.data.filter(item => item.object === "card")
        if (cards.length === 0) {
          setLoading(false)
          setCardLoading(false)
          setCardExists(false)
          return
        }

        const defaultCardIndex = cards.findIndex(card => card.id === customer.default_source)
        cards[defaultCardIndex].default = true

        if (defaultOnly) {
          cards = cards.filter(item => item.default === true )
        }
        
        if (defaultNone) {
          cards = cards.filter(item => item.default !== true )
          if (cards.length === 0) {
            setLoading(false)
            setCardLoading(false)
            setCardExists(false)
            return
          }
        }

        if (filter) {
          cards = cards.filter(filter)
        }
        
        setCards(cards)
        setCardExists(true)
        setCardLoading(false)
      } catch (error) {
        console.log(error)
      }
      setLoading(false)
      setCardLoading(false)
    })()

    return () => {
      isMounted = false
    }

  }, [filter, forceUpdate])

  if (loading) return <CircularProgress />

  return (
    <>
      <List>
        {cards.map(card => (
          <ListItem 
            key={card.id}
            button
            divider
            onClick={() => onItemClick(card)}
          >
            <ListItemText 
              primary={
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Typography variant="subtitle1" style={{ marginRight: 10 }}>
                    •••• {card.last4}
                  </Typography>
                  <Typography variant="caption">
                    {card.default && !hideChip ? (
                      <Chip 
                        label="Default" 
                        color="primary"
                        style={{ height: 20, marginRight: 10 }}
                      />
                    ) : null}
                    Expires {card.exp_month+'/'+card.exp_year}
                  </Typography>
                </Grid>
              } 
              secondary={card.brand} 
              style={{ paddingRight: 0 }}
            />
          </ListItem>
        ))}
      </List>
    </>
  )
}

PaymentCards.defaultProps = {
  onItemClick: () => {},
  setCardExists: () => {},
  setCardLoading: () => {},
  defaultOnly: false,
  defaultNone: false,
  hideChip: false,
  forceUpdate: false,
  filter: null,
}

PaymentCards.propTypes = {
  onItemClick: PropTypes.func,
  setCardExists: PropTypes.func,
  setCardLoading: PropTypes.func,
  defaultOnly: PropTypes.bool,
  defaultNone: PropTypes.bool,
  hideChip: PropTypes.bool,
  forceUpdate: PropTypes.bool,
  filter: PropTypes.func,
}

export default compose(
  withUserData,
  withStyles(styles)
)(PaymentCards)