import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import isEqual from 'lodash/isEqual'

import DateForm, { getCurrentDate, zeroPad } from 'components/DateForm' 
import { volumeUnitsList, volumeUnitShort } from 'services/constantVariables/units'
import { eventTypes } from 'services/constantVariables/event'
import NumberField from 'components/NumberField'

function EventForm(props) {
  const {
    data,
    onChange,
  } = props

  // construct default data values
  const currentDate = getCurrentDate()
  const currentDateObj = new Date()
  const currentTime = zeroPad(currentDateObj.getHours().toString(), 2) 
    + zeroPad(currentDateObj.getMinutes().toString(), 2)

  const defaults = {
    name: '',
    type: 'Break',
    estimatedLoss: '0',
    estimatedLossUnit: 'Kgal',
    startTimestamp: currentDate + currentTime,
    endTimestamp: currentDate + currentTime,
    location: '',
    notes: '',
  }

  // map fields
  const initialData = data ? {
    name: data.name || '',
    type: data.type,
    estimatedLoss: data.estimatedLoss || '0',
    estimatedLossUnit: data.estimatedLossUnit,
    startTimestamp: data.startTimestamp,
    endTimestamp: data.endTimestamp,
    location: data.location || '',
    notes: data.notes || '',
  } : null

  // store initial data into a state to prevent unnecessary update when component rerenders
  const [event] = useState(initialData ? initialData : defaults)
  
  const [name, setName] = useState(event.name)
  const [type, setType] = useState(event.type)
  const [estimatedLoss, setEstimatedLoss] = useState(event.estimatedLoss)
  const [estimatedLossUnit, setEstimatedLossUnit] = useState(event.estimatedLossUnit)

  const startDateExtract = event.startTimestamp.slice(0, 8)
  const startTimeExtract = event.startTimestamp.slice(8, 10) + ':' + event.startTimestamp.slice(10, 12)
  const [startDate, setStartDate] = useState(startDateExtract)
  const [startTime, setStartTime] = useState(startTimeExtract)
  
  const endDateExtract = event.endTimestamp.slice(0, 8)
  const endTimeExtract = event.endTimestamp.slice(8, 10) + ':' + event.endTimestamp.slice(10, 12)
  const [endDate, setEndDate] = useState(endDateExtract)
  const [endTime, setEndTime] = useState(endTimeExtract)
  
  const [location, setLocation] = useState(event.location)
  const [notes, setNotes] = useState(event.notes)

  useEffect(() => {
    const currentEvent = {
      name,
      type,
      estimatedLoss,
      estimatedLossUnit,
      startTimestamp: startDate + startTime.replace(':', ''),
      endTimestamp: endDate + endTime.replace(':', ''),
      location,
      notes, 
    }
    const isUpdated = isEqual(currentEvent, event) ? false : true

    onChange(currentEvent, isUpdated)
  
  }, [
    name,
    type,
    estimatedLoss,
    estimatedLossUnit,
    startDate,
    startTime,
    endDate,
    endTime,
    location,
    notes,
  ])
  
  return (
    <>
      <TextField
        id="event-name"
        label="Name"
        value={name}
        onChange={e => setName(e.target.value)}
        fullWidth
      />
      <Grid>
        <TextField 
          id="event-type" 
          label="Type" 
          value={type} 
          onChange={e => setType(e.target.value)} 
          margin="normal" 
          select
          SelectProps={{
            native: true,
          }} 
        >
          {eventTypes.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </TextField>
      </Grid>
      <NumberField
        id="event-estloss"
        label="Estimated Loss"
        value={estimatedLoss}
        onChange={v => setEstimatedLoss(v)}
        margin="normal"
        fullWidth
        InputProps={{
          inputProps: {
            style: { textAlign: 'right' }
          },
          endAdornment: (
            <InputAdornment 
              style={{ whiteSpace: 'nowrap' }} 
              position="end"
            >
              {volumeUnitShort[estimatedLossUnit]}
            </InputAdornment>
          ),
        }}
      />
      <Grid>
        <TextField 
          id="event-estunit" 
          label="Unit" 
          value={estimatedLossUnit} 
          onChange={e => setEstimatedLossUnit(e.target.value)} 
          margin="normal" 
          select
          SelectProps={{
            native: true,
          }} 
        >
          {volumeUnitsList.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </TextField>
      </Grid>
      <DateForm
        label="Start Date"
        year={startDate.substr(0,4)}
        month={startDate.substr(4,2)}
        day={startDate.substr(6,2)} 
        onChange={date => setStartDate(`${date.year}${date.month}${date.day}`)}
      />
      <TextField 
        label="Start Time"
        value={startTime}
        onChange={e => setStartTime(e.target.value)}
        type="time"
        margin="dense"
      />
      <DateForm
        label="End Date"
        year={endDate.substr(0,4)}
        month={endDate.substr(4,2)}
        day={endDate.substr(6,2)} 
        onChange={date => setEndDate(`${date.year}${date.month}${date.day}`)}
      />
      <TextField 
        label="End Time"
        value={endTime}
        onChange={e => setEndTime(e.target.value)}
        type="time"
        margin="dense"
      />
      <TextField
        id="event-location"
        label="Location"
        value={location}
        onChange={e => setLocation(e.target.value)}
        margin="normal"
        fullWidth
        multiline
        rows={2}
      />  
      <TextField
        id="event-notes"
        label="Notes"
        value={notes}
        onChange={e => setNotes(e.target.value)}
        fullWidth
        multiline
        rows={3}
      /> 
    </>
  )
}

EventForm.defaultProps = {
  onChange: () => {}
}

EventForm.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
}

export default EventForm