import React, { useState, useEffect } from 'react'
import { compose } from 'recompose'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { API, graphqlOperation } from 'aws-amplify'

import * as queries from 'graphql/queries.js'
import ToolbarContent from 'components/ToolbarContent'
import withUserData from 'components/withUserData'

const styles = theme => ({
  button: {
    padding: 20,
  },
})

function WaterSystemActions(props) {
  const { 
    classes,
    match, 
    history, 
    location,
  } = props

  const [pws, setPws] = useState(location.state.pws)

  let isMounted
  
  useEffect(() => {
    isMounted = true
    
    ;(async() => {
      const resultGetWaterSystem = await API.graphql(graphqlOperation(queries.getWaterSystem, { id: match.params.id }))
      const pws = resultGetWaterSystem.data.getWaterSystem
      if(!isMounted) return
      setPws(pws)
    })()

    return () => {
      isMounted = false
    }

  }, [match.params.id])

  return (
    <>
      <ToolbarContent
        screenTitle = {pws.name}
        isMainScreen = {false}
      />
      <Button 
        className={classes.button}
        variant="contained" 
        color="secondary"
        fullWidth
        style={{ marginTop: 10, }}
        onClick={() => history.push({
          pathname: `/water-systems/view/${match.params.id}`,
          state: { pws },
        })}
      >
        CONFIGURATION
      </Button>
      <Button 
        className={classes.button}
        variant="contained" 
        color="secondary"
        fullWidth
        style={{ marginTop: 20, }}
        onClick={() => history.push({
          pathname: `/water-systems/${match.params.id}/sources`,
          state: { pws },
        })}
      >
        SOURCES
      </Button>
      <Button 
        className={classes.button}
        variant="contained" 
        color="secondary"
        fullWidth
        style={{ marginTop: 20, }}
        onClick={() => history.push({
          pathname: `/water-systems/${match.params.id}/cost-inputs`,
          state: { pws },
        })}
      >
        COST INPUTS
      </Button>
      <Button 
        className={classes.button}
        variant="contained" 
        color="secondary"
        fullWidth
        style={{ marginTop: 20, }}
        onClick={() => history.push({
          pathname: `/production-data/pws/${match.params.id}`,
          state: { pws },
        })}
      >
        PRODUCTION DATA
      </Button>
      <Button 
        className={classes.button}
        variant="contained" 
        color="secondary"
        fullWidth
        style={{ marginTop: 20, }}
        onClick={() => history.push({
          pathname: `/consumption-data/pws/${match.params.id}`,
          state: { pws },
        })}
      >
        CONSUMPTION DATA
      </Button>
      <Button 
        className={classes.button}
        variant="contained" 
        color="secondary"
        fullWidth
        style={{ marginTop: 20, }}
        onClick={() => history.push({
          pathname: `/event-data/pws/${match.params.id}`,
          state: { pws },
        })}
      >
        EVENT DATA
      </Button>
    </>
  )
}

export default compose(
  withStyles(styles),
  withUserData
)(WaterSystemActions)