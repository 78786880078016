import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  buttonDelete: {
    color: theme.palette.error.main
  }
})

const handleOnChange = (e, defaultValue, setAllowConfirm) => {
  if(e.target.value === defaultValue) {
    setAllowConfirm(true)
  } else {
    setAllowConfirm(false)
  }
}

const handleClose = (setOpenDialog, setConfirm) => {
  setOpenDialog(false)
  setConfirm(false)
}

const handleDelete = (setOpenDialog, setConfirm) => {
  setOpenDialog(false)
  setConfirm(true)
}

function ConfirmDeleteDialog(props) {
  const {
    classes,
    objectName,
    fieldDescription,
    defaultValue,
    setOpenDialog,
    setConfirm,
    startText,
    actionText,
    confirmText,
    cancelText,
  } = props
  const [open, setOpen] = useState(props.open)
  const [allowConfirm, setAllowConfirm] = useState(false)

  useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleClose(setOpenDialog, setConfirm)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{actionText || 'Delete'} {objectName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {startText}
          </DialogContentText>
          <DialogContentText>
            This action is permanent and cannot be undone.
            To confirm, please enter {fieldDescription}: "{defaultValue}".
          </DialogContentText>
          <TextField
            margin="dense"
            id="name"
            placeholder={defaultValue}
            fullWidth
            onChange={(e) => handleOnChange(e, defaultValue, setAllowConfirm)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(setOpenDialog, setConfirm)}>
            {cancelText || 'CANCEL'}
          </Button>
          <Button 
            className={classes.buttonDelete}
            disabled={!allowConfirm} 
            onClick={() => handleDelete(setOpenDialog, setConfirm)} 
          >
            {confirmText || 'DELETE'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

ConfirmDeleteDialog.propTypes = {
  setConfirm: PropTypes.func.isRequired,
  setOpenDialog: PropTypes.func.isRequired,
  objectName: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  startText: PropTypes.string,
  actionText: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
}

export default withStyles(styles)(ConfirmDeleteDialog)