import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'
import LockIcon from '@material-ui/icons/Lock'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import { formatNumber } from 'accounting'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { API, graphqlOperation } from 'aws-amplify'

import * as queries from 'graphql/queries'
import withUserData from 'components/withUserData'

const styles = theme => ({
  item: {
    marginBottom: 12,
  },
})

const getItemComponents = item => {
  const itemComponents = [
    { 
      type: 'SUBSCRIBED', 
      icon: () => <CheckIcon style={{ color: '#4caf50' }} />,
      text: 'Ready'
    }, 
    { 
      type: 'UNSUBSCRIBED', 
      icon: () => <LockIcon />,
      text: 'New PWS - payment required'
    },  
    { 
      type: 'MODIFIED', 
      icon: LockIcon,
      text: 'Configuration changed - payment required'
    },
    { 
      type: 'NO_CONNECTION', 
      icon: () => <NotInterestedIcon style={{ color: '#f44336' }} />,
      text: 'Invalid Configuration',
    },
  ]

  if (item.activeConnections === '0') {
    return itemComponents.find(component => component.type === 'NO_CONNECTION')
  }
  
  if (item.subscribed || item.trialActive) {
    return itemComponents.find(component => component.type === 'SUBSCRIBED')
  } else {
    // configuration changed
    // if () {
    //   return itemComponents.find(component => component.type === 'MODIFIED')
    // }

    return itemComponents.find(component => component.type === 'UNSUBSCRIBED')
  }
}

function PublicWaterSystems(props) {
  const { 
    classes,
    onItemClick,
    notFoundMessage,
    setNotFound,
    trialActive,
  } = props
  const { organizationId } = props.userContext.userData

  const [pws, setPws] = useState([])
  const [queryComplete, setQueryComplete] = useState(false)

  let isMounted

  useEffect(() => {
    isMounted = true

    ;(async() => {
      if(!isMounted) return
      setQueryComplete(false)
      const listWaterSystemsFilter = {
        filter: {
          organizationId: {
            eq: organizationId
          }
        },
        limit: 1000
      }
      const resultListWaterSystems = await API.graphql(graphqlOperation(queries.listWaterSystems, listWaterSystemsFilter))  
      const pws = resultListWaterSystems.data.listWaterSystems.items

      // sort by subscription status
      pws.sort((a, b) => {
        return b.subscribed - a.subscribed
      })
      
      // sort by number of active connections
      pws.sort((a, b) => {
        if (a.subscribed === b.subscribed) {
          return b.activeConnections - a.activeConnections
        }

        return null
      })

      if (pws.length === 0) {
        setNotFound(true)
      } else {
        setNotFound(false)
      }
      
      setPws(pws)
      setQueryComplete(true)
    })()

    return () => {
      isMounted = false
    }
  
  }, [props.id])

  if(!queryComplete) {
    return (
      <ListItem> 
        <CircularProgress />
      </ListItem>
    )
  }

  if(!pws.length) {
    return (
      <ListItem>
        <ListItemText primary={notFoundMessage || ''} />
      </ListItem>
    )
  }
  
  return (
    <>
      {pws.map((item) => { 
        item.trialActive = trialActive
        const itemComponents = getItemComponents(item)

        return (        
          <Card 
            className={classes.item} 
            key={item.id}
            raised
          >
            <CardActionArea 
              onClick={() => onItemClick(item, itemComponents.type)}
            >
              <CardHeader 
                avatar={<itemComponents.icon />}
                title={
                  <>
                    <Typography 
                      variant="h6" 
                      component="h3"
                    >
                      {item.name}
                    </Typography>  
                    <Typography>
                      {itemComponents.text}
                    </Typography>  
                  </>
                }
                subheader={`${formatNumber(item.activeConnections)} Active Connections`}
              />
            </CardActionArea>
          </Card>
        )})
      }
    </>
  )
}

PublicWaterSystems.defaultProps = {
  onItemClick: () => {},
  setNotFound: () => {},
  trialActive: false,
}

PublicWaterSystems.propTypes = {
  onItemClick: PropTypes.func,
  notFoundMessage: PropTypes.string,
  setNotFound: PropTypes.func,
  trialActive: PropTypes.bool,
}

export default compose(
  withUserData,
  withStyles(styles)
)(PublicWaterSystems)
