import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
// import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import isEqual from 'lodash/isEqual'
import { withStyles } from '@material-ui/core/styles'

import DateForm, { YearDropdown, getCurrentDate, } from 'components/DateForm'
import { volumeUnitsList } from 'services/constantVariables/units'
import NumberField from 'components/NumberField'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%',
  },
  // locationHeader: {
  //   marginTop: 16,
  // },
  // locationField: {
  //   marginLeft: 16,
  //   width: '100%',
  // },
  switchLabel: {
  	width: '100%',
  	marginLeft: 0,
  	marginTop: 16,
  	justifyContent: 'space-between',
  },
  input: {
  },
  menu: {
    width: 50,
    textAlign: 'right',
    maxHeight: 300,
  },
})

function WaterSourceForm(props) {
  const { 
  	classes,
  	data, 
  	setIsUpdated,
  	setUpdatedData,
  } = props

  //define fields
  const fields = {
  	id: data.id,
	  name: data.name || '',
	  type: data.type || '',
		units: data.units || 'Kgal',
		isMetered: data.isMetered || false,
		estimate: data.estimate || '',
		meterSize: data.meterSize || '',
		meterMakeModel: data.meterMakeModel || '',
		meterInstallYear: data.meterInstallYear || new Date().getFullYear().toString(),
		meterCalibDate: data.meterCalibDate || getCurrentDate().slice(0,6).concat('01'), // yyyymmdd, default day to 01
    // latitude: data.latitude || '',
    // longitude: data.longitude || '',
		waterSystemId: data.waterSystemId,
  }

  //define each data fields as states
  const [name, setName] = useState(fields.name)
  const [type, setType] = useState(fields.type)
  const [units, setUnits] = useState(fields.units)
  const [isMetered, setIsMetered] = useState(fields.isMetered)
  const [estimate, setEstimate] = useState(fields.estimate)
  const [meterSize, setMeterSize] = useState(fields.meterSize)
  const [meterMakeModel, setMeterMakeModel] = useState(fields.meterMakeModel)
  const [meterInstallYear, setMeterInstallYear] = useState(fields.meterInstallYear)
  const [meterCalibDate, setMeterCalibDate] = useState(fields.meterCalibDate)
  // const [latitude, setLatitude] = useState(fields.latitude)
  // const [longitude, setLongitude] = useState(fields.longitude)
  // const [permissionDenied, setPermissionDenied] = useState(false)

  let isMounted

  useEffect(() => {
    isMounted = true

    if(isMounted) {
      // if (!latitude && !longitude) {      
      //   const setLocation = position => {
      //     setLatitude(position.coords.latitude)
      //     setLongitude(position.coords.longitude)
      //   }

      //   const error = err => {
      //     console.log(`GEOLOCATION ERROR(${err.code}): ${err.message}`)
      //     if (err.code === 1) {
      //       setPermissionDenied(true)
      //     }
      //   }

      //   if (navigator.geolocation) {
      //     navigator.geolocation.getCurrentPosition(setLocation, error)      
      //   } else {
      //     console.log('No location retrieved')
      //   }
      // } 

      const initialData = fields
      let currentData = JSON.parse(JSON.stringify(initialData))   
      currentData.name = name
      currentData.type = type
      currentData.units = units
      currentData.isMetered = isMetered
      currentData.estimate = estimate
      currentData.meterSize = meterSize
      currentData.meterMakeModel = meterMakeModel
      currentData.meterInstallYear = meterInstallYear
      currentData.meterCalibDate = meterCalibDate
      // currentData.latitude = latitude
      // currentData.longitude = longitude

      if(isEqual(currentData, initialData)) {
        setIsUpdated(false)
        setUpdatedData(initialData)
      } else {
        setIsUpdated(true)
        setUpdatedData(currentData)
      }
    }

    return () => {
      isMounted = false
    }
  }, [
		name,
		type,
		units,
		isMetered,
		estimate,
		meterSize,
		meterMakeModel,
		meterInstallYear,
		meterCalibDate,
    // latitude,
    // longitude,
	])

  return (
    <>
      <form className={classes.container} noValidate autoComplete="off">
        <TextField 
          id="name" 
          label="Name" 
          className={classes.textField} 
          value={name} 
          onChange={(e) => setName(e.target.value)} 
          margin="normal" 
          inputProps={{ className: classes.input }}
          autoFocus
        />
        {/* <Typography 
          className={classes.locationHeader} 
          color="textSecondary" 
          variant="caption"
        >
          GPS Location 
          <p>
            {permissionDenied ? '(Access to location has been denied. Grant permission to enable auto-detection of GPS location.)' : ''}
          </p>
        </Typography>
        <TextField 
          id="type" 
          label="Latitude" 
          type="number"
          className={classes.locationField} 
          value={latitude} 
          onChange={(e) => setLatitude(e.target.value)} 
          margin="normal" 
          inputProps={{ className: classes.input }}
        />
        <TextField 
          id="type" 
          label="Longitude"
          type="number" 
          className={classes.locationField} 
          value={longitude} 
          onChange={(e) => setLongitude(e.target.value)} 
          margin="normal" 
          inputProps={{ className: classes.input }}
        /> */}
        <TextField 
          id="type" 
          label="Source Type" 
          className={classes.textField} 
          value={type} 
          onChange={(e) => setType(e.target.value)} 
          margin="normal" 
          inputProps={{ className: classes.input }}
        />
        <TextField 
          id="units" 
          label="Measurement Units" 
          className={classes.textField} 
          value={units} 
          onChange={(e) => setUnits(e.target.value)} 
          margin="normal" 
          inputProps={{ className: classes.input }}
          select
          SelectProps={{
          	native: true,
            MenuProps: {
              className: classes.menu,
            },
          }} 
        >
        	{volumeUnitsList.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </TextField>
        <FormControlLabel
        	className={classes.switchLabel}
          control={
            <Switch
              checked={isMetered}
              onChange={(e) => setIsMetered(e.target.checked)}
              value="checkedB"
              color="secondary"
            />
          }
          label="Is your source metered?"
          labelPlacement="start"
        />
        <NumberField 
          style={isMetered ? null : { display: 'none' }}
          id="meter-size" 
          label="Meter size (inches)" 
          className={classes.textField} 
          value={meterSize} 
          onChange={v => setMeterSize(v)}
          margin="normal" 
          inputProps={{ className: classes.input }}
        />
        <TextField 
          style={isMetered ? null : { display: 'none' }}
          id="meter-make-model" 
          label="Meter make and model" 
          className={classes.textField} 
          value={meterMakeModel} 
          onChange={(e) => setMeterMakeModel(e.target.value)} 
          margin="normal" 
          inputProps={{ className: classes.input }}
        />
        <YearDropdown
          style={isMetered ? null : { display: 'none' }}
          id="meter-install-year"  
          label="Meter install year" 
          value={meterInstallYear} 
          onChange={(e) => setMeterInstallYear(e.target.value)} 
        />
        <DateForm 
          style={isMetered ? null : { display: 'none' }}
          label="Meter calibration date"
          year={meterCalibDate.substr(0,4)}
          month={meterCalibDate.substr(4,2)}
          day={meterCalibDate.substr(6,2)}
          onChange={date => setMeterCalibDate(`${date.year}${date.month}${date.day}`)}
        />
        <TextField
          style={isMetered ? { display: 'none' } : null}
          id="estimate" 
          label="How do you estimate your volumes?" 
          className={classes.textField} 
          value={estimate} 
          onChange={(e) => setEstimate(e.target.value)} 
          margin="normal" 
          inputProps={{ className: classes.input }}
          helperText="Ex. Pump run times, Storage volumes, Guessing"
        />
      </form>
    </>
  )
}

WaterSourceForm.propTypes = {
	data: PropTypes.object.isRequired,
	setIsUpdated: PropTypes.func.isRequired,
	setUpdatedData: PropTypes.func.isRequired,
}

export default withStyles(styles)(WaterSourceForm)