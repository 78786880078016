import React, { useState, useEffect } from 'react'
import { compose } from 'recompose'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'
import { API, graphqlOperation } from 'aws-amplify'
import { withStyles } from '@material-ui/core'

import * as queries from 'graphql/queries'
import * as mutations from 'graphql/mutations'
import ToolbarContent from 'components/ToolbarContent'
import withUserData from 'components/withUserData'

const LabeledText = (props) => (
  <>
    <Typography 
      variant="subtitle2" 
      color="textSecondary" 
      style={{ 
        marginTop: 5, 
      }}
    >
      {props.label}
    </Typography>
    <Typography 
      variant="subtitle1" 
      style={{ 
        marginBottom: 5, 
      }} 
    >
      {props.value||<>&nbsp;</>}
    </Typography>
    <Divider />        
  </>
)

const styles = theme => ({
  editButton: {
    marginTop: 10,
  },
  optionButton: {
    marginTop: 40,
  },
  nextButton: {
    marginTop: 5,
  }
})

const handleSendInvite = (email, setMessage, setOpenSnack, history) => {
  if (!email) {
    setMessage('Email is empty, please edit contact first')
    setOpenSnack(true)
    return 
  }
  
  history.push({
    pathname: '/user-management/new',
    state: { response: 'user', email: email },
  })
}

const handleDisableUser = async(user, history) => {
  const inputApiAction = {
    name: 'disableUser',
    parameters: [
      user.id,
      user.userPoolId,
      user.email,
    ]
  } 
  await API.graphql(graphqlOperation(mutations.createApiAction, { input: inputApiAction } ))
  history.push('/user-management')
}

function UserView(props) {
  const { 
    classes,
    match, 
    history, 
  } = props

  const [user, setUser] = useState({})
  const [openSnack, setOpenSnack] = useState(false)
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  let isMounted
  
  useEffect(() => {
    isMounted = true
    
    ;(async() => {
      setLoading(true)

      const queryUser = await API.graphql(graphqlOperation(queries.getUser, { id: match.params.userId }))
      const queryUserData = queryUser.data.getUser
      
      if (!isMounted) return
      setUser({
        id: queryUserData.id,
        firstName: queryUserData.firstName,
        lastName: queryUserData.lastName,
        company: queryUserData.company, 
        title: queryUserData.title, 
        phone: queryUserData.phone, 
        phone2: queryUserData.phone2,
        email: queryUserData.email, 
        userPoolId: queryUserData.userPoolId,
      })

      setLoading(false)
    })()

    return () => {
      isMounted = false
    }
  }, [match.params.userId])

  if (loading) return <CircularProgress />

  return (
    <>
      <ToolbarContent
        screenTitle = "User"
        isMainScreen = {false}
      />
      <LabeledText label="First Name" value={user.firstName} />
      <LabeledText label="Last Name" value={user.lastName} />
      <LabeledText label="Company" value={user.company} />
      <LabeledText label="Title" value={user.title} />
      <LabeledText label="Phone" value={user.phone} />
      <LabeledText label="Secondary Phone" value={user.phone2} />
      <LabeledText label="Email" value={user.email} />
      <Button 
        className={classes.editButton}
        variant="contained" 
        color="secondary"
        fullWidth
        onClick={() => history.push({
          pathname: `/user-management/edit/${match.params.userId}`,
          state: { user: user },
        })}
      >
        EDIT
      </Button>
      {user.userPoolId ? (
        <>
          <Button 
            className={classes.optionButton}
            fullWidth
            onClick={() => handleDisableUser(user, history)}
          >
            DISABLE USER ACCOUNT
          </Button>
        </>
      ) : (
        <>
          <Button 
            className={classes.optionButton}
            fullWidth
            onClick={() => handleSendInvite(user.email, setMessage, setOpenSnack, history)}
          >
            SEND EMAIL INVITE
          </Button>
        </>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnack}
        onClose={() => setOpenSnack(false)}
        action={
          <Button 
            color="secondary" 
            size="small"
            onClick={() => setOpenSnack(false)}
          >
            CLOSE
          </Button>
        }
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={
          <span id="message-id">
            {message} 
          </span>
        }
      />
    </>
  )
}

export default compose(
  withUserData,
  withStyles(styles)
)(UserView)