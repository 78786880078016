import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { formatNumber } from 'accounting'
import { withStyles } from '@material-ui/core/styles'
import { API, graphqlOperation } from 'aws-amplify'

import * as queries from 'graphql/queries'
import * as mutations from 'graphql/mutations'
import ToolbarContent from 'components/ToolbarContent'
import ContentHeader from 'components/ContentHeader'
import ConfirmDeleteDialog from 'components/ConfirmDeleteDialog'
import LabeledNumber from 'components/LabeledNumber'
import { volumeUnitsList, volumeUnitShort } from 'services/constantVariables/units'
import { formatDate } from 'services/utilityFunctions'

const styles = theme => ({
  textField: {
    minWidth: 150,
  },
  percentField: {
    textAlign: "right",
  },
  button: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  buttonDelete: {
    color: theme.palette.error.main 
  },
  customLabel: {
    fontSize: '.85rem',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'block',
  },
  verticalSpace: {
    marginBottom: theme.spacing.unit * 2,
    backgroundColor: 'transparent',
  },
  textRight: {
    textAlign: 'right'
  },
  value: {
    fontSize: '0.875rem',
    textAlign: 'right'
  }
})

const handleDelete = async consumptionId => {
  try {
    await API.graphql(graphqlOperation(mutations.deleteConsumptionAndUse, { input: { id: consumptionId } }))
  } catch (error) {
    console.log(error)
  }
}

function ConsumptionAndUseView(props) {
  const {
    classes,
    history,
    location,
  } = props
  
  const { pws, consumption } = location.state

  const [startDate, setStartDate] = useState(consumption.startDate || '')
  const [endDate, setEndDate] = useState(consumption.endDate || '')
  const [meteredBilledConsumption, setMeteredBilledConsumption] = useState(consumption.meteredBilledConsumption || 0)
  const [meteredUnbilledConsumption, setMeteredUnbilledConsumption] = useState(consumption.meteredUnbilledConsumption || 0)
  const [unmeteredBilledConsumption, setUnmeteredBilledConsumption] = useState(consumption.unmeteredBilledConsumption || 0)
  const [unmeteredUnbilledConsumption, setUnmeteredUnbilledConsumption] = useState(consumption.unmeteredUnbilledConsumption || 0)
  const [unit, setUnit] = useState(consumption.unit || volumeUnitsList[0])

  const total = Number(meteredBilledConsumption) + 
                Number(meteredUnbilledConsumption) +
                Number(unmeteredBilledConsumption) +
                Number(unmeteredUnbilledConsumption)

  let isMounted

  useEffect(() => {
    isMounted = true
    
    ;(async () => {
      try {
        const result = await API.graphql(graphqlOperation(queries.getConsumptionAndUse, { id: consumption.id }))
        const consumptionData = result.data.getConsumptionAndUse
        
        if (!isMounted) return

        setStartDate(consumptionData.startDate)
        setEndDate(consumptionData.endDate)
        setMeteredBilledConsumption(consumptionData.meteredBilledConsumption)
        setMeteredUnbilledConsumption(consumptionData.meteredUnbilledConsumption)
        setUnmeteredBilledConsumption(consumptionData.unmeteredBilledConsumption)
        setUnmeteredUnbilledConsumption(consumptionData.unmeteredUnbilledConsumption)
        setUnit(consumptionData.unit)

        history.replace({ 
          state: {
            pws,
            consumption: consumptionData
          }
        })

      } catch (error) {
        console.log(error)
      }
    })()

    return () => {
      isMounted = false
    }
  }, [])
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [deleteInProgress, setDeleteInProgress] = useState(false)
  
  const [confirmDelete, setConfirmDelete] = useState(false)
  useEffect(() => {
    if (confirmDelete) {
      (async () => {
        setDeleteInProgress(true)
        try {
          await handleDelete(consumption.id)
          history.goBack()
        } catch (error) {
          console.log(error)
        }
      })()
    }
  }, [confirmDelete])

  return (
    <>
      <ToolbarContent
        screenTitle="Consumption Data View"
        isMainScreen={false}
      />
      <ContentHeader
        breadcrumbs={[
          { name: 'Consumption Data', goBack: true, step: 2 },
          { name: pws.name, goBack: true, step: 1 }, 
          { name: formatDate(startDate, 'Mmm/dd/yyyy') + ' - ' + formatDate(endDate, 'Mmm/dd/yyyy') } 
        ]}
        history={history}
      />
      <Grid>
        <Typography 
          variant="caption"
          gutterBottom
        >
          Start Date
        </Typography>
        <Typography
          variant="subtitle1"
        >
          {formatDate(startDate, 'Mmm/dd/yyyy')}
        </Typography>
      </Grid>
      <Grid >
        <Typography 
          variant="caption"
          gutterBottom
        >
          End Date
        </Typography>
        <Typography
          variant="subtitle1"
        >
          {formatDate(endDate, 'Mmm/dd/yyyy')}
        </Typography>
      </Grid>
      <Divider className={classes.verticalSpace}/>
      <Typography variant="h6" color="secondary">
        Metered
      </Typography>
      <LabeledNumber
        label="Billed Consumption"
        value={`${formatNumber(meteredBilledConsumption, 2)} ${volumeUnitShort[unit]}`}
      />
      <LabeledNumber
        label="Unbilled Consumption"
        value={`${formatNumber(meteredUnbilledConsumption, 2)} ${volumeUnitShort[unit]}`}
      />
      <Divider className={classes.verticalSpace}/>
      <Typography variant="h6" color="secondary">
        Unmetered
      </Typography>
      <LabeledNumber
        label="Billed Consumption"
        value={`${formatNumber(unmeteredBilledConsumption, 2)} ${volumeUnitShort[unit]}`}
      />
      <LabeledNumber
        label="Unbilled Consumption"
        value={`${formatNumber(unmeteredUnbilledConsumption, 2)} ${volumeUnitShort[unit]}`}
      />
      <Divider className={classes.verticalSpace}/>
      <LabeledNumber
        label="Total for time period"
        value={`${formatNumber(total, 2)} ${volumeUnitShort[unit]}`}
        variant="bold"
      />
      <Divider className={classes.verticalSpace}/>
      <Button
        className={classes.button}
        variant="contained"
        fullWidth
        onClick={() => {
          history.push({
            pathname: location.pathname.replace('view', 'edit'),
            state: { consumption, pws },
          })
        }}
      >
        EDIT
      </Button>
      <Button
        className={`${classes.button} ${classes.buttonDelete}`}
        fullWidth
        disabled={consumption.id ? false : true || deleteInProgress}
        onClick={() => {
          setOpenDeleteDialog(true)
        }}
      >
        DELETE
      </Button>
      <ConfirmDeleteDialog 
        open={openDeleteDialog} 
        setOpenDialog={setOpenDeleteDialog} 
        setConfirm={setConfirmDelete} 
        objectName="Consumption &amp; Use"
        fieldDescription="word" 
        actionText="Delete"
        confirmText="CONFIRM"
        cancelText="BACK"
        defaultValue="DELETE"
      />
    </>
  )
}

ConsumptionAndUseView.defaultProps = {
  error: false,
}

ConsumptionAndUseView.propTypes = {
  error: PropTypes.bool,
}

export default withStyles(styles)(ConsumptionAndUseView)