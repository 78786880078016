import React from 'react'

import ToolbarContent from 'components/ToolbarContent'
import ContentHeader from 'components/ContentHeader'
import AddFabButton from 'components/AddFabButton'
import ConsumptionAndUseList from './components/ConsumptionAndUseList'

function ConsumptionAndUse(props) {
  const {
    history,
    location,
  } = props

  const { pws } = location.state

  return (
    <>
      <ToolbarContent
        screenTitle={`Consumption Data`}
        isMainScreen={false}
      />
      <ContentHeader
        breadcrumbs={[
          { name: 'Consumption Data', goBack: true, step: 1 }, 
          { name: pws.name } 
        ]}
        history={history}
      />
      <ConsumptionAndUseList 
        waterSystemId={pws.id} 
        onItemClick={consumption => {
          history.push({
            pathname: `${location.pathname}/view/${consumption.id}`,
            state: { consumption, pws },
          })
        }} 
      />
      <AddFabButton
        onClick={() => history.push({
          pathname: `${location.pathname}/new`,
          state: { pws },
        })}
      />
    </>
  )
}

export default ConsumptionAndUse