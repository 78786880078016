import React from 'react'
import PropTypes from 'prop-types'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'

import { Helmet } from 'react-helmet'

function AppToolbar(props) {
  const {
    menuClick,
    title,
    renderIcon
  } = props

  function Icon() {
    return (
      <IconButton 
        color="inherit" 
        aria-label="Menu" 
        onClick={() => menuClick()}
      >
        <MenuIcon />
      </IconButton>
    )
  }

  return (
    <>
      <Toolbar>
        {renderIcon ? <Icon /> : null}
        <Typography variant="h6" color="inherit">
          {title}
        </Typography>
      </Toolbar>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
    </> 
  )
}

AppToolbar.defaultProps = {
  isMainScreen: true
}

AppToolbar.propTypes = {
  menuClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  isMainScreen: PropTypes.bool.isRequired,
  renderIcon: PropTypes.bool.isRequired,
}

export default AppToolbar


