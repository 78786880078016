import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import { API, graphqlOperation } from 'aws-amplify'
import { compose } from 'recompose'

import * as queries from 'graphql/queries'
import withUserData from 'components/withUserData'

const styles = () => ({
  title: {
    marginBottom: 30,
  },
  body: {
    marginBottom: 20,
  },
  button: {
    marginTop: 40,
  },
})

const prettyDate = date => {
  const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  const month = MONTHS[date.getMonth()]
  const day = date.getDate()
  const year = date.getFullYear()

  return `${month} ${day}, ${year}`
}

function useTrialInfo(organizationId, billingStatus) {

  const [trial, setTrial] = useState()
  const [endDate, setEndDate] = useState()

  let isMounted

  useEffect(() => {
    isMounted = true

    ;(async () => {
      try {
        const listTrialFilter = {
          filter: {
            and: [
              { 
                organizationId: { eq: organizationId }
              },
              { 
                trialName: { eq: "First Usage Trial" }
              },
            ],
          },
          limit: 1000
        }
  
        const resultListTrial = await API.graphql(graphqlOperation(queries.listTrials, listTrialFilter))
        const trial = resultListTrial.data.listTrials.items[0]
        
        if (!isMounted || !trial) return
        const endDate = new Date(trial.endDate)
        
        setEndDate(endDate)
        setTrial(trial)
      } catch (error) {
        console.log(error)
      }
    })()

    return () => {
      isMounted = false
    }
  }, [billingStatus])

  return { trial, endDate }
}

function TrialInfo(props) {
  const {
    classes,
    userContext,
  } = props
  const { organizationId } = userContext.userData

  const { trial, endDate } = useTrialInfo(organizationId)

  return (
    <>
      <Typography 
        className={classes.title}
        align="center" 
        variant="h6" 
        color="primary"
      >
        {trial ? (
          <>
            You have {trial.daysLeft} {trial.daysLeft === '1' ? 'day' : 'days'} left of your free trial
          </>
        ) : <CircularProgress />}
      </Typography>
      <Typography
        className={classes.body}
        align="center"
      >
        {endDate ? <>End date: {prettyDate(endDate)}</> : null}
      </Typography>
      <Typography
        className={classes.body}
      >
        Configure your water system.  Add sources and record production data.  You can generate as many reports as you want with configurable time periods.
      </Typography>
      <Typography>
        <a href="https://waterutilityapp.com/support/free-trial">
          Check out our support page for more details about the free trial 
        </a>
      </Typography>
    </>
  )
}

export default compose(
  withUserData,
  withStyles(styles),
)(TrialInfo)

export { 
  useTrialInfo,
  prettyDate,
} 