import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { Redirect } from 'react-router-dom'
import { API, graphqlOperation, } from 'aws-amplify'

import * as mutations from 'graphql/mutations'
import { UserContext } from 'contexts'
import ToolbarContent from 'components/ToolbarContent'
import withUserData from 'components/withUserData'

const styles = () => ({
  button: {
    margin: 'auto',
  },
})

class JoinOrganization extends Component {
  state = {
    joinRequest: null,
    updatedUserData: null,
  }

  joinOrganization = organizationId => async () => {
    const inputCreateJoinRequest = {
      state: "NEW",
      userId: this.props.userContext.currentUser.attributes.sub,
      joinRequestUserId: this.props.userContext.currentUser.attributes.sub,
      organizationId: organizationId,  
      joinRequestOrganizationId: organizationId,
    }
    try {
      const joinRequestData = await API.graphql(graphqlOperation(mutations.createJoinRequest, {input: inputCreateJoinRequest}))
      const joinRequest = joinRequestData.data.createJoinRequest
      //connect current User to newly created join request
      const inputUpdateUser = {
        id: this.props.userContext.currentUser.attributes.sub, 
        userJoinRequestId: joinRequest.id
      }
      const updateUserResult = await API.graphql(graphqlOperation(mutations.updateUser, {input: inputUpdateUser}))
      const updatedUserData = updateUserResult.data.updateUser

      await this.setState({ 
        joinRequest, 
        updatedUserData 
      })
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const { classes, match } = this.props
    const { joinRequest, updatedUserData } = this.state

    if(joinRequest) {
      return (
        <>
          <UserContext.Consumer>
            {({ updateUserData }) => {
              updateUserData(updatedUserData)
            }}
          </UserContext.Consumer>
          <Redirect to={`/onboarding/request-sent/${joinRequest.id}`} />      
        </>
      ) 
    }

    //check required variables
    if(!match) {
      //redirect to error screen but for now...
      return (
        <>
          <ToolbarContent
            screenTitle = "Join Organization"
            isMainScreen = {false}
          />
          <Typography paragraph>
            Unable to retrieve organization details 
          </Typography>
        </>
      )
    }

    return (
      <>
        <ToolbarContent
          screenTitle = "Join Organization"
          isMainScreen = {false}
        />
        <Grid item xs={12} sm={6}>
          <Typography color="secondary" variant="h6" gutterBottom>
            You found it!
          </Typography>
          <Typography paragraph>
            Send a request to join <b>{match.params.orgname}</b>.
            Your account will be ready to use as soon as they approve the request!
          </Typography>
          <Button 
            className={classes.button} 
            variant="contained" 
            color="primary"
            fullWidth
            onClick={this.joinOrganization(match.params.orgid)}
          >
            SEND REQUEST TO JOIN
          </Button>
        </Grid>
      </>
    )
  }
}

export default compose(
  withUserData,
  withStyles(styles)
)(JoinOrganization)