import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Skeleton from 'react-loading-skeleton'
import { formatNumber } from 'accounting'
import { styled, makeStyles } from '@material-ui/styles'
import { API, graphqlOperation } from 'aws-amplify'

import * as mutations from 'graphql/mutations'
import { formatDate } from 'services/utilityFunctions/index'
import Cover from './Cover'
import Background from './Background'
import ScopeAndObjective from './ScopeAndObjective'
import Methodology from './Methodology'
import AuditResults from './AuditResults'
import Recommendations from './Recommendations'
import Definitions from './Definitions'
import References from './References'

const Gap = styled(Divider)({
  background: 'transparent !important',
  height: '20px !important',
})

const useStyles = makeStyles({
  subtitle: {
    fontSize: 16,
  }
})

function ReportContents(props) {
  const {
    location, 
    history,
  } = props

  const {
    pws,
    dateRange,
    unit: inputUnit,
    volumeDifference,
    UACVolume
  } = location.state

  const dataDef = {
    pwsName: 'not set',
    timePeriod: ['000000', '000000'],
    timePeriodDays: 'not set',
    sourceNames: [],
    dataQuality: '3',
    isMetered: true,
    isFullyMetered: true,
    hasTestingAndReplacementProgram: true,
    aveMeterAge: 'not set',
    connectionsValidity: 'not set',
    waterMainLenValidity: 'not set',
    aveSysPressureValidity: 'not set',
    unit: 'Kgal',
    sourceInput: 'not set',
    totalMeteredUsage: 'not set',
    lossVolume: 'not set',
    UARL: 'not set',
    realWaterLoss: 'not set',
    apparentWaterLoss: 'not set',
    nonRevenueWater: 'not set',
    realWaterLossCost: 'not set',
    apparentWaterLossCost: 'not set',
    ILI: 'not set',
    realLossPerService: 'not set',
    percentLoss: '0%',
    unauthorizedConsumption: 'not set',
    customerMeteringInaccuracies: 'not set',
    sysDataHandlingErrors: 'not set',
    aveRetailUnitCost: 'not set',
    waterSupplied: 'not set',
    waterMainLen: 'not set',
    serviceConnections: 'not set',
    avePipeLength: 'not set',
    aveSysPressure: 'not set',
    chartData: []
  }

  const classes = useStyles()
  
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(dataDef)
  const [error, setError] = useState(false)
  
  let {
    pwsName,
    timePeriod,
    totalDays: timePeriodDays,
    sourceNames,
    dataQuality,
    isMetered,
    isFullyMetered,
    hasTestingAndReplacementProgram,
    aveMeterAge,
    connectionsValidity,
    waterMainLenValidity,
    aveSysPressureValidity,
    unit,
    sourceInput,
    totalMeteredUsage,
    lossVolume,
    UARL,
    realWaterLoss,
    apparentWaterLoss,
    nonRevenueWater,
    realWaterLossCost,
    apparentWaterLossCost,
    ILI,
    realLossPerService,
    percentLoss,
    unauthorizedConsumption,
    customerMeteringInaccuracies,
    sysDataHandlingErrors,
    aveRetailUnitCost,
    waterSupplied,
    waterMainLen,
    serviceConnections,
    avePipeLength,
    aveSysPressure,
    chartData,
  } = data

  // format date for display
  const periodStart = formatDate(timePeriod[0], 'Month yyyy')
  const periodEnd = formatDate(timePeriod[1], 'Month yyyy')

  // process amount fields to display as dd,ddd.dd
  sourceInput = formatNumber(sourceInput, 2)
  totalMeteredUsage = formatNumber(totalMeteredUsage, 2)
  lossVolume = formatNumber(lossVolume, 2)
  UARL = formatNumber(UARL, 2)
  realWaterLoss = formatNumber(realWaterLoss, 2)
  apparentWaterLoss = formatNumber(apparentWaterLoss, 2)
  nonRevenueWater = formatNumber(nonRevenueWater, 2)
  realWaterLossCost = formatNumber(realWaterLossCost, 2)
  apparentWaterLossCost = formatNumber(apparentWaterLossCost, 2)
  ILI = formatNumber(ILI, 2)
  realLossPerService = formatNumber(realLossPerService, 2)
  percentLoss = formatNumber(percentLoss, 2)
  unauthorizedConsumption = formatNumber(unauthorizedConsumption, 2)
  customerMeteringInaccuracies = formatNumber(customerMeteringInaccuracies, 2)
  sysDataHandlingErrors = formatNumber(sysDataHandlingErrors, 2)
  aveRetailUnitCost = formatNumber(aveRetailUnitCost, 2)
  waterSupplied = formatNumber(waterSupplied, 2)
  waterMainLen = formatNumber(waterMainLen, 2)
  avePipeLength = formatNumber(avePipeLength, 2)
  aveSysPressure = formatNumber(aveSysPressure, 2)
  
  serviceConnections = formatNumber(serviceConnections)

  let isMounted

  useEffect(() => {
    isMounted = true

    ;(async () => {
      // get report data
      let dateRangeAry = [dateRange[0].date, dateRange[1].date]
      const inputApiAction = {
        name: 'getReportsData',
        parameters: [
          pws.id,
          dateRangeAry,
          inputUnit,
          volumeDifference,
          UACVolume
        ]
      } 

      try {
        const result = await API.graphql(graphqlOperation(mutations.createApiAction, { input: inputApiAction })) 
        if (!isMounted) return
        
        const reportDataJSON = result.data.createAPIAction.getReportDataResolver
        if (!reportDataJSON) {
          setError(true)
          setLoading(false)
          return
        }

        const reportData = JSON.parse(reportDataJSON)

        setData(reportData)
        setLoading(false)
      } catch (error) {
        console.log(error)
        setError(true)
        setLoading(false)
      }

    })()

    return () => {
      isMounted = false
    }
  }, [])

  if (loading) {
    return (
      <>
        <Grid style={{ maxWidth: '7.5in', marginLeft: 'auto', marginRight: 'auto' }}>  
          <Gap />
          <Typography
            variant="h5"
            align="center"
          >
            <Skeleton width="70%" />
          </Typography>
          <Typography
            variant="h5"
            align="center"
          >
            <Skeleton width="90%" />
          </Typography>
          <Typography 
            variant="h6"
            align="center"
          >
            <Skeleton width="80%" />
          </Typography>
          <Gap />
          <Skeleton count={30} />
        </Grid>
      </>
    )
  }

  if (error) {
    return(
      <>
        <Gap />
        <Typography color="error">
          There is something wrong with your configurations that makes the generation of the report impossible.
        </Typography>
      </>
    )
  }

  return (
    <>
      <Grid id="report" style={{ maxWidth: '7.5in', marginLeft: 'auto', marginRight: 'auto' }}>
        <section>
          <Cover 
            pwsName={pwsName}
            timePeriod={`${periodStart} - ${periodEnd}`}
          />
        </section>  
        <Gap />
        <section className="page-break">
          <Background
            sourceNames={sourceNames}
            periodStart={periodStart}
            periodEnd={periodEnd}
          />
        </section>
        <Gap />
        <section>
          <ScopeAndObjective 
            pwsName={pwsName}
            timePeriod={`${periodStart} - ${periodEnd}`}
          />
        </section>
        <Gap />
        <section className="page-break">
          <AuditResults 
            dataQuality={dataQuality}
            pwsName={pwsName}
            timePeriod={`${periodStart} - ${periodEnd}`}
            timePeriodDays={timePeriodDays}
            unit={unit}
            sourceInput={sourceInput}
            totalMeteredUsage={totalMeteredUsage}
            lossVolume={lossVolume}
            UARL={UARL}
            CARL={realWaterLoss}
            realWaterLoss={realWaterLoss}
            apparentWaterLoss={apparentWaterLoss}
            nonRevenueWater={nonRevenueWater}
            realWaterLossCost={realWaterLossCost}
            apparentWaterLossCost={apparentWaterLossCost}
            ILI={ILI}
            realLossPerService={realLossPerService}
            percentLoss={percentLoss}
            unauthorizedConsumption={unauthorizedConsumption}
            customerMeteringInaccuracies={customerMeteringInaccuracies}
            sysDataHandlingErrors={sysDataHandlingErrors}
            aveRetailUnitCost={aveRetailUnitCost}
            waterSupplied={waterSupplied}
            waterMainLen={waterMainLen}
            serviceConnections={serviceConnections}
            avePipeLength={avePipeLength}
            aveSysPressure={aveSysPressure}
            chartData={chartData}
            history={history}
            classes={classes}
          />
        </section>
        <Gap />
        <section className="page-break">
          <Recommendations 
            isMetered={isMetered}
            isFullyMetered={isFullyMetered}
            hasTestingAndReplacementProgram={hasTestingAndReplacementProgram}
            aveMeterAge={aveMeterAge}
            connectionsValidity={connectionsValidity}
            waterMainLenValidity={waterMainLenValidity}
            aveSysPressureValidity={aveSysPressureValidity}
            classes={classes}
          />
        </section >
        <Gap />
        <section className="page-break">
          <Methodology />
        </section>
        <Gap />
        <section className="page-break">
          <Definitions />
        </section>
        <Gap />
        <section className="page-break">
          <References />
        </section>
      </Grid>
    </>
  )
}

export default ReportContents