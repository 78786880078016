import React, { useEffect, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Skeleton from 'react-loading-skeleton'
import { formatNumber } from 'accounting'
import { withStyles } from '@material-ui/core/styles'
import { API, graphqlOperation } from 'aws-amplify'

import * as queries from 'graphql/queries'
import * as mutations from 'graphql/mutations'
import ToolbarContent from 'components/ToolbarContent'
import ContentHeader from 'components/ContentHeader'
import { prettifyTimestamp, prettifyDateRange } from 'services/utilityFunctions'
import LabeledText from 'components/LabeledText'
import ConfirmDeleteDialog from 'components/ConfirmDeleteDialog'
import Img from 'components/Img'

const styles = theme => ({
  image: {
    width: 250,
    margin: 'auto',
    display: 'block',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
  },
  buttonDelete: {
    color: theme.palette.error.main 
  }
})

const handleDelete = async sourceProductionId => {
  try {
    await API.graphql(graphqlOperation(mutations.deleteSourceProduction, { input: { id: sourceProductionId } }))
  } catch (error) {
    console.log(error)
  }
}

function ProductionDataView(props) {
  const {
    location,
    history,
    classes,
  } = props

  const { 
    pwsName,
    source,
    sourceProduction: stateSourceProd,
  } = location.state
  
  const [sourceProduction, setSourceProduction] = useState({})
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [deleteInProgress, setDeleteInProgress] = useState(false)
  
  const [confirmDelete, setConfirmDelete] = useState(false)
  
  const currentSourceProd = sourceProduction || stateSourceProd
  const sourceProdId = stateSourceProd.id
  
  currentSourceProd.timestamp = currentSourceProd.type === "meter" ? currentSourceProd.timestamp : null
  const prettyTimeStamp = currentSourceProd.timestamp ? prettifyTimestamp(currentSourceProd.timestamp) : <Skeleton /> 
  const prettyDateRange = currentSourceProd.startDate ? prettifyDateRange(currentSourceProd.startDate, currentSourceProd.endDate) : <Skeleton />

  let isMounted
  
  useEffect(() => {
    if (confirmDelete) {
      (async () => {
        setDeleteInProgress(true)
        try {
          await handleDelete(sourceProdId)
          history.goBack()
        } catch (error) {
          console.log(error)
        }
      })()
    }
  }, [confirmDelete])
  
  useEffect(() => {
    isMounted = true
    
    ;(async () => {
      try {
        const result = await API.graphql(graphqlOperation(queries.getSourceProduction, { id: sourceProdId }))
        const sourceProduction = result.data.getSourceProduction
        
        if (!isMounted) return
        
        setSourceProduction(sourceProduction)
  
      } catch (error) {
        console.log(error)
      }
    })()

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <>
      <ToolbarContent
        screenTitle = {`Production Data View`}
        isMainScreen = {false}
      />
      <ContentHeader
        breadcrumbs={[
          { name: 'Production Data', goBack: true, step: 3 },
          { name: pwsName, goBack: true, step: 2 }, 
          { name: source.name, goBack: true, step: 1 }, 
          { name: currentSourceProd.type === 'meter' ? prettyTimeStamp : prettyDateRange },
        ]}
        history={history}
      />
      <Img
        className={classes.image}
        src={source.installImage}
        width={250}
        loader={
          <CircularProgress className={classes.image} />
        }
        unloader={
          <img
            id="install"
            alt="install"
            className={classes.image}
            src={require('images/default-image.png')}
          />
        }
      />
      {currentSourceProd.type === 'meter' ? (
        <>
          <LabeledText 
            label="Meter Reading" 
            value={sourceProduction.id ? formatNumber(sourceProduction.reading, 2) + ' ' + source.units : <Skeleton />}
          />
          <LabeledText 
            label="Pump Run Time" 
            value={sourceProduction.pumpRunTime || <Skeleton />}
          />
        </>
      ) : (
        <>
          <LabeledText 
            label="Volume" 
            value={sourceProduction.id ? formatNumber(sourceProduction.volume, 2) + ' ' + source.units : <Skeleton />}
          />
        </>
      )}
      <Button
        className={classes.button}
        color="default"
        variant="contained"
        fullWidth
        disabled={sourceProduction.id ? false : true || deleteInProgress}
        onClick={() => {
          history.push({
            pathname: location.pathname.replace('view', 'edit'),
            state: { pwsName, source, sourceProduction }
          })
        }}
      >
        EDIT
      </Button>
      <Button
        className={`${classes.button} ${classes.buttonDelete}`}
        fullWidth
        disabled={sourceProduction.id ? false : true || deleteInProgress}
        onClick={() => {
          setOpenDeleteDialog(true)
        }}
      >
        DELETE
      </Button>
      <ConfirmDeleteDialog 
        open={openDeleteDialog} 
        setOpenDialog={setOpenDeleteDialog} 
        setConfirm={setConfirmDelete} 
        objectName="Source Production"
        fieldDescription="word" 
        actionText="Delete"
        confirmText="CONFIRM"
        cancelText="BACK"
        defaultValue="DELETE"
      />
    </>
  )
}

export default withStyles(styles)(ProductionDataView)