import React, { useEffect, } from 'react'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'

import ToolbarContent from 'components/ToolbarContent'
import withUserContext from 'components/withUserData'
import PublicWaterSystems from './components/PublicWaterSystems'
import AddFabButton from 'components/AddFabButton'

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
})

function WaterSystems(props) {
  const { 
    history, 
    location,
  } = props

  useEffect(() => {

  })

  return (
    <>
      <ToolbarContent
        screenTitle = "Water Systems"
        isMainScreen = {true}
      />
      <PublicWaterSystems 
        onItemClick={item => history.push({
          pathname: `water-systems/actions/${item.id}`,
          state: { pws: item },
        })}
        notFoundMessage="Tap the plus button to create your first Public Water System configuration."
      />
      <div style={{ marginBottom: 50 }} />
      <AddFabButton 
        onClick={() => history.push(`${location.pathname}/new`)}
      />
    </>
  )
}

export default compose(
  withUserContext,
  withStyles(styles)
)(WaterSystems)