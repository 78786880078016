import React, { useState } from 'react'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from '@material-ui/styles'

import ToolbarContent from 'components/ToolbarContent'
import ContentHeader from 'components/ContentHeader'
import { volumeUnitsList, volumeUnitShort } from 'services/constantVariables/units'
import NumberField from 'components/NumberField'

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: 50,
  },
  sliderWrapper: {
    height: 200,
    float: 'left'
  },
  menu: {
    textAlign: 'right',
    maxHeight: 300,
  },
  switchLabel: {
  	width: '100%',
  	marginLeft: 0,
  	marginTop: 16,
  	justifyContent: 'space-between',
  },
  customLabel: {
    fontSize: '.85rem',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'block',
  },
  value: {
    fontSize: '0.875rem',
    textAlign: 'right'
  }
}))

const cleanNumber = numberString => {
  numberString = String(numberString)
  return Number(numberString.replace(/,/g,''))
}

function ReportOptions(props) {
  const classes = useStyles()
  const {
    location,
    history,
    match,
  } = props

  const { pws, dateRange } = location.state
  const [showStorageDiff, setShowStorageDiff] = useState(false)
  const [unit, setUnit] = useState('Kgal')
  const [startVolume, setStartVolume] = useState('0')
  const [endVolume, setEndVolume] = useState('0')

  return (
    <>
      <ToolbarContent
        screenTitle = 'Report Options'
        isMainScreen = {false}
      />
      <ContentHeader
        breadcrumbs={[
          { name: 'Reports', goBack: true, step: 2 }, 
          { name: pws.name + ' Date Range Select', goBack: true, step: 1 },
          { name: 'Additional Options' },
        ]}
        history={history}
      />
      <TextField 
        id="unit" 
        label="Unit of volume for this report" 
        value={unit} 
        onChange={(e) => setUnit(e.target.value)} 
        margin="normal" 
        fullWidth
        select
        SelectProps={{
          native: true,
          MenuProps: {
            className: classes.menu,
          },
        }} 
      >
        {volumeUnitsList.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </TextField>
      <FormControlLabel
        className={classes.switchLabel}
        control={
          <Switch
            checked={showStorageDiff}
            onChange={(e) => setShowStorageDiff(e.target.checked)}
            color="secondary"
          />
        }
        label="Include storage volume difference?"
        labelPlacement="start"
      />
      <Grid 
        style={{ display: showStorageDiff ? 'flex' : 'none' }}
        container
        direction="column"
      >
        <Grid 
          container
          spacing={16}
        >
          <Grid item xs={7}>
            <Typography
              variant="caption"
              component={() => (
                <span className={classes.customLabel}>
                  Start Volume
                </span>
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <NumberField 
              fullWidth
              value={startVolume}
              onChange={v => setStartVolume(v)}
              InputProps={{
                endAdornment: <InputAdornment style={{ whiteSpace: 'nowrap' }} position="end">{volumeUnitShort[unit]}</InputAdornment>,
                inputProps: {
                  className: classes.value
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid 
          container
          spacing={16}
        >
          <Grid item xs={7}>
            <Typography
              variant="caption"
              component={() => (
                <span className={classes.customLabel}>
                  End Volume
                </span>
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <NumberField 
              fullWidth
              value={endVolume}
              onChange={v => setEndVolume(v)}
              InputProps={{
                endAdornment: <InputAdornment style={{ whiteSpace: 'nowrap' }} position="end">{volumeUnitShort[unit]}</InputAdornment>,
                inputProps: {
                  className: classes.value
                }
              }}
            />
          </Grid>
        </Grid>
        <Divider style={{ height: 10, background: 'transparent' }} />
        <Grid container spacing={16}>
          <Grid item xs={6}>
            <Typography
              variant="subtitle2"
            >
              Difference
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography  
              align="right"
              variant="subtitle2"
            >
              {cleanNumber(startVolume) - cleanNumber(endVolume)} {volumeUnitShort[unit]}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider style={{ background: 'transparent' }} />
      <Button
        className={classes.button}
        variant="contained"
        fullWidth
        color="primary"
        onClick={() => {
          history.push({
            pathname: match.url.replace('options', 'run'),
            state: { 
              pws,
              dateRange,
              unit,
              volumeDifference: cleanNumber(startVolume) - cleanNumber(endVolume),
           }
          })
        }}
      >
        RUN REPORT
      </Button>
    </>
  )
}

export default ReportOptions