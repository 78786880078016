import React, { useState, useEffect, } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import Close from '@material-ui/icons/Close'
import { Storage } from 'aws-amplify'

import Img from 'components/Img'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  centerWrapper: {
    textAlign: 'center',
  },
  imageGroup: {
    display: 'inline-block',
    position: 'relative',
    minWidth: 100,
    minHeight: 100,
  },
  buttonResetUpload: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: 5,
  },
  buttonUpload: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    margin: 5,
  },
  image: {
    width: 250,
    overflow: 'hidden',
    margin: 'auto',
    display: 'block',
  },
  input: {
    display: 'none',
  },
})

const handleImageUpload = async (event, setImagePreview, setImageFileData, setIsImageChanged) => {
  event.persist()
  
  const imageFileData = event.target.files[0]
  event.target.value = ''

  if(!imageFileData) {
    console.log('Warning! No image file detected.')
    return
  }

  let reader = new FileReader()
  reader.onload = e => {
  	setImagePreview(e.target.result)
  }

  reader.readAsDataURL(imageFileData)

  setImageFileData(imageFileData)
  setIsImageChanged(true)
}

const resetUpload = (initial, setImagePreview, setImageFileData, setIsImageChanged) => {
  //reset
  setImagePreview(initial)
  setImageFileData(null)
  setIsImageChanged(false)
}

const storageUpload = async (imageFileData, filename, setImage) => {
	//begin upload image to S3
  if(imageFileData) {
    const result = await Storage.put(`${filename}.jpg`, imageFileData, {
      contentType: 'image/jpeg'
    })

    const imageUrl = await Storage.get(result.key)
    
    if (imageUrl) return imageUrl.split('?')[0]
  }
}

function ImagePicker(props) {
	const {
		classes,
    id,
    width,
    camera,
		initialImageSrc,
		setStorageUploadFunc,
	} = props

  const src = initialImageSrc || require('images/default-image.png')

  const filename = id

	const [isImageChanged, setIsImageChanged] = useState(false)
	const [imagePreview, setImagePreview] = useState(src)
	const [imageFileData, setImageFileData] = useState()
  const [image, setImage] = useState()

	useEffect(() => {
		setStorageUploadFunc({
			imageFileData: imageFileData, 
      filename: filename,
      url: image,
			async upload() {
				return await storageUpload(this.imageFileData, this.filename, setImage)
			},
		})
  }, [imageFileData, image])
  
	return (
		<>
      <div className={classes.imageGroup}>
        <Img
          src={imagePreview} 
          width={width}
          imgProps={{
            id: id,
            alt: "Water Source Site Image"
          }}
        />
        <input
          accept="image/*"
          capture={camera ? 'camera' : null}
          className={classes.input}
          id={`${id}-upload`}
          type="file"
          onChange={(e) => 
          	handleImageUpload(
          		e, 
          		setImagePreview, 
          		setImageFileData,
          		setIsImageChanged,
        		)
          }
        />
        <label htmlFor={`${id}-upload`}>
          <Button 
            variant="contained"
            component="span" 
            className={classes.buttonUpload}
          >
            EDIT
          </Button>
        </label>
        <Fab 
          className={classes.buttonResetUpload}
          style={isImageChanged ? null : { display: 'none' }}
          component="span" 
          size="small"
          onClick={() => 
          	resetUpload(
          		src, 
          		setImagePreview, 
          		setImageFileData, 
          		setIsImageChanged
      			)
          }
        >
          <Close />
        </Fab>
      </div>
		</>
	)
}

ImagePicker.defaultProps = {
	setStorageUploadFunc: () => {},
}

ImagePicker.propTypes = {
	classes: PropTypes.object,
  id: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  camera: PropTypes.bool,
	initialImageSrc: PropTypes.string,
	setStorageUploadFunc: PropTypes.func,
}

export default withStyles(styles)(ImagePicker)