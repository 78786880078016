import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import InputAdornment from '@material-ui/core/InputAdornment'
import isEqual from 'lodash/isEqual'
import { withStyles } from '@material-ui/core/styles'

import ValidityButton from 'components/ValidityButton'
import NumberField from 'components/NumberField'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%',
  },
  menu: {
    width: 50,
  },
})

const answers = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  }
]

const NumberFieldWithValidityButton = props => {
  const {
    onValidityChange,
    validity,
    ...fieldProps
  } = props

  return (
    <Grid 
      container 
      justify="flex-end"
      style={{ flexWrap: 'nowrap' }}
    >
      <Grid 
        item 
        style={{ width: 'calc(100% - 40px)' }}
      >
        <NumberField {...fieldProps} />
      </Grid>
      <Grid 
        item 
        container 
        direction="column" 
        justify="flex-end"
        style={{ width: 'auto' }} 
      >
        <Grid 
          item 
          style={{ 
            marginBottom: 8, 
            border: "1px solid #cacaca",
            borderRadius: 3
          }}
        >
          <ValidityButton validity={validity} onChange={v => onValidityChange(v)} />
        </Grid>
      </Grid>
    </Grid>
  )
}

NumberFieldWithValidityButton.defaultProps = {
  onValidityChange: () => {},
  validity: 3,
}

NumberFieldWithValidityButton.propTypes = {
  onValidityChange: PropTypes.func,
  validity: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
} 

function WaterSystemForm(props) {
  const { pws, classes } = props

  const [name, setName] = useState(pws.name)
  const [activeConnections, setActiveConnections] = useState(pws.activeConnections)
  const [activeConnectionsValidity, setActiveConnectionsValidity] = useState(pws.activeConnectionsValidity || '3')
  const [inactiveConnections, setInactiveConnections] = useState(pws.inactiveConnections)
  const [inactiveConnectionsValidity, setInactiveConnectionsValidity] = useState(pws.inactiveConnectionsValidity || '3')
  const [waterMainLen, setWaterMainLen] = useState(pws.waterMainLen)
  const [waterMainLenValidity, setWaterMainLenValidity] = useState(pws.waterMainLenValidity || '3')
  const [percentFlexiblePipe, setPercentFlexiblePipe] = useState(pws.percentFlexiblePipe)
  const [aveSysPressure, setAveSysPressure] = useState(pws.aveSysPressure)
  const [aveSysPressureValidity, setAveSysPressureValidity] = useState(pws.aveSysPressureValidity || '3')
  const [isFullyMetered, setIsFullyMetered] = useState(pws.isFullyMetered === null ? false : pws.isFullyMetered)
  const [hasProgram, setHasProgram] = useState(pws.hasProgram === null ? false : pws.hasProgram)
  const [aveMeterAge, setAveMeterAge] = useState(pws.aveMeterAge)
  const [avePipeLength, setAvePipeLength] = useState(pws.avePipeLength)

  let isMounted

  useEffect(() => {
    isMounted = true
    
    if(isMounted) {
      let currentPws = JSON.parse(JSON.stringify(pws))   
      currentPws.name = name
      currentPws.activeConnections = activeConnections || '0'
      currentPws.activeConnectionsValidity = activeConnectionsValidity || '3'
      currentPws.inactiveConnections = inactiveConnections || '0'
      currentPws.inactiveConnectionsValidity = inactiveConnectionsValidity || '3'
      currentPws.waterMainLen = waterMainLen || '0'
      currentPws.waterMainLenValidity = waterMainLenValidity || '3'
      currentPws.percentFlexiblePipe = percentFlexiblePipe || '0'
      currentPws.aveSysPressure = aveSysPressure || '0'
      currentPws.aveSysPressureValidity = aveSysPressureValidity || '3'
      currentPws.isFullyMetered = isFullyMetered
      currentPws.hasProgram = hasProgram
      currentPws.aveMeterAge = aveMeterAge || '0'
      currentPws.avePipeLength = avePipeLength || '0'
  
      if(isEqual(currentPws, pws)) {
        props.setIsUpdated(false)
        props.setPws(pws)
      } else {
        props.setIsUpdated(true)
        props.setPws(currentPws)
      }
    }

    return () => {
      isMounted = false
    }
  }, [
    name,
    activeConnections,
    activeConnectionsValidity,
    inactiveConnections,
    inactiveConnectionsValidity,
    waterMainLen,
    waterMainLenValidity,
    percentFlexiblePipe,
    aveSysPressure,
    aveSysPressureValidity,
    avePipeLength,
    isFullyMetered,
    hasProgram,
    aveMeterAge,
  ])

  return (
    <>
      <form className={classes.container} noValidate autoComplete="off">
        <TextField 
          id="name" 
          label="Name" 
          className={classes.textField} 
          value={name} 
          onChange={(e) => { setName(e.target.value) }} 
          margin="normal" 
          autoFocus
        />
        <NumberFieldWithValidityButton 
          id="active-connections" 
          label="Active Connections" 
          className={classes.textField}
          margin="normal"
          value={activeConnections}
          precision={0}
          onChange={v => setActiveConnections(v)}
          validity={activeConnectionsValidity}
          onValidityChange={v => setActiveConnectionsValidity(v)}
        />
        <NumberFieldWithValidityButton 
          id="inactive-connections" 
          label="Inactive Connections" 
          className={classes.textField}
          margin="normal"
          value={inactiveConnections}
          precision={0}
          onChange={v => setInactiveConnections(v)}
          validity={inactiveConnectionsValidity}
          onValidityChange={v => setInactiveConnectionsValidity(v)}
        />
        <NumberFieldWithValidityButton
          id="water-main-length" 
          label="Miles of Water Main"
          className={classes.textField} 
          margin="normal"
          value={waterMainLen}
          onChange={v => setWaterMainLen(v)} 
          validity={waterMainLenValidity}
          onValidityChange={v => setWaterMainLenValidity(v)}  
        />
        <TextField 
          id="ave-pipe-length" 
          label="What percentage of water main is flexible pipe?" 
          type="number"
          className={classes.textField} 
          value={percentFlexiblePipe} 
          onChange={(e) => { 
            if (e.target.value >= 0 && e.target.value <= 100) {
              setPercentFlexiblePipe(Number(e.target.value).toString()) 
            }
          }} 
          onFocus={e => {
            if (e.target.value === "0") {
              setPercentFlexiblePipe('')
            }
          }}
          onBlur={e => {
            if (e.target.value === '') {
              setPercentFlexiblePipe('0')
            }
          }}
          margin="normal"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
        />
        <NumberFieldWithValidityButton
          id="ave-sys-pressure" 
          label="Average System Pressure" 
          className={classes.textField}
          margin="normal"
          value={aveSysPressure}
          onChange={v => setAveSysPressure(v)}
          validity={aveSysPressureValidity}
          onValidityChange={v => setAveSysPressureValidity(v)}
        />
        <TextField 
          id="is-fully-metered" 
          label="System fully metered" 
          className={classes.textField} 
          value={isFullyMetered} 
          onChange={(e) => { setIsFullyMetered(e.target.value) }} 
          margin="normal"
          select
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }} 
        >
          {answers.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField 
          id="has-program" 
          label="Meter testing and replacement program" 
          className={classes.textField} 
          value={hasProgram} 
          onChange={(e) => { setHasProgram(e.target.value) }} 
          margin="normal"
          select
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }} 
        >
          {answers.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))} 
        </TextField>
        <NumberField 
          id="ave-meters" 
          label="Average age of customer meters" 
          className={classes.textField} 
          margin="normal"
          value={aveMeterAge} 
          onChange={v => setAveMeterAge(v)}
        />
        <NumberField 
          id="ave-pipe-length" 
          label="Avg Length of Customer Pipe (Lp) (in feet)" 
          className={classes.textField} 
          margin="normal"
          value={avePipeLength} 
          onChange={v => setAvePipeLength(v)}
          helperText={avePipeLength ? null : 'This is usually zero'}
        />
      </form>
    </>
  )
}

export default withStyles(styles)(WaterSystemForm)