import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Skeleton from 'react-loading-skeleton'
import { formatNumber } from 'accounting'
import { withStyles } from '@material-ui/core/styles'
import { API, graphqlOperation } from 'aws-amplify'

import * as queries from 'graphql/queries'
import * as mutations from 'graphql/mutations'
import ToolbarContent from 'components/ToolbarContent'
import LabeledNumberField from 'components/LabeledNumberField'
import LabeledNumber from 'components/LabeledNumber'
import LabeledNumberUnitField from 'components/LabeledNumberUnitField'
import { volumeUnitShort } from 'services/constantVariables/units'

const styles = theme => ({
  verticalSpace: {
    marginBottom: theme.spacing.unit * 2,
    backgroundColor: 'transparent',
  },
  button: {
    marginBottom: 16,
  },
  buttonContainer: {
    position: 'sticky',
    bottom: 0,
    paddingTop: 16,
    background: '#fff'
  }
})

const handleSave = async args => {
  const {
    waterSystemId,
    aveRetailUnitCost,
    unit,
    annualElectricBill,
    annualChemicalBill,
    totalAnnualCost,
    productionOverride,
    annualProductionVolume,
    productionCost,
    productionCostUnit,
    costInputsId
  } = args

  // no cost inputs record found, create a new one
  if (!costInputsId) {
    const inputCreateCostInputs = {
      waterSystemId,
      costInputsWaterSystemId: waterSystemId,
      aveRetailUnitCost,
      unit,
      annualElectricBill,
      annualChemicalBill,
      productionOverride,
      productionCost,
      productionCostUnit,
      totalAnnualCost,
      annualProductionVolume,
    }

    try {
      const result = await API.graphql(graphqlOperation(mutations.createCostInputs, { input: inputCreateCostInputs }))
      const costInputs = result.data.createCostInputs
  
      // update water system data
      if (costInputs) {
        const inputUpdateWaterSystem = {
          id: waterSystemId,
          waterSystemCostInputsId: costInputs.id,
        }
        await API.graphql(graphqlOperation(mutations.updateWaterSystem, { input: inputUpdateWaterSystem }))
      }
  
      return costInputs.id
    } catch (error) {
      return console.log(error)  
    }
  }

  // cost inputs record found, begin update
  const inputUpdateCostInputs = {
    id: costInputsId,
    aveRetailUnitCost,
    unit,
    annualElectricBill,
    annualChemicalBill,
    productionOverride,
    productionCost,
    productionCostUnit,
    totalAnnualCost,
    annualProductionVolume,
  }

  try {
    await API.graphql(graphqlOperation(mutations.updateCostInputs, { input: inputUpdateCostInputs }))
 
  } catch (error) {
    return console.log(error)  
  }
}

const getCostInputs = async waterSystemId => {
  try {
    const result = await API.graphql(graphqlOperation(queries.getWaterSystem, { id: waterSystemId }))
    const pws = result.data.getWaterSystem
    
    return pws.costInputs

  } catch (error) {
    console.log(error)  
    return null 
  }
}

const cleanNumber = numberString => {
  numberString = String(numberString)
  return Number(numberString.replace(/,/g,''))
}


const calculateTotalVariableProdCost = (costs, volume) => {
  if (!costs 
    || !volume
    || cleanNumber(costs) === 0
    || cleanNumber(volume) === 0) return '0'

  const rate = cleanNumber(costs) / cleanNumber(volume)
  return formatNumber(rate, 2)
}

function CostInputs(props) {
  const {
    classes,
    location,
  } = props

  let waterSystemId 
  try {
    waterSystemId = location.state.pws.id
  } catch (error) {
    console.log(error)
  }
  
  const [viewOnly, setViewOnly] = useState(true)
  const [costInputsId, setCostInputsId] = useState()

  const [annualProductionVolume, setAnnualProductionVolume] = useState(0)
  const [combinedAnnualVariableCosts, setCombinedAnnualVariableCosts] = useState(0)
  const [productionOverride, setProductionOverride] = useState(false)

  // recalculation of production cost
  useEffect(() => {
    setProductionCost(calculateTotalVariableProdCost(combinedAnnualVariableCosts, annualProductionVolume))
  }, [combinedAnnualVariableCosts, annualProductionVolume, productionOverride])

  const [annualElectricBill, setAnnualElectricBill] = useState(0)
  const [annualChemicalBill, setAnnualChemicalBill] = useState(0)
  useEffect(() => {
    const combinedAnnualVariableCosts = cleanNumber(annualElectricBill) + cleanNumber(annualChemicalBill)
    setCombinedAnnualVariableCosts(formatNumber(combinedAnnualVariableCosts, 2))   
  }, [annualElectricBill, annualChemicalBill])
  
  const [aveRetailUnitCost, setAveRetailUnitCost] = useState(0)
  const [unit, setUnit] = useState('Kgal')
  const [productionCost, setProductionCost] = useState(0)
  const [productionCostUnit, setProductionCostUnit] = useState('Kgal')
  const [totalAnnualCost, setTotalAnnualCost] = useState(0)
  const [loading, setLoading] = useState(false)
  const [saveInProgress, setSaveInProgress] = useState(false)
  
  let isMounted
  
  useEffect(() => {
    isMounted = true
    if (!waterSystemId) return

    (async () => {
      setLoading(true)

      const costInputs = await getCostInputs(waterSystemId)
      
      if (costInputs) {
        let {
          id,
          aveRetailUnitCost,
          unit,
          annualElectricBill,
          annualChemicalBill,
          annualProductionVolume,
          productionOverride,
          productionCost,
          productionCostUnit,
          totalAnnualCost,
        } = costInputs
        
        if (!isMounted) return

        setCostInputsId(id)
        setAveRetailUnitCost(aveRetailUnitCost)
        setUnit(unit)
        setAnnualElectricBill(annualElectricBill)
        setAnnualChemicalBill(annualChemicalBill)
        setTotalAnnualCost(totalAnnualCost)
        setProductionOverride(productionOverride)
        setAnnualProductionVolume(annualProductionVolume)

        // set last to supercede recalculation on the effect above
        setProductionCost(productionCost)
        setProductionCostUnit(productionCostUnit)

        setLoading(false)

        return
      }

      if (!isMounted) return
      
      setAnnualProductionVolume(0)
      setAveRetailUnitCost(0)
      setUnit('Kgal')
      setAnnualElectricBill(0)
      setAnnualChemicalBill(0)
      setProductionOverride(false)
      setProductionCost(0)
      setProductionCostUnit('Kgal')
      setTotalAnnualCost(0)

      setLoading(false)
    })()

    return () => {
      isMounted = false
    } 
  }, [viewOnly])

  return (
    <>
      <ToolbarContent
        screenTitle={`Cost Inputs`}
        isMainScreen={false}
      />
      {viewOnly ? (
        <LabeledNumber
          label="Average retail unit cost"
          value={loading ? <Skeleton /> : `$${formatNumber(aveRetailUnitCost, 2)} / ${volumeUnitShort[unit]}`}
        />
      ) : (
        <LabeledNumberUnitField 
          label="Average retail unit cost"
          startAdornment="$"  
          value={aveRetailUnitCost}
          onValueChange={v => setAveRetailUnitCost(v)}
          disabled={loading}
          per
          unit={unit}
          onUnitChange={u => setUnit(u)}
        />
      )}
      <Divider className={classes.verticalSpace} />
      <Typography variant="subtitle1">
        Calculated variable production costs
      </Typography>
      {viewOnly ? (
        <LabeledNumber
          label="Annual Electric Bill"
          value={loading ? <Skeleton /> : `$${formatNumber(annualElectricBill, 2)}`}
        />
      ) : (
        <LabeledNumberField 
          label="Annual Electric Bill"
          startAdornment="$"
          value={annualElectricBill}
          precision={2}
          onChange={v => setAnnualElectricBill(v)}
          disabled={loading || productionOverride}
        />
      )}
      {viewOnly ? (
        <LabeledNumber
          label="Annual Chemical Bill"
          value={loading ? <Skeleton /> : `$${formatNumber(annualChemicalBill, 2)}`}
        />
      ) : (
        <LabeledNumberField 
          label="Annual Chemical Bill"
          startAdornment="$"
          value={annualChemicalBill}
          precision={2}
          onChange={v => setAnnualChemicalBill(v)}
          disabled={loading || productionOverride}
        />
      )}
      <Divider className={classes.verticalSpace} />
      <Typography variant="subtitle1">
        Variable production costs
      </Typography>
      {productionOverride ? (
        <>
          {viewOnly ? (
            <>
              <LabeledNumber
                label="Variable Production Cost"
                value={loading ? <Skeleton /> : `$${formatNumber(productionCost, 2)} / ${volumeUnitShort[productionCostUnit]}`}
                variant="bold"
              />
            </>
          ) : (
            <>
              <LabeledNumberUnitField 
                label="Production Cost"
                startAdornment="$"
                value={productionCost}
                onValueChange={v => setProductionCost(v)}
                disabled={loading}
                unit={productionCostUnit}
                onUnitChange={u => setProductionCostUnit(u)}
                per
              />
              <Link 
                color="secondary"
                onClick={() => setProductionOverride(false)}
              >
                Tap to cancel production cost override
              </Link>
            </>
          )}
        </>
      ) : (
        <>
          {viewOnly ? (
            <LabeledNumber
              label="Annual Production Volume"
              value={loading ? <Skeleton /> : `${formatNumber(annualProductionVolume, 2)} ${volumeUnitShort[productionCostUnit]}`}
            />
          ) : (
            <LabeledNumberUnitField 
              label="Annual Production Volume"
              value={annualProductionVolume}
              onValueChange={v => setAnnualProductionVolume(v)}
              disabled={loading}
              unit={productionCostUnit}
              onUnitChange={u => setProductionCostUnit(u)}
            />
          )}
          <LabeledNumber
            label="Combined Annual Variable Costs"
            value={loading ? <Skeleton /> : `$${combinedAnnualVariableCosts}`}
          />
          <LabeledNumber
            label="Variable Production Cost"
            value={loading ? <Skeleton /> : `$${formatNumber(productionCost, 2)} / ${volumeUnitShort[productionCostUnit]}`}
            variant="bold"
          />
          {!viewOnly ? (
            <Link 
              color="secondary"
              onClick={() => setProductionOverride(true)}
            >
              Tap to override production cost
            </Link>
          ) : null}
        </>
      )}
      <Divider className={classes.verticalSpace} />
      {viewOnly ? (
        <>
          <LabeledNumber
            label="Total annual cost to operate system"
            value={loading ? <Skeleton /> : `$${formatNumber(totalAnnualCost, 2)}`}
          />
          <Divider className={classes.verticalSpace} />
          <Button
            className={classes.button} 
            variant="contained" 
            color="default"
            fullWidth
            disabled={loading}
            onClick={() => {
              setViewOnly(false)
            }}
          >
            EDIT
          </Button>
        </>
      ) : (
        <>
          <LabeledNumberField 
            label="Total annual cost to operate system"
            startAdornment="$"
            value={totalAnnualCost}
            precision={2}
            onChange={v => setTotalAnnualCost(v)}
            disabled={loading}
          />
          <Divider className={classes.verticalSpace} />
          <Grid className={classes.buttonContainer}>
            <Button
              className={classes.button} 
              variant="contained" 
              color="primary"
              fullWidth
              disabled={loading || saveInProgress}
              onClick={async () => {
                setLoading(true)
                setSaveInProgress(true)

                const newId = await handleSave({
                  waterSystemId,
                  aveRetailUnitCost: cleanNumber(aveRetailUnitCost),
                  unit,
                  annualElectricBill: cleanNumber(annualElectricBill),
                  annualChemicalBill: cleanNumber(annualChemicalBill),
                  totalAnnualCost: cleanNumber(totalAnnualCost),
                  annualProductionVolume: cleanNumber(annualProductionVolume),
                  productionOverride,
                  productionCost: cleanNumber(productionCost),
                  productionCostUnit: productionCostUnit,
                  costInputsId
                })

                if (newId) {
                  setCostInputsId()
                }
                
                setLoading(false)
                setSaveInProgress(false)
                setViewOnly(true)
              }}
            >
              {saveInProgress ? <CircularProgress color="inherit" size={16} style={{ marginRight: 5 }} /> : null}
              SAVE
            </Button>
          </Grid>
          <Button
            className={classes.button} 
            variant="contained" 
            color="default"
            fullWidth
            onClick={() => {
              setViewOnly(true)
            }}
          >
            CANCEL
          </Button>
        </>
      )}
    </>
  )
}

export default withStyles(styles)(CostInputs)