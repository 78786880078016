import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { toFixed as acToFixed } from 'accounting' 

function NumberField(props) {
  const {
    value: initialValue,
    onChange,
    precision,
    ...otherProps
  } = props

  const [value, setValue] = useState(initialValue || '')

  useEffect(() => {
    setValue(addThousandsSep(value))
  }, [])

  useEffect(() => {
    onChange(value.replace(/,/g,''))
  }, [value, onChange])

  const addThousandsSep = numberString => {
    numberString = numberString.replace(/,/g,'')

    if (precision) {
      numberString = acToFixed(parseFloat(numberString), precision)
    }

    const int = Math.floor(numberString)

    if (!(numberString.indexOf('.') !== -1)) {
      return int.toLocaleString()
    }

    if (numberString.slice(-1) === '.') {
      return int.toLocaleString() + '.'
    }
    
    const decimal = '.' + numberString.split('.')[1]

    return int.toLocaleString() + decimal
  }

  return (
    <TextField 
      value={value}
      onChange={e => {
        let currentValue = e.target.value
        const cleanValue = currentValue.replace(/,/g,'')
        const formattedValue = cleanValue.replace(/^0+/, '')

        if (Number(formattedValue) < 0 || isNaN(Number(formattedValue))) return

        if (Number(formattedValue) === 0) {
          setValue('')
          return
        }
        
        setValue(formattedValue)
      }}
      onFocus={e => {
        if (e.target.value === '0') {
          setValue('')
          return
        }
        setValue(e.target.value.replace(/,/g,''))
      }}
      onBlur={e => {
        if (e.target.value === '') {
          setValue(addThousandsSep('0'))
          return
        }
        setValue(addThousandsSep(e.target.value))
      }}
      {...otherProps}
    />  
  )
}

NumberField.defaultProps = {
  value: 0,
  onChange: () => {},
  precision: null,
}

NumberField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  precision: PropTypes.number,
}

export default NumberField