import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

function ScopeAndObjective(props) {
  const {
    pwsName,
    timePeriod,
  } = props

  return (
    <>
      <Typography variant="h6" gutterBottom>
        SCOPE &amp; OBJECTIVE
      </Typography>
      <Typography paragraph>
        This report is generated from WUA software and conforms to the AWWA/IWA water audit
        methodology, with the exception of calculating a UARL and ILI for small systems, see note
        above. The reliability and completeness of this report is reliant upon complete and accurate
        user input data.
      </Typography>
      <Typography paragraph>
        The objectives of the WUA audit is to determine the volume and cost ($) of Water Loss,
        estimate the portion of Real and Apparent Water Loss and help guide the system to improve
        data integrity. The process of performing a water audit provides valuable insight into system
        operations often overlooked.
      </Typography>
      <Typography paragraph>
        The audit scope included analyzing {pwsName} production and consumption data from {timePeriod}.
      </Typography>
    </>
  )
}

ScopeAndObjective.propTypes = {
  pwsName: PropTypes.string.isRequired,
  timePeriod: PropTypes.string.isRequired,
}

export default ScopeAndObjective