import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import WarningIcon from '@material-ui/icons/Warning'
import { formatMoney } from 'accounting'
import { API, graphqlOperation } from 'aws-amplify'

import * as mutations from 'graphql/mutations'
import LabeledNumber from 'components/LabeledNumber'
import { getTier } from 'services/utilityFunctions/subscription'

const handleClose = (setOpenDialog) => {
  setOpenDialog(false)
}

const handleConfirm = async (subscriptionId, quantity, prorationDate) => {
  const inputApiAction = {
    name: 'stripeQuanUpdateSub',
    parameters: [
      subscriptionId,
      quantity,
      prorationDate,
    ]
  }

  // update subscription quantity
  try {
    await API.graphql(graphqlOperation(mutations.createApiAction, { input: inputApiAction }))
  } catch (error) {
    console.log(error)
  }
}

const getProrationData = async (subscriptionId, quantity) => {
  const inputApiAction = {
    name: 'stripeProrationPreview',
    parameters: [
      subscriptionId,
      quantity
    ]
  }

  try {
    const result = await API.graphql(graphqlOperation(mutations.createApiAction, { input: inputApiAction }))
    const prorationJSON = result.data.createAPIAction.stripeProrationPreview
    const proration = JSON.parse(prorationJSON)
    let { 
      customer, 
      current_prorations: currentProrations, 
      invoice,
      cost 
    } = proration

    // get default card
    let cards = customer.sources.data.filter(item => item.object === "card")
    const defaultCard = cards.find(card => card.id === customer.default_source)
    
    // reformat amounts
    cost = (cost < 0 ? '-' : '') + formatMoney(Math.abs(cost) / 100)
    currentProrations.forEach(element => {
      element.amount = (element.amount < 0 ? '-' : '') + formatMoney(Math.abs(element.amount) / 100)
    })

    return {
      prorationDate: invoice.subscription_proration_date,
      defaultCard,
      currentProrations,
      cost
    } 

  } catch (error) {
    console.log(error)
  }
}

function SubscriptionWarning(props) {
  const {
    setOpenDialog,
    subscriptionId,
    prevActiveConnections,
    newActiveConnections,
    onConfirm,
  } = props
  const [open, setOpen] = useState(props.open)
  const [updateInProgress, setUpdateInProgress] = useState(false)
  const [paymentInProgress, setPaymentInProgress] = useState(false)
  const [screen, setScreen] = useState(1)
  const [prorationDate, setProrationDate] = useState()
  const [defaultCard, setDefaultCard] = useState({})
  const [currentProrations, setCurrentProrations] = useState([])
  const [cost, setCost] = useState()
  
  useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  if (!open) return null
  
  const prevTier = getTier(prevActiveConnections)
  const newTier = getTier(newActiveConnections)
  
  const upgrade = prevTier.id < newTier.id
  
  function FirstScreen() {
    return (
      <>
        <DialogContent>
          {upgrade ? (
            <DialogContentText>
              Your change to the number of active connections is going
              to upgrade your annual subscription from Tier {prevTier.id} to Tier {newTier.id}.
              You will be credited with the prorated amount for the unused time
              of your current subscription. 
              Would you like to proceed?
            </DialogContentText>
          ) : (
            <DialogContentText>
              You are making a change to the number of active connections that is going
              to downgrade your annual subscription from 
              Tier {prevTier.id} to {newTier.id === 0 ? newTier.name : <>Tier {newTier.id}</>}.
              Please call our support hotline to confirm the change.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          {upgrade ? (
            <>
              <Button 
                disabled={updateInProgress}
                onClick={() => handleClose(setOpenDialog)}
              >
                CANCEL
              </Button>
              <Button 
                disabled={updateInProgress}
                onClick={async () => {
                  setScreen(2)
                  setUpdateInProgress(true)
                  const proration = await getProrationData(subscriptionId, newActiveConnections)
                  const {
                    prorationDate,
                    defaultCard,
                    currentProrations,
                    cost,
                  } = proration

                  setProrationDate(prorationDate)
                  setDefaultCard(defaultCard)
                  setCurrentProrations(currentProrations)
                  setCost(cost)

                  setUpdateInProgress(false)
                }}
              >
                YES, I WANT TO PROCEED
              </Button>
            </>
          ) : (
            <Button 
              disabled={updateInProgress}
              onClick={() => handleClose(setOpenDialog)}
            >
              OKAY
            </Button>
          )}
        </DialogActions>
      </>
    )
  }

  function SecondScreen() {
    return (
      <>
        <DialogContent style={{ width: 600 }}>
          {updateInProgress ? (
            <CircularProgress color="inherit" /> 
          ) : (
            <>
              <DialogContentText>
                You will be charged immediately on your default credit card
                after confirmation of upgrade. A new billing cycle for this
                upgrade in subscription will start today.
              </DialogContentText>
              <Grid style={{ margin: 16 }}>
                <Typography style={{ marginLeft: -16 }}>
                  Default Card
                </Typography>
                <Typography variant="subtitle1">
                  {defaultCard.brand} •••• {defaultCard.last4}
                </Typography>
                <Typography variant="caption">
                  Expires {defaultCard.exp_month + '/' + defaultCard.exp_year}
                </Typography>
              </Grid>
              <Typography>
                Proration
              </Typography>
              <Grid style={{ marginLeft: 16 }}>
                {currentProrations.map((item, index) => (
                  <div key={index}>
                    <LabeledNumber 
                      label={item.description}
                      value={`${item.amount}`}
                    />
                  </div>
                ))}
                <LabeledNumber 
                  value={<>Total<span style={{ whiteSpace: 'pre' }}>&#9;</span><span>{cost}</span></>}
                  variant="bold"
                />
              </Grid>
            </>
          )} 
        </DialogContent>
        <DialogActions>
          <Button 
            disabled={updateInProgress}
            onClick={() => {
              setCost(null)
              setScreen(1)
            }}
          >
            BACK
          </Button>
          <Button 
            color="primary"
            variant="contained"
            disabled={updateInProgress || paymentInProgress}
            onClick={async () => {
              setPaymentInProgress(true)
              await handleConfirm(subscriptionId, newActiveConnections, prorationDate)
              onConfirm()
              setPaymentInProgress(false)
              setOpen(false)
            }} 
          >
            {paymentInProgress ? <CircularProgress color="inherit" size={16} style={{ marginRight: 5 }} /> : null}
            UPGRADE NOW{cost ? <> &amp; PAY {cost}</> : null}
          </Button>
        </DialogActions>
      </>
    )
  }

  return (
    <>
      <Dialog
        open={open || updateInProgress}
        onClose={() => handleClose(setOpenDialog)}  
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <WarningIcon 
            fontSize="large"
            color="primary"
            style={{
              marginBottom: -5,
              marginRight: 10,
            }}
          />
          Subscription Tier Change
        </DialogTitle>
        {(() => {
          switch (screen) {
            case 1:
              return <FirstScreen />
            case 2:
              return <SecondScreen />
            default:
              return <FirstScreen />
          }
        })()}
      </Dialog>
    </>
  )
}

SubscriptionWarning.propTypes = {
  setOpenDialog: PropTypes.func.isRequired,
  open: PropTypes.bool,
  subscriptionId: PropTypes.string,
  prevActiveConnections: PropTypes.string,
  newActiveConnections: PropTypes.string,
  onConfirm: PropTypes.func,
}

export default SubscriptionWarning