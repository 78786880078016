// eslint-disable
// this is an auto generated file. This will be overwritten

export const createEvent = `mutation CreateEvent($input: CreateEventInput!) {
  createEvent(input: $input) {
    id
    waterSystemId
    name
    type
    estimatedLoss
    estimatedLossUnit
    estimatedLossNormal
    startTimestamp
    endTimestamp
    location
    notes
    unitNormalizer
  }
}
`;
export const updateEvent = `mutation UpdateEvent($input: UpdateEventInput!) {
  updateEvent(input: $input) {
    id
    waterSystemId
    name
    type
    estimatedLoss
    estimatedLossUnit
    estimatedLossNormal
    startTimestamp
    endTimestamp
    location
    notes
    unitNormalizer
  }
}
`;
export const deleteEvent = `mutation DeleteEvent($input: DeleteEventInput!) {
  deleteEvent(input: $input) {
    id
    waterSystemId
    name
    type
    estimatedLoss
    estimatedLossUnit
    estimatedLossNormal
    startTimestamp
    endTimestamp
    location
    notes
    unitNormalizer
  }
}
`;
export const createMaintenanceJournal = `mutation CreateMaintenanceJournal($input: CreateMaintenanceJournalInput!) {
  createMaintenanceJournal(input: $input) {
    id
    waterSystemId
    modifiedBy
    timestamp
    text
  }
}
`;
export const updateMaintenanceJournal = `mutation UpdateMaintenanceJournal($input: UpdateMaintenanceJournalInput!) {
  updateMaintenanceJournal(input: $input) {
    id
    waterSystemId
    modifiedBy
    timestamp
    text
  }
}
`;
export const deleteMaintenanceJournal = `mutation DeleteMaintenanceJournal($input: DeleteMaintenanceJournalInput!) {
  deleteMaintenanceJournal(input: $input) {
    id
    waterSystemId
    modifiedBy
    timestamp
    text
  }
}
`;
export const createOrganization = `mutation CreateOrganization($input: CreateOrganizationInput!) {
  createOrganization(input: $input) {
    id
    name
    description
    logo
    customerId
    billingStatus
    mailingAddress {
      street
      street2
      city
      state
      zip
      country
    }
    users {
      items {
        id
        email
        firstName
        lastName
        company
        title
        phone
        phone2
        userPoolId
        organizationId
        status
      }
      nextToken
    }
    joinRequest {
      id
      userId
      user {
        id
        email
        firstName
        lastName
        company
        title
        phone
        phone2
        userPoolId
        organizationId
        status
      }
      organizationId
      organization {
        id
        name
        description
        logo
        customerId
        billingStatus
      }
      state
      timestamp
    }
  }
}
`;
export const updateOrganization = `mutation UpdateOrganization($input: UpdateOrganizationInput!) {
  updateOrganization(input: $input) {
    id
    name
    description
    logo
    customerId
    billingStatus
    mailingAddress {
      street
      street2
      city
      state
      zip
      country
    }
    users {
      items {
        id
        email
        firstName
        lastName
        company
        title
        phone
        phone2
        userPoolId
        organizationId
        status
      }
      nextToken
    }
    joinRequest {
      id
      userId
      user {
        id
        email
        firstName
        lastName
        company
        title
        phone
        phone2
        userPoolId
        organizationId
        status
      }
      organizationId
      organization {
        id
        name
        description
        logo
        customerId
        billingStatus
      }
      state
      timestamp
    }
  }
}
`;
export const deleteOrganization = `mutation DeleteOrganization($input: DeleteOrganizationInput!) {
  deleteOrganization(input: $input) {
    id
    name
    description
    logo
    customerId
    billingStatus
    mailingAddress {
      street
      street2
      city
      state
      zip
      country
    }
    users {
      items {
        id
        email
        firstName
        lastName
        company
        title
        phone
        phone2
        userPoolId
        organizationId
        status
      }
      nextToken
    }
    joinRequest {
      id
      userId
      user {
        id
        email
        firstName
        lastName
        company
        title
        phone
        phone2
        userPoolId
        organizationId
        status
      }
      organizationId
      organization {
        id
        name
        description
        logo
        customerId
        billingStatus
      }
      state
      timestamp
    }
  }
}
`;
export const createUser = `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    email
    firstName
    lastName
    company
    title
    phone
    phone2
    userPoolId
    organizationId
    status
    organization {
      id
      name
      description
      logo
      customerId
      billingStatus
      mailingAddress {
        street
        street2
        city
        state
        zip
        country
      }
      users {
        nextToken
      }
      joinRequest {
        id
        userId
        organizationId
        state
        timestamp
      }
    }
    joinRequest {
      id
      userId
      user {
        id
        email
        firstName
        lastName
        company
        title
        phone
        phone2
        userPoolId
        organizationId
        status
      }
      organizationId
      organization {
        id
        name
        description
        logo
        customerId
        billingStatus
      }
      state
      timestamp
    }
  }
}
`;
export const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    email
    firstName
    lastName
    company
    title
    phone
    phone2
    userPoolId
    organizationId
    status
    organization {
      id
      name
      description
      logo
      customerId
      billingStatus
      mailingAddress {
        street
        street2
        city
        state
        zip
        country
      }
      users {
        nextToken
      }
      joinRequest {
        id
        userId
        organizationId
        state
        timestamp
      }
    }
    joinRequest {
      id
      userId
      user {
        id
        email
        firstName
        lastName
        company
        title
        phone
        phone2
        userPoolId
        organizationId
        status
      }
      organizationId
      organization {
        id
        name
        description
        logo
        customerId
        billingStatus
      }
      state
      timestamp
    }
  }
}
`;
export const deleteUser = `mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    id
    email
    firstName
    lastName
    company
    title
    phone
    phone2
    userPoolId
    organizationId
    status
    organization {
      id
      name
      description
      logo
      customerId
      billingStatus
      mailingAddress {
        street
        street2
        city
        state
        zip
        country
      }
      users {
        nextToken
      }
      joinRequest {
        id
        userId
        organizationId
        state
        timestamp
      }
    }
    joinRequest {
      id
      userId
      user {
        id
        email
        firstName
        lastName
        company
        title
        phone
        phone2
        userPoolId
        organizationId
        status
      }
      organizationId
      organization {
        id
        name
        description
        logo
        customerId
        billingStatus
      }
      state
      timestamp
    }
  }
}
`;
export const createJoinRequest = `mutation CreateJoinRequest($input: CreateJoinRequestInput!) {
  createJoinRequest(input: $input) {
    id
    userId
    user {
      id
      email
      firstName
      lastName
      company
      title
      phone
      phone2
      userPoolId
      organizationId
      status
      organization {
        id
        name
        description
        logo
        customerId
        billingStatus
      }
      joinRequest {
        id
        userId
        organizationId
        state
        timestamp
      }
    }
    organizationId
    organization {
      id
      name
      description
      logo
      customerId
      billingStatus
      mailingAddress {
        street
        street2
        city
        state
        zip
        country
      }
      users {
        nextToken
      }
      joinRequest {
        id
        userId
        organizationId
        state
        timestamp
      }
    }
    state
    timestamp
  }
}
`;
export const updateJoinRequest = `mutation UpdateJoinRequest($input: UpdateJoinRequestInput!) {
  updateJoinRequest(input: $input) {
    id
    userId
    user {
      id
      email
      firstName
      lastName
      company
      title
      phone
      phone2
      userPoolId
      organizationId
      status
      organization {
        id
        name
        description
        logo
        customerId
        billingStatus
      }
      joinRequest {
        id
        userId
        organizationId
        state
        timestamp
      }
    }
    organizationId
    organization {
      id
      name
      description
      logo
      customerId
      billingStatus
      mailingAddress {
        street
        street2
        city
        state
        zip
        country
      }
      users {
        nextToken
      }
      joinRequest {
        id
        userId
        organizationId
        state
        timestamp
      }
    }
    state
    timestamp
  }
}
`;
export const deleteJoinRequest = `mutation DeleteJoinRequest($input: DeleteJoinRequestInput!) {
  deleteJoinRequest(input: $input) {
    id
    userId
    user {
      id
      email
      firstName
      lastName
      company
      title
      phone
      phone2
      userPoolId
      organizationId
      status
      organization {
        id
        name
        description
        logo
        customerId
        billingStatus
      }
      joinRequest {
        id
        userId
        organizationId
        state
        timestamp
      }
    }
    organizationId
    organization {
      id
      name
      description
      logo
      customerId
      billingStatus
      mailingAddress {
        street
        street2
        city
        state
        zip
        country
      }
      users {
        nextToken
      }
      joinRequest {
        id
        userId
        organizationId
        state
        timestamp
      }
    }
    state
    timestamp
  }
}
`;
export const createUserInvite = `mutation CreateUserInvite($input: CreateUserInviteInput!) {
  createUserInvite(input: $input) {
    id
    organizationId
    email
    state
    remarks
  }
}
`;
export const updateUserInvite = `mutation UpdateUserInvite($input: UpdateUserInviteInput!) {
  updateUserInvite(input: $input) {
    id
    organizationId
    email
    state
    remarks
  }
}
`;
export const deleteUserInvite = `mutation DeleteUserInvite($input: DeleteUserInviteInput!) {
  deleteUserInvite(input: $input) {
    id
    organizationId
    email
    state
    remarks
  }
}
`;
export const createWaterSystem = `mutation CreateWaterSystem($input: CreateWaterSystemInput!) {
  createWaterSystem(input: $input) {
    id
    organizationId
    name
    activeConnections
    activeConnectionsValidity
    inactiveConnections
    inactiveConnectionsValidity
    waterMainLen
    waterMainLenValidity
    percentFlexiblePipe
    aveSysPressure
    aveSysPressureValidity
    avePipeLength
    isFullyMetered
    hasProgram
    aveMeterAge
    subscriptionId
    subscribed
    waterSources {
      items {
        id
        name
        type
        units
        isMetered
        estimate
        meterSize
        meterMakeModel
        meterInstallYear
        meterCalibDate
        waterSystemId
        sourceImage
        installImage
        latitude
        longitude
      }
      nextToken
    }
    costInputs {
      id
      waterSystemId
      waterSystem {
        id
        organizationId
        name
        activeConnections
        activeConnectionsValidity
        inactiveConnections
        inactiveConnectionsValidity
        waterMainLen
        waterMainLenValidity
        percentFlexiblePipe
        aveSysPressure
        aveSysPressureValidity
        avePipeLength
        isFullyMetered
        hasProgram
        aveMeterAge
        subscriptionId
        subscribed
      }
      aveRetailUnitCost
      unit
      aveRetailUnitCostNormal
      annualElectricBill
      annualChemicalBill
      totalAnnualCost
      productionCost
      productionCostUnit
      productionCostNormal
      annualProductionVolume
      annualProductionVolumeNormal
      productionOverride
      unitNormalizer
    }
  }
}
`;
export const updateWaterSystem = `mutation UpdateWaterSystem($input: UpdateWaterSystemInput!) {
  updateWaterSystem(input: $input) {
    id
    organizationId
    name
    activeConnections
    activeConnectionsValidity
    inactiveConnections
    inactiveConnectionsValidity
    waterMainLen
    waterMainLenValidity
    percentFlexiblePipe
    aveSysPressure
    aveSysPressureValidity
    avePipeLength
    isFullyMetered
    hasProgram
    aveMeterAge
    subscriptionId
    subscribed
    waterSources {
      items {
        id
        name
        type
        units
        isMetered
        estimate
        meterSize
        meterMakeModel
        meterInstallYear
        meterCalibDate
        waterSystemId
        sourceImage
        installImage
        latitude
        longitude
      }
      nextToken
    }
    costInputs {
      id
      waterSystemId
      waterSystem {
        id
        organizationId
        name
        activeConnections
        activeConnectionsValidity
        inactiveConnections
        inactiveConnectionsValidity
        waterMainLen
        waterMainLenValidity
        percentFlexiblePipe
        aveSysPressure
        aveSysPressureValidity
        avePipeLength
        isFullyMetered
        hasProgram
        aveMeterAge
        subscriptionId
        subscribed
      }
      aveRetailUnitCost
      unit
      aveRetailUnitCostNormal
      annualElectricBill
      annualChemicalBill
      totalAnnualCost
      productionCost
      productionCostUnit
      productionCostNormal
      annualProductionVolume
      annualProductionVolumeNormal
      productionOverride
      unitNormalizer
    }
  }
}
`;
export const deleteWaterSystem = `mutation DeleteWaterSystem($input: DeleteWaterSystemInput!) {
  deleteWaterSystem(input: $input) {
    id
    organizationId
    name
    activeConnections
    activeConnectionsValidity
    inactiveConnections
    inactiveConnectionsValidity
    waterMainLen
    waterMainLenValidity
    percentFlexiblePipe
    aveSysPressure
    aveSysPressureValidity
    avePipeLength
    isFullyMetered
    hasProgram
    aveMeterAge
    subscriptionId
    subscribed
    waterSources {
      items {
        id
        name
        type
        units
        isMetered
        estimate
        meterSize
        meterMakeModel
        meterInstallYear
        meterCalibDate
        waterSystemId
        sourceImage
        installImage
        latitude
        longitude
      }
      nextToken
    }
    costInputs {
      id
      waterSystemId
      waterSystem {
        id
        organizationId
        name
        activeConnections
        activeConnectionsValidity
        inactiveConnections
        inactiveConnectionsValidity
        waterMainLen
        waterMainLenValidity
        percentFlexiblePipe
        aveSysPressure
        aveSysPressureValidity
        avePipeLength
        isFullyMetered
        hasProgram
        aveMeterAge
        subscriptionId
        subscribed
      }
      aveRetailUnitCost
      unit
      aveRetailUnitCostNormal
      annualElectricBill
      annualChemicalBill
      totalAnnualCost
      productionCost
      productionCostUnit
      productionCostNormal
      annualProductionVolume
      annualProductionVolumeNormal
      productionOverride
      unitNormalizer
    }
  }
}
`;
export const createWaterSource = `mutation CreateWaterSource($input: CreateWaterSourceInput!) {
  createWaterSource(input: $input) {
    id
    name
    type
    units
    isMetered
    estimate
    meterSize
    meterMakeModel
    meterInstallYear
    meterCalibDate
    waterSystemId
    sourceImage
    installImage
    latitude
    longitude
    waterSystem {
      id
      organizationId
      name
      activeConnections
      activeConnectionsValidity
      inactiveConnections
      inactiveConnectionsValidity
      waterMainLen
      waterMainLenValidity
      percentFlexiblePipe
      aveSysPressure
      aveSysPressureValidity
      avePipeLength
      isFullyMetered
      hasProgram
      aveMeterAge
      subscriptionId
      subscribed
      waterSources {
        nextToken
      }
      costInputs {
        id
        waterSystemId
        aveRetailUnitCost
        unit
        aveRetailUnitCostNormal
        annualElectricBill
        annualChemicalBill
        totalAnnualCost
        productionCost
        productionCostUnit
        productionCostNormal
        annualProductionVolume
        annualProductionVolumeNormal
        productionOverride
        unitNormalizer
      }
    }
  }
}
`;
export const updateWaterSource = `mutation UpdateWaterSource($input: UpdateWaterSourceInput!) {
  updateWaterSource(input: $input) {
    id
    name
    type
    units
    isMetered
    estimate
    meterSize
    meterMakeModel
    meterInstallYear
    meterCalibDate
    waterSystemId
    sourceImage
    installImage
    latitude
    longitude
    waterSystem {
      id
      organizationId
      name
      activeConnections
      activeConnectionsValidity
      inactiveConnections
      inactiveConnectionsValidity
      waterMainLen
      waterMainLenValidity
      percentFlexiblePipe
      aveSysPressure
      aveSysPressureValidity
      avePipeLength
      isFullyMetered
      hasProgram
      aveMeterAge
      subscriptionId
      subscribed
      waterSources {
        nextToken
      }
      costInputs {
        id
        waterSystemId
        aveRetailUnitCost
        unit
        aveRetailUnitCostNormal
        annualElectricBill
        annualChemicalBill
        totalAnnualCost
        productionCost
        productionCostUnit
        productionCostNormal
        annualProductionVolume
        annualProductionVolumeNormal
        productionOverride
        unitNormalizer
      }
    }
  }
}
`;
export const deleteWaterSource = `mutation DeleteWaterSource($input: DeleteWaterSourceInput!) {
  deleteWaterSource(input: $input) {
    id
    name
    type
    units
    isMetered
    estimate
    meterSize
    meterMakeModel
    meterInstallYear
    meterCalibDate
    waterSystemId
    sourceImage
    installImage
    latitude
    longitude
    waterSystem {
      id
      organizationId
      name
      activeConnections
      activeConnectionsValidity
      inactiveConnections
      inactiveConnectionsValidity
      waterMainLen
      waterMainLenValidity
      percentFlexiblePipe
      aveSysPressure
      aveSysPressureValidity
      avePipeLength
      isFullyMetered
      hasProgram
      aveMeterAge
      subscriptionId
      subscribed
      waterSources {
        nextToken
      }
      costInputs {
        id
        waterSystemId
        aveRetailUnitCost
        unit
        aveRetailUnitCostNormal
        annualElectricBill
        annualChemicalBill
        totalAnnualCost
        productionCost
        productionCostUnit
        productionCostNormal
        annualProductionVolume
        annualProductionVolumeNormal
        productionOverride
        unitNormalizer
      }
    }
  }
}
`;
export const createApiAction = `mutation CreateApiAction($input: CreateAPIActionInput!) {
  createAPIAction(input: $input) {
    id
    name
    parameters
    result
    disableUserResolver
    stripeCreateCustomerResolver
    stripeUpdateCustomerResolver
    stripeRetrieveCustomerResolver
    stripeSubscribeToPlanResolver
    stripeCancelSubscription
    stripeCardCreateResolver
    stripeDeleteCardResolver
    stripeListTransactionsResolver
    stripeCouponVerify
    stripeQuanUpdateSub
    stripeProrationPreview
    getProdAndConsDatesResolver
    getReportDataResolver
    getAnnualProduction
    getProdConOneMonthPrior
  }
}
`;
export const updateApiAction = `mutation UpdateApiAction($input: UpdateAPIActionInput!) {
  updateAPIAction(input: $input) {
    id
    name
    parameters
    result
    disableUserResolver
    stripeCreateCustomerResolver
    stripeUpdateCustomerResolver
    stripeRetrieveCustomerResolver
    stripeSubscribeToPlanResolver
    stripeCancelSubscription
    stripeCardCreateResolver
    stripeDeleteCardResolver
    stripeListTransactionsResolver
    stripeCouponVerify
    stripeQuanUpdateSub
    stripeProrationPreview
    getProdAndConsDatesResolver
    getReportDataResolver
    getAnnualProduction
    getProdConOneMonthPrior
  }
}
`;
export const deleteApiAction = `mutation DeleteApiAction($input: DeleteAPIActionInput!) {
  deleteAPIAction(input: $input) {
    id
    name
    parameters
    result
    disableUserResolver
    stripeCreateCustomerResolver
    stripeUpdateCustomerResolver
    stripeRetrieveCustomerResolver
    stripeSubscribeToPlanResolver
    stripeCancelSubscription
    stripeCardCreateResolver
    stripeDeleteCardResolver
    stripeListTransactionsResolver
    stripeCouponVerify
    stripeQuanUpdateSub
    stripeProrationPreview
    getProdAndConsDatesResolver
    getReportDataResolver
    getAnnualProduction
    getProdConOneMonthPrior
  }
}
`;
export const createTrial = `mutation CreateTrial($input: CreateTrialInput!) {
  createTrial(input: $input) {
    id
    organizationId
    trialName
    length
    endDate
    daysLeft
  }
}
`;
export const updateTrial = `mutation UpdateTrial($input: UpdateTrialInput!) {
  updateTrial(input: $input) {
    id
    organizationId
    trialName
    length
    endDate
    daysLeft
  }
}
`;
export const deleteTrial = `mutation DeleteTrial($input: DeleteTrialInput!) {
  deleteTrial(input: $input) {
    id
    organizationId
    trialName
    length
    endDate
    daysLeft
  }
}
`;
export const createSourceProduction = `mutation CreateSourceProduction($input: CreateSourceProductionInput!) {
  createSourceProduction(input: $input) {
    id
    waterSourceId
    waterSystemId
    installImage
    type
    timestamp
    startDate
    endDate
    pumpRunTime
    reading
    unit
    readingNormal
    volume
    volumeUnit
    volumeNormal
    unitNormalizer
  }
}
`;
export const updateSourceProduction = `mutation UpdateSourceProduction($input: UpdateSourceProductionInput!) {
  updateSourceProduction(input: $input) {
    id
    waterSourceId
    waterSystemId
    installImage
    type
    timestamp
    startDate
    endDate
    pumpRunTime
    reading
    unit
    readingNormal
    volume
    volumeUnit
    volumeNormal
    unitNormalizer
  }
}
`;
export const deleteSourceProduction = `mutation DeleteSourceProduction($input: DeleteSourceProductionInput!) {
  deleteSourceProduction(input: $input) {
    id
    waterSourceId
    waterSystemId
    installImage
    type
    timestamp
    startDate
    endDate
    pumpRunTime
    reading
    unit
    readingNormal
    volume
    volumeUnit
    volumeNormal
    unitNormalizer
  }
}
`;
export const createConfig = `mutation CreateConfig($input: CreateConfigInput!) {
  createConfig(input: $input) {
    name
    value
  }
}
`;
export const updateConfig = `mutation UpdateConfig($input: UpdateConfigInput!) {
  updateConfig(input: $input) {
    name
    value
  }
}
`;
export const deleteConfig = `mutation DeleteConfig($input: DeleteConfigInput!) {
  deleteConfig(input: $input) {
    name
    value
  }
}
`;
export const createConsumptionAndUse = `mutation CreateConsumptionAndUse($input: CreateConsumptionAndUseInput!) {
  createConsumptionAndUse(input: $input) {
    id
    waterSystemId
    startDate
    endDate
    meteredBilledConsumption
    meteredUnbilledConsumption
    unmeteredBilledConsumption
    unmeteredUnbilledConsumption
    unit
    meteredBilledConsumptionNormal
    meteredUnbilledConsumptionNormal
    unmeteredBilledConsumptionNormal
    unmeteredUnbilledConsumptionNormal
    unitNormalizer
  }
}
`;
export const updateConsumptionAndUse = `mutation UpdateConsumptionAndUse($input: UpdateConsumptionAndUseInput!) {
  updateConsumptionAndUse(input: $input) {
    id
    waterSystemId
    startDate
    endDate
    meteredBilledConsumption
    meteredUnbilledConsumption
    unmeteredBilledConsumption
    unmeteredUnbilledConsumption
    unit
    meteredBilledConsumptionNormal
    meteredUnbilledConsumptionNormal
    unmeteredBilledConsumptionNormal
    unmeteredUnbilledConsumptionNormal
    unitNormalizer
  }
}
`;
export const deleteConsumptionAndUse = `mutation DeleteConsumptionAndUse($input: DeleteConsumptionAndUseInput!) {
  deleteConsumptionAndUse(input: $input) {
    id
    waterSystemId
    startDate
    endDate
    meteredBilledConsumption
    meteredUnbilledConsumption
    unmeteredBilledConsumption
    unmeteredUnbilledConsumption
    unit
    meteredBilledConsumptionNormal
    meteredUnbilledConsumptionNormal
    unmeteredBilledConsumptionNormal
    unmeteredUnbilledConsumptionNormal
    unitNormalizer
  }
}
`;
export const createCostInputs = `mutation CreateCostInputs($input: CreateCostInputsInput!) {
  createCostInputs(input: $input) {
    id
    waterSystemId
    waterSystem {
      id
      organizationId
      name
      activeConnections
      activeConnectionsValidity
      inactiveConnections
      inactiveConnectionsValidity
      waterMainLen
      waterMainLenValidity
      percentFlexiblePipe
      aveSysPressure
      aveSysPressureValidity
      avePipeLength
      isFullyMetered
      hasProgram
      aveMeterAge
      subscriptionId
      subscribed
      waterSources {
        nextToken
      }
      costInputs {
        id
        waterSystemId
        aveRetailUnitCost
        unit
        aveRetailUnitCostNormal
        annualElectricBill
        annualChemicalBill
        totalAnnualCost
        productionCost
        productionCostUnit
        productionCostNormal
        annualProductionVolume
        annualProductionVolumeNormal
        productionOverride
        unitNormalizer
      }
    }
    aveRetailUnitCost
    unit
    aveRetailUnitCostNormal
    annualElectricBill
    annualChemicalBill
    totalAnnualCost
    productionCost
    productionCostUnit
    productionCostNormal
    annualProductionVolume
    annualProductionVolumeNormal
    productionOverride
    unitNormalizer
  }
}
`;
export const updateCostInputs = `mutation UpdateCostInputs($input: UpdateCostInputsInput!) {
  updateCostInputs(input: $input) {
    id
    waterSystemId
    waterSystem {
      id
      organizationId
      name
      activeConnections
      activeConnectionsValidity
      inactiveConnections
      inactiveConnectionsValidity
      waterMainLen
      waterMainLenValidity
      percentFlexiblePipe
      aveSysPressure
      aveSysPressureValidity
      avePipeLength
      isFullyMetered
      hasProgram
      aveMeterAge
      subscriptionId
      subscribed
      waterSources {
        nextToken
      }
      costInputs {
        id
        waterSystemId
        aveRetailUnitCost
        unit
        aveRetailUnitCostNormal
        annualElectricBill
        annualChemicalBill
        totalAnnualCost
        productionCost
        productionCostUnit
        productionCostNormal
        annualProductionVolume
        annualProductionVolumeNormal
        productionOverride
        unitNormalizer
      }
    }
    aveRetailUnitCost
    unit
    aveRetailUnitCostNormal
    annualElectricBill
    annualChemicalBill
    totalAnnualCost
    productionCost
    productionCostUnit
    productionCostNormal
    annualProductionVolume
    annualProductionVolumeNormal
    productionOverride
    unitNormalizer
  }
}
`;
export const deleteCostInputs = `mutation DeleteCostInputs($input: DeleteCostInputsInput!) {
  deleteCostInputs(input: $input) {
    id
    waterSystemId
    waterSystem {
      id
      organizationId
      name
      activeConnections
      activeConnectionsValidity
      inactiveConnections
      inactiveConnectionsValidity
      waterMainLen
      waterMainLenValidity
      percentFlexiblePipe
      aveSysPressure
      aveSysPressureValidity
      avePipeLength
      isFullyMetered
      hasProgram
      aveMeterAge
      subscriptionId
      subscribed
      waterSources {
        nextToken
      }
      costInputs {
        id
        waterSystemId
        aveRetailUnitCost
        unit
        aveRetailUnitCostNormal
        annualElectricBill
        annualChemicalBill
        totalAnnualCost
        productionCost
        productionCostUnit
        productionCostNormal
        annualProductionVolume
        annualProductionVolumeNormal
        productionOverride
        unitNormalizer
      }
    }
    aveRetailUnitCost
    unit
    aveRetailUnitCostNormal
    annualElectricBill
    annualChemicalBill
    totalAnnualCost
    productionCost
    productionCostUnit
    productionCostNormal
    annualProductionVolume
    annualProductionVolumeNormal
    productionOverride
    unitNormalizer
  }
}
`;
