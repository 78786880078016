import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { Redirect } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'
import fecha from 'fecha'
import { compose } from 'recompose'

import * as queries from 'graphql/queries'
import * as mutations from 'graphql/mutations'
import ToolbarContent from 'components/ToolbarContent'
import withUserData from 'components/withUserData'
import { UserContext } from 'contexts'
import { requestState } from 'services/constantVariables/joinRequest'

const styles = () => ({
  button: {
    margin: 'auto',
  },
})

class PendingRequest extends Component {
  state = {
    joinRequest: null,
    date: null,
    time: null,
    updateContext: false,
    updatedUserData: null,
    redirect: false,
  }

  componentDidMount = async () => {
    await this.getJoinRequest()
    this.setDateTime()
  }

  getJoinRequest = async () => {
    const joinRequestData = await API.graphql(graphqlOperation(queries.getJoinRequest, {id: this.props.match.params.reqid}))
    if(joinRequestData) {
      const joinRequest = joinRequestData.data.getJoinRequest
      const queryUser = await API.graphql(graphqlOperation(queries.getUser, {id: this.props.userContext.currentUser.attributes.sub}))
      const updatedUserData = queryUser.data.getUser
      this.setState({
        updateContext: true, 
        updatedUserData,
        joinRequest,
      })
    }
  }

  dismissJoinRequest = async (request) => {
    const inputUpdateJoinRequest = {
      id: request.id, 
      state: requestState.DISMISSED,
    }
    await API.graphql(graphqlOperation(mutations.updateJoinRequest, {input: inputUpdateJoinRequest}))
    const queryUser = await API.graphql(graphqlOperation(queries.getUser, {id: this.props.userContext.currentUser.attributes.sub}))
    const updatedUserData = queryUser.data.getUser
    this.setState({ 
      updatedUserData,
      updateContext: true,
      redirect: true,
    })
  }

  updateContext = () => {
    this.setState({ updateContext: false })
    return (
      <UserContext.Consumer>
        {({ updateUserData }) => {
          updateUserData(this.state.updatedUserData)
        }}
      </UserContext.Consumer>
    )
  }

  setDateTime = async () => {
    if(!this.state.joinRequest) return
    if(!this.state.joinRequest.timestamp) return
    const dateObj = Date.parse(this.state.joinRequest.timestamp)
    const date = fecha.format(new Date(dateObj), 'mediumDate');
    const time = fecha.format(new Date(dateObj), 'HH:mmA');
    await this.setState({
      date,
      time,
    })
  }

  renderNewRequestContent = (classes, joinRequest) => {
    return (
      <Grid item xs={12} sm={6}>
        <Typography color="secondary" variant="body2" gutterBottom>
          Your request has been sent
        </Typography>
        {this.state.date ? (
          <Typography paragraph>
            Your request was sent on {this.state.date} at {this.state.time}.
          </Typography>
        ): null}
        <Typography paragraph>
          It could take some time for a user from <b>{joinRequest.organization.name}</b> to approve your request.
        </Typography>
        <Typography paragraph>
          If you are not approved in several business days or there is some other problem please call support at 1-800-WATER-APP.
        </Typography>
        <Button 
          className={classes.button} 
          variant="contained" 
          color="primary"
          fullWidth
          onClick={() => this.getJoinRequest()}
        >
          REFRESH
        </Button>
      </Grid>
    )
  }

  renderDeniedRequestContent = (classes, joinRequest) => {
    return (
      <Grid item xs={12} sm={6}>
        <Typography color="secondary" variant="body2" gutterBottom>
          Your request has been denied
        </Typography>
        <Typography paragraph>
          Check other organizations or create a new one.
        </Typography>
        <Button 
          className={classes.button} 
          variant="contained" 
          color="primary"
          fullWidth
          onClick={() => this.dismissJoinRequest(joinRequest)}
        >
          START OVER
        </Button>
      </Grid>
    )
  }

  render() {
    const { classes } = this.props
    const { 
      joinRequest, 
      updateContext,
      redirect,
    } = this.state

    if(!joinRequest) {
      return (
        <>
          <ToolbarContent
            screenTitle = "Checking"
            isMainScreen = {false}
            renderIcon = {false}
          />
          <p>Getting request details</p>
        </>
      ) 
    }

    return (
      <>
        {updateContext ? this.updateContext() : null}
        <ToolbarContent
          screenTitle = "Request Status"
          isMainScreen = {false}
          renderIcon = {false}
        />
        {joinRequest.state === requestState.NEW ? this.renderNewRequestContent(classes, joinRequest) : null}
        {joinRequest.state === requestState.DENIED ? this.renderDeniedRequestContent(classes, joinRequest) : null}
        {joinRequest.state === requestState.APPROVED ||
         joinRequest.state === requestState.DISMISSED ? <Redirect to={{ pathname: "/", }} /> : null}
        {redirect ? <Redirect to={{ pathname: "/", }} /> : null}
      </>
    )
  }
}

export default compose(
  withUserData,
  withStyles(styles)
)(PendingRequest)