import React from 'react'
import { compose } from 'recompose'

import ToolbarContent from 'components/ToolbarContent'
import withUserData from 'components/withUserData'
import CreateThruEmail from './scenes/CreateThruEmail'
import CreateNonUser from './scenes/CreateNonUser'

function UserNew(props) {
  const { response } = props.location.state

  const Content = () => {
    if (response === 'user') {
      return <CreateThruEmail {...props} />
    } else if (response === 'contact') {
      return <CreateNonUser {...props} />
    }
  }

  return (
    <>
      <ToolbarContent
        screenTitle = {`New ${response}`}
        isMainScreen = {false}
      />
      <Content />
    </>
  )
}

export default compose(
  withUserData,
)(UserNew)