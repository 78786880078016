export const joinPath = (base, path) => {
  const cleanBase = base.charAt(base.length-1) === '/' ? base.slice(0, -1) : base
  const cleanPath = path.charAt(0) === '/' ? path.slice(0) : path

  return cleanBase + '/' + cleanPath
}

export const months = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
]

export const formatDate = (dateString, format) => {
  if (!dateString) return

  if ((String(dateString).length !== 8 && String(dateString).length !== 6) || !dateString) {
    return console.error('Warning: Invalid date string format')
  }

  // Mmm dd, yyyy
  if (format === 'Mmm dd, yyyy') {
    const divider = ', '
    const monthIndex = Number(dateString.slice(4, 6)) - 1
    const outputComponents = [
      months[monthIndex].slice(0, 3),
      ' ',
      dateString.slice(6),
      divider,
      dateString.slice(0, 4)
    ]

    return outputComponents.join('')
  }

  // Mmm/dd/yyyy
  if (format === 'Mmm/dd/yyyy') {
    const divider = '/'
    const monthIndex = Number(dateString.slice(4, 6)) - 1
    const outputComponents = [
      months[monthIndex].slice(0, 3),
      divider,
      dateString.slice(6),
      divider,
      dateString.slice(0, 4)
    ]

    return outputComponents.join('')
  }

  // Mmm dd
  if (format === 'Mmm dd') {
    const divider = ' '
    const monthIndex = Number(dateString.slice(4, 6)) - 1
    const outputComponents = [
      months[monthIndex].slice(0, 3),
      divider,
      dateString.slice(6),
    ]

    return outputComponents.join('')
  }

  // Mmm yyyy
  if (format === 'Mmm yyyy') {
    const divider = ' '
    const monthIndex = Number(dateString.slice(4, 6)) - 1
    const outputComponents = [
      months[monthIndex].slice(0, 3),
      divider,
      dateString.slice(0, 4)
    ]

    return outputComponents.join('')
  }

  // Month yyyy
  if (format === 'Month yyyy') {
    const divider = ' '
    const monthIndex = Number(dateString.slice(4, 6)) - 1
    const outputComponents = [
      months[monthIndex],
      divider,
      dateString.slice(0, 4)
    ]

    return outputComponents.join('')
  }

  // yyyy-mm-dd
  const divider = '-'
  const outputComponents = [
    dateString.slice(0, 4), 
    divider, 
    dateString.slice(4, 6), 
    divider,
    dateString.slice(6)  
  ]

  return outputComponents.join('')
} 

export const prettifyTimestamp = timestamp => {
  if (!timestamp) return

  return formatDate(timestamp.slice(0, 8), 'Mmm/dd/yyyy') + ' ' + timestamp.slice(8,10) + ':' + timestamp.slice(10,12) 
}

export const prettifyDateRange = (start, end) => {
  return formatDate(start, 'Mmm/dd/yyyy') + ' - ' + formatDate(end, 'Mmm/dd/yyyy')
}

export const getTimeString = (hours, minutes) => {
  let time = new Date()
  time.setHours(hours)
  time.setMinutes(minutes)
  
  return time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
}

export default null 