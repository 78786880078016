import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Skeleton from 'react-loading-skeleton'
import { withStyles } from '@material-ui/core/styles'
import { API, graphqlOperation } from 'aws-amplify'
import { compose } from 'recompose'

import * as mutations from 'graphql/mutations'
import withUserData from 'components/withUserData'

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
  },
  button: {
    marginTop: theme.spacing.unit,
  },
  content: {
    paddingLeft: theme.spacing.unit*2,
    paddingRight: theme.spacing.unit*2,
  },
  tableWrapper: {
    overflowX: 'auto',
  }
})

const CustomTableCell = withStyles(theme => ({
  head: {
    color: theme.palette.primary.dark,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const SkeletonRows = () => {
  const items = [1, 2, 3]
  return items.map(item => (
    <TableRow key={item}>
      <TableCell
        component="th" 
        scope="row"
        align="center"
      >
        <Skeleton />
      </TableCell>
      <TableCell align="center">
        <Skeleton width={30} />
      </TableCell>
      <TableCell align="center">
        <Skeleton width={30} />
      </TableCell>
      <TableCell align="center">
        <Skeleton width={30} />
      </TableCell>
      <TableCell align="center">
        <Skeleton width={30} />
      </TableCell>
    </TableRow>
  ))
}

const TableRows = props => {
  const { 
    payments, 
    limit,
  } = props
  
  return payments.length === 0 ? (
    <TableRow>
      <TableCell align="center" colSpan={3}>
        <Typography variant="caption">
          Nothing to show
        </Typography>
      </TableCell>
    </TableRow>
  ) : (
    payments.slice(0, limit).map((item, index) => (
      <TableRow key={index}>
        <TableCell 
          component="th" 
          scope="row"
          align="center"
        >
          {dateFormat(item.date)}
        </TableCell>
        <TableCell align="center">
          {item.metadata.water_system}
        </TableCell>
        <TableCell align="center">
          {Number(item.connections).toLocaleString()}
        </TableCell>
        <TableCell align="center">
          ${(Number(item.amount)/100).toLocaleString()}
        </TableCell>
        <TableCell align="center">
          {item.discount.percent_off ? `${item.discount.name} ${item.discount.percent_off}% OFF` : null}
        </TableCell>
      </TableRow>
    ))
  )
}

const dateFormat = unix_timestamp => {
  const dateObj = new Date(unix_timestamp*1000)
  const date = dateObj.getDate()
  const month = dateObj.getMonth() + 1
  const year = dateObj.getFullYear()
  return `${month}/${date}/${year}`
}

function Payments(props) {
  const {
    classes,
    limit,
    userContext,
  } = props

  const { customerId } = userContext.userData.organization

  const [payments, setPayments] = useState([])
  const [loading, setLoading] = useState(true)

  let isMounted
  
  useEffect(() => {
    isMounted = true
    
    ;(async () => {
      const inputApiActionTransaction = {
        name: 'stripeListTransactions',
        parameters: [
          customerId,
        ]
      }
       
      try {
        const transactions = await API.graphql(graphqlOperation(mutations.createApiAction, { input: inputApiActionTransaction } ))
        const paymentsJSON = transactions.data.createAPIAction.stripeListTransactionsResolver
        const payments = JSON.parse(paymentsJSON)
        
        if (!isMounted) return

        if (payments.error) {
          console.log(payments.error)
        } else {
          setPayments(payments)
        }

      } catch (error) {
        console.log(error)
      }
      setLoading(false)
    })()

    return () => {
      isMounted = false
    }

  }, [])

  return (
    <>
      <div className={classes.tableWrapper}>
        <Table padding="dense">
          <TableHead>
            <TableRow>
              <CustomTableCell align="center">Date</CustomTableCell>
              <CustomTableCell align="center">PWS</CustomTableCell>
              <CustomTableCell align="center">Connections</CustomTableCell>
              <CustomTableCell align="center">Amount</CustomTableCell>
              <CustomTableCell align="center">Coupon</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? <SkeletonRows /> : <TableRows payments={payments} limit={limit || 10} />}
          </TableBody>
        </Table>
      </div>
    </>
  )
}

export default compose(
  withUserData,
  withStyles(styles),
)(Payments)