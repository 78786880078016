import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'

import DateForm, { getCurrentDate, zeroPad } from 'components/DateForm' 
import { formatDate } from 'services/utilityFunctions'
import Img from 'components/Img'
import NumberField from 'components/NumberField'

const styles = theme => ({
  type: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  divider: {
    marginBottom: 20,
    backgroundColor: 'transparent',
  },
  image: {
    width: 250,
    margin: 'auto',
    display: 'block',
  },
  switchForm: {
    marginRight: 0,
  },
  buttonSave: {
    margin: 'auto',
    marginBottom: 16,
  },
  buttonContainer: {
    position: 'sticky',
    bottom: 0,
    paddingTop: 16,
    background: '#fff'
  }
})

function ProductionDataForm(props) {
  const {
    classes,
    history,
    source,
    sourceProduction,
    handleSave,
    switchDisabled,
    lastReading,
  } = props
  
  const currentDateObj = new Date()
  const currentTime = zeroPad(currentDateObj.getHours().toString(), 2) 
    + zeroPad(currentDateObj.getMinutes().toString(), 2)

  const currentDate = getCurrentDate()
  
  const defaults = {  
    id: null,
    timestamp: currentDate + currentTime,
    startDate: currentDate,
    endDate: currentDate,
    type: 'meter',
    pumpRunTime: '',
    reading: '',
    volume: '',
  }
  
  const inputVal = sourceProduction || defaults

  const id = inputVal.id
  const extractedDate = inputVal.timestamp ? inputVal.timestamp.slice(0, 8) : null
  const extractedTime = inputVal.timestamp ? inputVal.timestamp.slice(8, 10) + ':' + inputVal.timestamp.slice(10, 12) : null

  const [type, setType] = useState(inputVal.type)

  const [date, setDate] = useState(extractedDate)
  const [time, setTime] = useState(extractedTime)
  const [pumpRunTime, setPumpRunTime] = useState(inputVal.pumpRunTime)
  const [currentMeterRead, setCurrentMeterRead] = useState(inputVal.reading)
  
  const [startDate, setStartDate] = useState(inputVal.startDate)
  const [endDate, setEndDate] = useState(inputVal.endDate)
  const [volume, setVolume] = useState(inputVal.volume)

  const [saveInProgress, setSaveInProgress] = useState(false)

  useEffect(() => {
    if (sourceProduction) return
    type === 'meter' ? setDate(startDate) : setStartDate(date)
  }, [type])

  return (
    <>
      <FormControl 
        className={classes.type}
        component="fieldset" 
        fullWidth
      >
        <FormLabel>Production Record Type</FormLabel>
        <FormControlLabel
          className={classes.switchForm}
          control={
            <>
              Historical
              <Switch
                disabled={switchDisabled}
                checked={type === 'meter' ? false : true}
                onChange={() => type === 'meter' ? setType('historical') : setType('meter')}
                color="default"
              />
              Meter
            </>
          }
          labelPlacement="start"
        />
      </FormControl>
      <Img
        className={classes.image}
        src={source.installImage}
        width={250}
        loader={
          <CircularProgress className={classes.image} />
        }
        unloader={
          <img
            id="install"
            alt="install"
            className={classes.image}
            src={require('images/default-image.png')}
          />
        }
      />
      {type === 'meter' ? (
        <>
          <DateForm
            year={date.substr(0,4)}
            month={date.substr(4,2)}
            day={date.substr(6,2)} 
            onChange={date => setDate(`${date.year}${date.month}${date.day}`)}
          />
          <TextField 
            label="Time"
            value={time}
            onChange={e => setTime(e.target.value)}
            type="time"
          />
          <Grid container direction="row">
            <NumberField 
              label="Current Meter Read"
              value={currentMeterRead}
              onChange={v => setCurrentMeterRead(v)}
              helperText={lastReading ? (
                <>
                  {`Last Reading: ${lastReading.reading} ${source.units} on ${formatDate(lastReading.timestamp.slice(0, 8), 'Mmm/dd/yyyy')}`}
                </>
              ) : null}
              InputProps={{
                endAdornment: <InputAdornment 
                  style={{ whiteSpace: 'nowrap' }} 
                  position="end"
                >
                  {source.units}
                </InputAdornment>
              }}
              fullWidth
              margin="normal"
            />
          </Grid>
          <NumberField 
            label="Pump Run Time"
            value={pumpRunTime}
            onChange={v => setPumpRunTime(v)}
          />
        </>
      ) : (
        <>
          <DateForm
            label="Start Date"
            year={startDate.substr(0,4)}
            month={startDate.substr(4,2)}
            day={startDate.substr(6,2)} 
            onChange={date => setStartDate(`${date.year}${date.month}${date.day}`)}
          />
          <DateForm
            label="End Date"
            year={endDate.substr(0,4)}
            month={endDate.substr(4,2)}
            day={endDate.substr(6,2)} 
            onChange={date => setEndDate(`${date.year}${date.month}${date.day}`)}
          />
          <Grid container direction="row">
            <NumberField 
              label="Volume"
              value={volume}
              onChange={v => setVolume(v)}
              InputProps={{
                endAdornment: <InputAdornment 
                  style={{ whiteSpace: 'nowrap' }} 
                  position="end"
                >
                  {source.units}
                </InputAdornment>
              }}
              fullWidth
              margin="normal"
            />
          </Grid>
        </>
      )}
      <Divider 
        className={classes.divider}
      />
      <Grid className={classes.buttonContainer}>
        <Button
          className={classes.buttonSave}
          color="primary"
          variant="contained"
          fullWidth
          disabled={saveInProgress 
            || (type === 'meter' && (!currentMeterRead || !pumpRunTime))
            || (type !== 'meter' && !volume)
          }
          onClick={async () => {
            setSaveInProgress(true)
            try {
              if (type === 'meter') {
                await handleSave({
                  id,
                  waterSourceId: source.id,
                  waterSystemId: source.waterSystemId,
                  type,
                  date,
                  time,
                  pumpRunTime,
                  currentMeterRead,
                })
              } else {
                await handleSave({
                  id,
                  waterSourceId: source.id,
                  waterSystemId: source.waterSystemId,
                  type,
                  startDate,
                  endDate,
                  volume,
                })
              }
              history.goBack()
            } catch (error) {
              console.log(error)
            }
            setSaveInProgress(false)
          }}
        >
          {saveInProgress ? <CircularProgress color="inherit" size={16} style={{ marginRight: 5 }} /> : null}
          SAVE
        </Button>
      </Grid>
    </>
  )
}

ProductionDataForm.defaultProps = {
  handleSave: () => {}
}

ProductionDataForm.propTypes = {
  handleSave: PropTypes.func,
}

export default withStyles(styles)(ProductionDataForm)