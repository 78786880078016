import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import isEqual from 'lodash/isEqual'
import validator from 'email-validator'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%'
  },
})

const handleEmailChange = (e, setEmail, setEmailVerified) => {
  setEmail(e.target.value)
  if (validator.validate(e.target.value)) {
    setEmailVerified(true)
  } else {
    setEmailVerified(false)
  }
}

function UserForm(props) {
  const { 
    classes,
    user,
    setUser,
    setIsUpdated,
    emailDisabled,
  } = props

  const [firstName, setFirstName] = useState(user.firstName || '')
  const [lastName, setLastName] = useState(user.lastName || '')
  const [company, setCompany] = useState(user.company || '')
  const [title, setTitle] = useState(user.title || '')
  const [phone, setPhone] = useState(user.phone || '')
  const [phone2, setPhone2] = useState(user.phone2 || '')
  const [email, setEmail] = useState(user.email || '')
  const [emailVerified, setEmailVerified] = useState(true)

  useEffect(() => {
    let currentUser = JSON.parse(JSON.stringify(user))
    currentUser.firstName = firstName  
    currentUser.lastName = lastName 
    currentUser.company = company
    currentUser.title = title
    currentUser.phone = phone
    currentUser.phone2 = phone2
    currentUser.email = email

    if(isEqual(user, currentUser)) {
      setUser(currentUser)
      setIsUpdated(false)
    } else {
      if ( email && !emailVerified ) {
        currentUser.email = ''
        setUser(currentUser)
        setIsUpdated(false)
      } else {
        setUser(currentUser)
        setIsUpdated(true)
      }
    }
  }, [
    firstName,
    lastName, 
    company,
    title,
    phone,
    phone2,
    email,
    emailVerified,
  ])

  return (
    <>
      <form className={classes.container} noValidate autoComplete="off">
        <TextField
          id="first-name"
          label="First Name"
          className={classes.textField}
          value={firstName||''}
          onChange={(e) => setFirstName(e.target.value)}
          margin="normal"
        />
        <TextField
          id="last-name"
          label="Last Name"
          className={classes.textField}
          value={lastName||''}
          onChange={(e) => setLastName(e.target.value)}
          margin="normal"
        />
        <TextField
          id="company"
          label="Company"
          className={classes.textField}
          value={company||''}
          onChange={(e) => setCompany(e.target.value)}
          margin="normal"
        />
        <TextField
          id="title"
          label="Title"
          className={classes.textField}
          value={title||''}
          onChange={(e) => setTitle(e.target.value)}
          margin="normal"
        />
        <TextField
          id="phone"
          label="Phone"
          type="tel"
          className={classes.textField}
          value={phone||''}
          onChange={(e) => setPhone(e.target.value)}
          margin="normal"
        />
        <TextField
          id="phone2"
          label="Secondary Phone"
          type="tel"
          className={classes.textField}
          value={phone2||''}
          onChange={(e) => setPhone2(e.target.value)}
          margin="normal"
        />
        <TextField
          id="email"
          label={email && !emailVerified ? 'Enter a valid email address' : 'Email'}
          type="email"
          className={classes.textField}
          value={email}
          error={email ? !emailVerified : false}
          onChange={(e) => handleEmailChange(e, setEmail, setEmailVerified)}
          disabled={emailDisabled}
          margin="normal"
        />
      </form>
    </>
  )
}

UserForm.propTypes = {
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  setIsUpdated: PropTypes.func.isRequired,
  emailDisabled: PropTypes.bool,
}

UserForm.defaultProps = {
  emailDisabled: true,
}

export default withStyles(styles)(UserForm)