import React from 'react'
import Typography from '@material-ui/core/Typography'

import LossAnalysisTable from './components/LossAnalysisTable'

function Methodology(props) {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        METHODOLOGY
      </Typography>
      <Typography>
        To accomplish our audit objectives, WUA software performed the following steps with data
        inputs from user:
      </Typography>
      <Typography component="article">
        <ul>
          <li>Sum Source Volumes - Water Supplied</li>
          <li>Sum Consumption</li>
          <ul>
            <li>Billed Authorized Consumption</li>
            <ul>
              <li>Billed Metered Consumption</li>
              <ul>
                <li>Normal metered usage</li>
                <li>Revenue Water</li>
              </ul>
              <li>Billed Unmetered Consumption - Estimated</li>
              <ul>
                <li>Less common usage based upon estimated consumption</li>
                <li>Revenue Water</li>
              </ul>
            </ul>
            <li>Unbilled Authorized Consumption</li>
            <ul>
              <li>Unbilled Metered Consumption</li>
              <ul>
                <li>City Hall, Parks, City Shop or other entities which are unbilled</li>
                <li>Non-Revenue Water</li>
              </ul>
              <li>Unbilled Authorized Unmetered Consumption - Estimated</li>
              <ul>
                <li>Fire fighting, flushing of mains, etc.</li>
                <li>
                  The default value of 1.25% of Source Volume total is used unless the Utility tracks
                  and estimates these uses
                </li>
                <li>Non-Revenue Water</li>
              </ul>
            </ul>
          </ul>
          <li>Estimate Real Loss </li>
          <ul>
            <li>Water Supplied - Consumption - Apparent Loss</li>
            <li>Volume </li>
            <li>Cost in $$</li>
          </ul>
          <li>Estimate Apparent Loss</li>
          <ul>
            <li>Unauthorized Consumption - Non Revenue Water</li>
            <ul>
              <li>Defaut estimate of 0.25% of Water Supplied</li>
              <li>Water theft</li>
            </ul>
            <li>Customer Metering Inaccuracies - Non Revenue Water</li>
            <ul>
              <li>
                Default estimate of 2.5% of Billed Metered Consumption if the utility has a meter
                replacement program
              </li>
              <li>
                This estimate could in reality be much higher, especially if the utility has a 
                significant portion of old meters and does not have a meter replacement program
              </li>
            </ul>
            <li>Systematic Data Handling Errors - Non Revenue Water</li>
            <ul>
              <li>Default estimate of 0.25% of Billed Metered Consumption</li>
              <li>Failed or inaccurate meter reads</li>
              <li>Billing adjustments</li>
            </ul>
            <li>Volume</li>
            <li>Cost in $$</li>
          </ul>
          <li>Calculate Non-Revenue Water</li>
          <ul>
            <li>Real Loss + Apparent Loss + Unbilled Authorized Consumption</li>
            <li>Volume</li>
            <li>Cost $$ </li>
          </ul>
          <li>Calculate the UARL (Unavoidable Annual Real Loss) for the system</li>
          <ul>
            <li>
              UARL (gallons) = (5.41Lm + 0.15Nc + 7.5Lc) X P X D 
              &ldquo;Number of days from time period&rdquo;
            </li>
            <ul>
              <li>Lm = length of mains (miles)</li>
              <li>Nc = number of customer service connections</li>
              <ul>
                <li>Active and Inactive</li>
                <ul>
                  <li>The more holes you put in your pipe, the more leakage</li>
                </ul>
              </ul>
              <li>Lc = total length of customer service connection piping (miles)</li>
              <ul>
                <li>Lc = Nc &nbsp;X (Lp/5280) &nbsp;</li>
                <li>Lp = the average distance of customer service connection piping (feet)</li>
              </ul>
              <li>P = Pressure (psi)</li>
              <li>D = Number of days</li>
            </ul>
          </ul>
          <li>Calculate the ILI for the system</li>
          <li>Calculate gal/service connection/day</li>
          <li>Calculate % Loss of Water Supplied</li>
          <ul>
            <li>
              Not a recommended performance indicator, below is an example why the use of % loss 
              is not a reliable performance indicator.
              <LossAnalysisTable />
            </li>
            <li>
              The above example outlines why the use of % of supplied volume loss is not a reliable 
              performance indicator. The difference of water loss volume between
              2017 to 2018 was minimal, however the % loss difference is substantial. What
              changed is the system pumped and sold more water in 2017 than in 2018. The
              question is: “Did the system perform better in 2017 than in 2018?” NO!
            </li>
          </ul>
        </ul>
      </Typography>
    </>
  )
}

export default Methodology

