import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import isEqual from 'lodash/isEqual'
import { withStyles } from '@material-ui/core/styles'
import { API, graphqlOperation } from 'aws-amplify'

import * as mutations from 'graphql/mutations'
import ToolbarContent from 'components/ToolbarContent'
import ContentHeader from 'components/ContentHeader'
import ConsumptionAndUseForm from '../../components/ConsumptionAndUseForm'
import { formatDate } from 'services/utilityFunctions'

const styles = theme => ({
  button: {
    marginBottom: 16,
  },
  buttonContainer: {
    position: 'sticky',
    bottom: 0,
    paddingTop: 16,
    background: '#fff'
  }
})

const handleSave = async args => {
  const {
    id,
    waterSystemId,
    startDate,
    endDate,
    meteredBilledConsumption,
    meteredUnbilledConsumption,
    unmeteredBilledConsumption,
    unmeteredUnbilledConsumption,
    unit,
  } = args

  const inputUpdateConsumptionAndUse = {
    id,
    waterSystemId,
    startDate,
    endDate,
    meteredBilledConsumption,
    meteredUnbilledConsumption,
    unmeteredBilledConsumption,
    unmeteredUnbilledConsumption,
    unit,
  }

  try {
    await API.graphql(graphqlOperation(mutations.updateConsumptionAndUse, { input: inputUpdateConsumptionAndUse }))
  } catch (error) {
    console.log(error)  
  }
}

function ConsumptionAndUseEdit(props) {
  const {
    classes,
    history,
    location,
  } = props
  
  const initialConsumption = location.state.consumption
  const pws = location.state.pws
  const [consumption, setConsumption] = useState(initialConsumption)
  const [error, setError] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  const [loading, setLoading] = useState(false)

  const {
    startDate, 
    endDate
  } = consumption

  useEffect(() => {
    if (isEqual(initialConsumption, consumption)) {
      setIsChanged(false)
    } else {
      setIsChanged(true)
    }
  }, [consumption])

  return (
    <>
      <ToolbarContent
        screenTitle="Consumption Data Edit"
        isMainScreen={false}
      />
      <ContentHeader
        breadcrumbs={[
          { name: 'Consumption Data', goBack: true, step: 3 },
          { name: pws.name, goBack: true, step: 2 }, 
          { name: formatDate(startDate, 'Mmm/dd/yyyy') + ' - ' + formatDate(endDate, 'Mmm/dd/yyyy'), goBack: true, step: 1 }, 
          { name: 'Edit' }, 
        ]}
        history={history}
      />
      <ConsumptionAndUseForm 
        consumption={initialConsumption} 
        setConsumption={setConsumption} 
        error={error}
      />
      <Grid className={classes.buttonContainer}>
        <Button
          className={classes.button}
          disabled={loading || !isChanged}
          onClick={async () => {
            const {
              waterSystemId,
              startDate,
              endDate,
            } = consumption

            if (!(waterSystemId && startDate && endDate)) {
              return setError(true)
            }
            
            setLoading(true)
            await handleSave(consumption)
            setLoading(false)
            history.goBack()
          }}
          variant="contained"
          color="primary"  
          fullWidth
        >
          {loading ? <CircularProgress color="inherit" size={16} style={{ marginRight: 5 }} /> : null}
          SAVE
        </Button>
      </Grid>
    </>
  )
}

export default withStyles(styles)(ConsumptionAndUseEdit)