import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Skeleton from 'react-loading-skeleton'
import EventNoteIcon from '@material-ui/icons/EventNote'

import InfoCard from '../../../../../../components/InfoCard'

function NotesAndEvents(props) {
  const {
    event
  } = props

  const Events = () => event.map((item, index) => (
    <div key={index}>
      <Typography variant="h5">
        {item.loss} {item.unit}
      </Typography>
      <Typography>
        {item.name} - {item.type}
      </Typography>
    </div>
  ))

  return (
    <>
      <InfoCard 
        Icon={EventNoteIcon}
        color="red"
        // title="Notes &amp; Events"
        title="Events"
        bodyText={event ? ( event.length > 0 ? <Events /> : "") : <Skeleton />}
        caption={event ? ( event.length > 0 ? "" : "No events found") : <Skeleton />}
        linkHref=""
        linkText=""
      />
    </>
  )
}

NotesAndEvents.propTypes = {
  event: PropTypes.array,
}

export default NotesAndEvents