import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import DashboardTwoTone from '@material-ui/icons/DashboardTwoTone'
import AssignmentTwoTone from '@material-ui/icons/AssignmentTwoTone'
import BuildTwoTone from '@material-ui/icons/BuildTwoTone'
import ShowChartTwoTone from '@material-ui/icons/ShowChartTwoTone'
import AvTimerTwoTone from '@material-ui/icons/AvTimerTwoTone'
import EventTwoTone from '@material-ui/icons/EventTwoTone'
import AccountBalanceTwoTone from '@material-ui/icons/AccountBalanceTwoTone'
import AttachMoneyTwoTone from '@material-ui/icons/AttachMoneyTwoTone'
import PersonAddTwoTone from '@material-ui/icons/PersonAddTwoTone'
import ExitToAppTwoTone from '@material-ui/icons/ExitToAppTwoTone'
import NotesTwoTone from '@material-ui/icons/NotesTwoTone'

import { AuthStateContext } from 'contexts'

class AppDrawer extends Component {
  state = {
    currentUser: {},
  }

  itemClick = () => () => {
    if(this.props.itemClick){
      this.props.itemClick()
    }
  }

  async componentDidMount() {
    this.mounted = true

    const currentUser = await Auth.currentAuthenticatedUser()
    if (this.mounted) {
      this.setState({ currentUser })
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  render() {
    const { currentUser } = this.state

    return (
      <>
        <img 
          alt="WUA Logo"
          src={require('images/wua-logo-min.png')} 
          style={{ 
            maxWidth: 125,
            marginRight: 'auto', 
            marginLeft: 'auto', 
          }} 
        />
        <List>
            {currentUser ? (
              <>
                <ListItem style={{ display: 'block'}}>
                  <Typography>
                    {currentUser.username}
                  </Typography>
                  <Typography>
                    {currentUser.attributes ? currentUser.attributes.email : null}
                  </Typography>
                </ListItem>
              </>
            ) : null}
        </List>
        <Divider />
        <List>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <ListItem button onClick={this.itemClick()}>
              <ListItemIcon>
                <DashboardTwoTone />
              </ListItemIcon>  
              <ListItemText primary="Dashboard" />
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List
          component="nav"
          subheader={
            <ListSubheader component="div" disableSticky={true}>
              Administration
            </ListSubheader>
          }
        >
          <Link to="/organization" style={{ textDecoration: 'none' }}>
            <ListItem button onClick={this.itemClick()}>
              <ListItemIcon>
                <AccountBalanceTwoTone />
              </ListItemIcon>  
              <ListItemText primary="Organization Settings" />
            </ListItem>
          </Link>
          <Link to="/billing" style={{ textDecoration: 'none' }}>
            <ListItem button onClick={this.itemClick()}>
              <ListItemIcon>
                <AttachMoneyTwoTone />
              </ListItemIcon>  
              <ListItemText primary="Billing" />
            </ListItem>
          </Link>
          <Link to="/user-management" style={{ textDecoration: 'none' }}>
            <ListItem button onClick={this.itemClick()}>
              <ListItemIcon>
                <PersonAddTwoTone />
              </ListItemIcon>  
              <ListItemText primary="User Management" />
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List
          component="nav"
          subheader={
            <ListSubheader component="div" disableSticky={true}>
              Operations
            </ListSubheader>
          }
        >
          <Link to="/water-systems" style={{ textDecoration: 'none' }}>
            <ListItem button onClick={this.itemClick()}>
              <ListItemIcon>
                <BuildTwoTone />
              </ListItemIcon>  
              <ListItemText primary="Water Systems" />
            </ListItem>
          </Link>
          <Link to="/production-data" style={{ textDecoration: 'none' }}>
            <ListItem button onClick={this.itemClick()}>
              <ListItemIcon>
                <AssignmentTwoTone />
              </ListItemIcon>  
              <ListItemText primary="Production Data" />
            </ListItem>
          </Link>
          <Link to="/consumption-data" style={{ textDecoration: 'none' }}>
            <ListItem button onClick={this.itemClick()}>
              <ListItemIcon>
                <AvTimerTwoTone />
              </ListItemIcon>  
              <ListItemText primary="Consumption Data" />
            </ListItem>
          </Link>
          <Link to="/event-data" style={{ textDecoration: 'none' }}>
            <ListItem button onClick={this.itemClick()}>
              <ListItemIcon>
                <EventTwoTone />
              </ListItemIcon>  
              <ListItemText primary="Event Data" />
            </ListItem>
          </Link>
          <Link to="/reports" style={{ textDecoration: 'none' }}>
            <ListItem button onClick={this.itemClick()}>
              <ListItemIcon>
                <ShowChartTwoTone />
              </ListItemIcon>  
              <ListItemText primary="Reports" />
            </ListItem>
          </Link>
          <Link to="/maintenance-journal" style={{ textDecoration: 'none' }}>
            <ListItem button onClick={this.itemClick()}>
              <ListItemIcon>
                <NotesTwoTone />
              </ListItemIcon>  
              <ListItemText primary="Maintenance Journal" />
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List>
          <AuthStateContext.Consumer>
            {value => (
              <ListItem 
                button 
                onClick={async() => {
                  await Auth.signOut()
                  value.onStateChange('signedOut', null);
                }}>
                <ListItemIcon>
                  <ExitToAppTwoTone />
                </ListItemIcon>  
                <ListItemText primary="Logout" />
              </ListItem>
            )}
          </AuthStateContext.Consumer>
        </List>
      </>
    )
  }
}

AppDrawer.propTypes = {
  handleClick: PropTypes.func
}

export default AppDrawer