import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { styled } from '@material-ui/styles'

const commonStyle = {
  border: '1px solid black'
}

const cellStyle = {
  paddingLeft: 8,
  paddingRight: 8,
}

const Table = styled('table')({
  ...commonStyle
})

const TBody = styled('tbody')({
  ...commonStyle
})

const Tr = styled('tr')({
  ...commonStyle
})

const Td = styled('td')({ 
  ...commonStyle, 
  ...cellStyle 
})

const Th = styled('th')({ 
  ...commonStyle, 
  ...cellStyle 
})

function LossAnalysisTable(props) {
  return (
    <>
      <Grid style={{ overflowX: 'auto' }}>
        <Typography component="article">
          <Table>
            <TBody>
              <Tr>
                <Td>&nbsp;</Td>
                <Th>2017</Th>
                <Th>2018</Th>
                <Th>Difference</Th>
              </Tr>
              <Tr>
                <Td>Pumped Water kgal</Td>
                <Td>15,092</Td>
                <Td>9,183</Td>
                <Td>5,909</Td>
              </Tr>
              <Tr>
                <Td>Metered Water kgal</Td>
                <Td>13,334</Td>
                <Td>7,525</Td>
                <Td>5,809</Td>
              </Tr>
              <Tr>
                <Td>Water Loss kgal</Td>
                <Td>1,758</Td>
                <Td>1,658</Td>
                <Td>100</Td>
              </Tr>
              <Tr>
                <Td>% Loss</Td>
                <Td>11.6%</Td>
                <Td>18.1%</Td>
                <Td>6.5%</Td>
              </Tr>
            </TBody>
          </Table>
        </Typography>
      </Grid>
    </>
  )
}

export default LossAnalysisTable