import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'
import { API, graphqlOperation } from 'aws-amplify'

import * as queries from 'graphql/queries'
import * as mutations from 'graphql/mutations'
import ToolbarContent from 'components/ToolbarContent'
import ContentHeader from 'components/ContentHeader'
import ConfirmDeleteDialog from 'components/ConfirmDeleteDialog'
import { formatDate, getTimeString } from 'services/utilityFunctions'

const styles = theme => ({
  button: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  buttonDelete: {
    color: theme.palette.error.main 
  },
  verticalSpace: {
    marginBottom: theme.spacing.unit * 2,
    backgroundColor: 'transparent',
  },
})

const handleDelete = async id => {
  try {
    await API.graphql(graphqlOperation(mutations.deleteMaintenanceJournal, { input: { id } }))
  } catch (error) {
    console.log(error)
  }
}

function MaintenanceJournalView(props) {
  const {
    classes,
    history,
    location,
    match,
  } = props
  
  const { pwsId, maintenanceJournalId } = match.params
  const { pws, maintenanceJournal } = location.state || {}
  
  const initData = {
    pwsName: pws ? pws.name : '',
    modifiedBy: maintenanceJournal ? maintenanceJournal.modifiedBy : null,
    timestamp: maintenanceJournal ? maintenanceJournal.timestamp : null,
    text: maintenanceJournal ? maintenanceJournal.text : null,
  }

  const [pwsName, setPwsName] = useState(initData.pwsName)
  const [modifiedBy, setModifiedBy] = useState(initData.modifiedBy)
  const [timestamp, setTimestamp] = useState(initData.timestamp)
  const [text, setText] = useState(initData.text)

  const date = formatDate(timestamp ? timestamp.slice(0, 8) : null, 'Mmm dd, yyyy')
  const time = timestamp ? getTimeString(timestamp.slice(8, 10), timestamp.slice(10, 12)) : null

  let isMounted

  useEffect(() => {
    isMounted = true
    
    setStates()

    return () => {
      isMounted = false
    }
  }, [])

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [deleteInProgress, setDeleteInProgress] = useState(false)
  
  const [confirmDelete, setConfirmDelete] = useState(false)
  useEffect(() => {
    if (confirmDelete) {
      (async () => {
        setDeleteInProgress(true)
        try {
          await handleDelete(maintenanceJournal.id)
          history.goBack()
        } catch (error) {
          console.log(error)
        }
      })()
    }
  }, [confirmDelete])

  async function setStates() {
    const data = await getViewData() || {}
    
    if (!isMounted) return
    
    setPwsName(data.pwsName)
    setModifiedBy(data.modifiedBy)
    setTimestamp(data.timestamp)
    setText(data.text)
  }

  async function getViewData() {
    try {
      const resultPws = await API.graphql(graphqlOperation(queries.getWaterSystem, { id: pwsId }))
      const resultMaintenanceJournal = await API.graphql(graphqlOperation(queries.getMaintenanceJournal, { id: maintenanceJournalId }))
      const pws = resultPws.data.getWaterSystem
      const maintenanceJournal = resultMaintenanceJournal.data.getMaintenanceJournal
      
      if (!isMounted) return
      
      history.replace({
        state: { pws, maintenanceJournal }
      })
      
      return {
        pwsName: pws.name,
        modifiedBy: maintenanceJournal.modifiedBy,
        timestamp: maintenanceJournal.timestamp,
        text: maintenanceJournal.text,
      }

    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <ToolbarContent
        screenTitle="Maintenance Journal View"
        isMainScreen={false}
      />
      <ContentHeader
        breadcrumbs={[
          { name: 'Maintenance Journal', goBack: true, step: 2 },
          { name: pwsName, goBack: true, step: 1 }, 
          { name: "View" } 
        ]}
        history={history}
      />
      <Typography>
        {date} {time}
      </Typography>
      <Typography
        variant="caption"
        gutterBottom
      >
        Last modified by: {modifiedBy}
      </Typography>
      <Typography 
        variant="subtitle1"
        style={{ whiteSpace: 'pre-line', lineHeight: '1rem' }}
      >
        {text}
      </Typography>
      <Divider className={classes.verticalSpace}/>
      <Button
        className={classes.button}
        variant="contained"
        fullWidth
        onClick={() => {
          history.push({
            pathname: location.pathname.replace('view', 'edit'),
            state: { maintenanceJournal, pws },
          })
        }}
      >
        EDIT
      </Button>
      <Button
        className={`${classes.button} ${classes.buttonDelete}`}
        fullWidth
        disabled={deleteInProgress}
        onClick={() => {
          setOpenDeleteDialog(true)
        }}
      >
        DELETE
      </Button>
      <ConfirmDeleteDialog 
        open={openDeleteDialog} 
        setOpenDialog={setOpenDeleteDialog} 
        setConfirm={setConfirmDelete} 
        objectName="Maintenance Journal"
        fieldDescription="word" 
        actionText="Delete"
        confirmText="CONFIRM"
        cancelText="BACK"
        defaultValue="DELETE"
      />
    </>
  )
}

MaintenanceJournalView.defaultProps = {
  error: false,
}

MaintenanceJournalView.propTypes = {
  error: PropTypes.bool,
}

export default withStyles(styles)(MaintenanceJournalView)