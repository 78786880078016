import React from 'react'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles'

import ToolbarContent from 'components/ToolbarContent'
import { tiersList } from 'services/constantVariables/subscriptionTier'

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
    overflowX: 'auto',
  },
})

function PriceChart(props) {
  const {
    classes,
  } = props

  return (
    <>
      <ToolbarContent
        screenTitle = "Price Chart"
        isMainScreen = {false}
      />
      <Paper className={classes.root}>
        <Table padding="dense">
          <TableHead>
            <TableRow>
              <TableCell>Tier</TableCell>
              <TableCell align="center">Active Connections</TableCell>
              <TableCell align="center">Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tiersList.map(tier => (
              <TableRow key={tier.id}>
                <TableCell component="th" scope="row" align="center">
                  {tier.id}
                </TableCell>
                <TableCell align="center">
                  {tier.description}
                </TableCell>
                <TableCell align="center">
                  {tier.price}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  )
}

export default withStyles(styles)(PriceChart) 