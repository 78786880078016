import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import ConsumptionIcon from '@material-ui/icons/AvTimer'

import InfoCard from 'components/InfoCard'

function Consumption(props) {
  const {
    conVolume,
    unit
  } = props

  return (
    <>
      <InfoCard 
        Icon={ConsumptionIcon}
        color="purple"
        title="Consumption"
        bodyText={conVolume ? `${conVolume} ${unit}` : <Skeleton />}
        caption=""
        linkHref=""
        linkText=""
      />
    </>
  )
}

Consumption.propTypes = {
  conVolume: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired
}

export default Consumption