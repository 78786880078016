// eslint-disable
// this is an auto generated file. This will be overwritten

export const getEvent = `query GetEvent($id: ID!) {
  getEvent(id: $id) {
    id
    waterSystemId
    name
    type
    estimatedLoss
    estimatedLossUnit
    estimatedLossNormal
    startTimestamp
    endTimestamp
    location
    notes
    unitNormalizer
  }
}
`;
export const listEvents = `query ListEvents(
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      waterSystemId
      name
      type
      estimatedLoss
      estimatedLossUnit
      estimatedLossNormal
      startTimestamp
      endTimestamp
      location
      notes
      unitNormalizer
    }
    nextToken
  }
}
`;
export const getMaintenanceJournal = `query GetMaintenanceJournal($id: ID!) {
  getMaintenanceJournal(id: $id) {
    id
    waterSystemId
    modifiedBy
    timestamp
    text
  }
}
`;
export const listMaintenanceJournals = `query ListMaintenanceJournals(
  $filter: ModelMaintenanceJournalFilterInput
  $limit: Int
  $nextToken: String
) {
  listMaintenanceJournals(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      waterSystemId
      modifiedBy
      timestamp
      text
    }
    nextToken
  }
}
`;
export const getOrganization = `query GetOrganization($id: ID!) {
  getOrganization(id: $id) {
    id
    name
    description
    logo
    customerId
    billingStatus
    mailingAddress {
      street
      street2
      city
      state
      zip
      country
    }
    users {
      items {
        id
        email
        firstName
        lastName
        company
        title
        phone
        phone2
        userPoolId
        organizationId
        status
      }
      nextToken
    }
    joinRequest {
      id
      userId
      user {
        id
        email
        firstName
        lastName
        company
        title
        phone
        phone2
        userPoolId
        organizationId
        status
      }
      organizationId
      organization {
        id
        name
        description
        logo
        customerId
        billingStatus
      }
      state
      timestamp
    }
  }
}
`;
export const listOrganizations = `query ListOrganizations(
  $filter: ModelOrganizationFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      logo
      customerId
      billingStatus
      mailingAddress {
        street
        street2
        city
        state
        zip
        country
      }
      users {
        nextToken
      }
      joinRequest {
        id
        userId
        organizationId
        state
        timestamp
      }
    }
    nextToken
  }
}
`;
export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    email
    firstName
    lastName
    company
    title
    phone
    phone2
    userPoolId
    organizationId
    status
    organization {
      id
      name
      description
      logo
      customerId
      billingStatus
      mailingAddress {
        street
        street2
        city
        state
        zip
        country
      }
      users {
        nextToken
      }
      joinRequest {
        id
        userId
        organizationId
        state
        timestamp
      }
    }
    joinRequest {
      id
      userId
      user {
        id
        email
        firstName
        lastName
        company
        title
        phone
        phone2
        userPoolId
        organizationId
        status
      }
      organizationId
      organization {
        id
        name
        description
        logo
        customerId
        billingStatus
      }
      state
      timestamp
    }
  }
}
`;
export const listUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      email
      firstName
      lastName
      company
      title
      phone
      phone2
      userPoolId
      organizationId
      status
      organization {
        id
        name
        description
        logo
        customerId
        billingStatus
      }
      joinRequest {
        id
        userId
        organizationId
        state
        timestamp
      }
    }
    nextToken
  }
}
`;
export const getJoinRequest = `query GetJoinRequest($id: ID!) {
  getJoinRequest(id: $id) {
    id
    userId
    user {
      id
      email
      firstName
      lastName
      company
      title
      phone
      phone2
      userPoolId
      organizationId
      status
      organization {
        id
        name
        description
        logo
        customerId
        billingStatus
      }
      joinRequest {
        id
        userId
        organizationId
        state
        timestamp
      }
    }
    organizationId
    organization {
      id
      name
      description
      logo
      customerId
      billingStatus
      mailingAddress {
        street
        street2
        city
        state
        zip
        country
      }
      users {
        nextToken
      }
      joinRequest {
        id
        userId
        organizationId
        state
        timestamp
      }
    }
    state
    timestamp
  }
}
`;
export const listJoinRequests = `query ListJoinRequests(
  $filter: ModelJoinRequestFilterInput
  $limit: Int
  $nextToken: String
) {
  listJoinRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      user {
        id
        email
        firstName
        lastName
        company
        title
        phone
        phone2
        userPoolId
        organizationId
        status
      }
      organizationId
      organization {
        id
        name
        description
        logo
        customerId
        billingStatus
      }
      state
      timestamp
    }
    nextToken
  }
}
`;
export const getUserInvite = `query GetUserInvite($id: ID!) {
  getUserInvite(id: $id) {
    id
    organizationId
    email
    state
    remarks
  }
}
`;
export const listUserInvites = `query ListUserInvites(
  $filter: ModelUserInviteFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserInvites(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      organizationId
      email
      state
      remarks
    }
    nextToken
  }
}
`;
export const getWaterSystem = `query GetWaterSystem($id: ID!) {
  getWaterSystem(id: $id) {
    id
    organizationId
    name
    activeConnections
    activeConnectionsValidity
    inactiveConnections
    inactiveConnectionsValidity
    waterMainLen
    waterMainLenValidity
    percentFlexiblePipe
    aveSysPressure
    aveSysPressureValidity
    avePipeLength
    isFullyMetered
    hasProgram
    aveMeterAge
    subscriptionId
    subscribed
    waterSources {
      items {
        id
        name
        type
        units
        isMetered
        estimate
        meterSize
        meterMakeModel
        meterInstallYear
        meterCalibDate
        waterSystemId
        sourceImage
        installImage
        latitude
        longitude
      }
      nextToken
    }
    costInputs {
      id
      waterSystemId
      waterSystem {
        id
        organizationId
        name
        activeConnections
        activeConnectionsValidity
        inactiveConnections
        inactiveConnectionsValidity
        waterMainLen
        waterMainLenValidity
        percentFlexiblePipe
        aveSysPressure
        aveSysPressureValidity
        avePipeLength
        isFullyMetered
        hasProgram
        aveMeterAge
        subscriptionId
        subscribed
      }
      aveRetailUnitCost
      unit
      aveRetailUnitCostNormal
      annualElectricBill
      annualChemicalBill
      totalAnnualCost
      productionCost
      productionCostUnit
      productionCostNormal
      annualProductionVolume
      annualProductionVolumeNormal
      productionOverride
      unitNormalizer
    }
  }
}
`;
export const listWaterSystems = `query ListWaterSystems(
  $filter: ModelWaterSystemFilterInput
  $limit: Int
  $nextToken: String
) {
  listWaterSystems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      organizationId
      name
      activeConnections
      activeConnectionsValidity
      inactiveConnections
      inactiveConnectionsValidity
      waterMainLen
      waterMainLenValidity
      percentFlexiblePipe
      aveSysPressure
      aveSysPressureValidity
      avePipeLength
      isFullyMetered
      hasProgram
      aveMeterAge
      subscriptionId
      subscribed
      waterSources {
        nextToken
      }
      costInputs {
        id
        waterSystemId
        aveRetailUnitCost
        unit
        aveRetailUnitCostNormal
        annualElectricBill
        annualChemicalBill
        totalAnnualCost
        productionCost
        productionCostUnit
        productionCostNormal
        annualProductionVolume
        annualProductionVolumeNormal
        productionOverride
        unitNormalizer
      }
    }
    nextToken
  }
}
`;
export const getWaterSource = `query GetWaterSource($id: ID!) {
  getWaterSource(id: $id) {
    id
    name
    type
    units
    isMetered
    estimate
    meterSize
    meterMakeModel
    meterInstallYear
    meterCalibDate
    waterSystemId
    sourceImage
    installImage
    latitude
    longitude
    waterSystem {
      id
      organizationId
      name
      activeConnections
      activeConnectionsValidity
      inactiveConnections
      inactiveConnectionsValidity
      waterMainLen
      waterMainLenValidity
      percentFlexiblePipe
      aveSysPressure
      aveSysPressureValidity
      avePipeLength
      isFullyMetered
      hasProgram
      aveMeterAge
      subscriptionId
      subscribed
      waterSources {
        nextToken
      }
      costInputs {
        id
        waterSystemId
        aveRetailUnitCost
        unit
        aveRetailUnitCostNormal
        annualElectricBill
        annualChemicalBill
        totalAnnualCost
        productionCost
        productionCostUnit
        productionCostNormal
        annualProductionVolume
        annualProductionVolumeNormal
        productionOverride
        unitNormalizer
      }
    }
  }
}
`;
export const listWaterSources = `query ListWaterSources(
  $filter: ModelWaterSourceFilterInput
  $limit: Int
  $nextToken: String
) {
  listWaterSources(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      type
      units
      isMetered
      estimate
      meterSize
      meterMakeModel
      meterInstallYear
      meterCalibDate
      waterSystemId
      sourceImage
      installImage
      latitude
      longitude
      waterSystem {
        id
        organizationId
        name
        activeConnections
        activeConnectionsValidity
        inactiveConnections
        inactiveConnectionsValidity
        waterMainLen
        waterMainLenValidity
        percentFlexiblePipe
        aveSysPressure
        aveSysPressureValidity
        avePipeLength
        isFullyMetered
        hasProgram
        aveMeterAge
        subscriptionId
        subscribed
      }
    }
    nextToken
  }
}
`;
export const getApiAction = `query GetApiAction($id: ID!) {
  getAPIAction(id: $id) {
    id
    name
    parameters
    result
    disableUserResolver
    stripeCreateCustomerResolver
    stripeUpdateCustomerResolver
    stripeRetrieveCustomerResolver
    stripeSubscribeToPlanResolver
    stripeCancelSubscription
    stripeCardCreateResolver
    stripeDeleteCardResolver
    stripeListTransactionsResolver
    stripeCouponVerify
    stripeQuanUpdateSub
    stripeProrationPreview
    getProdAndConsDatesResolver
    getReportDataResolver
    getAnnualProduction
    getProdConOneMonthPrior
  }
}
`;
export const listApiActions = `query ListApiActions(
  $filter: ModelAPIActionFilterInput
  $limit: Int
  $nextToken: String
) {
  listAPIActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      parameters
      result
      disableUserResolver
      stripeCreateCustomerResolver
      stripeUpdateCustomerResolver
      stripeRetrieveCustomerResolver
      stripeSubscribeToPlanResolver
      stripeCancelSubscription
      stripeCardCreateResolver
      stripeDeleteCardResolver
      stripeListTransactionsResolver
      stripeCouponVerify
      stripeQuanUpdateSub
      stripeProrationPreview
      getProdAndConsDatesResolver
      getReportDataResolver
      getAnnualProduction
      getProdConOneMonthPrior
    }
    nextToken
  }
}
`;
export const getTrial = `query GetTrial($id: ID!) {
  getTrial(id: $id) {
    id
    organizationId
    trialName
    length
    endDate
    daysLeft
  }
}
`;
export const listTrials = `query ListTrials(
  $filter: ModelTrialFilterInput
  $limit: Int
  $nextToken: String
) {
  listTrials(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      organizationId
      trialName
      length
      endDate
      daysLeft
    }
    nextToken
  }
}
`;
export const getSourceProduction = `query GetSourceProduction($id: ID!) {
  getSourceProduction(id: $id) {
    id
    waterSourceId
    waterSystemId
    installImage
    type
    timestamp
    startDate
    endDate
    pumpRunTime
    reading
    unit
    readingNormal
    volume
    volumeUnit
    volumeNormal
    unitNormalizer
  }
}
`;
export const listSourceProductions = `query ListSourceProductions(
  $filter: ModelSourceProductionFilterInput
  $limit: Int
  $nextToken: String
) {
  listSourceProductions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      waterSourceId
      waterSystemId
      installImage
      type
      timestamp
      startDate
      endDate
      pumpRunTime
      reading
      unit
      readingNormal
      volume
      volumeUnit
      volumeNormal
      unitNormalizer
    }
    nextToken
  }
}
`;
export const getConfig = `query GetConfig($id: ID!) {
  getConfig(id: $id) {
    name
    value
  }
}
`;
export const listConfigs = `query ListConfigs(
  $filter: ModelConfigFilterInput
  $limit: Int
  $nextToken: String
) {
  listConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      name
      value
    }
    nextToken
  }
}
`;
export const getConsumptionAndUse = `query GetConsumptionAndUse($id: ID!) {
  getConsumptionAndUse(id: $id) {
    id
    waterSystemId
    startDate
    endDate
    meteredBilledConsumption
    meteredUnbilledConsumption
    unmeteredBilledConsumption
    unmeteredUnbilledConsumption
    unit
    meteredBilledConsumptionNormal
    meteredUnbilledConsumptionNormal
    unmeteredBilledConsumptionNormal
    unmeteredUnbilledConsumptionNormal
    unitNormalizer
  }
}
`;
export const listConsumptionAndUses = `query ListConsumptionAndUses(
  $filter: ModelConsumptionAndUseFilterInput
  $limit: Int
  $nextToken: String
) {
  listConsumptionAndUses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      waterSystemId
      startDate
      endDate
      meteredBilledConsumption
      meteredUnbilledConsumption
      unmeteredBilledConsumption
      unmeteredUnbilledConsumption
      unit
      meteredBilledConsumptionNormal
      meteredUnbilledConsumptionNormal
      unmeteredBilledConsumptionNormal
      unmeteredUnbilledConsumptionNormal
      unitNormalizer
    }
    nextToken
  }
}
`;
export const getCostInputs = `query GetCostInputs($id: ID!) {
  getCostInputs(id: $id) {
    id
    waterSystemId
    waterSystem {
      id
      organizationId
      name
      activeConnections
      activeConnectionsValidity
      inactiveConnections
      inactiveConnectionsValidity
      waterMainLen
      waterMainLenValidity
      percentFlexiblePipe
      aveSysPressure
      aveSysPressureValidity
      avePipeLength
      isFullyMetered
      hasProgram
      aveMeterAge
      subscriptionId
      subscribed
      waterSources {
        nextToken
      }
      costInputs {
        id
        waterSystemId
        aveRetailUnitCost
        unit
        aveRetailUnitCostNormal
        annualElectricBill
        annualChemicalBill
        totalAnnualCost
        productionCost
        productionCostUnit
        productionCostNormal
        annualProductionVolume
        annualProductionVolumeNormal
        productionOverride
        unitNormalizer
      }
    }
    aveRetailUnitCost
    unit
    aveRetailUnitCostNormal
    annualElectricBill
    annualChemicalBill
    totalAnnualCost
    productionCost
    productionCostUnit
    productionCostNormal
    annualProductionVolume
    annualProductionVolumeNormal
    productionOverride
    unitNormalizer
  }
}
`;
export const listCostInputss = `query ListCostInputss(
  $filter: ModelCostInputsFilterInput
  $limit: Int
  $nextToken: String
) {
  listCostInputss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      waterSystemId
      waterSystem {
        id
        organizationId
        name
        activeConnections
        activeConnectionsValidity
        inactiveConnections
        inactiveConnectionsValidity
        waterMainLen
        waterMainLenValidity
        percentFlexiblePipe
        aveSysPressure
        aveSysPressureValidity
        avePipeLength
        isFullyMetered
        hasProgram
        aveMeterAge
        subscriptionId
        subscribed
      }
      aveRetailUnitCost
      unit
      aveRetailUnitCostNormal
      annualElectricBill
      annualChemicalBill
      totalAnnualCost
      productionCost
      productionCostUnit
      productionCostNormal
      annualProductionVolume
      annualProductionVolumeNormal
      productionOverride
      unitNormalizer
    }
    nextToken
  }
}
`;
export const searchOrganizations = `query SearchOrganizations(
  $filter: SearchableOrganizationFilterInput
  $sort: SearchableOrganizationSortInput
  $limit: Int
  $nextToken: Int
) {
  searchOrganizations(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      logo
      customerId
      billingStatus
      mailingAddress {
        street
        street2
        city
        state
        zip
        country
      }
      users {
        nextToken
      }
      joinRequest {
        id
        userId
        organizationId
        state
        timestamp
      }
    }
    nextToken
  }
}
`;
