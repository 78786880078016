import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { Elements, StripeProvider } from 'react-stripe-elements'

import withUserData from 'components/withUserData'
import CheckoutForm from 'components/CheckoutForm'

function PaymentMethodAdd(props) {
  const {
    userContext,
    onComplete,
  } = props

  const { email, organizationId } = userContext.userData
  const { customerId } = userContext.userData.organization

  if (!process.env.REACT_APP_STRIPE_API_KEY) {
    return (
      <>
        Error on configuration
      </>
    )
  }

  return (
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
      <div>
        <Typography variant="h6">
          Add Payment Method
        </Typography>
        <Elements>
          <CheckoutForm 
            email={email}
            organizationId={organizationId}
            customerId={customerId}
            handleOnComplete={() => onComplete()} 
          />
        </Elements>
      </div>
    </StripeProvider>
  )
}

PaymentMethodAdd.defaultProps = {
  onComplete: () => {},
}

PaymentMethodAdd.propTypes = {
  onComplete: PropTypes.func,
}

export default withUserData(PaymentMethodAdd)