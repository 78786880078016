import React from 'react'
import Grid from '@material-ui/core/Grid'

import ToolbarContent from 'components/ToolbarContent'
import ContentHeader from 'components/ContentHeader'
import WaterSourcesList from 'scenes/WaterSystems/scenes/WaterSources/components/WaterSourcesList'

function ProductionDataSourcesList(props) {
  const {
    history,
    location,
    match,
  } = props

  const { pws } = location.state

  return (
    <Grid>
      <ToolbarContent
        screenTitle = {`Production Data`}
        isMainScreen = {false}
      />
      <ContentHeader
        breadcrumbs={[
          { name: 'Production Data', goBack: true, step: 1 },
          { name: pws.name } 
        ]}
        history={history}
      />
      <WaterSourcesList 
        pwsId={pws.id} 
        onItemClick={source => history.push({
          pathname: match.path.replace('pws/:pwsId', `source/${source.id}`),
          state: { pwsName: pws.name, source }  
        })}
      />
    </Grid>
  )
}

export default ProductionDataSourcesList