import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { ToolbarContext } from 'contexts'

function ToolbarContent(props) {
  const { 
    screenTitle,
    isMainScreen,
    renderIcon
  } = props
  
  const { 
    title, 
    update, 
    isMainScreen: isMainScreenCtx 
  } = useContext(ToolbarContext)

  if (
    (title === props.screenTitle && isMainScreen === isMainScreenCtx) 
    || !update
  ) {
    return null
  }

  update(screenTitle, isMainScreen, renderIcon)

  return <React.Fragment/>
}

ToolbarContent.defaultProps = {
  renderIcon: true,
}

ToolbarContent.propTypes = {
  screenTitle: PropTypes.string.isRequired,
  isMainScreen: PropTypes.bool.isRequired,
  renderIcon: PropTypes.bool,
}

export default ToolbarContent