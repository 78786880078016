import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'

import SubdivisionTable from './components/SubdivisionTable'

const useStyles = makeStyles({
  root: {
    marginTop: 70,
    marginBottom: 20,
  },
  gap: {
    background: 'transparent',
    height: 20,
  }
})

function Cover(props) {
  const {
    pwsName,
    timePeriod,
  } = props
  
  const classes = useStyles()

  return (
    <>
      <Grid className={classes.root}>
        <Typography 
          variant="h5"
          align="center"
        >
          {pwsName}
        </Typography>
        <Typography 
          variant="h5"
          align="center"
        >
          Water Loss Control Report
        </Typography>
        <Typography 
          variant="h6"
          align="center"
          gutterBottom
        >
          {timePeriod}
        </Typography>
        <Divider className={classes.gap} />
        <SubdivisionTable />
      </Grid>
    </>
  )
}

Cover.propTypes = {
  pwsName: PropTypes.string.isRequired,
  timePeriod: PropTypes.string.isRequired,
}

export default Cover