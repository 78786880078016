import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import {CardElement, injectStripe} from 'react-stripe-elements'
import { compose } from 'recompose'
import { API, graphqlOperation } from 'aws-amplify'

import * as mutations from 'graphql/mutations'
import { UserContext } from 'contexts'
import withUserData from 'components/withUserData'

const styles = () => ({
  button: {
    marginTop: 10,
  }
})

const handleSubmit = async (stripe, organizationId, email, customerId, setLoading, setUpdateUserContext, userData, setUserData, handleOnComplete) => {
  setLoading(true)
  let { token } = await stripe.createToken()
  if (!token) return setLoading(false)

  let inputApiAction = {
    name: '',
    parameters: [],
  }
  
  if (customerId) {
    inputApiAction.name = 'stripeCardCreate'
    inputApiAction.parameters = [
      customerId,
      token.id,
    ]
  } else {
    inputApiAction.name = 'stripeCreateCustomer'
    inputApiAction.parameters = [
      organizationId,
      email,
      token.id,
    ]
  }

  try {
    const actionResult = await API.graphql(graphqlOperation(mutations.createApiAction, { input: inputApiAction } ))
    
    //update User context
    if (!customerId) {
      const customerJSON = actionResult.data.createAPIAction.stripeCreateCustomerResolver
      const customer = JSON.parse(customerJSON)
      userData.organization.customerId = customer.id
      setUserData(userData)
      setUpdateUserContext(true)
    }

    handleOnComplete()
  } catch (error) { 
    console.log(error)
  }
  setLoading(false)
}

function CheckoutForm(props) {
  const {
    classes,
    email,
    organizationId,
    customerId,
    stripe,
    handleOnComplete,
    userContext,
  } = props

  const [loading, setLoading] = useState(false)
  const [updateUserContext, setUpdateUserContext] = useState(false)
  const [userData, setUserData] = useState(userContext.userData)

  let isMounted

  useEffect(() => {
    isMounted = true  
    
    if (!isMounted) return
    if (setUpdateUserContext) setUpdateUserContext(false)

    return () => {
      isMounted = false
    }
  }, [updateUserContext])
  
  return (
    <>
      <p>Card Details</p>
      {updateUserContext ? (
        <UserContext.Consumer>
          {({ updateUserData }) => {
            updateUserData(userData)
          }}
        </UserContext.Consumer>
      ): null}
      <CardElement />
      <Button 
        className={classes.button}
        variant="contained"
        color="primary"
        disabled={loading}
        fullWidth
        onClick={async e => {
          await handleSubmit(stripe, organizationId, email, customerId, setLoading, setUpdateUserContext, userData, setUserData, handleOnComplete)
        }}
      >
        {loading ? <CircularProgress color="inherit" size={16} style={{ marginRight: 5 }} /> : null}
        CONFIRM
      </Button>
    </>
  )
}

CheckoutForm.propTypes = {
  email: PropTypes.string,
  organizationId: PropTypes.string,
  customerId: PropTypes.string,
  handleOnComplete: PropTypes.func,
}

export default compose(
  injectStripe,
  withUserData,
  withStyles(styles),
)(CheckoutForm)