import React from 'react'

import ToolbarContent from 'components/ToolbarContent'
import ReportContents from './components/ReportContents'

function ReportRun(props) {

  return (
    <>
      <ToolbarContent
        screenTitle = 'Run Report'
        isMainScreen = {false}
      />
      <ReportContents {...props} />
    </>
  )
}

export default ReportRun