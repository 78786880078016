import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'


class DevDebug extends Component {

	render() {
		if(process.env.NODE_ENV !== "development")
			return <Redirect to={{ pathname: `/`, }} />

		return (
			<Card>
				<CardContent>
					<h3>DevDebug</h3>
					<div>
						NODE_ENV: {process.env.NODE_ENV}
					</div>

					<div>
						REACT_APP_STRIPE_API_KEY: {process.env.REACT_APP_STRIPE_API_KEY}
					</div> 
				</CardContent>
			</Card>
		)
		
	}

}

export default DevDebug