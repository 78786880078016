import React, { useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { API, graphqlOperation } from 'aws-amplify'

import * as mutations from 'graphql/mutations'
import ToolbarContent from 'components/ToolbarContent'
import ContentHeader from 'components/ContentHeader'
import ConsumptionAndUseForm from '../../components/ConsumptionAndUseForm'

const styles = theme => ({
  button: {
    marginBottom: 16,
  },
  buttonContainer: {
    position: 'sticky',
    bottom: 0,
    paddingTop: 16,
    background: '#fff'
  }
})

const handleRecord = async args => {
  const {
    waterSystemId,
    startDate,
    endDate,
    meteredBilledConsumption,
    meteredUnbilledConsumption,
    unmeteredBilledConsumption,
    unmeteredUnbilledConsumption,
    unit,
  } = args

  const inputCreateConsumptionAndUse = {
    waterSystemId,
    startDate,
    endDate,
    meteredBilledConsumption,
    meteredUnbilledConsumption,
    unmeteredBilledConsumption,
    unmeteredUnbilledConsumption,
    unit,
  }
  
  try {
    const result = await API.graphql(graphqlOperation(mutations.createConsumptionAndUse, { input: inputCreateConsumptionAndUse }))
    const consumption = result.data.createConsumptionAndUse
    
    return consumption
  } catch (error) {
    return console.log(error)  
  }
}

function ConsumptionAndUse(props) {
  const {
    classes,
    history,
    location,
  } = props

  const { pws } = location.state
  const [consumption, setConsumption] = useState()
  const [error, setError] = useState(false)
  
  const [loading, setLoading] = useState(false)

  return (
    <>
      <ToolbarContent
        screenTitle="New Consumption Record"
        isMainScreen={false}
      />
      <ContentHeader
        breadcrumbs={[
          { name: 'Consumption Data', goBack: true, step: 2 },
          { name: pws.name, goBack: true, step: 1 }, 
          { name: 'New' }, 
        ]}
        history={history}
      />
      <ConsumptionAndUseForm 
        waterSystemId={pws.id}
        setConsumption={setConsumption} 
        error={error}
      />
      <Grid className={classes.buttonContainer}>
        <Button
          className={classes.button}
          disabled={loading}
          onClick={async () => {
            const {
              waterSystemId,
              startDate,
              endDate,
            } = consumption

            if (!(waterSystemId && startDate && endDate)) {
              return setError(true)
            }

            setLoading(true)
            const newConsumption = await handleRecord(consumption)
            
            if (!newConsumption) return
            
            setLoading(false)
            history.replace({
              pathname: location.pathname.replace('new', `view/${newConsumption.id}`),
              state: { 
                pws, 
                consumption: newConsumption 
              }
            })
          }}
          variant="contained"
          color="primary"  
          fullWidth
        >
          {loading ? <CircularProgress color="inherit" size={16} style={{ marginRight: 5 }} /> : null}
          RECORD IN DATABASE
        </Button>
      </Grid>
    </>
  )
}


export default withStyles(styles)(ConsumptionAndUse)