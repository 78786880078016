import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import ToolbarContent from '../ToolbarContent'

const styles = theme => ({
  main: {
    height: 'calc(100vh - 86px)',
  },
  content: {
    maxHeight: 'calc(100vh - 130px)',
    overflowY: 'scroll',
    paddingTop: 50,
  },
})

class AppErrorBoundary extends Component {
  state = {
    hasError: false
  }
  
  componentDidCatch(error, info) {
    this.setState({ hasError: true })
    
    // log error
    // log(error, log)
  }

  render() {
    const { 
      classes,
      children,
    } = this.props

    if (this.state.hasError) {
      return (
        <>
          <ToolbarContent
            screenTitle = "Application Error"
            isMainScreen = {false}
            renderIcon = {false}
          />
          <Grid className={classes.main} container direction="column" justify="space-between">
            <Grid className={classes.content}>
              <Typography variant="h5">Sorry, something went wrong</Typography>
              <Typography>Please go back and try again.</Typography>
            </Grid>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              component={Link}
              to="/"
              onClick={() => this.setState({ hasError: false })}
              fullWidth
            >
              GO TO DASHBOARD
            </Button>
          </Grid>
        </>
      )
    }

    return children
  }
}

export default withStyles(styles)(AppErrorBoundary)