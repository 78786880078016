import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { styled } from '@material-ui/styles'

const commonStyle = {
  border: '1px solid black'
}

const cellStyle = {
  paddingLeft: 8,
  paddingRight: 8,
}

const Table = styled('table')({
  ...commonStyle
})

const TBody = styled('tbody')({
  ...commonStyle
})

const Tr = styled('tr')({
  ...commonStyle
})

const Td = styled('td')({ 
  ...commonStyle, 
  ...cellStyle 
})

const Th = styled('th')({ 
  ...commonStyle, 
  ...cellStyle 
})

function ILIRangeTable(props) {
  return (
    <>
      <Grid style={{ overflowX: 'auto' }}>
        <Typography component="article">
          <Table>
            <TBody>
              <Tr>
                <Th>Target ILI Range</Th>
                <Th>Water Resources Considerations</Th>
                <Th>Financial Considerations</Th>
                <Th>Operational Considerations</Th>
              </Tr>
              <Tr>
                <Td>1.0 – 3.0</Td>
                <Td>Water resources are greatly limited</Td>
                <Td>Water resources are costly to develop or purchase</Td>
                <Td>Existing supply infrastructure is limited, the system cannot handle much leakage, every drop counts!</Td>
              </Tr>
              <Tr>
                <Td>3.0 – 5.0</Td>
                <Td>Water resources are sufficient </Td>
                <Td>Water resources can be developed at reasonable expense</Td>
                <Td>Existing supply infrastructure is sufficient</Td>
              </Tr>
              <Tr>
                <Td>5.0 – 8.0</Td>
                <Td>Water resources are plentiful</Td>
                <Td>Cost to obtain or treat water is low</Td>
                <Td>Superior reliability, capacity and integrity of infrastructure </Td>
              </Tr>
              <Tr>
                <Td>Greater than 8.0</Td>
                <Td colSpan={3}>Your system leaks like a sieve! Contamination and poor service reliability would be a concern.</Td>
              </Tr>
              <Tr>
                <Td>Less than 1.0</Td>
                <Td colSpan={3}>Either your system has world class low leakage levels, your data is be flawed, low water pressure, or a very well run small system.</Td>
              </Tr>
            </TBody>
          </Table>
        </Typography>
      </Grid>
    </>
  )
}

export default ILIRangeTable