import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import CircularProgress from '@material-ui/core/CircularProgress'

import ToolbarContent from 'components/ToolbarContent'
import { UserContext } from 'contexts'
import * as queries from 'graphql/queries'
import routes from 'routes'
import AppErrorBoundary from 'components/AppErrorBoundary'

const styles = () => ({
  root: {
    padding: 16,
    maxWidth: 800,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  centerWrapper: {
    paddingTop: 140,
    textAlign: 'center',
  },
})

class AppContent extends Component{
  state = {
    userContextValue: {
      currentUser: {},
      userData: {},
      updateUserData: (userData) => {
        this.updateUserData(userData)
      },
    },
    checkComplete: false,
  }

  updateUserData = (userData) => {
    this.setState(prevState => ({
      userContextValue: {
        ...prevState.userContextValue,
        userData,
      },
    }))
  }

  async componentDidMount() {
    const currentUser = await Auth.currentAuthenticatedUser()
    const queryUser = await API.graphql(graphqlOperation(queries.getUser, {id: currentUser.attributes.sub}))
    const userData = queryUser.data.getUser
    await this.setState(prevState => ({
      userContextValue: {
        ...prevState.userContextValue,
        currentUser,
        userData,
      },
      checkComplete: true,
    }))
  }

  render() {
    const { classes } = this.props
    const { userContextValue, checkComplete } = this.state
    const { userData } = userContextValue

    if(!checkComplete) {
      return (
        <div className={classes.root}>
          <ToolbarContent
            screenTitle = "Checking"
            isMainScreen = {false}
            renderIcon = {false}
          />
          <div className={classes.centerWrapper} >
            <CircularProgress />
          </div>
        </div>
      )
    }

    return (
      <div className={classes.root}>
        <AppErrorBoundary>
          <UserContext.Provider value={userContextValue}>
            <Switch>
              {/* If user already belongs to an organization, block user from visiting onboarding by redirect */}
              {/* If user has no organization, block user from using parts of the app and redirect to onboarding */}
              {userData.organizationId ? 
                <Redirect from="/onboarding" to="/" /> 
              : (
                routes.map((route, index) => (
                  route.path && route.path.substring(0, 11) !== "/onboarding" ? (
                    <Redirect key={index} from={route.path} to="/onboarding" exact />
                  ) : null
                ))
              )}
              <Redirect from="/index.html" to="/" exact />
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  render={route.component}
                />
              ))}
            </Switch>
          </UserContext.Provider>
        </AppErrorBoundary>
      </div>
    )
  }
}

export default withStyles(styles)(AppContent)