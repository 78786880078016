import React, { useState, useEffect, } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { API, graphqlOperation } from 'aws-amplify'

import * as mutations from 'graphql/mutations.js'
import { removeEmpty } from 'services/graphqlHelpers/'
import ToolbarContent from 'components/ToolbarContent'
import ImagePicker from 'components/ImagePicker'
import WaterSourceForm from '../../components/WaterSourceForm'

const styles = theme => ({
  buttonSave: {
    margin: 'auto',
    marginBottom: 16,
  },
  buttonContainer: {
    position: 'sticky',
    bottom: 0,
    paddingTop: 16,
    background: '#fff'
  },
  label: {
    marginBottom: 10,
  }
})

const handleSave = async(newValues, setTarget, setSaveProgress, history, sourceImage, installImage) => {
  setTarget(newValues)
  setSaveProgress(true)
  
  const sourceImageUrl = await sourceImage.upload()
  const installImageUrl = await installImage.upload()

  let inputUpdateWaterSource = {
    ...newValues,
    sourceImage: sourceImageUrl,
    installImage: installImageUrl,
  }
  
  inputUpdateWaterSource = removeEmpty(inputUpdateWaterSource)

  try {
    await API.graphql(graphqlOperation(mutations.updateWaterSource, { input: inputUpdateWaterSource }))
    history.goBack()
  } catch (error) {
    console.log(error)  
  }
}

function WaterSourceEdit(props) {
	const {
		classes,
		location,
    history,
	} = props

  const [source, setSource] = useState(location.state.source)
  const [sourceImage, setSourceImage] = useState({})
  const [installImage, setInstallImage] = useState({})
  const [updatedSource, setUpdatedSource] = useState(location.state.source)
  const [isUpdated, setIsUpdated] = useState(false)
  const [saveProgress, setSaveProgress] = useState(false)

  useEffect(() => {
    if (sourceImage.imageFileData || installImage.imageFileData) {
      setIsUpdated(true)
    } else {
      setIsUpdated(false)
    }
  }, [
    sourceImage,
    installImage,
  ])

	return (
		<>
			<ToolbarContent
				screenTitle = {`Edit ${source.name}`}
        isMainScreen = {false}
			/>
      <Typography 
        className={classes.label}
        color="textSecondary" 
        variant="caption"
      >
        Water Source Site Image 
      </Typography>
      <ImagePicker 
        id={`${source.id}-source`} 
        initialImageSrc={source.sourceImage} 
        setStorageUploadFunc={setSourceImage}
        width={250}
      />
			<WaterSourceForm 
        data={source}
        setIsUpdated={setIsUpdated} 
        setUpdatedData={setUpdatedSource} 
      />
			<div style={updatedSource.isMetered ? null : { display: 'none' }}>
        <Typography 
          className={classes.label}
          color="textSecondary" 
          variant="caption"
        >
          Installation Image 
        </Typography>
        <ImagePicker 
          id={`${source.id}-install`}
          initialImageSrc={source.installImage} 
          setStorageUploadFunc={setInstallImage} 
          width={250}
        />
      </div>
      <Grid className={classes.buttonContainer}>
        <Button
          className={classes.buttonSave} 
          variant="contained" 
          color="primary"
          disabled={!isUpdated || saveProgress}
          fullWidth
          onClick={() => handleSave(
            updatedSource,
            setSource,
            setSaveProgress,
            history,
            sourceImage,
            installImage,
          )}
        > 
          {saveProgress ? <CircularProgress color="inherit" size={16} style={{ marginRight: 5 }} /> : null}
          SAVE
        </Button>
      </Grid>
		</>	
	)
}

export default withStyles(styles)(WaterSourceEdit)