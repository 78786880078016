import React, { useState, } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { API, graphqlOperation } from 'aws-amplify'

import * as mutations from 'graphql/mutations'
import { removeEmpty } from 'services/graphqlHelpers'
import ToolbarContent from 'components/ToolbarContent'
import withUserData from 'components/withUserData'
import WaterSystemForm from '../../components/WaterSystemForm'

const styles = theme => ({
  buttonSave: {
    margin: 'auto',
    marginBottom: 16,
  },
  buttonContainer: {
    position: 'sticky',
    bottom: 0,
    paddingTop: 16,
    background: '#fff'
  }
})

const handleSave = async (fieldsUpdate, setPws, setSaveProgress, history) => {
  setPws(fieldsUpdate)
  setSaveProgress(true)
  
  let inputCreateWaterSystem = {
    ...fieldsUpdate,
  }
  
  inputCreateWaterSystem = removeEmpty(inputCreateWaterSystem)

  try {
    await API.graphql(graphqlOperation(mutations.createWaterSystem, { input: inputCreateWaterSystem }))
    history.push('/water-systems')
  } catch (error) {
    console.log(error)  
  }
}

function WaterSystemNew(props) {
  const { 
    classes,
    history,
  } = props
  const { userData } = props.userContext

  const defaultPws = {
    organizationId: userData.organizationId,
    name: '',
    activeConnections: '0',
    activeConnectionsValidity: '3',
    inactiveConnections: '0',
    inactiveConnectionsValidity: '3',
    waterMainLen: '0',
    waterMainLenValidity: '3',
    percentFlexiblePipe: '0',
    aveSysPressure: '0',
    aveSysPressureValidity: '3',
    avePipeLength: '0',
    isFullyMetered: true,
    hasProgram: false,
    aveMeterAge: '0',
  }

  const [pws, setPws] = useState(defaultPws)
  const [updatedPws, setUpdatedPws] = useState(defaultPws)
  const [isUpdated, setIsUpdated] = useState(false)
  const [saveProgress, setSaveProgress] = useState(false)

  return (
    <>
      <ToolbarContent
        screenTitle = 'New PWS'
        isMainScreen = {false}
      />
      <WaterSystemForm pws={pws} setIsUpdated={setIsUpdated} setPws={setUpdatedPws} />
      <Grid className={classes.buttonContainer}>
        <Button
          className={classes.buttonSave} 
          variant="contained" 
          color="primary"
          disabled={!isUpdated || saveProgress}
          fullWidth
          onClick={() => handleSave(
            updatedPws,
            setPws,
            setSaveProgress,
            history,
          )}
        > 
          {saveProgress ? <CircularProgress color="inherit" size={16} style={{ marginRight: 5 }} /> : null}
          SAVE
        </Button>
      </Grid>
    </>
  )
}

export default compose(
  withUserData,
  withStyles(styles)
)(WaterSystemNew)