import React from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

import Payments from '../Payments'

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
  },
  button: {
    marginTop: theme.spacing.unit,
  },
})

function PaymentHistory(props) {
  const {
    classes,
    history,
  } = props

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h6">
          Payment History
        </Typography>
        <Payments limit={3} />
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={() => history.push('/billing/payment-history')}
        >
          VIEW ALL
        </Button>
      </Paper>
    </>
  )
}

export default withStyles(styles)(PaymentHistory)