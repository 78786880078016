import React, { useState, useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { API, graphqlOperation } from 'aws-amplify'

import { requestState } from 'services/constantVariables/joinRequest'
import * as queries from 'graphql/queries'
import * as mutations from 'graphql/mutations'
import { userStatus } from 'services/constantVariables/user'

const options = [
  'APPROVE',
  'DENY',
]
const ITEM_HEIGHT = 48

const handleOptionClick = async(request, option, setAnchorEl, setUpdate) => {
  switch(option) {
    case 'APPROVE':
      const inputApproveJoinRequest = {
        id: request.id, 
        state: requestState.APPROVED,
      }
      await API.graphql(graphqlOperation(mutations.updateJoinRequest, {input: inputApproveJoinRequest}))
  
      const inputUpdateUser = {
        id: request.user.id, 
        organizationId: request.organization.id,
        userOrganizationId: request.organization.id,
        status: userStatus.USER,
      }
      await API.graphql(graphqlOperation(mutations.updateUser, {input: inputUpdateUser}))

      break

    case 'DENY':
      const inputDenyJoinRequest = {
        id: request.id, 
        state: requestState.DENIED,
      }
      await API.graphql(graphqlOperation(mutations.updateJoinRequest, {input: inputDenyJoinRequest}))
      
      break

    default:
      return
  }

  setAnchorEl(null)
  
  setUpdate(prevState => !prevState)
} 

function RequestUsers(props) {
  const {
    organizationId,
    history,
    location,
    setUpdate,
  } = props
  const [queryComplete, setQueryComplete] = useState(false)
  const [requests, setUsers] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)

  let isMounted

  useEffect(() => {
    isMounted = true

    ;(async() => {
      if(!isMounted) return
      setQueryComplete(false)
      const argsListJoinRequests = {
        filter: { 
          and: [
            { organizationId: { eq: organizationId },
            state: { eq: requestState.NEW } }
          ] 
        },
        limit: 1000
      }
      const queryRequests = await API.graphql(graphqlOperation(queries.listJoinRequests, argsListJoinRequests))
      const requests = queryRequests.data.listJoinRequests.items
      setQueryComplete(true)
      setUsers(requests)
    })()

    return () => {
      isMounted = false
    }
  
  }, [])

  if(!queryComplete) {
    return (
      <ListItem>
        <CircularProgress />
      </ListItem>
    )
  }

  if(!requests.length) {
    return (
      <ListItem>
        <ListItemText primary="None" />
      </ListItem>
    )
  }
  
  const open = Boolean(anchorEl)

  return (
    <>
      {requests.map((request) => (
        <ListItem 
          key={request.user.id}
          button
          onClick={() => history.push({
            pathname: `${location.pathname}/view/${request.user.id}`,
            state: { user: request.user },
          })}
        >
          <Avatar>
            <AccountCircleIcon />
          </Avatar>
          <ListItemText 
            primary={`${request.user.firstName||''} ${request.user.lastName||''}`} 
            secondary={request.user.email} 
          />
          <ListItemSecondaryAction>
            <IconButton
              aria-label="More"
              aria-owns={open ? request.id : undefined}
              aria-haspopup="true"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id={request.id}
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: 200,
                },
              }}
            >
              {options.map((option) => (
                <MenuItem 
                  key={option} 
                  selected={false} 
                  onClick={() => handleOptionClick(request, option, setAnchorEl, setUpdate)}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </>
  )
}

export default RequestUsers