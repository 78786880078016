export const removeEmpty = (object) => {
  const resultObject = JSON.parse(JSON.stringify(object))

  Object.keys(resultObject).forEach(key => {
    if (resultObject[key] && typeof resultObject[key] === 'object')
      resultObject[key] = removeEmpty(resultObject[key])
    else if (resultObject[key] === undefined || resultObject[key] === null || resultObject[key] === '')
      delete resultObject[key]
  })

  return resultObject
}