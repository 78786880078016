import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import NoneIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/styles'

const colors = [
  { name: 'blue', hex: '#00bcd4' },
  { name: 'purple', hex: '#9c27b0' },
  { name: 'green', hex: '#4caf50' },
  { name: 'red', hex: '#f44336' },
  { name: 'orange', hex: '#ff9800' },
]

const useStyles = makeStyles({
  body: {
    paddingTop: 0,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 20,
    wordBreak: 'break-word',
  },
  headerIcon: {
    marginTop: -20,
    float: 'left',
    padding: 16,
    color: 'white',
  },
  headerTitle: {
    paddingTop: 10,
    paddingLeft: 20,
  },
  bodyText: {
    paddingTop: 10,
  },
  caption: {
    paddingTop: 10,
  },
  inline: {
    display: 'inline-flex'  
  }
})

function HeaderIcon(props) {
  const classes = useStyles()
  const {
    color: colorName
  } = props

  return (
    <Paper 
      style={{ background: colors.find(color => color.name === colorName).hex }} 
      className={classes.headerIcon} elevation={10}
    >
      {props.children || <NoneIcon />}
    </Paper>
  ) 
}

HeaderIcon.defaultProps = {
  color: 'blue',
}

HeaderIcon.propTypes = {
  color: PropTypes.oneOf(colors.map(item => item.name)),
}

function HeaderTitle(props) {
  const {
    align
  } = props

  const classes = useStyles()

  return (
    <Typography 
      className={classNames(
        classes.headerTitle,
        {[classes.inline]: align === "left" ? true : false}
      )} 
      color="textSecondary"
      align={align ? align : "right"}
      variant="subtitle1"
    >
      {props.children}
    </Typography>
  ) 
}


function BodyText(props) {
  const classes = useStyles()

  return (
    <Typography className={classes.bodyText} align="right" variant="h5">
      {props.children}
    </Typography>
  ) 
}

function Caption(props) {
  const classes = useStyles()

  return (
    <Typography className={classes.caption} variant="caption">
      {props.children}
    </Typography>
  )
}

function InfoCard(props) {
  const {
    Icon,
    color,
    title,
    titleAlign,
    bodyText,
    BodyContent,
    caption,
    linkHref,
    linkText,
  } = props
  
  const classes = useStyles()
  
  return (
    <>
      <Paper className={classes.body}>
        <HeaderIcon color={color}>
          {Icon ? <Icon /> : null}
        </HeaderIcon>
        <HeaderTitle align={titleAlign}>
          {title}
        </HeaderTitle>
        <BodyText>
          {bodyText || <>&nbsp;</>}
        </BodyText>
        <div>
          {BodyContent}
        </div>
        <Divider />
        <Caption>
          {caption || <>&nbsp;</>}
        </Caption>
        <Link 
          color="secondary"
          href={linkHref}
        >
          {linkText}
        </Link>
      </Paper>
    </>
  )
}

InfoCard.propTypes = {
  Icon: PropTypes.func,
  color: PropTypes.oneOf(colors.map(item => item.name)),
  title: PropTypes.string,
  titleAlign: PropTypes.oneOf(['left', 'center', 'right']),
  bodyText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  BodyContent: PropTypes.element,
  caption: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  linkHref: PropTypes.string,
  linkText: PropTypes.string,
}

export default InfoCard