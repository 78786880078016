export const volumeUnitsList = [
	'Kgal',
	'Gallon',
	'Mgal',
	'Cubic Feet',
	'100 Cubic Foot',
	'Acre Feet',
]

export const volumeUnitShort = {
	'Kgal': 'Kgal',
	'Gallon': 'gal',
	'Mgal': 'MG',
	'Cubic Feet': 'ft3',
	'100 Cubic Foot': 'CCF',
	'Acre Feet': 'Acre-ft',
}

export default null