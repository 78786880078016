import React from 'react'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  content: {
    overflowWrap: 'break-word',
  }
})

function References(props) {
  const classes = useStyles()

  return (
    <>
      <Typography variant="h6" gutterBottom>
        REFERENCES
      </Typography>
      <Typography 
        className={classes.content}
        gutterBottom
      >
        A.O.Lambert, J. Koelbl, D. Fuchs-Hanusch (2014) ,Interpreting ILIs in Small Systems.&nbsp;
        <Link 
          href="http://www.leakssuite.com/wp-content/uploads/2014/10/Low-ILIs-paper-ALJKDFH19Oct.pdf" 
          color="secondary"
        >
          http://www.leakssuite.com/wp-content/uploads/2014/10/Low-ILIs-paper-ALJKDFH19Oct.pdf
        </Link>
      </Typography>
      <Typography 
        className={classes.content}
        gutterBottom
      >
        Lambert A, Brown TG, Takizawa M, Weimer D (1999). A review of performance indicators for real 
        losses from water supply systems. J Water SRT – Aqua Vol.48, No.6, pp. 227-237, 1999.&nbsp;
        <Link 
          href="http://www.leakssuite.com/wp-content/uploads/2012/11/1999_LambertBrown-et-al-AQUA-1999M.pdf"
          color="secondary"
        >
          http://www.leakssuite.com/wp-content/uploads/2012/11/1999_LambertBrown-et-al-AQUA-1999M.pdf
        </Link>
        .
      </Typography>
      <Typography 
        className={classes.content}
        gutterBottom
      >
        U.S. Environmental Protection Agency. 2009. Drinking Water Infrastructure Needs Survey Fact Sheet, 
        EPA 816‐F‐09‐003.&nbsp;
        <Link 
          href="http://water.epa.gov/infrastructure/drinkingwater/dwns/factsheet.cfm"
          color="secondary"
        >
          http://water.epa.gov/infrastructure/drinkingwater/dwns/factsheet.cfm
        </Link>
        .
      </Typography>
      <Typography 
        className={classes.content}
        gutterBottom
      >
        Dieter, C.A., and Maupin, M.A., 2017, Public supply and domestic water use in the United States, 2015: 
        U.S. Geological Survey Open-File Report 2017–1131, 6 p.,&nbsp;
        <Link 
          href="https://doi.org/10.3133/ofr20171131"
          color="secondary">
          https://doi.org/10.3133/ofr20171131
        </Link>
        .
      </Typography>
    </>
  )
}

export default References