import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'

import ToolbarContent from 'components/ToolbarContent'

const styles = theme => ({
  button: {
    margin: 'auto',
  },
})

function Onboarding (props) {
  const { classes } = props

  return (
    <>
      <ToolbarContent
        screenTitle = "Join or set up a new"
        isMainScreen = {false}
        renderIcon = {false}
      />
      <Grid item xs={12} sm={6}>
        <Typography color="secondary" variant="h6" gutterBottom>
          Are you the first person from your organization to join?
        </Typography>
        <Typography paragraph>
          Setting up a duplicate account for an existing municipality would be a problem.
        </Typography>
        <Typography paragraph>  
          To avoid this we'll have you search for your organization in the database before creating a new one.
        </Typography>
        <Typography color="secondary" variant="h6" gutterBottom>
          If you find it
        </Typography>
        <Typography paragraph>  
          If you see your organization, just tap it's name and you can request to join.  That's it, you're done!
        </Typography>
        <Typography color="secondary" variant="h6" gutterBottom>
          If you don't find it
        </Typography>
        <Typography paragraph>
          If you don't find your organization you can then create a new account for your workplace.
        </Typography>
        <Grid item>
          <Button 
            className={classes.button} 
            variant="contained" 
            color="primary"
            fullWidth
            component={Link}
            to="/onboarding/search-organization"
          >
            SEARCH FOR MY ORGANIZATION
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(styles)(Onboarding)