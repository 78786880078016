import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { formatNumber } from 'accounting'
import { API, graphqlOperation } from 'aws-amplify'
import { withStyles } from '@material-ui/core/styles'

import Img from 'components/Img'
import ToolbarContent from 'components/ToolbarContent'
import LabeledText from 'components/LabeledText'
import * as queries from 'graphql/queries.js'
import { formatDate } from 'services/utilityFunctions'

const styles = () => ({
  caption: {
    textAlign: 'center',
  },
  name: {
    marginTop: 5,
    textAlign: 'center'
  },
  button: {
    margin: 'auto',
    marginTop: 50, 
  },
  logo: {
    width: 250,
    margin: 'auto',
    display: 'block',
  },
  installPic: {
    width: 200,
    margin: 'auto',
    display: 'block',
  },
  location: {
    marginLeft: 16,
  },
})

function WaterSourceView(props) {
	const {
		classes,
		location,
    history,
    match,
  } = props
  
  const [source, setSource] = useState(location.state.source)
  const sourceId = match.params.id
  
  let isMounted

  useEffect(() => {
    isMounted = true

    ;(async () => {
      const resultGetWaterSource = await API.graphql(graphqlOperation(queries.getWaterSource, { id: sourceId } )) 
      const source = resultGetWaterSource.data.getWaterSource
      if (!isMounted) return
      setSource(source)
    })()

    return () => {
      isMounted = false
    }
  }, [sourceId])

	return (
		<>
			<ToolbarContent
				screenTitle = {`${source.name}`}
        isMainScreen = {false}
			/>
      <Img
        className={classes.logo}
        src={source.sourceImage}
        width={250}
        imgProps={{
          id: "water-source-image",
          alt: "Water Source Site"
        }}
        unloader={
          <img
            id="water-source-image"
            alt="Water Source Site"
            className={classes.logo}
            src={require('images/default-image.png')}
          />
        }
      />
      <Typography className={classes.caption} variant="caption">
        Water Source Site Image
      </Typography>
      <Typography className={classes.name} variant="h4">
			  {source.name}
      </Typography>
      {/* <LabeledText label="GPS Location" hideDivider hideValue />
      <div className={classes.location}>
        <LabeledText label="Latitude" value={source.latitude} />
        <LabeledText label="Longitude" value={source.longitude} />
      </div> */}
			<LabeledText label="Type" value={source.type} />
      <LabeledText label="Units" value={source.units} />
      <LabeledText label="Is metered?" value={source.isMetered ? 'Yes' : 'No'} />
      {source.isMetered ? (
        <>
        <LabeledText label="Meter size (inches)" value={formatNumber(source.meterSize, 2)} />
        <LabeledText label="Meter make and model" value={source.meterMakeModel} />
        <LabeledText label="Meter install year" value={source.meterInstallYear} />
        <LabeledText label="Meter calibration date" value={formatDate(source.meterCalibDate, 'Mmm/dd/yyyy')} />
        <LabeledText label="Meter installation picture" hideDivider hideValue />
        <Img
          className={classes.logo}
          width={250}
          src={source.installImage}
          imgProps={{
            id: "water-source-install",
            alt: "Installation"
          }}
          unloader={
            <img
              id="water-source-install"
              alt="Installation"
              className={classes.logo}
              src={require('images/default-image.png')}
            />
          }
        />
        </>
      ) : (
        <>
          <LabeledText label="Volumes are estimated by" value={source.estimate} />
        </>
      )}
      <Button 
        className={classes.button}
        variant="contained" 
        color="secondary"
        fullWidth
        onClick={() => history.push({
          pathname: `/water-systems/${match.params.pwsId}/sources/edit/${match.params.id}`,
          state: { source },
        })}
      >
        EDIT
     </Button>
		</>
	)
}

export default withStyles(styles)(WaterSourceView)