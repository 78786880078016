import React, { useEffect } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import * as mutations from 'graphql/mutations'
import { removeEmpty } from 'services/graphqlHelpers'
import ToolbarContent from 'components/ToolbarContent'
import ContentHeader from 'components/ContentHeader'
import ProductionDataForm from '../../components/ProductionDataForm'

const handleSave = async (params) => {
  const {
    id,
    waterSourceId,
    waterSystemId,
    type,
    date,
    time,
    pumpRunTime,
    currentMeterRead,
    startDate,
    endDate,
    volume,
  } = params
  
  let inputSourceProductionCreate = {
    id,
    waterSourceId,
    waterSystemId,
    type,
    timestamp: String(date) + String(time).replace(':', ''),
    reading: currentMeterRead,
    pumpRunTime,
    startDate,
    endDate,
    volume,
  }

  inputSourceProductionCreate = removeEmpty(inputSourceProductionCreate)
  
  try {
    await API.graphql(graphqlOperation(mutations.createSourceProduction, { input: inputSourceProductionCreate }))
  } catch (error) {
    console.log(error)
  }
}

function ProductionDataNew(props) {
  const {
    location,
    history,
  } = props

  const { pwsName, source, lastReading } = location.state

  useEffect(() => {

  }, [])

  return (
    <>
      <ToolbarContent
        screenTitle = {`New Production Data Record`}
        isMainScreen = {false}
      />
      <ContentHeader
        breadcrumbs={[
          { name: 'Production Data', goBack: true, step: 3 },
          { name: pwsName, goBack: true, step: 2 }, 
          { name: source.name, goBack: true, step: 1 }, 
          { name: 'New Record' } 
        ]}
        history={history}
      />
      <ProductionDataForm 
        source={source}
        handleSave={async (args) => handleSave(args)}
        history={history}
        lastReading={lastReading || null}
      />
    </>
  )
}

export default ProductionDataNew