import React, { useState } from 'react'
import { Slider } from 'antd'

import "antd/dist/antd.css"

function CustomSlider(props) {
  const {
    dates,
    height,
    group,
    activeGroup,
    activeGroupSetter,
    onChange
  } = props

  const [sliderValue, setSliderValue] = useState([0, 100])

  return (
    <div onClick={() => activeGroupSetter(group)} style={{ height }}>
      <Slider 
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
        tipFormatter={null} 
        vertical 
        range 
        disabled={activeGroup === group ? false : true}
        step={100 / (height / 35.99)} 
        value={sliderValue} 
        onChange={value => {
          setSliderValue(value)
  
          // select items by group number
          const dateGroup = dates.filter(item => item.group === group)
  
          // convert slider range value into indices of dates array
          const upperLimit = dateGroup.length - Math.round((value[0] / 100) * dateGroup.length)
          const lowerLimit = dateGroup.length - Math.round((value[1] / 100) * dateGroup.length)
  
          const startIndex = lowerLimit
          const lastIndex = upperLimit - 1
  
          // unselect dates that don't fall between the value range
          dateGroup.forEach((item, index) => {
            if (index < startIndex || index > lastIndex) {
              item.tag = "unselected"
            } else {
              item.tag = "common"
            }
          })
          
          let newDates = dates
          onChange(newDates)
        }}
      />
    </div>
  )
}

export default CustomSlider