import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  customLabel: {
    fontSize: '.85rem',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'block',
  },
  value: {
    fontSize: '0.875rem',
    textAlign: 'right'
  }
})

const MUIVariantMapping = {
  default: {
    label: "caption",
    value: "body1",
  },
  bold: {
    label: "subtitle2",  
    value: "subtitle2",
  }
}

function LabeledNumber(props) {
  const {
    classes,
    label,
    value,
    variant
  } = props

  return (
    <>
      <Grid container spacing={16}>
        <Grid item xs={7}>
          {variant === 'default' ? (
            <Typography
              component={() => (
                <span className={classes.customLabel}>
                  {label}
                </span>
              )}
            />
          ) : (
            <Typography variant={MUIVariantMapping[variant].label}>
              {label}
            </Typography>
          )}
        </Grid>
        <Grid item xs={5}>
          <Typography variant={MUIVariantMapping[variant].value} className={classes.value}> 
            {value}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

LabeledNumber.defaultProps = {
  variant: 'default'
}

LabeledNumber.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  variant: PropTypes.oneOf(['default', 'bold'])
}

export default withStyles(styles)(LabeledNumber)