import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import { API, graphqlOperation } from 'aws-amplify'
import { compose } from 'recompose'

import * as mutations from 'graphql/mutations'
import * as queries from 'graphql/queries'
import withUserData from 'components/withUserData'
import { UserContext } from 'contexts'

const styles = () => ({
  title: {
    marginBottom: 30,
  },
  body: {
    marginBottom: 20,
  },
  button: {
    marginTop: 40,
  },
})

const handleStartTrial = async (organizationId, userData, setUserData, setUpdateUserContext, setLoading) => {
  setLoading(true)
  
  let inputCreateTrial = {
    organizationId: organizationId,
    trialName: null,
    length: null,
  }

  // issue trial for first usage
  try {
    const resultGetConfig = await API.graphql(graphqlOperation(queries.getConfig, { id: 1 }))
    const config = resultGetConfig.data.getConfig

    if (!config) return console.log('No configuration item found.')

    inputCreateTrial.trialName = config.name
    inputCreateTrial.length = config.value

    await API.graphql(graphqlOperation(mutations.createTrial, { input: inputCreateTrial }))
  
    const resultGetOrganization = await API.graphql(graphqlOperation(queries.getOrganization, { id: organizationId }))
    const organization = resultGetOrganization.data.getOrganization
  
    const updatedUserData = userData
    updatedUserData.organization = organization
  
    setUserData(updatedUserData)
    setUpdateUserContext(true)
  } catch (error) {
    console.log(error)
  }
}

function StartTrial(props) {
  const {
    classes,
    userContext,
  } = props
  const { organizationId } = userContext.userData

  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState(userContext.userData)
  const [updateUserContext, setUpdateUserContext] = useState(false)

  useEffect(() => {
    setUpdateUserContext(false)
  }, [updateUserContext])

  return (
    <>
      <Typography 
        className={classes.title}
        align="center" 
        variant="h6" 
        color="secondary"
      >
        {updateUserContext ? (
          <UserContext.Consumer>
            {({ updateUserData }) => {
              updateUserData(userData)
            }}
          </UserContext.Consumer>
        ) : null}
        Start your 30 day free trial
      </Typography>
      <Typography
        className={classes.body}
      >
        Use Water Utility App free for 30 days to see how it works and how much time it saves.
      </Typography>
      <Typography
        className={classes.body}
      >
        Configure your water system.  Add sources and record production data.  You can generate as many reports as you want with configurable time periods.
      </Typography>
      <Typography>
        <a href="https://waterutilityapp.com/support/free-trial">
          Check out our support page for more details about the free trial 
        </a>
      </Typography>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        fullWidth
        disabled={loading}
        onClick={() => handleStartTrial(organizationId, userData, setUserData, setUpdateUserContext, setLoading)}
      >
        {loading ? <CircularProgress color="inherit" size={16} style={{ marginRight: 5 }} /> : null}
        Start your 30 day free trial
      </Button>
    </>
  )
}

export default compose(
  withUserData,
  withStyles(styles),
)(StartTrial)