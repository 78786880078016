import React from 'react'
import Button from '@material-ui/core/Button'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { API, graphqlOperation } from 'aws-amplify'

import * as mutations from 'graphql/mutations'
import ToolbarContent from 'components/ToolbarContent'
import withUserData from 'components/withUserData'
import LabeledText from 'components/LabeledText'

const styles = theme => ({
  button: {
    marginTop: 15,
  },
  buttonDelete: {
    color: theme.palette.error.main
  }
})

const handleMakeDefault = async (cardId, customerId, history) => {
  const attributes = [{
    property: 'default_source', 
    value: cardId
  }]
  const attributesJSON = JSON.stringify(attributes)
  const inputApiAction = {
    name: 'stripeUpdateCustomer',
    parameters: [
      customerId,
      attributesJSON,
    ]
  } 
  await API.graphql(graphqlOperation(mutations.createApiAction, { input: inputApiAction }))
  history.goBack()      
}

const handleDelete = async (cardId, customerId, history) => {
  const inputApiAction = {
    name: 'stripeDeleteCard',
    parameters: [
      customerId,
      cardId,
    ]
  }
  await API.graphql(graphqlOperation(mutations.createApiAction, { input: inputApiAction }))
  history.goBack()
}

function PaymentInfoEdit(props) {
  const {
    classes,
    location,
    history,
    userContext,
  } = props

  const { customerId } = userContext.userData.organization
  const { card } = location.state 

  return (
    <>
      <ToolbarContent
        screenTitle = "Edit Payment Method"
        isMainScreen = {false}
      />
      <LabeledText label="Brand" value={card.brand} />
      <LabeledText label="Last 4" value={card.last4} />
      <LabeledText label="Expires" value={card.exp_month+'/'+card.exp_year} />
      {!card.default ? (
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => handleMakeDefault(card.id, customerId, history)}
        >
          Make Default
        </Button>
      ) : null}
      <Button
        className={`${classes.button} ${classes.buttonDelete}`}
        fullWidth
        onClick={() => handleDelete(card.id, customerId, history)}
      >
        Delete
      </Button>
    </>
  )
}

export default compose(
  withUserData,
  withStyles(styles),
)(PaymentInfoEdit)