import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: '1200',
  },
  drawer: {
    flexShrink: 0, 
    zIndex: 1100, 
    width: 300,
  },
  drawerContent: {
    marginTop: 64,
    paddingTop: 20,
    height: 'calc(100% - 64px)', 
    display: 'grid', 
    overflow: 'auto',
  },
  content: {
    flexGrow: 1, 
    paddingLeft: 10,
    paddingRight: 10,
    MozPaddingEnd: '10px',
    marginTop: 74,
    maxWidth: 840,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  contentPaper: {
    minHeight: 'calc(100vh - 84px)',
    position: 'relative',
    marginBottom: 10,
  },
  paper: {
    width: 'inherit',
    zIndex: 'inherit', 
  }
})

function AppFrameLarge(props) {
  const {
    AppToolbar, 
    AppDrawer,
    onRender,
  } = props

  const classes = useStyles()

  useEffect(() => {
    onRender({
      content: classes.content, 
      contentPaper: classes.contentPaper
    })
  }, [])

  return (
    <>
      <AppBar 
        classes={{ root: classes.appBar }}
        position="fixed"  
        color="secondary" 
      >
        <AppToolbar 
          menuClick={() => {}}
          renderIcon={false}
        />
      </AppBar>
      <Drawer 
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.paper
        }}
      >
        <div className={classes.drawerContent}>
          <AppDrawer />
        </div>
      </Drawer>
      {/* <main className={classes.content}>
        <Paper className={classes.contentPaper}>
          <AppContent />
        </Paper>
      </main> */}
    </>
  )
}

AppFrameLarge.defaultProps = {
  AppToolbar: () => {},
  AppDrawer: () => {},
  onRender: () => {},
} 
  
AppFrameLarge.propTypes = {
  AppToolbar: PropTypes.func.isRequired,
  AppDrawer: PropTypes.func.isRequired,
  onRender: PropTypes.func,
}

export default AppFrameLarge