const appTheme = {
  palette: {
    primary: {
      main: '#ff9900',
      contrastText: '#ffffff',
      dark: '#024459',
    },
    secondary: {
      main: '#04ace0',
      contrastText: '#ffffff',
    },
    error: {
      main: '#b71c1c',
      contrastText: '#ffffff',
    },
  },
}

export default appTheme