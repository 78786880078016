import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { API, graphqlOperation } from 'aws-amplify'

import * as queries from 'graphql/queries.js'

function SourceCount(props) {
	const { pwsId } = props
	const [count, setCount] = useState()

	let isMounted

	useEffect(() => {
		isMounted = true

		;(async() => {
    	const listWaterSourcesFilter = {
        filter: {
          waterSystemId: {
            eq: pwsId
          }
				},
				limit: 1000
      }
      const resultListWaterSources = await API.graphql(graphqlOperation(queries.listWaterSources, listWaterSourcesFilter))  
      let sources = resultListWaterSources.data.listWaterSources.items
			if (!isMounted) return
			setCount(sources.length)
		})()
		
		return () => {
			isMounted = false
		}

	}, [pwsId])

	return (
		<>
			<span>{count === null || count === undefined ? '--' : count}</span>
		</>
	)
}

SourceCount.propTypes = {
	pwsId: PropTypes.string.isRequired,
}

export default SourceCount