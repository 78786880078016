import React from 'react'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const styles = theme => ({
  label: {
    marginTop: 5, 
  },
  value: {
    marginBottom: 5, 
    whiteSpace: 'pre-wrap'
  }
})

function LabeledText(props) {
  const { 
    classes,
    label,
    value,
    hideDivider,
    hideValue,
  } = props

  return (
    <>
      <Typography 
        className={classes.label}
        variant="subtitle2" 
        color="textSecondary"
      >
        {label}
      </Typography>
      <Typography 
        className={classes.value}
        variant="subtitle1"
      >
        {hideValue ? null : value||<>&nbsp;</>}
      </Typography>
      {hideDivider ? null : <Divider />}
    </>
  )
}

LabeledText.defaultProps = {
  hideDivider: false,
  hideValue: false,
}

LabeledText.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), 
  hideDivider: PropTypes.bool,
  hideValue: PropTypes.bool,
}

export default withStyles(styles)(LabeledText)