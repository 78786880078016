import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

function Recommendations(props) {
  const {
    isMetered,
    isFullyMetered,
    hasTestingAndReplacementProgram,
    aveMeterAge,
    connectionsValidity,
    waterMainLenValidity,
    aveSysPressureValidity,
    classes,
  } = props

  return (
    <>
      <Typography variant="h6" gutterBottom>
        RECOMMENDATIONS
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle2">
        Source Metering
      </Typography>
      <Typography component="article">
        {isMetered ? (
          <ul>
            <li>Pursue regular meter calibration</li>
            <ul>
              <li>Website hotlink</li>
            </ul>
          </ul>
        ) : (
          <ul>
            <li>Ensure all sources are metered</li>
            <li>Install source meter</li>
          </ul>
        )}
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle2">
        Customer Metering
      </Typography>
      <Typography component="article">
        <ul>
          {isFullyMetered ? ( 
            <>
              {hasTestingAndReplacementProgram ? (
                <li>Maintain meter testing and replacement program</li>  
              ) : (
                <>
                <li>Establish a Meter testing and replacement program</li>   
                <ul>
                  <li>Website hotlink</li>   
                </ul>
              </>
              )}
            </>
          ) : (
            <>
              <li>Pursue installing customer meters</li>
              <li>Meter all connections</li>
              <ul>
                <li>Hotlink to website page with reference information on customer meters</li>
              </ul>
            </>
          )}
          {aveMeterAge < 20 ? (
            <>
              <li>Meters tend to become less accurate with age, they under-register.</li>
            </>
          ) : null}
          {connectionsValidity < 3 ? (
            <>
              <li>Determine number of connections, active and inactive with more accuracy</li>
            </>
          ) : null}
        </ul>
      </Typography>
      <Typography component="article">
      {waterMainLenValidity < 3 ? (
        <>
          <Typography className={classes.subtitle} variant="subtitle2">
            Mapping
          </Typography>
          <ul>
            <li>Consider creating a GIS system or updating your GIS to improve mapping information</li>
          </ul>
        </>
      ) : null}
      </Typography>
      <Typography component="article">
      {aveSysPressureValidity < 3 ? (
        <>
          <Typography className={classes.subtitle} variant="subtitle2">
            Pressure
          </Typography>
          <ul>
            <li>Preform pressure testing to determine an average system pressure more accurately</li>   
            <ul>
              <li>Hotlink to website for “how to”</li>   
            </ul>
          </ul>
        </>
      ) : null} 
      </Typography>
      <Typography>
        The following steps are general recommendations to reduce water loss:
      </Typography>
      <Typography component="article">
        <ul>
          <li>Investigate and analyze night flow patterns to help refine the system&rsquo;s Real Loss estimates</li>
          <ul>
            <li>Most storage tanks will level out in the middle of the night</li>
            <ul>
              <li>Unless there is commercial or industrial usage</li>
            </ul>
          </ul>
          <li>Ensure estimated and adjusted billed volumes are included in the correct time period</li>
          <li>Perform annual inspection and testing of large, high volume meters</li>
          <li>Preform random inspections and testing of &ldquo;normal&rdquo; customer meters</li>
          <li>Review customer accounts looking for unusual usage patterns</li>
          <ul>
            <li>High usage accounts</li>
            <ul>
              <li>Field investigations</li>
              <li>Consider testing and/or replacement of high usage account meters</li>
              <li>Accounts with high metered usage may be under-registering</li>
              <ul>
                <li>This can contribute to apparent loss</li>
              </ul>
            </ul>
            <li>Low or zero usage accounts</li>
            <ul>
              <li>Field investigations</li>
              <li>Sign of possible theft or meter not working properly</li>
            </ul>
            <li>Other unusual usage patterns</li>
          </ul>
          <li>Perform field investigations looking for Lush landscaping with low water usage</li>
          <ul>
            <li>Sign of possible theft or meter not working properly</li>
          </ul>
          <li>Evaluate internal controls related to customer usage and billing</li>
          <ul>
            <li>Check for meter reading lag</li>
            <li>Check for reading unit consistency</li>
          </ul>
        </ul>
      </Typography>
    </>
  )
}

Recommendations.propTypes = {
  isMetered: PropTypes.bool.isRequired,
  isFullyMetered: PropTypes.bool.isRequired,
  hasTestingAndReplacementProgram: PropTypes.bool.isRequired,
  aveMeterAge: PropTypes.string,
  connectionsValidity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  waterMainLenValidity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  aveSysPressureValidity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default Recommendations