import React, { useState } from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

import PaymentCards from '../PaymentCards'

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
  },
  content: {
    paddingLeft: theme.spacing.unit*2,
    paddingRight: theme.spacing.unit*2,
  }
})

function PaymentMethod(props) {
  const {
    classes,
    history
  } = props

  const [cardExists, setCardExists] = useState(true)

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h6">
          Payment Methods
        </Typography>
        {!cardExists ? (
          <Typography variant="caption" align="center">
            Nothing to show
          </Typography>
        ): null}
        <PaymentCards  
          defaultOnly 
          setCardExists={setCardExists}
        />
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={() => history.push('/billing/payment-info')}
        >
          MANAGE
        </Button>
      </Paper>
    </>
  )
}

export default withStyles(styles)(PaymentMethod)