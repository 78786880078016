import React, { useState, useEffect, } from 'react'
import PropTypes from 'prop-types'
import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import { withStyles } from '@material-ui/core/styles'

import PaymentCards from 'scenes/Billing/components/PaymentCards'
import PaymentMethodAdd from '../PaymentMethodAdd'

const styles = theme => ({
  main: {
    minHeight: 200,
  },
  fab: {
    position: 'sticky',
    float: 'right',
    bottom: theme.spacing.unit*2,
  },
  backButton: {
    marginTop: 10,
  }
})

function PaymentMethodChange(props) {
  const {
    classes,
    onItemClick,
  } = props

  const [cardExists, setCardExists] = useState(true)
  const [addMode, setAddMode] = useState(false)

  useEffect(() => {

  }, [])

  return (
    <>
      <div className={classes.main}>
        {addMode ? (
          <>
            <PaymentMethodAdd onComplete={() => setAddMode(false)} />
            <Button
              className={classes.backButton}
              fullWidth
              onClick={() => setAddMode(false)}
            >
              Go back
            </Button>
          </>
        ) : (
          <>
            {!cardExists ? <>No other credit cards found. Tap the "Plus" button to add a new one.</> : null}
            <PaymentCards 
              setCardExists={setCardExists}
              onItemClick={card => onItemClick(card)}
            />
            <div style={{ height: 100 }}/>
            <Fab 
              className={classes.fab} 
              color="primary"
              onClick={() => setAddMode(true)}
            >
              <AddIcon />
            </Fab>
          </>   
        )}
      </div>
    </>
  )
}

PaymentMethodChange.defaultProps = {
  onItemClick: () => {}
}

PaymentMethodChange.propTypes = {
  onItemClick: PropTypes.func
}

export default withStyles(styles)(PaymentMethodChange)