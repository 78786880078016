import React from 'react'
import List from '@material-ui/core/List'

import ToolbarContent from 'components/ToolbarContent'
import ContentHeader from 'components/ContentHeader'
import AddFabButton from 'components/AddFabButton'
import MaintenanceJournalListItems from './components/MaintenanceJournalListItems'

function MaintenanceJournalList(props) {
  const {
    history,
    location,
  } = props

  const { pws } = location.state

  return (
    <>
      <ToolbarContent
        screenTitle={`Maintenance Journal`}
        isMainScreen={false}
      />
      <ContentHeader
        breadcrumbs={[
          { name: 'Maintenance Journal', goBack: true, step: 1 }, 
          { name: pws.name } 
        ]}
        history={history}
      />
      <List>
        <MaintenanceJournalListItems 
          waterSystemId={pws.id} 
          onItemClick={maintenanceJournal => {
            history.push({
              pathname: `${location.pathname}/view/${maintenanceJournal.id}`,
              state: { maintenanceJournal, pws },
            })
          }} 
        />
      </List>
      <AddFabButton
        onClick={() => history.push({
          pathname: `${location.pathname}/new`,
          state: { pws },
        })}
      />
    </>
  )
}

export default MaintenanceJournalList