import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography'
import { formatNumber } from 'accounting'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { API, graphqlOperation } from 'aws-amplify'

import withUserData from 'components/withUserData'
import * as queries from 'graphql/queries'
import SourceCount from './components/SourceCount'

const styles = theme => ({
  item: {
    backgroundColor: theme.palette.secondary.main,
    marginBottom: 12,
  },
  itemText: {
    color: 'white',
  }
})

function PublicWaterSystems(props) {
  const { 
    classes,
    onItemClick,
    notFoundMessage,
    setNotFound,
  } = props
  const { organizationId } = props.userContext.userData

  const [pws, setPws] = useState([])
  const [queryComplete, setQueryComplete] = useState(false)

  let isMounted

  useEffect(() => {
    isMounted = true

    ;(async() => {
      setQueryComplete(false)
      const listWaterSystemsFilter = {
        filter: {
          organizationId: {
            eq: organizationId
          }
        },
        limit: 1000
      }
      const resultListWaterSystems = await API.graphql(graphqlOperation(queries.listWaterSystems, listWaterSystemsFilter))  
      const pws = resultListWaterSystems.data.listWaterSystems.items
      
      if(!isMounted) return
      if (pws.length === 0) {
        setNotFound(true)
      } else {
        setNotFound(false)
      }
      
      setPws(pws)
      setQueryComplete(true)
    })()

    return () => {
      isMounted = false
    }
  
  }, [props.id])

  if(!queryComplete) {
    return (
      <ListItem> 
        <CircularProgress />
      </ListItem>
    )
  }

  if(!pws.length) {
    return (
      <ListItem>
        <ListItemText primary={notFoundMessage || ''} />
      </ListItem>
    )
  }
  
  return (
    <>
      {pws.map((item) => (        
        <Card 
          className={classes.item} 
          key={item.id}
          raised
        >
          <CardActionArea 
            onClick={() => onItemClick(item)}
          >
            <CardContent>
              <Typography 
                className={classes.itemText} 
                variant="h5" 
                component="h3"
              >
                {item.name}
              </Typography>
              <Typography 
                className={classes.itemText}
                component="p"
              >
                <SourceCount pwsId={item.id}/> Sources
              </Typography>
              <Typography
                className={classes.itemText}
                component="p"
              >
                {formatNumber(item.activeConnections)} Active Connections
              </Typography>    
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </>
  )
}

PublicWaterSystems.defaultProps = {
  onItemClick: () => {},
  setNotFound: () => {},
}

PublicWaterSystems.propTypes = {
  onItemClick: PropTypes.func,
  notFoundMessage: PropTypes.string,
  setNotFound: PropTypes.func,
}

export default compose(
  withUserData,
  withStyles(styles)
)(PublicWaterSystems)
