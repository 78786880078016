import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Check from '@material-ui/icons/Check'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import ToolbarContent from 'components/ToolbarContent'

const styles = () => ({
  buttonEditOrg: {
    marginTop: 60,
    marginBottom: 30,
  },
})

function NewOrganization (props) {
  const { classes } = props
  return (
    <>
      <ToolbarContent
        screenTitle = "New Organization"
        isMainScreen = {false}
      />
      <Grid item xs={12} sm={6}>
        <Typography color="secondary" variant="h5" gutterBottom>
          Welcome to your new account
        </Typography>
        <Typography paragraph>
          The following configuration has been created for you:
        </Typography>
        <List>
          <ListItem>
            <Check />
            <ListItemText primary="Organization" />
          </ListItem>
          <ListItem>
            <Check />
            <ListItemText primary="1 Public Water System" />
          </ListItem>
          <ListItem>
            <Check />
            <ListItemText primary="1 Water Source" />
          </ListItem>
        </List>
        <Button 
          className={classes.buttonEditOrg} 
          variant="contained" 
          color="primary"
          fullWidth
          component={Link}
          to={`/organization/edit`}
        >
          EDIT ORGANIZATION INFO
        </Button>
        <Button 
          className={classes.buttonSkip}  
          color="primary"
          fullWidth
          component={Link}
          to={`/`}
        >
          SKIP AND GO TO DASHBOARD
        </Button>
      </Grid>
    </>
  )
}

export default withStyles(styles)(NewOrganization)