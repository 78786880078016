import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import { API, graphqlOperation } from 'aws-amplify'

import * as mutations from 'graphql/mutations'
import ToolbarContent from 'components/ToolbarContent'
import ConfirmDeleteDialog from 'components/ConfirmDeleteDialog'
import LabeledText from 'components/LabeledText'

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
  },
  button: {
    marginTop: 10,
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  }
})

const handleCancel = async pws => {
  const inputApiAction = {
    name: 'stripeCancelSubscription',
    parameters: [
      pws.subscriptionId,
      pws.id
    ]
  }
  
  await API.graphql(graphqlOperation(mutations.createApiAction, { input: inputApiAction }))
}

function SubscriptionCancel(props) {
  const {
    classes,
    history,
    location,
  } = props
  const { pws } = location.state
  
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [cancelInProgress, setCancelInProgress] = useState(false)

  const [confirmDelete, setConfirmDelete] = useState(false)
  useEffect(() => {
    if (confirmDelete) {
      (async () => {
        setCancelInProgress(true)
        try {
          await handleCancel(pws)
          history.goBack()
        } catch (error) {
          console.log(error)
        }
      })()
    }
  }, [confirmDelete])

  if (!pws) history.goBack()

  return (
    <>
      <ToolbarContent
        screenTitle="Cancel Subscription"
        isMainScreen={false}
      />
      <LabeledText label="Water System" value={pws.name} />
      <LabeledText label="Active Connections" value={pws.activeConnections} />
      <Button 
        className={classes.button}
        variant="contained"
        color="error"
        fullWidth
        disabled={cancelInProgress}
        onClick={async () => {
          setOpenDeleteDialog(true)
        }}
      >
        {cancelInProgress ? <CircularProgress color="inherit" size={16} style={{ marginRight: 5 }} /> : null}
        CANCEL SUBSCRIPTION
      </Button>
      <ConfirmDeleteDialog 
        open={openDeleteDialog} 
        setOpenDialog={setOpenDeleteDialog} 
        setConfirm={setConfirmDelete} 
        objectName='Subscription'
        fieldDescription='PWS name' 
        actionText="Cancel"
        confirmText="CONFIRM"
        cancelText="BACK"
        defaultValue={pws.name}
      />
    </>
  )
}

export default withStyles(styles)(SubscriptionCancel) 