import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { styled } from '@material-ui/styles'

const commonStyle = {
  border: '1px solid black'
}

const cellStyle = {
  paddingLeft: 8,
  paddingRight: 8,
}

const Table = styled('table')({
  ...commonStyle
})

const TBody = styled('tbody')({
  ...commonStyle
})

const Tr = styled('tr')({
  ...commonStyle
})

const Td = styled('td')({ 
  ...commonStyle, 
  ...cellStyle 
})

const Th = styled('th')({ 
  ...commonStyle, 
  ...cellStyle 
})

function WaterLossTable(props) {
  const {
    pwsName,
    timePeriod,
    unit,
    sourceInput,
    totalMeteredUsage,
    lossVolume,
    UARL,
    realWaterLoss,
    apparentWaterLoss,
    nonRevenueWater,
    realWaterLossCost,
    apparentWaterLossCost,
    ILI,
    realLossPerService,
    percentLoss,
  } = props
  
  return (
    <>
      <Grid style={{ overflowX: 'auto' }}>
        <Typography component="article" gutterBottom>
          <Table>
            <TBody>
              <Tr>
                <Th>{pwsName}</Th>
                <Th>{timePeriod}</Th>
              </Tr>
              <Tr>
                <Td>Source Input</Td>
                <Td>{sourceInput} {unit}</Td>
              </Tr>
              <Tr>
                <Td>Total Metered Usage</Td>
                <Td>{totalMeteredUsage} {unit}</Td>
              </Tr>
              <Tr>
                <Td>Loss Volume</Td>
                <Td>{lossVolume} {unit}</Td>
              </Tr>
              <Tr>
                <Td>UARL<sup>1</sup></Td>
                <Td>{UARL} {unit}</Td>
              </Tr>
              <Tr>
                <Td>Real Loss (CARL)<sup>2</sup></Td>
                <Td>{realWaterLoss} {unit}</Td>
              </Tr>
              <Tr>
                <Td>Apparent Loss<sup>3</sup></Td>
                <Td>{apparentWaterLoss} {unit}</Td>
              </Tr>
              <Tr>
                <Td>Non-Revenue Water</Td>
                <Td>{nonRevenueWater} {unit}</Td>
              </Tr>
              <Tr>
                <Td>Real Water Loss $$</Td>
                <Td>${realWaterLossCost}</Td>
              </Tr>
              <Tr>
                <Td>Apparent Water Loss $$ @ retail cost<sup>3</sup></Td>
                <Td>${apparentWaterLossCost}</Td>
              </Tr>
              <Tr>
                <Td>ILI<sup>4</sup></Td>
                <Td>{ILI}</Td>
              </Tr>
              <Tr>
                <Td>Real Loss per service connection per day</Td>
                <Td>{realLossPerService} Gallon</Td>
              </Tr>
              <Tr>
                <Td>Percent Loss</Td>
                <Td>{percentLoss}%</Td>
              </Tr>
            </TBody>
          </Table>
          <Typography variant="caption" style={{ paddingTop: 10 }}>
            <ul style={{ listStyle: 'none' }}>
              <li>
                <sup>1</sup>UARL gal=(5.41Lm + 0.15Nc + 7.5Lc) x P x D
              </li>
              <li>
                <sup>2</sup>Production Cost @ “$Production Cost”/”Units”
              </li>
              <li>
                <sup>3</sup>Apparent Loss @ “Retail Cost”/”Units” + Apparent Loss @ Production Cost
              </li>
              <li>
                <sup>4</sup>ILI = CARL/UARL
              </li>
            </ul>
          </Typography>
        </Typography>
      </Grid>
    </>
  )
}

WaterLossTable.propTypes = {
  pwsName: PropTypes.string.isRequired,
  timePeriod: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  sourceInput: PropTypes.string,
  totalMeteredUsage: PropTypes.string,
  lossVolume: PropTypes.string,
  UARL: PropTypes.string,
  realWaterLoss: PropTypes.string,
  apparentWaterLoss: PropTypes.string,
  nonRevenueWater: PropTypes.string,
  realWaterLossCost: PropTypes.string,
  apparentWaterLossCost: PropTypes.string,
  ILI: PropTypes.string,
  realLossPerService: PropTypes.string,
  percentLoss: PropTypes.string,
}

export default WaterLossTable