import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'

import * as queries from 'graphql/queries'
import ToolbarContent from 'components/ToolbarContent'

const styles = () => ({
  button: {
    margin: 'auto',
  },
})

class RequestSent extends Component {
  state = {
    joinRequest: null,
    organization: null,
  }

  async componentDidMount() {
    const joinRequestData = await API.graphql(graphqlOperation(queries.getJoinRequest, {id: this.props.match.params.reqid}))
    if(joinRequestData) {
      const joinRequest = joinRequestData.data.getJoinRequest 
      const organizationData = await API.graphql(graphqlOperation(queries.getOrganization, {id: joinRequest.organizationId}))
      const organization = organizationData.data.getOrganization
      this.setState({ joinRequest, organization })
    }
  }

  render() {
    const { classes, } = this.props
    const { joinRequest, organization } = this.state

    if(!joinRequest) {
      return (
        <>
          <ToolbarContent
            screenTitle = "Checking"
            isMainScreen = {false}
            renderIcon = {false}
          />
          <p>Getting request details</p>
        </>
      ) 
    }

    return (
      <>
        <ToolbarContent
          screenTitle = "Request Sent"
          isMainScreen = {false}
        />
        <Grid item xs={12} sm={6}>
          <Typography color="secondary" variant="h6" gutterBottom>
            Your request has been sent
          </Typography>
          <Typography paragraph>
            It could take some time for a user from <b>{organization.name}</b> to approve your request.
          </Typography>
          <Typography paragraph>
            Please be patient, and possibly call someone you know there to alert them about your request if it takes too long.
          </Typography>
          <Button 
            className={classes.button} 
            variant="contained" 
            color="primary"
            fullWidth
            component={Link}
            to="/"
          >
            GO TO DASHBOARD
          </Button>
        </Grid>
      </>
    )
  }
}

export default withStyles(styles)(RequestSent)