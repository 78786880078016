import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from '@material-ui/styles'

import NumberField from 'components/NumberField'
import { volumeUnitsList, volumeUnitShort } from 'services/constantVariables/units'

const useStyles = makeStyles({
  customLabel: {
    fontSize: '.85rem',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'block',
  },
  value: {
    fontSize: '0.875rem',
    textAlign: 'right'
  },
  menu: {
    fontSize: '0.875rem',
    textAlign: 'right'
  },
  select: {
    paddingBottom: 9
  }
})

function LabeledNumberUnitField(props) {
  const classes = useStyles()
  const {
    label,
    startAdornment,
    endAdornment,
    value: initialValue,
    onValueChange,
    unit: initialUnit,
    onUnitChange,
    disabled,
    per,
    precision,
  } = props

  const [value, setValue] = useState(initialValue)
  const [unit, setUnit] = useState(initialUnit)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    setUnit(initialUnit)
  }, [initialUnit])

  useEffect(() => {
    onValueChange(value)
  }, [value])

  useEffect(() => {
    onUnitChange(unit)
  }, [unit])

  return (
    <Grid container justify="space-between" spacing={16}>
      <Grid item xs={4} md={7}>
        <Typography
          variant="caption"
          component={() => (
            <span className={classes.customLabel}>
              {label}
            </span>
          )}
        />
      </Grid>
      <Grid container item direction="row" justify="flex-end" xs={8} md={5} spacing={8}>
        <Grid item xs={7} sm={9} container justify="flex-end">
          <NumberField
            margin="none"
            value={value}
            precision={precision}
            disabled={disabled}
            onChange={v => setValue(v)}
            InputProps={{
              inputProps: {
                className: classes.value
              },
              endAdornment: endAdornment ? (
                <InputAdornment 
                  style={{ whiteSpace: 'nowrap' }} 
                  position="end"
                >
                  {endAdornment}
                </InputAdornment>
              ) : null,
              startAdornment: startAdornment ? (
                <InputAdornment 
                  style={{ whiteSpace: 'nowrap' }} 
                  position="start"
                >
                  {startAdornment}
                </InputAdornment>
              ) : null,
            }}
          />  
        </Grid>
        <Grid item container direction="column" xs={5} sm={3}>
          <TextField
            id="unit" 
            className={classes.value} 
            value={unit}
            fullWidth 
            onChange={(e) => setUnit(e.target.value)} 
            margin="none" 
            disabled={disabled}
            select
            InputProps={{
              classes: {
                root: classes.menu
              }
            }}
            SelectProps={{
              native: true,
              classes: {
                select: classes.select
              },
              MenuProps: {
                className: classes.value,
              },
            }} 
          >
            {volumeUnitsList.map((option, i) => (
              <option key={i} value={option}>
                {per ? '/ ' : null}{volumeUnitShort[option]}
              </option>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </Grid>
  )
}

LabeledNumberUnitField.defaultProps = {
  onValueChange: () => {},
  onUnitChange: () => {},
  disabled: false,
  per: false,
  precision: null,
}

LabeledNumberUnitField.propTypes = {
  label: PropTypes.string,
  startAdornment: PropTypes.string,
  endAdornment: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onValueChange: PropTypes.func,
  unit: PropTypes.string,
  onUnitChange: PropTypes.func,
  disabled: PropTypes.bool,
  per: PropTypes.bool,
  precision: PropTypes.number,
}

export default LabeledNumberUnitField